import { Button, DialogActions, Typography } from '@mui/material';
import { useState } from 'react';
import api from '../../services/api';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MessageSuccess from '../../messages/messageSuccess';
import MessageError from '../../messages/messageError';
import LoadingShort from '../../load/loadingShort';
import FichaDirectorTecnico from './fichaDirectorTecnico';
import FormCadastroDirectorTecnico from '../departamentos/dlif/licenciamento/cadastroEstabelecimento/stepsFormularios/stepCadastroDirectorTecnico';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const ChangeDirectorTecnico = (props) => {
    const userId = useSelector(state => state.account.user?._id);
    const directorTecnico = useSelector(state => state.dadosdirectorTecnico?.data)
    const [open, setOpen] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleChange = () => {
        setMessageSuccess('')
        setMessageError('')
        setOpen(true)

        api.put('/estabelecimento/edit', {
            'estabelecimentoId': props.estabelecimento._id,
            'directorTecnicoId': directorTecnico?._id,
            userId
        })
            .then(response => {
                // console.log(response) 
                setOpen(false)
                setMessageSuccess('Mudança feita com Sucesso.')

                setTimeout(() => {
                    // navigate(-1)
                    window.location.reload()
                }, 5000);

            }).catch(err => {
                // console.log(err)
                setOpen(false)
                setMessageError('Erro ao salvar')
            })
    }

    return (
        <>
            <BootstrapDialog
                scroll='body'
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <div style={{ padding: 20 }}>

                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {messageError && <MessageError message={messageError} />}
                    <Typography>
                        <strong>DIRECTOR TÉCNICO ACTUAL</strong>
                    </Typography>

                    <FichaDirectorTecnico directorTecnico={props.estabelecimento?.directorTecnico} />

                    <Typography >
                        <strong> NOVO DIRECTOR TÉCNICO </strong>
                    </Typography>

                    <FormCadastroDirectorTecnico changeDirectorTecnico={true} />

                    {/* {!messageSuccess && */}
                    <DialogActions>
                        {/* <Button title='Cancelar' onClick={() => props.setOpen(false)} size="small">Cancelar</Button> */}
                        <Button disabled={!directorTecnico} onClick={handleChange} size="small">
                            {open ? <LoadingShort /> : 'Salvar'}
                        </Button>
                    </DialogActions>
                    {/* } */}
                </div>
            </BootstrapDialog>
        </>
    );
}

export default ChangeDirectorTecnico;
