import { Box, Container } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import CardOpcoes from '../../utils/cardOpcoes';
import HeaderSession from '../../utils/headerSession';
import RegisterAccess from '../../utils/registerAccess';

const useStyles = makeStyles((theme) => ({

    containerCardesService: {
        padding: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        //  flexDirection: 'wrap',
        /* ALINHAR OS ITENS NA HORIZONTAL E NA VERTIVACL */
        justifyContent: 'center',
        alignItems: 'center',
        /* AFASTAR UM ITEM DO OUTRO */
        gap: '1rem',
    }

}))

export default function Farmacovigilancia() {
    const classes = useStyles()
    return (
        <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
            <RegisterAccess page={'farmacovigilancia'} />
            <HeaderSession title='FARMACOVIGILÂNCIA' />

            <Box className={classes.containerCardesService}>
                <CardOpcoes nomeService={'Ensaios Clínicos'} link={'/ensaios-clinicos'} />
                <CardOpcoes nomeService={'Reações Adversas'} link={'/reacoes-adversas'} />
            </Box>
        </Container>

    )
}
