
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import LoadingShort from "../../load/loadingShort";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center',
        width: '17%',
        height: 200,
        minWidth: 130,
        // backgroundColor: '#fff',
        border: '1px solid #85287e',
        borderRadius: 10,
        transition: '.5s',
        '&:hover': { background: '#85287d8a', },

        [theme.breakpoints.down('xs')]: { width: '100%' },
    }
}))

type cardServicesProps = {
    icone?: string;
    nomeService: string;
    link: string;
    count: number | null;
    load?: boolean;
}

export default function CardServices({ icone, nomeService, link, count, load }: cardServicesProps) {
    const classes = useStyles()

    return (
        <CardActionArea className={classes.root} >
            <Link style={{ textDecoration: 'none' }} to={link}>
                <CardContent>
                    <div style={{ height: 80 }}>
                        <img src={icone} alt={''} style={{ height: 100 }} />
                    </div>
                </CardContent>

                <CardContent style={{ padding: 8 }}>
                    <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1.3, fontSize: 16 }}>
                        {nomeService}
                    </Typography>
                </CardContent>

                <br />
                <CardContent style={{ padding: 0, position: 'absolute', bottom: 0, right: '50%', left: '50%' }}>
                    {load ?
                        <LoadingShort text={''} />
                        :
                        <Typography color="initial" variant="subtitle1" style={{ fontSize: 20, fontWeight: 'bold' }}>
                            {count}
                        </Typography>
                    }
                </CardContent>
            </Link>
        </CardActionArea>
    )
}