import { Typography } from "@material-ui/core";
// import HeaderGestorConfigs from "../../../../utils/headerGestorConfigs";
// import { format, parseISO } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import ReenviarEmail from "../licenciamento/reenviarEmail";
import { Card, ListItemIcon, MenuItem } from "@mui/material";
import MessageSuccess from "../../../../messages/messageSuccess";


import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import Loading from "../../../../load/loading";


export default function RelatorioActividades({ estabelecimento }) {
    const [load, setLoad] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('');
    const [openReenviarEmail, setOpenReenviarEmail] = useState(false);
    const [novoEmail, setNovoEmail] = useState('');
    const [estabelecimentos, setEstabelecimentos] = useState('')


    useEffect(() => {
        setLoad(true)
        api.get(`/estabelecimentos?status=Activo`)
            .then(response => {
                // console.log(response)
                setEstabelecimentos(response.data.estabelecimentos);
                // setConfigLista(response.data);

                setLoad(false)
            }).catch(err => { setLoad(false) })
    }, []);


    const generatePDF = (action) => {
        setLoad(action)
        setMessageSuccess('')

        // Criar um novo Documentoumento PDF
        const pdf = new jsPDF('p', 'mm', 'a4');
        // Capa do Relatório com Bordas
        pdf.addImage('/img/logo/InsigniaAngola.png', 'PNG', 90, 25, 23, 23);
        pdf.setFontSize(12);
        pdf.text("REPÚBLICA DE ANGOLA", 105, 55, { align: "center" });
        pdf.text("MINISTÉRIO DA SAÚDE", 105, 60, { align: "center" });
        pdf.text("AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE", 105, 65, { align: "center" });
        pdf.setFontSize(16);
        pdf.text("RELATÓRIO DE ACTIVIDADES", 105, 150, { align: "center" });
        pdf.setFontSize(10);
        pdf.text("MARÇO 2024", 105, 270, { align: "center" });

        // Adicionando bordas na capa
        pdf.setLineWidth(1);
        pdf.rect(20, 15, 170, 270);  // Rect(x, y, width, height)
        pdf.addPage();

        // contraCapa do Relatório com Bordas
        pdf.addImage('/img/logo/InsigniaAngola.png', 'PNG', 90, 25, 23, 23);
        pdf.setFontSize(12);
        pdf.text("REPÚBLICA DE ANGOLA", 105, 55, { align: "center" });
        pdf.text("MINISTÉRIO DA SAÚDE", 105, 60, { align: "center" });
        pdf.text("AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIAS DE SAÚDE", 105, 65, { align: "center" });
        pdf.setFontSize(16);
        pdf.text("RELATÓRIO DE ACTIVIDADES", 105, 150, { align: "center" });
        pdf.setFontSize(10);
        pdf.text("LUANDA/2024", 105, 250, { align: "center" });
        pdf.text("LAPRESENTAÇÃO", 105, 270, { align: "center" });
        
        // Sub-título
        pdf.addPage();
        pdf.setFontSize(12);
        // Conteúdo
        pdf.setFontSize(10);
        pdf.text("DEPARTAMENTO DE LICENCIAMENTO E INSPECÇÃO FARMACÊUTICA", 20, 20);
        pdf.text("Durante o período em referência registou-se o licenciamento de entidades farmacêuticas assim como a renovação de Autorizações de exercício de Actividade Farmacêutica conforme espelha a Tabela nº 1.", 20, 30);

        // Adicionar Tabela 1
        const tabela1 = [
            ["TIPO DE DOCUMENTO", "JANEIRO", "FEVEREIRO", "MARÇO"],
            ["Autorizações de Exercício da Actividade Farmacêutica", "71", "23", "74"],
            ["Total de pdfumentos Emitidos", "71", "23", "74"]
        ];
        pdf.autoTable({
            startY: 40,
            head: [tabela1[0]],
            body: tabela1.slice(1),
        });

        // Adicionar Tabela 2
        const tabela2 = [
            ["JANEIRO", "FEVEREIRO", "MARÇO"],
            ["DEPÓSITOS", "24", "5", "13"],
            ["FARMÁCIAS", "47", "17", "58"],
            ["FÁBRICAS", "0", "1", "0"],
            ["Lojas de Equip.", "0", "0", "3"]
        ];
        pdf.autoTable({
            startY: pdf.autoTable.previous.finalY + 10,
            head: [["", "", ""]],
            body: tabela2,
        });

        // Adicionar outras tabelas conforme necessário, ajustando a posição de início
        const tabela3 = [
            ["MUNICÍPIO", "FARMÁCIAS", "IMPORTADORES", "DISTRIBUIDORES", "FABRICAS", "REPRESENTANTES DE LABORATÓRIOS", "LOJAS DE EQUIP.", "ERVANÁRIAS"],
            ["Talatona", "2", "0", "0", "0", "0", "0", "0"],
            ["Cacuaco", "0", "0", "1", "0", "0", "0", "0"],
            ["Belas", "0", "1", "0", "0", "0", "0", "0"],
            ["Cazenga", "0", "0", "0", "0", "0", "0", "0"],
            ["Icolo e Bengo", "0", "0", "0", "0", "0", "0", "0"],
            ["K. Kiaxi", "2", "1", "1", "0", "0", "2", "0"],
            ["Quissama", "0", "0", "0", "0", "0", "0", "0"],
            ["Viana", "5", "1", "1", "0", "0", "0", "0"],
            ["Luanda", "1", "1", "0", "0", "0", "1", "0"],
            ["Total", "10", "4", "3", "0", "0", "3", "0"]
        ];
        pdf.autoTable({
            startY: pdf.autoTable.previous.finalY + 10,
            head: [tabela3[0]],
            body: tabela3.slice(1),
        });




        if (action === 'print') {
            // imprimir PDF
            const pdfBlob = pdf.output('blob');
            // Criar um URL para o Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);
            // Abrir o PDF em uma nova aba
            window.open(pdfUrl, '_blank');
            setLoad(false)
            setMessageSuccess('Sucesso')
        }

        if (action === 'save') {
            // Salvar PDF
            pdf.save(`relatorio-de-actividades-${estabelecimento.nome}.pdf`);
            setLoad(false)
            setMessageSuccess('Sucesso')
        }


        if (action === 'Enviando_email') {
            const pdfBlob = pdf.output('blob');
            // Criar um objeto FormData para enviar o Blob como um arquivo

            const msg = {
                to: novoEmail ?? estabelecimento?.email,
                // from: process.env.SENDER_EMAIL!,
                subject: `Autorização-de-exercicio`,
                html:
                    `<h1>Estabelecimento '${estabelecimento?.nome}'</h1
                    <p>
                      A Solicitação de Licenciamento do Estabelecimento <b>${estabelecimento?.nome}</b> com 
                      Recibo nº ${estabelecimento?.numeroEntrada} foi aprovada, em anexo enviamos a Autorização-de-exercicio.
                    </p>
            
                    <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
                    `,
            };

            const formData = new FormData();
            formData.append('to', msg.to); //
            formData.append('subject', msg.subject); //
            formData.append('html', msg.html); //
            formData.append('attachment', pdfBlob, `relatorio-de-actividades-${estabelecimento?.nome}.pdf`); // O terceiro argumento é o nome do arquivo

            // for (let dado of formData.values()) { console.log(dado); }

            api.post('/email/send', formData)
                .then(response => {
                    // console.log(response)
                    setOpenReenviarEmail(false)
                    setLoad(false)
                    setMessageSuccess('E-mail enviado com Sucesso')
                }).catch(err => {
                    setOpenReenviarEmail(false)
                    // console.log(err) 
                    setLoad(false)
                })
        }
    };

    return (
        <Card style={{ padding: 20 }}>
            {/* <LoadingBackdrop open={load} text={load} /> */}
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {/* {messageError && <MessageError message={messageError} />} */}

            <ReenviarEmail
                estabelecimento={estabelecimento}
                setNovoEmail={setNovoEmail}
                generatePDF={generatePDF}
                openReenviarEmail={openReenviarEmail}
                setOpenReenviarEmail={setOpenReenviarEmail}
                load={load}
            />

            {/* <HeaderGestorConfigs
                menu={true}
                generatePDF={generatePDF}
                setOpenReenviarEmail={setOpenReenviarEmail}
                configArea={'autorizacaoExercio'}
                title={''}
            /> */}

            <Typography style={{ fontWeight: 'bold' }}>RELATÓRIO DE ACTIVIDADES</Typography>
            {estabelecimentos ?
                <div >
                    <MenuItem size='small' onClick={() => generatePDF('print')}> <ListItemIcon> <PrintIcon color='secondary' />  </ListItemIcon> Imprimir </MenuItem>
                    <MenuItem size='small' onClick={() => generatePDF('save')}> <ListItemIcon> <DownloadIcon color='secondary' />  </ListItemIcon>  Baixar PDF </MenuItem>
                    <MenuItem size='small' onClick={() => setOpenReenviarEmail(true)}> <ListItemIcon> <AttachEmailIcon color='secondary' />  </ListItemIcon>  Enviar por email </MenuItem>
                </div>
                :
                <Loading />
            }
        </Card>
    )
}