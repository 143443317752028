// import QRCode from 'qrcode.react';
import { QRCodeSVG } from 'qrcode.react';

function QRCode({ link }) {
    const dadosParaQRCode = link;
    
    // fgColor='#67348d'
    return <QRCodeSVG value={dadosParaQRCode} size={100}  includeMargin />

}

export default QRCode;
