import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/system';
import ServicesMenuControlGeral from '../../utils/ServicesMenuControlGeral';


const useStyles = makeStyles((theme) => ({

    containerCardesService: {
        // height: '60vh',
        padding: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center',
        gap: '1rem',
        padding: 0,
    }
}))

export default function MenuControlGeral() {
    const classes = useStyles();

    return (
        <Box className={classes.containerCardesService}>
            {/* <RegisterAccess page={'menu bastionario'} /> */}

            <ServicesMenuControlGeral
                provincia={'Luanda'}
                icone={'/icones/mapaProvinciasAngola/Luanda.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Bengo'}
                icone={'/icones/mapaProvinciasAngola/Bengo.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Benguela'}
                icone={'/icones/mapaProvinciasAngola/Benguela.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Bie'}
                icone={'/icones/mapaProvinciasAngola/Bie.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Cabinda'}
                icone={'/icones/mapaProvinciasAngola/Cabinda.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Cuando-cubango'}
                icone={'/icones/mapaProvinciasAngola/Cuando-cubango.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Cuanza-norte'}
                icone={'/icones/mapaProvinciasAngola/Cuanza-norte.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Cuanza-sul'}
                icone={'/icones/mapaProvinciasAngola/Cuanza-sul.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Cunene'}
                icone={'/icones/mapaProvinciasAngola/Cunene.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Huambo'}
                icone={'/icones/mapaProvinciasAngola/Huambo.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Huila'}
                icone={'/icones/mapaProvinciasAngola/Huila.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Lunda-norte'}
                icone={'/icones/mapaProvinciasAngola/Lunda-norte.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Lunda-sul'}
                icone={'/icones/mapaProvinciasAngola/Lunda-sul.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Namibe'}
                icone={'/icones/mapaProvinciasAngola/Namibe.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Malange'}
                icone={'/icones/mapaProvinciasAngola/Malange.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Moxico'}
                icone={'/icones/mapaProvinciasAngola/Moxico.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Uige'}
                icone={'/icones/mapaProvinciasAngola/Uige.svg'}
            />

            <ServicesMenuControlGeral
                provincia={'Zaire'}
                icone={'/icones/mapaProvinciasAngola/Zaire.svg'}
            />
        </Box>
    );
}





