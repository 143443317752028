import { Card, Grid, Typography } from "@mui/material";
import Loading from "../../load/loading";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
import { useState } from "react";
import DadosPessoalEdit from "../dadosGenericos/dadosPessoalEdit";
import DirectorTecnicoEdit from "../dadosGenericos/directorTecnicoEdit";
import MessageSuccess from "../../messages/messageSuccess";
import MessageError from "../../messages/messageError";
import FotoEdit from "../../utils/fotoEdit";
import Delete from "../../utils/delete";
import DirectorTecnicoCreateRepresentante from "./directorTecnicoCreateRepresentante";


export default function FichaDirectorTecnico({ directorTecnico, header, menu, setRefresh }) {
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [editDados, setEditDados] = useState(false)
    const [openEditFoto, setOpenEditFoto] = useState(false)
    const [dadosDirectorTecnico, setdirectorTecnico] = useState(directorTecnico)
    const [open, setOpen] = useState(false);
    const [openDTCR, setOpenDTCR] = useState(false);
    // console.log(directorTecnico)

    return (
        <>
            {directorTecnico ?

                <Card style={{ padding: 20, minHeight: '20vh', marginTop: 20 }}>
                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {messageError && <MessageError message={messageError} />}

                    <Delete
                        open={open}
                        setOpen={setOpen}
                        url={'/director-tecnico/delete'}
                        msg={`Tem certeza que deseja eliminar Director(a) Técnico(a) ${directorTecnico?.dadosPessoais?.nome}`}
                        id={directorTecnico._id}
                    />

                    <DirectorTecnicoCreateRepresentante
                        open={openDTCR}
                        setOpen={setOpenDTCR}
                        directorTecnico={directorTecnico}
                    />

                    {header &&
                        <HeaderGestorConfigs
                            menu={menu ?? true}
                            configArea={'fichaDT'}
                            title={editDados ? 'EDITAR DADOS' : 'FICHA DE DIRECTOR TÉCNICO'}
                            directorTecnico={directorTecnico}
                            editDados={editDados}
                            setEditDados={setEditDados}
                            directorTecnicoCreateRepresentante={setOpenDTCR}
                            delete={setOpen}
                        />
                    }

                    {!editDados &&

                        <Grid container>
                            <Grid item marginRight={2}>
                                <FotoEdit
                                    openEditFoto={openEditFoto}
                                    setOpenEditFoto={setOpenEditFoto}
                                    dadosPessoal={directorTecnico?.dadosPessoais}
                                />
                            </Grid>

                            <Grid xs item >
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nome:</strong> {directorTecnico?.dadosPessoais?.nome ?? '####'}</Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>B.I:</strong> {directorTecnico?.dadosPessoais?.numeroBI ?? '####'}</Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Carteira:</strong> {directorTecnico?.numeroCarteira ?? '####'}</Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Ordem:</strong> {directorTecnico?.ordem ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Categoria:</strong> {directorTecnico?.categoria?.designacao ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>E-mail:</strong> {directorTecnico?.dadosPessoais?.email ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Telefone1:</strong> {directorTecnico?.dadosPessoais?.tel1 ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Telefone2:</strong> {directorTecnico?.dadosPessoais?.tel2 ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Rua:</strong> {directorTecnico?.dadosPessoais?.rua ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Bairro:</strong> {directorTecnico?.dadosPessoais?.bairro ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Município:</strong> {directorTecnico?.dadosPessoais?.municipio ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Provincia:</strong> {directorTecnico?.dadosPessoais?.provincia ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nacionalidade:</strong> {directorTecnico?.dadosPessoais?.nacionalidade ?? '####'} </Typography>
                            </Grid>
                        </Grid>
                    }

                    {(editDados === 'dadospessoaledit' || editDados === 'all') &&
                        <DadosPessoalEdit
                            dadosPessoais={directorTecnico.dadosPessoais}
                            setDadosPessoais={setdirectorTecnico}
                            cancelEditDados={setEditDados}
                            setRefresh={setRefresh}
                        />
                    }

                    {(editDados === 'dadosdirectortecnicoedit' || editDados === 'all') &&
                        <DirectorTecnicoEdit
                            dadosDirectorTecnico={directorTecnico}
                            setDadosDirectorTecnico={setdirectorTecnico}
                            cancelEditDados={setEditDados}
                            setMessageSuccess={setMessageSuccess}
                            setMessageError={setMessageError}
                            setRefresh={setRefresh}
                        />
                    }
                </Card>
                :
                <Loading />
            }
        </>

    )
}