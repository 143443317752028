import { Box, Container } from '@mui/material'
import RedesSociasFooter from './redesSociais/redesSociasFooter'
// import DocsProcurados from '../pages/home/docsProcurados'

export default function Footer() {

    return (
        <div style={{ background: '#d8dde5', minHeight: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 10, padding: 10 }}>

                <Box>
                    <Box style={{ display: 'flex' }}>
                        <img src="/img/logo/logoMinsa.svg" alt="" width={170} style={{ marginRight: 20 }} />
                        <img src="/img/logo/logoArmed.svg" alt="" width={130} />
                    </Box>
                    <br />
                    {/* <Divider />
                    <DocsProcurados /> */}
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />

                <Box >
                    <RedesSociasFooter />
                </Box>
            </Container>
        </div>
    )
}
