import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import { Card, Container } from "@material-ui/core";
// import HeaderGestorConfigs from '../../utils/headerGestorConfigs';
import printerService from '../../services/printer';
import PrintIcon from '@mui/icons-material/Print';
import { IconButton, MenuItem, TextField } from "@mui/material";

// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import Loading from '../../load/loading';
import RegisterAccess from '../../utils/registerAccess';

const CentralImpressaoQRcode = () => {
    const [estabelecimentos, setEstabelecimentos] = useState('')
    const [autorizacoes, setAutorizacoes] = useState('')
    const [opcao, setOpcao] = useState('Geral')
    const [load, setLoad] = useState(false)

    const [total, setTotal] = useState('')
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(8)
    const baseUrl = window.location.origin;


    useEffect(() => {
        setLoad(true)
        setAutorizacoes(null)

        opcao === 'Geral' &&
            api.get(`/estabelecimentos?page=${page}&perPage=${perPage}`)
                .then(response => {
                    // console.log(response)
                    setLoad(false)
                    setEstabelecimentos(response.data.estabelecimentos)
                    setTotal(response.data.totalItems)
                }).catch(error => setLoad(false))

        opcao === 'AutorizacoesEmitidas' &&
            api.get(`/autorizacaos?page=${page}&perPage=${perPage}`)
                .then(response => {
                    // console.log(response)
                    setLoad(false)
                    setAutorizacoes(response.data.autorizacaos);
                    setTotal(response.data.totalItems)
                }).catch(err => setLoad(false))
    }, [page, perPage])


    const getEstabelecimentosAutorizados = () => {
        setEstabelecimentos(null)
        setLoad(true)

        api.get(`/autorizacaos?page=${page}&perPage=${perPage}`)
            .then(response => {
                // console.log(response)
                setLoad(false)
                setAutorizacoes(response.data.autorizacaos);
                setTotal(response.data.totalItems)
            }).catch(err => setLoad(false))
    }

    const exportToPDF = () => {
        printerService.printerQrcode('centralqrcode')

    }

    const generatePDF = () => {
        // html2canvas(document.getElementById('centralqrcode')).then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     // const pdf = new jsPDF('p', 'mm', 'a4');
        //     const pdf = new jsPDF();
        //     pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
        //     pdf.save('declaração.pdf');
        // });
    };

    const HandlePrint = () => {
        var header = document.getElementById('header');
        header.style = "display: none"
        window.print()
        header.style = "display:  "
    }


    return (
        <Container style={{ padding: 10, marginTop: 20, width: '23cm' }}>
            <RegisterAccess page={'central de impressão de qrCode'} />
            {/* <HeaderGestorConfigs
                title={'CENTAL DE IMPRESSÃO DE QR CODE'}
                configArea={'centralqrcode'}
            exportToPDF={exportToPDF}
            /> */}

            <div id='header'>
                {/* SELECT OPCOES DE IMPRESSOES */}
                <TextField
                    fullWidth
                    type="text"
                    select
                    size="small"
                    variant="outlined"
                    defaultValue={'Geral'}
                >
                    <MenuItem onClick={() => { setPerPage(10); setOpcao('Geral') }} value='Geral'>
                        Geral
                    </MenuItem>
                    <MenuItem onClick={() => { getEstabelecimentosAutorizados(); setOpcao('AutorizacoesEmitidas') }} value='Estabelecimentos com Autorizações de Exercicio Emitidas'>
                        Estabelecimentos com Autorizações de Exercicio Emitidas
                    </MenuItem>
                </TextField>

                <Card style={{ padding: 20, marginBottom: 20, display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}>
                        <IconButton style={{ margin: 10 }} onClick={() => HandlePrint()}> <PrintIcon style={{ fontSize: 30 }} /></IconButton>
                        <input style={{ padding: 5 }} placeholder='Quantidade' type="number" onChange={(e) => setPerPage(e.target.value)} />
                        <input style={{ padding: 5, marginLeft: 10 }} placeholder='Pagina' type="number" onChange={(e) => setPage(e.target.value)} />
                    </div>

                    <p>Total: {total} </p>
                </Card>
            </div>
            <br />

            <div id='centralqrcode' style={{ width: '21cm', display: 'flex', flexWrap: 'wrap', gap: '2rem', marginLeft: 'auto', marginRight: 'auto' }}>

                {/* PARA OBETER TODOS ESTABELECIMENTO DO SISTEMA E GERAR OS SEUS QRCODE */}
                {estabelecimentos &&
                    estabelecimentos.map((estabelecimento) => (
                        <div style={{ textAlign: 'center', border: '1px solid', borderRadius: 5, width: '10cm', height: '15.5cm' }}>

                            <QRCodeSVG value={`${baseUrl}/verifica-estabelecimento/${estabelecimento._id}`} size={350} includeMargin />
                            <br />
                            <img src="/img/logo/logoArmed.svg" alt="ARMED" style={{ width: 270, margin: 20 }} />

                            <br />
                            <p style={{ margin: 5 }}>www.armed.gov.ao</p>
                            <p style={{ color: '#e8e8e8' }}>{estabelecimento?.nome.toUpperCase()} </p>
                        </div>
                    ))}


                {/* PARA OBETER TODOS ESTABELECIMENTO DO SISTEMA COM AUTORIZAO DE EXERCICIO EMITIDA E GERAR OS SEUS QRCODE */}
                {autorizacoes &&
                    autorizacoes.map((autorizacao) => (
                        <div style={{ textAlign: 'center', border: '1px solid', borderRadius: 5, width: '10cm', height: '15.5cm' }}>

                            <QRCodeSVG value={`${baseUrl}/verifica-estabelecimento/${autorizacao?.estabelecimento._id}`} size={350} includeMargin />
                            <br />
                            <img src="/img/logo/logoArmed.svg" alt="ARMED" style={{ width: 270, margin: 20 }} />

                            <br />
                            <p style={{ margin: 5 }}>www.armed.gov.ao</p>
                            <p style={{ color: '#e8e8e8' }}>{autorizacao?.estabelecimento?.nome.toUpperCase()} </p>
                        </div>
                    ))}
                <p>Quantidade: {perPage}</p>
                <p>Página: {page} </p>
            </div>


            {load && <Loading />}

        </Container>
    );
}

export default CentralImpressaoQRcode;
