import { Container } from '@mui/material'
import HeaderSession from '../../../../utils/headerSession'
import ListaVazia from '../../../../utils/listaVazia'

export default function DesalfandegarMedicamentosOutrosFarmaceuticos() {
    return (
        <Container style={{ minHeight: '70vh', maxWidth: 800 }}>

            <HeaderSession title='DESALFANDEGAR MECAMETOS E OUTRO PRODUTOS FARMACEUTICOS' />
            <ListaVazia text={'SERVIÇO INDISPONÍVEL'}/>
        </Container>
    )
}
 