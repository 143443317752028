import styled from "styled-components";

export const Container = styled.div`
justify-content: space-around;
  `;
export const Container2 = styled.div`
    max-width: 1110px;
  width: 98%;
  margin: 0 auto;
  `;

export const Header = styled.h1`display: flex;`;

export const Title = styled.div`
  color: ${(props) => props.color || '#fff'};
`;

export const Calendario = styled.div`
display: 'flex'; 
justify-content: 'end'; 
gap:'1rem';
`;
