
import { Container } from "@material-ui/core";
import HeaderSession from "../../utils/headerSession";
import RegisterAccess from "../../utils/registerAccess";
// import NavLateral from "./navLateral";

export default function GestDirectorTecnico({ element: component, ...rest }) {
    // const classes = useStyles()

    return (
        <Container>
           <RegisterAccess page={'gestão de director Técnico'}/>
            <HeaderSession title={'GESTÃO DE DIRECTORES TÉCNICOS'} />

                        {component}

        </Container>
    )
}