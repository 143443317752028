
import { useState } from "react";
import { Avatar, Box, Button, Dialog, DialogActions, Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import api from "../services/api";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import MessageAlert from "../messages/messageAlert";
import LoadingShort from "../load/loadingShort";

const tamanhoImage = 270;

const useStyles = makeStyles({
    avatarContainer: {
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        borderRadius: 5,
        transition: '.3s',
        '&:hover': {
            opacity: 0.5
        },
    },
})

function SimpleDialog(props) {
    const { onClose, open, submitEdit, setEdit, edit, classes, Foto, openLoad, messageSuccess, messageError } = props;

    const handleClose = () => { onClose(false); };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <div style={{ fontFamily: 'Work Sans', lineHeight: 1.5, padding: 10 }} align="center" >

                {messageSuccess && <MessageAlert type={'success'} message={messageSuccess} />}
                {messageError && <MessageAlert type={'error'} message={messageError} />}

                <Box className={classes.avatarContainer}>
                    <div>
                        {edit ?
                            <Avatar style={{ width: tamanhoImage, height: tamanhoImage }} variant="square" src={URL.createObjectURL(edit)} title="Clique para carregar uma imagem" />
                            :
                            <Avatar style={{ width: tamanhoImage, height: tamanhoImage }} variant="square" src={Foto} title="Clique para carregar uma imagem" />
                        }
                    </div>
                    <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="foto" style={{ display: 'none' }} onChange={(e) => setEdit(e.target.files[0])} />
                </Box>

                {(edit && !openLoad) ?
                    <DialogActions>
                        <Button size="small" color="error" onClick={() => setEdit('')}>cancelar </Button>
                        <Button variant="contained" size="small" color="success" onClick={submitEdit}>Salvar </Button>
                    </DialogActions>
                    :
                    <Button autoFocus style={{ marginRight: 8 }} size="small" >
                        <label htmlFor="foto" style={{ cursor: 'pointer', textAlign: 'center' }}> Carregar imagem</label>
                    </Button>
                }

                {openLoad && <LoadingShort text={'A Salvar'} />}
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function FotoEdit({ dadosPessoal, openEditFoto, setOpenEditFoto }) {
    const classes = useStyles()
    const [Foto, setFoto] = useState(dadosPessoal?.fotoURL)
    const [edit, setEdit] = useState('')
    const [openLoad, setOpenLoad] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const user = useSelector(state => state.account.user);

    const handleClose = () => {
        setOpenEditFoto && setOpenEditFoto(false);
        setEdit('')
    };

    const submitEdit = async () => {
        setOpenLoad(true)
        setMessageSuccess('')
        setMessageError('')

        const formData = new FormData();
        formData.append('foto', edit);
        formData.append('dadosPessoaisId', dadosPessoal._id);
        formData.append('userId', user?._id ?? '');

        await api.patch('/dados-pessoais/change-foto', formData)
            .then(res => {
                // setOpenEditFoto(false);
                setOpenLoad(false)
                setFoto(res.data.dadosPessoais.fotoURL)
                setEdit('')
                setMessageSuccess('Sucesso.')
                // console.log(res)

            }).catch(err => {
                // console.log(err)
                setOpenLoad(false)
                setMessageError('Não foi possivel realizar esta ação.')
            })
    }

    return (
        <>
            <SimpleDialog
                classes={classes}
                Foto={Foto}
                open={openEditFoto}
                openLoad={openLoad}
                onClose={handleClose}
                submitEdit={submitEdit}
                edit={edit}
                setEdit={setEdit}
                messageSuccess={messageSuccess}
                messageError={messageError}
            />

            {/* user?.isAdmin &&  */}
            <Tooltip title={dadosPessoal?.nome}>
                <Box onClick={() =>  setOpenEditFoto && setOpenEditFoto(true)} className={classes.avatarContainer}>
                    {edit
                        ?
                        <Avatar style={{ width: tamanhoImage, height: tamanhoImage }} className={classes.avatar} variant="square" src={URL.createObjectURL(edit)} title="Clique para carregar uma imagem" />
                        :
                        <Avatar style={{ width: tamanhoImage, height: tamanhoImage, borderRadius: 5 }} className={classes.avatar} variant="square" src={Foto} title={user?.tipo === 'Admin' ? "Clique para carregar uma imagem" : ''} />
                    }
                </Box>
            </Tooltip>
        </>
    )
}