import { useEffect, useState } from 'react'
import api from '../../../../../services/api';
import ReciboRenovacao from './reciboRenovacao';
import { Container } from '@mui/material';
import Loading from '../../../../../load/loading';
import { useParams } from 'react-router-dom';

export default function ExportRecibo() {
    const [solicitacaoRenovacao, setSolicitacaoRenovacao] = useState('')
    const { id } = useParams()

    useEffect(() => {
        api.get(`/solicitacao-renovacao/${id}`)
            .then(response => {
                // console.log(response)
                setSolicitacaoRenovacao(response.data.solicitacaoRenovacao);
            }).catch(err => '')
    }, [id])

    return (
        <Container style={{minHeight:'100vh'}}>
            {solicitacaoRenovacao ?
                <ReciboRenovacao renovacao={solicitacaoRenovacao} />
                :
                <Loading />
            }
        </Container>
    )
}
