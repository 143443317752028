
import { makeStyles, Typography } from "@material-ui/core";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        width: '16%',
        minHeight: 150,
        minWidth: 100,
        // backgroundColor: '#fff',
        border: '1px solid #85287e',
        borderRadius: 10,
        transition: '.5s',
        // '&:hover': { background: '#b1b7ea' },

        [theme.breakpoints.down('xs')]: { width: '100%' },
    }
}))

// type cardServicesProps = {
//     icone?: string;
//     nomeService: string;
//     link: string;
// }

export default function CardDepartamentos({ icone, nomeService, fase }) {
    const classes = useStyles()
    const { status } = useParams()

    return (
        <CardActionArea
            style={fase === status ? { background: '#b1b7ea', transition: '.5s', transform: 'scale(1.2)' } : null}
            className={classes.root}>
            <CardContent>
                <img src={icone} alt={''} style={{ height: 70 }} />
            </CardContent>

            <CardContent style={{ padding: 5 }}>
                <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1, fontSize: 15 }}>
                    {nomeService}
                </Typography>
            </CardContent>
        </CardActionArea>
    )
}