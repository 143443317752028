import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../utils/headerSession'
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 50,
        // textAlign: 'center',
        color:'#67348d'
    },
}))

export default function ReacoesAdversas() {
    const classes = useStyles()

    return (     
       <Container style={{ maxWidth: 800 }}>
        <RegisterAccess page={'reações adversas'} />
            <HeaderSession title='REAÇÕES ADVERSAS' />

                <div>
                    <p className={classes.root} >
                        <strong> Notificação de Reacções Adversas:</strong> é uma actividade de Saúde Pública, cujo objectivo é a
                        identificação, avaliação e prevenção dos riscos do uso dos medicamentos e outros produtos de
                        saúde.
                    </p>
                    <h1 className={classes.title}>
                        Ferramentas utilizadas na monitorização das notificações de
                        reacções adversas a medicamentos (RAM) e de produtos sem
                        qualidade.
                    </h1>
                    <br />
                    <p className={classes.root} >
                        <ul>
                            <li>
                                Ficha de notificação de reaçcões adversas utilizada em Angola
                                {/* <a href="/#"><small>Ver ficha</small></a> */}
                            </li>
                            <li>
                                Ficha de notificação de produtos sem qualidade
                                {/* <a href="/#"><small>Ver ficha</small></a> */}
                            </li>
                        </ul>
                    </p>
                </div>

                <div>
                    <h1 className={classes.title}>
                        Importância da notificação das Reacções Adversas.
                    </h1>
                    <br />
                    <p className={classes.root} >
                        A notificação de suspeitas de reacções adversas a medicamentos pelos profissionais de saúde,
                        permite a detecção de sinais associados à segurança dos medicamentos, podendo conduzir a:
                    </p>
                    <p className={classes.root} >
                        <ul style={{ marginLeft: 35 }}>
                            <li>
                                Detecção de novas reacções adversas medicamentosas.
                            </li>
                            <li>
                                Alteração da frequência de reacções adversas já conhecidas.
                            </li>
                            <li>
                                Identificação de novas interacções medicamentosas.
                            </li>
                            <li>
                                Questões associadas ao modo de utilização do medicamento.
                            </li>
                            <li>
                                Melhor conhecimento do perfil do medicamento.
                            </li>
                        </ul>
                    </p>
                    <p className={classes.root} >
                        Podem existir reacções adversas, por exemplo raras ou de aparecimento tardio, que não são
                        detectadas durante a fase experimental do desenvolvimento do medicamento. Estas reacções
                        adversas poderão ser identificadas através da notificação espontânea, com a utilização mais
                        alargada do medicamento.

                    </p>
                </div>

                <div>
                    <h1 className={classes.title}>
                        O que notificar
                    </h1>
                    <br />
                    <p className={classes.root} >
                        Todas as suspeitas de reacções adversas a medicamentos (RAM) e Evento Adverso Pós Vacinação
                        (EAPV), obtidas durante as práticas clínicas habituais.
                    </p>
                </div>

                <div>
                    <h1 className={classes.title}>
                        Como notificar
                    </h1>
                    <br />
                    <p className={classes.root} >
                        Para uma notificação ser válida, é necessário fornecer 4 informações:
                    </p>
                    <p className={classes.root} >
                        <ul style={{ marginLeft: 35 }}>
                            <li>
                                Os dados do doente (como iniciais, idade ou sexo).
                            </li>
                            <li>
                                Os medicamentos suspeitos de terem causado a RAM.
                            </li>
                            <li>
                                As reacções adversas observadas.
                            </li>
                            <li>
                                Os meios de contacto do notificador da RAM.
                            </li>
                        </ul>
                    </p>
                    <p className={classes.root} >
                        No entanto, para facilitar a avaliação do caso, a informação fornecida deve conter o maior número
                        de dados possíveis. Importa referir que os dados pessoais merecerão um tratamento confidencial.

                    </p>
                </div>

                <div>
                    <h1 className={classes.title}>
                        Como preencher a ficha de notificações e reacções adversas.
                    </h1>
                    <br />
                    <p className={classes.root} >
                        A Ficha de notificação de Reacções Adversas à medicamentos  possui 5 partes:
                    </p>
                    <p className={classes.root} >
                        <ul style={{ marginLeft: 35 }}>
                            <li>
                                A primeira parte, destina-se para identificar a Unidade Sanitária,
                                o tipo de serviço bem como a sua localização (informação inicial e complementar).
                            </li>
                            <li>
                                A segunda parte, permite identificar o paciente.
                            </li>
                            <li>
                                A terceira parte, destina-se a retratar a situação relacionada com os medicamentos.
                            </li>
                            <li>
                                A Quarta parte, destina se para descrever de forma breve os efeitos indesejáveis que foram produzidos pelo medicamento.
                            </li>
                            <li>
                                A quinta parte, serve para identificar o notificador quer seja profissional de saúde ou não.
                            </li>
                        </ul>
                    </p>
                </div>

                <div>
                    <h1 className={classes.title}>
                        Garantia de confidencialidade.
                    </h1>
                    <br />
                    <p className={classes.root} >
                        A notificação de RAM obriga ao envio e tratamento de alguns dados pessoais,
                        quer do doente quer do notificador (contacto). Só assim é possível validar a
                        notificação e/ou contactar o notificador se houver necessidade de esclarecimento
                        ou informação adicional. O tratamento desta informação cumpre os requisitos legais
                        em matéria de protecção de dados garantindo-se a sua segurança e confidencialidade.
                        A informação sobre os dados pessoais não é partilhada com entidades externas ao
                        Sistema Nacional de Farmacovigilância.
                    </p>
                </div>

                <div>
                    <h1 className={classes.title}>
                        Para onde enviar.
                    </h1>
                    <br />
                    <p className={classes.root} >
                        Em caso de dificuldades na submissão de uma notificação através do Portal RAM,
                        deve contactar o Departamento de Farmacovigilância através do endereço de
                        email: <a href="/#">farmacovigilanciangola@minsa.gov.ao</a>  ou através do nº de  telefone: 936123282.
                        Poderá ainda notificar em papel, imprimindo e preenchendo a respectiva ficha de notificação  
                         {/* <a href="/#"><small>Ver ficha</small></a> */}
                         .
                    </p>
                </div>

                <br />
            </Container>
    )
}
