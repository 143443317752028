import { Container } from '@mui/material'

const ManualUsoGestor = () => {
    return (
        <Container>
            <br />
            <iframe
                id="pdfIframe"
                title={`PDF Viewer`}
                src={'/manualde-de-uso/ManualdeUsoGestorARMED01.pdf'}
                width="100%"
                height="800px"
                style={{ border: 'none' }}
            />
        </Container>
    );
}

export default ManualUsoGestor;
