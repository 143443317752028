import { Button, DialogActions } from '@mui/material'
import React from 'react'

export default function ButtonLeft({ title, funcao, disabled }) {
    return (
        <DialogActions>
            <Button disabled={disabled} style={{ backgroundColor: '#85287e' }} variant="contained" onClick={funcao}>{title}</Button>
        </DialogActions>
    )
}
