import { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

class GraficoHistorico extends Component {

  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: { categories: ['victorina Barros ', 'Miusa Veloso', 'Iracelma Costa', 'AlcidesAlmeida', 'Margarida Aniceto','Paulo Jamba', 'Jose Quicomba',  'Pombal Mayembe', 'Maria Simao', 'Jose Chocolate ', 'Etelvina Miguel', 'Avei SIlva'] }
      },
      series: [
        {
          name: "series-1",
          data: [props.victorinaBarros, props.miusaVeloso, props.iracelmaCosta, props.alcidesAlmeida, props.margaridaAniceto, props.pauloJamba , props.joseQuicomba, props.pombalMayembe, props.mariaSimao, props.joseChocolate, props.etelvinaMiguel, props.aveiSilva]
        }
      ]
    };
  }

  render() {
    return (
      <div>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          // width="240%"
          height={300}
        />
      </div>
    );
  }
}

export default GraficoHistorico;