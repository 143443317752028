import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../../../utils/headerSession'
import { Link } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: 20,
        // textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.7
    },
}))

export default function InfoRepresentanteLaboratorioFarmaceutico() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
            <HeaderSession title='REQUISITOS ADMINISTRATIVOS PARA REPRESENTANTES DE LABORATÓRIOS FARMACÊUTICOS' />

            <p className={classes.root} >
                <ol>
                    <li>
                        Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde incluindo o NIF ( <Link to={'/_REQUERIMENTO-PARA-LICENCIAMENTO'}>Ver modelo</Link> ).
                    </li>
                    <li>
                        Cópia colorida do documento de identificação válido do requerente
                    </li>
                    <li>
                        Croquis de localização do escritório
                    </li>
                    <li>
                        Copia colorida do Certificado de Investidor Privado (CRIP) emitido pela AIPEX (se aplicável).
                    </li>
                    <li>
                        Certidão de Registo Comercial
                    </li>
                    <li>
                        Procuração de representação emitida pelo fabricante, autenticada pelos serviços consulares de Angola no País de origem.
                    </li>
                    <li>
                        Portfolio dos produtos a comercializar em Angola.
                    </li>
                    <li>
                        Documentos do Director Técnico:
                    </li>
                </ol>
                <ul>
                    <li>
                        Fotocópia colorida e autenticada do Diploma;
                    </li>
                    <li>
                        Fotocópia colorida do BI actualizado;
                    </li>
                    <li>
                        Contrato de trabalho autenticado;
                    </li>
                    <li>
                        Declaração original da OFA;
                    </li>
                    <li>
                        Termo de responsabilidade autenticado;
                    </li>
                    <li>
                        Uma fotografia tipo passe.
                    </li>
                </ul>
            </p>

            <br />
        </Container>
    )
}
