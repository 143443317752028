// import React from 'react'
import FichaAdmin from '../../../gestAdmin/fichaAdmin'
import ListaVazia from '../../../../utils/listaVazia'
import DateHora from '../../../../utils/dateHora'

export default function UltimaIntervencao({ estabelecimento }) {
    // console.log(estabelecimento)

    return (
        estabelecimento?.statusBy ?
            <>
                <span style={{ marginLeft: 70 }}>
                    <DateHora date={estabelecimento.updatedAt} />
                </span>
                <FichaAdmin admin={estabelecimento?.statusBy} />
            </>
            :
            <ListaVazia text={'Nenhuma intervenção feita.'} />
    )
}
