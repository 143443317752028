
import { useState } from "react";
import { Typography, Card, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions } from "@mui/material";
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import LoadingBackdrop from "../../../../load/loadingBackdrop";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box } from "@mui/material";
import CardDepartamentos from "./cardAreas";
import FazerObservacoes from "../fazerObservacoes.";
import api from "../../../../services/api";
import * as yup from 'yup'; // VALIDACAO
import RegisterAccess from "../../../../utils/registerAccess";

const useStyles = makeStyles((theme) => ({
    containerCardesService: {
        marginTop: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
    }
}))

export default function ChangeStatus({ estabelecimento }) {
    const classes = useStyles();
    const { id } = useParams()
    const { encaminhar } = useParams()
    const { status } = useParams()
    const navigate = useNavigate()
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [open, setOpen] = useState(false);
    const [obs, setObs] = useState('')
    const user = useSelector(state => state.account.user);
    const baseUrl = window.location.origin;
    const dispatch = useDispatch()


    const changeStatus = async () => {
        setOpen(true)
        setMessageSuccess('')
        setMessageError('')

        await api.patch('/estabelecimento/change-status', { estabelecimentoId: id, status, userId: user._id })
            .then(res => {
                // console.log(res)
                setOpen(false)
                setMessageSuccess(res.data.msg)

                obs && HandleCreateobservacao()

                // enviar e-mail de notificação de andamento do processo
                if (status === 'Inspecção Agendada' || status === 'Reprovado') {
                    senEmail()
                }

                dispatch({
                    type: 'alert',
                    payload: { alert: 'Processo encaminhado com sucesso.' },
                });

                window.setTimeout(() => {
                    if (user.funcao === 'Técnico') {
                        if (status === 'Análise') {
                            navigate(`/admin/solicitacoes/reprovadas`)
                        } else {
                            navigate(`/admin/recepcao/solicitacoes-de-licenciamento`)
                        }
                    } else {
                        navigate(`/admin/solicitacoes-de-licenciamento`)
                    }
                }, 3000);

            }).catch(err => {
                setOpen(false)
                setMessageError(err.response.data.message)
            })
    }

    async function senEmail() {
       
        const msgLic = {
            to: estabelecimento?.email,
            subject: 'Estado da solicitação de licenciamento de estabelecimento faramacêutico',
            html: `<h1>O processo está no estado: ${status}</h1>
            <p>
            Este é um email de acompanhamento da solicitação 
            de lienciamento do estabelecimento <b> ${estabelecimento?.nome} </b>
            com o número de entrada <b> ${estabelecimento?.numeroEntrada} </b>
            que submeteu na <b>ARMED</b> 
            </p>

            ${status === 'Reprovado' ? `
                </p>
                Observação:
                <br/>
                ${obs}
                </p>

                <p>
                Para fazer as devidas alterações acessa o link abaixo:
                </p>
                <a href='${baseUrl}/edite-processo-estabelecimento/${estabelecimento._id}'>Processo_${estabelecimento?.nome}</a>
                `
                    :
                    ''
                }
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        const msgRen = {
            to: estabelecimento?.email,
            subject: 'Estado da solicitação de renovação de autorização de exercício',
            html: `<h1>O processo está no estado: ${status}</h1>
           
            <p>
            Este é um email de acompanhamento da solicitação 
            de renovação de autorização de exercício para o estabelecimento <b> ${estabelecimento?.nome} </b>
            que submeteu na <b>ARMED</b> 
            </p>

            ${status === 'Reprovado' ? `
                </p>
                Observação:
                <br/>
                ${obs}
                </p>

                <p>
                Para fazer as devidas alterações acesse o link abaixo:
                </p>
                <a href='${baseUrl}/edite-processo-estabelecimento/${estabelecimento._id}'>Processo_${estabelecimento?.nome}</a>
                `
                    :
                    ''
                }
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        api.post('/email/send', estabelecimento?.tipoEntrada === 'Licenciamento' ? msgLic : msgRen)
            .then(response => {
                // console.log(response)
            }).catch(err => {
                // console.log(err) 
            })
    }

    async function HandleCreateobservacao() {
        setOpen(true)
        setMessageError('')
        setMessageSuccess('')

        await api.post('/observacao/create', {
            assunto: encaminhar === 'approve' ? 'Aprovação' : 'reprovação',
            descricao: obs,
            userId: user._id,
            estabelecimentoId: id
        }).then(res => {
            // console.log(res)
            // setobservacao({ ...observacao, descricao: '', assunto: '' })
            setOpen(false)
            setMessageSuccess(res.data.msg)

        }).catch(error => {
            setMessageError(error.response.data.message)
            setOpen(false)
        })
    }

    async function validate() {
        setMessageError('');

        let schema = yup.object().shape({
            observacao: yup.string("Necessário inserir as observações").required("Necessário inserir observações"),
        })

        try {
            await schema.validate({ 'observacao': obs })
            changeStatus()
        } catch (error) {
            setMessageError(error.errors);
        }
        return false
    }

    return (
        <Card style={{ padding: 20, paddingBottom: 30 }}>
            <RegisterAccess page={'alteração de estado de estabelecimento'} />
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={open} text={'A confirmar'} />


            <div style={{ marginBottom: 30 }}>
                <Typography style={{ lineHeight: 1.5 }} variant="h5">
                    Confirmação de
                    {encaminhar === 'approve' ? ' aprovação' : ' reprovação'}
                </Typography>

                <Divider />

                {encaminhar === 'approve' &&
                    <Typography >
                        Ao comfirmar esta ação estarás a afirmar que análisaste correctamente
                        toda informação e documentos anexado neste processo estão em conformidade!
                    </Typography>
                }

                <FazerObservacoes setObs={setObs} obs={obs} titulo={encaminhar === 'approve' ? '(caso seja necessário fazer alguma observação)' : '(Descreve os motivos desta reprovação)'} />

                <DialogActions>
                    <Button color="error" onClick={() => navigate(`/admin/analise/solicitacao-de-licenciamento/${id}`)} >Cancelar</Button>
                    <Button color="success" variant="contained" onClick={encaminhar === 'approve' ? changeStatus : validate}>Confirmar</Button>
                </DialogActions>
            </div>

            <Typography style={{ lineHeight: 1.5 }} variant="h5">
                O processo será encaminhado para:
            </Typography>
            <Divider />

            <Box className={classes.containerCardesService}>
                <CardDepartamentos fase={'Analisado'} icone={'/icones/chefedaSeccao.svg'} nomeService={'Chefe da Secção'} />
                <CardDepartamentos fase={'Analisado Chefe de Secção'} icone={'/icones/chefeDepartamento.svg'} nomeService={'Chefe do Departamento'} />
                <CardDepartamentos fase={'Inspecção Agendada'} icone={'/icones/inspeccaoAgendarVisita.svg'} nomeService={'Inspecção'} />
                <CardDepartamentos fase={'Inspecção Realizada'} icone={'/icones/chefeDepartamento.svg'} nomeService={'Análise da Inspecção'} />
                <CardDepartamentos fase={'Deferimento'} icone={'/icones/diretoraGeral.svg'} nomeService={'Diretora(a) Geral'} />
            </Box>
        </Card>
    )
}