
import {Container } from "@material-ui/core";
import HeaderSession from "../../utils/headerSession";
import RegisterAccess from "../../utils/registerAccess";

export default function GestRepresentantes({ element: component, ...rest }) {
    // const classes = useStyles()

    return (
        <Container>
           <RegisterAccess page={'gestão de representante'}/>
            <HeaderSession title={'GESTÃO DE REPRESENTANTES'} />

                    {component}

        </Container>
    )
}