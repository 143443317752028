
import { Typography, TextField } from "@material-ui/core";

export default function FazerObservacoes(props) {

    return (
        <div style={{ marginBottom: 20 }}>
            <Typography style={{ lineHeight: 1.5, fontSize: 22, marginTop: 10 }} variant="h1">
                Observação
            </Typography>
            <Typography style={{ lineHeight: 1.5, fontSize: 14 }} variant="subtitle1">
                {props?.titulo}
            </Typography>


            <TextField
                // autoFocus
                style={{ marginTop: 10, }}
                label="Descrever"
                fullWidth
                size="small"
                multiline
                minRows={3}
                name="observacao"
                variant="outlined"
                defaultValue={props.obs}
                onChange={(e) => props.setObs(e.target.value)}
            />
        </div>
    )
}