// validate
import * as yup from 'yup';

const validacaoDadosEmpresa = (dadosEmpresa) => {

    return new Promise(async (resolve, reject) => {
        let schema = yup.object().shape({
            // dadosEmpresa

            bairro: yup.string("Preencher campo  Bairro")
                .required("Preencher campo  Bairro"),

            // rua: yup.string("Preencher campo rua")
            //     .required("Preencher campo Onde Estudou"),

            municipio: yup.string("Preencher campo  municipio")
                .required("Preencher campo  municipio"),

            // provincia: yup.string("Preencher campo  provincia")
            //     .required("Preencher campo  provincia"),

            tel1: yup.string("Preencher campo telefone 1")
                .required("Preencher campo  telefone 1"),

            email: yup.string()
                .nullable("").email(" insira um endereço de email valido")
                .required("Preencher campo email"),

            nif: yup.string("Preencher campo NIF ..")
                .required("Preencher campo portador do NIF .."),
            // .max(14, " o NIF inserido contém mais de 14 caracteres.!"),

            nome: yup.string("Preencher campo nome da empresa")
                .required("Preencher campo nome da empresa"),

            logo: yup.string("Carregar a logo da empresa ")
                .required("Carregar logo da empresa",),
        })

        // if (dadosEmpresa?.logo?.size > 2473875) {
        //     return reject('logo da empresa demasiado pesado. escolha um ficheiro de tamanho não superior a 2mb');
        // }

        try {
            await schema.validate(dadosEmpresa)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};
const validacaoDadosRepresentante = (dadosRepresentante) => {

    return new Promise(async (resolve, reject) => {

        let schema = yup.object().shape({

            municipio: yup.string("Preencher campo  municipio")
                .required("Preencher campo  município"),

            // provincia: yup.string("Preencher campo  provincia")
            //     .required("Preencher campo  provincia"),

            email: yup.string()
                .nullable("").email(" insira um endereço de email valido")
                .required("Preencher campo email"),

            tel1: yup.string("Preencher campo telefone 1")
                .required("Preencher campo  telefone 1"),

            genero: yup.string("Preencher campo genero")
                .required("Preencher campo genero"),

            numeroBI: yup.string("Preencher campo portador do B.I ..")
                .required("Preencher campo portador do B.I ..")
                .max(14, "Número de B.I inserido não deve ter mais de 14 caracteres.!"),

            nome: yup.string("Preencher campo nome ")
                .required("Preencher campo nome"),

            foto: yup.string("Carregar  foto do(a) representante")
                .required("Carregar foto do(a) representante",),
        })

        // if (dadosRepresentante?.foto?.size > 2473875) {
        //     return reject('imagem demasiado pesado. escolha um ficheiro de tamanho não superior a 2mb');
        // }


        try {
            await schema.validate(dadosRepresentante)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};


const validacaoDadosEstabelecimento = (dadosEstabelecimento, tipoRegisto) => {
    return new Promise(async (resolve, reject) => {

        if (tipoRegisto === 'Inscrição') {
            let schema = yup.object().shape({

                comprovativoPE: yup.string(" Comprovativo de Pagamento de emolumentos")
                    .required(" Anexar Comprovativo de Pagamento de emolumentos"),

                certificadoND: yup.string(" Anexar Certificado de não devedor da AGT")
                    .required("Anexar Certificado de não devedor da AGT"),

                croquis: yup.string(" Anexar Planta com quota das instalações e croquis de localização ")
                    .required("Anexar Planta com quota das instalações e croquis de localização "),

                copiaDI: yup.string("Anexar Cópia colorida do documento de identificação ")
                    .required("Anexar Cópia colorida do documento de identificação "),

                termoRDT: yup.string("Anexar Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)")
                    .required("Anexar Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)"),

                escrituraCS: yup.string("Anexar Escritura de constituição de Sociedade (para Empresas)")
                    .required("Anexar Escritura de constituição de Sociedade (para Empresas)"),

                requerimentoARMED: yup.string("Anexar o  Requerimento dirigido a ARMED ")
                    .required("Anexar Requerimento dirigido a ARMED "),

                logo: yup.string("Carregar  imagem do estabelecimento ")
                    .required("Carregar imagem do estabelecimento",),
            })

            try {
                await schema.validate(dadosEstabelecimento)
                return resolve(true)
            } catch (error) {
                return reject(error.errors);
            }
        }

        let schema = yup.object().shape({
            // comprovativoPE: yup.string(" Comprovativo de Pagamento de emolumentos")
            //     .required(" Anexar Comprovativo de Pagamento de emolumentos"),

            // certificadoND: yup.string(" Anexar Certificado de não devedor da AGT")
            //     .required("Anexar Certificado de não devedor da AGT"),

            // croquis: yup.string(" Anexar Planta com quota das instalações e croquis de localização ")
            //     .required("Anexar Planta com quota das instalações e croquis de localização "),

            // copiaDI: yup.string("Anexar Cópia colorida do documento de identificação ")
            //     .required("Anexar Cópia colorida do documento de identificação "),

            // termoRDT: yup.string("Anexar Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)")
            //     .required("Anexar Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)"),

            // escrituraCS: yup.string("Anexar Escritura de constituição de Sociedade (para Empresas)")
            //     .required("Anexar Escritura de constituição de Sociedade (para Empresas)"),

            // requerimentoARMED: yup.string("Anexar o  Requerimento dirigido a ARMED ")
            //     .required("Anexar Requerimento dirigido a ARMED "),


            bairro: yup.string("Preencher campo  bairro")
                .required("Preencher campo  bairro"),

            // rua: yup.string("Preencher campo rua")
            //     .required("Preencher campo Onde Estudou"),

            municipio: yup.string("Preencher campo  municipio")
                .required("Preencher campo  municipio"),

            provincia: yup.string("Preencher campo  provincia")
                .required("Preencher campo  provincia"),

            // tel1: yup.string("Preencher campo telefone 1")
            //     .required("Preencher campo  telefone 1"),

            email: yup.string()
                .nullable("").email(" insira um endereço de email valido")
                .required("Preencher campo email"),

            // classeId: yup.string("Preencher campo classe de Estabelecimento")
            //     .required("Preencher campo portador Classe de Estabelecimento"),

            tipoId: yup.string("Preencher campo Tipo de Estabelecimento")
                .required("Preencher campo portador Tipo de Estabelecimento"),


            nome: yup.string("Preencher campo nome da empresa")
                .required("Preencher campo nome da empresa"),

            logo: yup.string("Carregar  imagem do estabelecimento")
                .required("Carregar imagem do estabelecimento",),
        })

        // if (dadosEstabelecimento?.logo?.size > 2473875) {
        //     return reject('Imagem estabelecimento demasiado pesado. escolha um ficheiro de tamanho não superior a 2mb');
        // }

        // if (dadosEstabelecimento?.requerimentoARMED?.size > 2473875) { return reject('Requerimento dirigido a ARMED  demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.escrituraCS?.size > 2473875) { return reject('Escritura de constituição de Sociedade (para Empresas) demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.croquis?.size > 2473875) { return reject('Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA) demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.termoRDT?.size > 2473875) { return reject('Planta com quota das instalações e croquis de localização demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.copiaDI?.size > 2473875) { return reject('Cópia colorida do documento de identificação demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.comprovativoPE?.size > 2473875) { return reject('Comprovativo de Pagamento de emolumentos demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }

        // VERIFICAÇÃO DE TIPO DE DOCUMENTO
        // if (dadosEstabelecimento?.requerimentoARMED?.type !== 'pdf) { return reject('Requerimento dirigido a ARMED  demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.escrituraCS?.type !== 'pdf) { return reject('Escritura de constituição de Sociedade (para Empresas) demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.croquis?.type !== 'pdf) { return reject('Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA) demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.termoRDT?.type !== 'pdf) { return reject('Planta com quota das instalações e croquis de localização demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.copiaDI?.type !== 'pdf) { return reject('Cópia colorida do documento de identificação demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }
        // if (dadosEstabelecimento?.comprovativoPE?.type !== 'pdf) { return reject('Comprovativo de Pagamento de emolumentos demasiado pesado. Anexa um ficheiro de tamanho não superior a 2mb'); }


        try {
            await schema.validate(dadosEstabelecimento)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }

    })
}


const validacaoDadosDirectorTecnico = (dadosdirectorTecnico) => {

    return new Promise(async (resolve, reject) => {

        if (dadosdirectorTecnico?.usedataRepresentante === 'nao') {
            let schema = yup.object().shape({
                // rua

                municipio: yup.string("Preencher campo  municipio")
                    .required("Preencher campo  municipio"),

                provincia: yup.string("Preencher campo  provincia")
                    .required("Preencher campo  provincia"),

                email: yup.string()
                    .nullable("").email(" Insira um endereço de email valido")
                    .required("Preencher campo email"),

                tel1: yup.string("Preencher campo telefone 1")
                    .required("Preencher campo  telefone 1"),

                genero: yup.string("Preencher campo genero")
                    .required("Preencher campo genero"),

                numeroBI: yup.string("Preencher campo portador do B.I ..")
                    .required("Preencher campo portador do B.I ..")
                    .max(14, "O número de billhete inserido contém mais de 14 caracteres.!"),

                nome: yup.string("Preencher campo nome ")
                    .required("Preencher campo nome"),

                foto: yup.string("Carregar foto do(a) Director Técnico")
                    .required("Carregar foto do(a) Director Técnico"),

            })

            try {
                await schema.validate(dadosdirectorTecnico)
                return resolve(true)
            } catch (error) {
                return reject(error.errors);
            }
        }

        let schema = yup.object().shape({
            // categoriaId

            ordem: yup.string("Preencher campo ordem")
                .required("Preencher campo  ordem"),

            numeroCarteira: yup.string("Preencher campo carteira profissional")
                .required("Preencher campo  carteira profissional"),

            usedataRepresentante: yup.string("Deseja usar os mesmos dados do Representante? Não ou Sim ")
                .required("Deseja usar os mesmos dados do Representante? Não ou Sim"),
        })

        // if (dadosdirectorTecnico?.foto?.size > 2473875) {
        //     return reject('Imagem demasiado pesado. escolha um ficheiro de tamanho não superior a 2mb');
        // }


        try {
            await schema.validate(dadosdirectorTecnico)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};
const validacaoInspeccao = (inspeccionar) => {

    return new Promise(async (resolve, reject) => {

        let schema = yup.object().shape({

            requerente: yup.string("Preencher área de ASSINATURAS - O REQUERENTE")
                .required("Preencher área de ASSINATURAS - O REQUERENTE"),


            nomeInstituicao2: yup.string("Preencher área de ASSINATURAS - NOME / INSTITUIÇÃO")
                .required("Preencher área de ASSINATURAS - NOME / INSTITUIÇÃO"),

            nomeInstituicaoCoordenador: yup.string("Preencher área de ASSINATURAS - NOME / INSTITUIÇÃO (COORDENADOR)")
                .required("Preencher área de ASSINATURAS - NOME / INSTITUIÇÃO (COORDENADOR)"),

            nomeInstituicao1: yup.string("Preencher área de ASSINATURAS - NOME / INSTITUIÇÃO")
                .required("Preencher área de ASSINATURAS - NOME / INSTITUIÇÃO"),

            condicoesSegurancaPublica: yup.string("Preencher campo  CONDIÇÕES DE SEGURANÇA PUBLICA")
                .required("Preencher campo CONDIÇÕES DE SEGURANÇA PUBLICA"),

            veredicto: yup.string("Preencher APÓS DELIBERAÇÃO, A COMISSÃO DE VISTORIA, CONSIDERA QUE O ESTABELECIMENTO COMERCIAL ESTÁ?")
                .required("Preencher APÓS DELIBERAÇÃO, A COMISSÃO DE VISTORIA, CONSIDERA QUE O ESTABELECIMENTO COMERCIAL ESTÁ?"),

            condicoesSaudePublica: yup.string("Preencher campo  CONDIÇÕES DE SAÚDE PUBLICA")
                .required("Preencher campo CONDIÇÕES DE SAÚDE PUBLICA"),

            estadoFuncionalidade: yup.string("Preencher campo 	ESTADO DA FUNCIONALIDADE")
                .required("Preencher campo 	ESTADO DA FUNCIONALIDADE"),

            bruta: yup.string("Preencher campo Bruta")
                .required("Preencher campo área Bruta"),

            exposicaoVenda: yup.string("Preencher campo  área de Exposição e Venda")
                .required("Preencher campo  área de Exposição e Venda"),

            cae: yup.string("Preencher campo Classificação da Actividade Económica a Exercer")
                .required("Preencher campo Classificação da Actividade Económica a Exercer"),

            classificacaoEstabelecimento: yup.string("Preencher campo Classificação do Estabelecimento Comercial")
                .required("Preencher campo Classificação do Estabelecimento Comercial"),

            estrangeiros: yup.string("Preencher campo Total de número de trabalhadores estrangeiros")
                .required("Preencher campo Total de número de trabalhadores estrangeiros"),

            nacionais: yup.string("Preencher campo Total de número de trabalhadores nacionais")
                .required("Preencher campo Total de número de trabalhadores nacionais"),

            mulheres: yup.string("Preencher campo Total de número de trabalhadores mulheres")
                .required("Preencher campo Total de número de trabalhadores mulheres "),

            homens: yup.string("Preencher campo Total de número de trabalhadores homens")
                .required("Preencher campo Total de número de trabalhadores homens"),

            portaEntrada: yup.string("Preencher campo PORTA DE ENTRADA DO PROCESSO")
                .required("Preencher campo PORTA DE ENTRADA DO PROCESSO"),
        })

        try {
            await schema.validate(inspeccionar)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};

export {
    validacaoDadosEmpresa,
    validacaoDadosRepresentante,
    validacaoDadosEstabelecimento,
    validacaoDadosDirectorTecnico,
    validacaoInspeccao,
};



