// import { IconButton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingBackdrop from '../../../load/loadingBackdrop';
import { useState } from 'react';
import api from '../../../services/api';
import { useSelector } from 'react-redux';

const DeleteNoticia = (props) => {
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.account.user);

    const HandleDeleteNoticia = () => {
        props.setMessageSuccess('')
        props.setMessageError('')
        setOpen(true)


        api.delete('/noticia/delete', { data: { 'id': props.noticiaId, 'userId': user._id } })
            .then(response => {
                // console.log(response)
                setOpen(false)
                props.setMessageSuccess('Eliminado com sucesso.')
                props.setForceUpdate(response)

            }).catch(err => {
                console.log(err)
                setOpen(false)
                props.setMessageError('Erro ao eliminar')
            })
    }

    return (
        <>
            <LoadingBackdrop open={open} text={'Deletando notícia. Aguarde!'} />
            <IconButton title='Apagar' onClick={HandleDeleteNoticia} color="error"><DeleteIcon /></IconButton >
        </>
    );
}

export default DeleteNoticia;
