
import { Typography } from "@mui/material";

export default function StatusEntrada({ status }) {

    return (
        <>
            {status === 'Licenciamento' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#f3d194', color: '#000', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Renovação' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#85287d8a', color: '#fff', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }
        </>
    )
}