import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../../../utils/headerSession'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        // textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.7
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        // paddingTop: 50,
        // textAlign: 'center',
        color: '#67348d'
    },
}))

export default function InfoProdutoControlado() {
    const classes = useStyles()

    return (

        <Container style={{ maxWidth: 800 }}>
            <HeaderSession title='PRODUTOS CONTROLADOS' />

            <h1 className={classes.title}>
                REQUISITOS PARA A EMISSÃO DE CERTIFICADOS DE IMPORTAÇÃO DE MEDICAMENTOS E PRODUTOS DE SAÚDE
            </h1>
            <h2 className={classes.title}>
                SUJEITOS A CONTROLO INTERNACIONAL E PELO MINISTÉRIO DA SAÚDE
            </h2>

            <p className={classes.root} >
                A solicitação da autorização para a importação de produto sujeito ao controlo internacional e nacional deve obdecer os seguintes requisitos:
            </p>
            <p className={classes.root} >
                <ol>
                    <li>
                        Solicitação de importação dirigida à ARMED, incluindo o número da Autorização de Exercício Farmacêutico e o Número de Identificação Fiscal, devendo ser assinada pelo Director Técnico da entidade importadora
                    </li>
                    <li>
                        Autorização de exportação emitida pelas entidades competentes do país de origem
                    </li>
                    <li>
                        Parecer da Direcção Nacional de Saúde Pública para a importação de antituberculosos
                    </li>
                    <li>
                        Parecer do Instituto Nacional de Luta contra a Sida para a importação de anti-retrovirais
                    </li>
                    <li>
                        Cópia da validação emitida pelo Instituto Nacional de Investigação em Saúde, para a importação de Testes de Diagnóstico Rápido para rastreio e diagnóstico de doenças infecciosas
                    </li>
                    <li>
                        Carta original da unidade hospitalar dirigida ao importador, assinada e carimbada pelo Director Geral da unidade hospitalar, para a importação de estupefacientes
                    </li>
                    <li>
                        Factura Proforma dos produtos a serem importados, na língua portuguesa (alternativamente na língua inglesa/francesa ou espanhola);
                    </li>
                    <li>
                        Certificado de Boas Práticas de Fabrico (GMP)
                    </li>
                    <li>
                        Cópia do Folheto Informativo, rótulo que acompanha o medicamento, deve incluir a Língua portuguesa
                    </li>
                </ol>
            </p>

            <p className={classes.root} >
                Após entrada do produto no País, o importador deve remeter à ARMED, uma cópia do certificado de exportação emitido pelas autoridades competentes do país de origem no periódo de oito dias, sob o risco de serem penalizados nas próximas importações
            </p>
            <p className={classes.root} >
                obs: As listas dos Narcóticos, Psicotrópicos e Substâncias Precurssoras sujeitos ao controlo pelo Conselho Internacional de Controlo de Narcóticos (INCB), podem ser consultadas no site <a href="/#">https:www.incb/org</a>.
            </p>
            <p className={classes.root} >
                <a href="/#">Clica para ver o modelo do Requerimento.</a>
            </p>

            <br />
        </Container>
    )
}
