
import { useState } from "react";
import { Card } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions, Tooltip } from "@mui/material";
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import LoadingBackdrop from "../../../../load/loadingBackdrop";
import { useNavigate, useParams } from "react-router-dom";
import FazerObservacoes from "../fazerObservacoes.";
import api from "../../../../services/api";
import * as yup from 'yup'; // VALIDACAO


export default function Approve({ renovacao }) {
    const { id } = useParams()
    // const { encaminhar } = useParams()
    const navigate = useNavigate()
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [open, setOpen] = useState(false);
    const [obs, setObs] = useState('')
    const user = useSelector(state => state.account.user);
    // const baseUrl = window.location.origin;
    const dispatch = useDispatch()

    const changeStatus = async (status) => {
        setOpen(true)
        setMessageSuccess('')
        setMessageError('')

        await api.patch('/solicitacao-renovacao/change-status', { solicitacaoRenovacaoId: id, status, userId: user._id })
            .then(res => {
                // console.log(res)
                setOpen(false)
                setMessageSuccess(res.data.msg)

                HandleCreateobservacao()

                // enviar e-mail de notificação
                senEmail(status)

                dispatch({
                    type: 'alert',
                    payload: { alert: `Solicitação ${status} com sucesso.` },
                });

                window.setTimeout(() => {
                    navigate(`/admin/solicitacoes-de-renovacao`)
                }, 3000);

            }).catch(err => {
                setOpen(false)
                setMessageError(err.response.data.message)
            })
    }

    async function senEmail(status) {

        const msgApprove = {
            to: renovacao?.email,
            subject: 'Solicitação de renovação de autorização de exercício foi Aprovada',
            html: `<h1>Solicitação ${status}</h1>
            <p>
            O pedido de renovação de autorização de exercício para o estabelecimento <b> ${renovacao?.estabelecimento?.nome} </b>
            com número de processo <b> ${renovacao?.estabelecimento?.numeroProcesso} </b>
            agora estará a passar pelas áreas de analise de processo. Fique atento ao email, informaremos sobre qualquer anomalia.
            </p>
            
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        const msgRepprove = {
            to: renovacao?.email,
            subject: 'Solicitação de renovação de autorização de exercício foi Reprovado',
            html: `<h1>Solicitação ${status}</h1>
            <p>
            O pedido de renovação de autorização de exercício para o estabelecimento <b> ${renovacao?.estabelecimento?.nome} </b>
            com número de autorização <b> ${renovacao?.estabelecimento?.numeroAutorizacao ?? '****'} </b>
            não foi aprovada pelo seguinte:
            </p>

            ${obs ? ` </p>${obs}</p> ` : ''}
    
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        api.post('/email/send', status === 'Aprovado' ? msgApprove : msgRepprove)
            .then(response => {
                // console.log(response)
            }).catch(err => {
                // console.log(err) 
            })
    }

    async function HandleCreateobservacao() {
        setOpen(true)
        setMessageError('')
        setMessageSuccess('')

        await api.post('/observacao/create', {
            assunto: 'Solicitação de renovação',
            descricao: obs ?? 'Aprovado',
            userId: user._id,
            estabelecimentoId: renovacao?.estabelecimento?._id
        }).then(res => {
            // console.log(res)
            // setobservacao({ ...observacao, descricao: '', assunto: '' })
            setOpen(false)
            setMessageSuccess(res.data.msg)

        }).catch(error => {
            setMessageError(error.response.data.message)
            setOpen(false)
        })
    }

    async function validate() {
        setMessageError('');

        let schema = yup.object().shape({
            observacao: yup.string("Necessário informar ao utente os motivos da reprovação").required("Necessário informar ao utente os motivos da reprovação"),
        })

        try {
            await schema.validate({ 'observacao': obs })
            changeStatus('Reprovado')
        } catch (error) {
            setMessageError(error.errors);
        }
        return false
    }

    return (
        <Card style={{ padding: 20, marginBottom: 10 }}>
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={open} />

            <FazerObservacoes setObs={setObs} obs={obs} titulo={'(caso seja necessário fazer alguma observação)'} />
            <DialogActions>
                <Button color="error" onClick={validate} >Reprovar</Button>
                {renovacao?.estabelecimento ?
                    <Button style={{ background: '#85287e' }} variant="contained" onClick={() => changeStatus('Aprovado')}>Aprovar</Button>
                    :
                    <Tooltip title="Primeiro insira o estabelecimento.!">
                        <Button style={{ background: '#85287e' }} variant="contained">Aprovar</Button>
                    </Tooltip>
                }
            </DialogActions>
        </Card>
    )
}