import { useEffect, useState } from 'react';
import Loading from '../../../../load/loading';
import api from '../../../../services/api';
import TabelaActividades from '../../../gestAdmin/tabelaActividades';
import TempoPassado from '../../../../utils/tempoPassado';
import { Card } from '@mui/material';

export default function HistoricoActividadesByModel({ model }) {
    const [historicosActividades, setHistoricosActividades] = useState('');
    const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState('')
    const [perPage, setPerPage] = useState(100)
    // console.log(estabelecimento)

    useEffect(() => {
        api.get(`/historico-actividades?cnp=false&modelId=${model._id}&page=${page}&perPage=${perPage}`)
            .then(response => {
                // console.log(response)
                setHistoricosActividades(response.data.historicoActividades);
                setConfigLista(response.data);
            }).catch(err => '')
    }, [model._id])


    return (
        historicosActividades ?
            <Card style={{ padding: 20 }}>
                <span style={{ marginLeft: 70 }}>
                    Ultima intervencao foi <TempoPassado date={model.updatedAt} />
                </span>
                <TabelaActividades
                    historicosActividades={historicosActividades}
                    configLista={configLista}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    page={page}
                />
            </Card>
            :
            <Loading />
    )
}
