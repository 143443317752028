
// import { format, parseISO } from 'date-fns';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function TempoPassado({ date }) {

    return (
        <span >
            {/* {date?.substring(0, 10).split('-').reverse().join('/')} */}
           
            {/* {format(parseISO(date), 'dd-MM-yyyy HH:mm:ss')} */}

            {/* {format(parseISO(date), 'dd-MM-yyyy HH:mm')} */}

            {/* {formatDistanceToNow(parsedDate, { addSuffix: true, unit: 'minute' })} */}

            {/* {formatDistance(parseISO(date), new Date())} */}

            {formatDistanceToNow(parseISO(date), {addSuffix: true, locale: ptBR , includeSeconds: true })}
        </span>
    )
} 