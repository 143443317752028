import { Card, Typography, TextField, DialogActions, Button, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Editor from "../../ckeditor/editor";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import ListaVazia from "../../utils/listaVazia";
import Loading from "../../load/loading";
import DateHora from "../../utils/dateHora";
import MessageAlert from '../../messages/messageAlert';
import LoadingBackdrop from '../../load/loadingBackdrop';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VerTodasObservacoes from "../departamentos/dlif/verTodasObservacoes";
import PrintIcon from '@mui/icons-material/Print';
import printerService from "../../services/printer";


const useStyles = makeStyles((theme) => ({
    root: {
        textDecoration: 'none',
        marginBottom: 10,
        padding: 20,
        transition: '1s',
        '&:hover': {
            background: '#85287d8a',
            cursor: 'pointer',
            // padding: 40,
        },
    },
}))

export default function Observacoes() {
    const classes = useStyles()
    const { id } = useParams()
    const user = useSelector(state => state.account.user);
    const [observacoesFeitas, setobservacoesFeitas] = useState('');
    const [observacao, setobservacao] = useState('');
    const [viewObservacao, setViewObservacao] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState('');
    // console.log(observacao)

    useEffect(() => {
        api.get(`/observacaos?estabelecimentoId=${id}`)
            .then(response => {
                setobservacoesFeitas(response.data.observacaos);
            }).catch(err => '')
    }, [id, refresh])

    // function HandleChange(e) {
    //     e.target.files ?
    //         setobservacao({ ...observacao, [e.target.name]: e.target.files[0] })
    //         :
    //         setobservacao({ ...observacao, [e.target.name]: e.target.value })
    // }

    async function HandleCreateobservacao() {
        setLoading(true)
        setRefresh(false)
        setMessageError('')
        setMessageSuccess('')

        await api.post('/observacao/create', { ...observacao, 'userId': user._id, 'estabelecimentoId': id })
            .then(res => {
                setRefresh(true)
                setobservacao({ ...observacao, descricao: '', assunto: '' })
                setLoading(false)
                setMessageSuccess(res.data.msg)

            }).catch(error => {

                setMessageError(error.response.data.message)
                setLoading(false)
            })
    }

    const exportToPDF = (viewObservacaoId) => {
        printerService.printer(viewObservacaoId)
    }

    return (
        <>
            {viewObservacao ?
                <Card style={{ padding: 20, position: 'relative' }}>
                    <IconButton size="small" onClick={() => setViewObservacao(null)}><CloseIcon /></IconButton>
                    <IconButton onClick={() => exportToPDF(viewObservacao._id)}> <PrintIcon /></IconButton>

                    <div id={viewObservacao._id}>
                        <Typography style={{ lineHeight: 1.5, fontSize: 20 }} variant="h1">
                            <strong>Observação de: </strong>
                            {viewObservacao.admin?.dadosPessoais.nome}
                            <Divider />
                            <br />
                            Assunto: {viewObservacao.assunto}
                        </Typography>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: viewObservacao.descricao }} />

                        <br />
                        Aos: <DateHora date={viewObservacao.updatedAt} />
                    </div>
                </Card>
                :
                <>
                    {observacoesFeitas ?
                        <>
                            <VerTodasObservacoes img={true} observacoesFeitas={observacoesFeitas} />

                            {observacoesFeitas.length > 0 ?
                                <>
                                    {observacoesFeitas.map((observacao) => (
                                        <Card key={observacao._id} className={classes.root} onClick={() => setViewObservacao(observacao)}>
                                            <strong>Assunto:</strong> {observacao.assunto}
                                            <br />
                                            <strong>Admin:</strong> {observacao.admin?.dadosPessoais.nome}
                                            <br />
                                            <strong>Data:</strong> <DateHora date={observacao.createdAt} />
                                        </Card>
                                    ))}
                                </>
                                :
                                <ListaVazia text={'Nenhuma observação encontrada.'} />
                            }
                        </>
                        :
                        <Loading text={'Buscando observações'} />
                    }

                    <Card style={{ padding: 20, minHeight: '40vh' }}>
                        <LoadingBackdrop open={loading} text={'A salvar observação'} />
                        {messageSuccess && <MessageAlert message={messageSuccess} type={'success'} />}
                        {messageError && <MessageAlert message={messageError} type={'error'} />}

                        <br />
                        <Typography align='center' variant="subtitle1" style={{ fontSize: 20 }}>
                            Descreva qualquer Observação a ser feita sobre o estabelecimento.
                        </Typography>
                        <br />

                        <TextField
                            margin='dense'
                            type="text"
                            label="Assunto"
                            fullWidth
                            size="small"
                            name="assunto"
                            variant="outlined"
                            onChange={(e) => setobservacao({ ...observacao, assunto: [e.target.value] })}
                            defaultValue={observacao?.assunto}
                        />

                        {/* EDITOR DE TEXTO CKEditor*/}
                        <Editor
                            editorData={observacao?.descricao}
                            setEditorData={setobservacao}
                        />

                        <DialogActions>
                            <Button onClick={HandleCreateobservacao} disabled={!observacao?.descricao} variant="contained" style={{ background: '#85287d8a' }}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </Card>
                </>
            }
        </>
    )
}