
import { useEffect, useState } from 'react'
import { Grid, MenuItem, TextField, Typography, Card, Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import apiCNP from '../../../../../services/apiCNP'
import LoadingBackdrop from '../../../../../load/loadingBackdrop';
import MessageError from '../../../../../messages/messageError';
import HeaderSession from '../../../../../utils/headerSession';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Alert from '@mui/material/Alert';
import api from '../../../../../services/api';
import CadastroEstabelecimento from '../../licenciamento/cadastroEstabelecimento';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    gridItem: { padding: 5 },
    card: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 8,
        minHeight: 90,
        padding: 5,

        fontSize: 11,
        border: '1px dashed #3e3d3f', borderRadius: 5,
        // '&:hover': { border: '1px dashed #3e3d3f', borderRadius: 5 },
    }
}))

export default function Index({ setSelected }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const [renovacao, setRenovacao] = useState()
    const [renovacaoResponse, setRenovacaoResponse] = useState()
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false);

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            }).catch(err => '')

        renovacao?.provinciaId &&
            apiCNP.get(`/municipios-by-provincia/${renovacao.provinciaId}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                }).catch(err => '')
    }, [renovacao?.provinciaId])


    function HandleChange(e) {

        e.target.files ?
            setRenovacao({ ...renovacao, [e.target.name]: e.target.files[0] })
            :
            setRenovacao({ ...renovacao, [e.target.name]: e.target.value })

        e.target.name === 'provinciaId' &&
            apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);

                    setRenovacao((prevRenovacao) => ({ ...prevRenovacao, provincia: res.data.provincia.designacao }));
                }).catch(err => '')
    }

    async function createrenovacao() {
        setOpen(true)
        setErrorMessage('')
        // console.log(renovacao)

        const formData = new FormData();
        formData.append('numeroAutorizacao', renovacao?.numeroAutorizacao);
        formData.append('nome', renovacao?.nome);
        formData.append('numeroBI', renovacao?.numeroBI);
        formData.append('ligacaoEstabelecimento', renovacao?.ligacaoEstabelecimento);
        formData.append('email', renovacao?.email);
        formData.append('tel', renovacao?.tel);
        formData.append('provincia', renovacao?.provincia);
        formData.append('municipio', renovacao?.municipio);
        formData.append('bairro', renovacao?.bairro);
        formData.append('rua', renovacao?.rua);
        // DOCUMENTOS ANEXADOS
        formData.append('bi', renovacao?.bi);
        formData.append('autorizacao', renovacao?.autorizacao);
        formData.append('requerimentoDG', renovacao?.requerimentoDG);
        formData.append('declaracaoOFA', renovacao?.declaracaoOFA);
        formData.append('comprovativoPE', renovacao?.comprovativoPE);

        await api.post('/solicitacao-renovacao/register', formData)
            .then(res => {
                setOpen(false)
                // console.log(res)
                setRenovacao(res.data.solicitacaoRenovacao)
                setRenovacaoResponse(res.data.solicitacaoRenovacao)

                // CONDICAO QUE CONTROLA SE O ESTABELECIMENTO SERÁ INSERIDO OU NAO
                // SELECTED 2 MOSTRA O FORMULARIO DE CADASTRO/INSERCAO DE ESTABELECIMENTO
                // SELECTED 1 MOSTRA O RECIBO DE INSERCAO DE ESTABELECIMENTO

                if (res.data.solicitacaoRenovacao?.estabelecimento) {
                    // setSelected(2);
                    navigate(`/solicitacao-de-renovacao-de-estabelecimento/recibo/${res.data.solicitacaoRenovacao?._id}`)
                    return null
                }

                // if (res.data.solicitacaoRenovacao?.estabelecimento) {
                //     setSelected(2)
                // } else {
                //     setSelected(1)
                // }

            }).catch(error => {
                setOpen(false)
                // console.log(error)
                setErrorMessage(error.response?.data.message)
            })
    }

    return (
        <Container >
            <HeaderSession title='SOLICITAÇÃO DE RENOVAÇÃO DE ESTABELECIMENTO' />
            {errorMessage && <MessageError message={errorMessage} />}

            <LoadingBackdrop open={open} text={'A enviar solicitação de renovação'} />

            <Typography style={{ fontSize: 18, marginBottom: 15 }}>
                <b>OBS: </b>
                Após solicitar uma renovação deverá aguardar sua aprovação pela ARMED,
                assim que as informações do solicitante e do estabelecimento forem verificadas e validadas vai receber um e-mail com o link de acesso
                para área de actualizão de dados.
            </Typography>

            <Card style={{ padding: 20 }}>
                <TextField
                    type="text"
                    required
                    label="Autorização do Estabelecimento"
                    fullWidth
                    size="small"
                    name="numeroAutorizacao"
                    variant="outlined"
                    onChange={HandleChange}
                    defaultValue={renovacao?.numeroAutorizacao}
                />
                <br />
                <br />
                <Typography style={{ fontSize: 20, margin: 5 }}>
                    Preenche os seguintes dados:
                </Typography>

                <Grid container >
                    <Grid xs={12} md item className={classes.gridItem}>

                        <TextField
                            required
                            type="text"
                            label="Nome"
                            fullWidth
                            size="small"
                            name="nome"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.nome}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Nº de B.I"
                            fullWidth
                            size="small"
                            name="numeroBI"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.numeroBI}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Tipo de ligação com o Estabelecimento"
                            fullWidth
                            size="small"
                            name="ligacaoEstabelecimento"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.ligacaoEstabelecimento}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="number"
                            label="Telefone"
                            fullWidth
                            size="small"
                            name="tel"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.tel}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="email"
                            label="Email"
                            fullWidth
                            size="small"
                            name="email"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.email}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Província"
                            select
                            fullWidth
                            size="small"
                            name="provinciaId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.provinciaId ?? ''}
                        >
                            <MenuItem disabled value=''>Selecione Províncía</MenuItem>
                            {provincias?.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia._id}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>
                    <Grid xs={12} md item className={classes.gridItem}>
                        {renovacao?.provinciaId ?
                            <TextField
                                required
                                type="text"
                                label="Municipio"
                                select
                                fullWidth
                                size="small"
                                name="municipio"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={renovacao?.municipio ?? ''}
                            >
                                <MenuItem disabled value=''>Selecione Municipio</MenuItem>
                                {municipios?.map((municipio) => (
                                    <MenuItem key={municipio._id} value={municipio.designacao}>
                                        {municipio.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                            :
                            <TextField
                                type="text"
                                disabled
                                label="Municipio"
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                        }
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Bairro"
                            fullWidth
                            size="small"
                            name="bairro"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.bairro}
                        />
                    </Grid>

                    <Grid xs={12} md={4} item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Rua"
                            fullWidth
                            size="small"
                            name="rua"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={renovacao?.rua}
                        />
                    </Grid>
                </Grid>
            </Card>

            <br />

            <Card style={{ padding: 20 }}>
                <Typography style={{ fontSize: 20, margin: 5 }}>
                    Anexar Documentos:
                </Typography>

                <Grid container>
                    <Grid xs={12} md item >
                        <Card className={classes.card}>
                            <label htmlFor="renovacao1" style={{ margin: 10, cursor: 'pointer' }} >
                                {renovacao?.bi ?
                                    <span title="Alterar">
                                        <Alert severity="success">
                                            {renovacao.bi.name.slice(0, 10) + '...'}
                                        </Alert>
                                    </span>
                                    :
                                    <span>
                                        <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                        Cópia colorida do Bilhete de Identidade do Solicitante
                                    </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="bi" id="renovacao1" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>

                    <Grid xs={12} md item >
                        <Card className={classes.card}>
                            <label htmlFor="renovacao4" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    renovacao?.autorizacao ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {renovacao.autorizacao.name.slice(0, 10) + '...'}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Cópia colorida da Autorização de Exercício do Estabelecimento
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="autorizacao" id="renovacao4" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>
                    {/* ############################################################################################# */}

                    <Grid xs={12} md item >
                        <Card className={classes.card}>
                            <label htmlFor="renovacao5" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    renovacao?.requerimentoDG ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {renovacao.requerimentoDG.name.slice(0, 10) + '...'}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Requerimento dirigido ao Director Geral da ARMED
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="requerimentoDG" id="renovacao5" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>

                    </Grid>

                    <Grid disabled xs={12} md item >
                        {/* ############################################################################################# */}
                        <Card disabled className={classes.card}>
                            <label htmlFor="renovacao6" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    renovacao?.declaracaoOFA ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {renovacao.declaracaoOFA.name.slice(0, 10) + '...'}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Declaração original e actualizada da OFA (ou ASSOFARMA)
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="declaracaoOFA" id="renovacao6" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>

                    <Grid disabled xs={12} md item >
                        <Card disabled className={classes.card}>
                            <label htmlFor="renovacao7" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    renovacao?.comprovativoPE ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {renovacao.comprovativoPE.name.slice(0, 10) + '...'}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Comprovativo de Pagamento de emolumentos
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="comprovativoPE" id="renovacao7" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>
                </Grid>
            </Card>

            {/* <DialogActions>
                <Button variant="contained" style={{ background: '#85287e' }} onClick={createrenovacao}>
                    Enviar
                </Button>
            </DialogActions> */}

            <br />
            <Alert severity="warning">Por favor preenche devidamente os formulários para actualização de dados.</Alert>
            <CadastroEstabelecimento
                tipoRegisto={'Inserção'}
                renovacao={renovacao}
                renovacaoResponse={renovacaoResponse}
                setRenovacao={setRenovacao}
                setSelected={setSelected}
                createrenovacao={createrenovacao}
            />

        </Container>
    )
}

