
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from "@mui/material";
import CardNoticias from './cardNoticias';
import 'swiper/css';
import 'swiper/css/pagination';
import HeaderSession from '../../../utils/headerSession';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import Loading from '../../../load/loading';
import RegisterAccess from '../../../utils/registerAccess';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    marginBottom: 20,
  },

  containerCardesNoticia: {
    marginTop: '1rem',
    display: 'flex',
    //  flexDirection: 'wrap',
    flexWrap: 'wrap',
    /* ALINHAR OS ITENS NA HORIZONTAL E NA VERTIVACL */
    justifyContent: 'center',
    alignItems: 'center',
    /* AFASTAR UM ITEM DO OUTRO */
    gap: '1.5rem',
  }
}))


export default function PageNoticias() {
  const classes = useStyles();
  const [noticias, setNoticias] = useState('')

  useEffect(() => {
    api.get(`/noticias?seccao=Notícia`)
      .then(response => {
        // console.log(response)
        setNoticias(response.data.noticias);
      }).catch(error => {
        // console.log(error)
      })
  }, [])



  return (
    <Container style={{ maxWidth: 800, minHeight: '70vh' }}>
     <RegisterAccess page={'noticias'} />
      <HeaderSession title='NOTÍCIAS' />

      <Box className={classes.containerCardesNoticia}>
        {noticias ?

          noticias.map((noticia) => (
            noticia.status === "Activo" &&
            <CardNoticias key={noticia._id} noticia={noticia} />
          ))
          :
          <Loading />
        }
      </Box>
      <br />
      <br />
    </Container>
  )
}
