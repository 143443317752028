import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../utils/headerSession'
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
}))

export default function Decretos() {
    const classes = useStyles()
    // <a href={''} target={'_blank'} rel="noreferrer">Ver decreto</a>
    return (
        <Container style={{ maxWidth: 800 }}>
            <RegisterAccess page={'decretos'} />
            <div>
                <HeaderSession title='DECRETOS' />

                <p className={classes.root} >
                    Decreto Presidencial nº 182/22, de 22 de Julho Aprova o processo de simplificação de procedimentos na Administração Pública.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n 18222 de 22 de Julho.pdf'} target={'_blank'} rel="noreferrer">Ver decreto </a>
                </p>
                <p className={classes.root} >
                    Decreto Executivo nº 1/22, Estabelece as condições  para realização do teste rápido pelas Farmácias.
                    <a href={'/decretos-e-despachos/Decreto Executivo nº 122.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 136/21, de 1 de Junho Aprova a Agência Reguladora de Medicamentos e Tecnologias de Saúde (ARMED).
                    <a href={'/decretos-e-despachos/Decreto-Presidencial-No-136-21-de-1-de-Junho-sobre-ARMED.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 161/21, de 21 de Junho Aprova o Projecto de Simplificação de Procedimentos na Administração Pública (SIMPLIFICA).
                    <a href={'/decretos-e-despachos/Decreto-Presidencial-N°-161-21-Simplifica.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº202/21, de 26 de Agosto Elimina a Exigência aos particulares, para efeitos da emissão da autorização para o exercicio Farmacêutica.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n-202-21-de-26 de Agosto.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Executivo nº 426/21,  de 16 de Setembro Aprova a Lista Nacional de Medicamentos Essenciais.
                    <a href={'/decretos-e-despachos/Decreto Executivo n-426-21-de-16 de Setembro.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº253/21, de 14 de Outubro Regulamento Sobre a Farmacovigilância.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n-253-21-de 14 de Outubro.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 253/20, de 02 de Outubro Aprova a Politica Nacional de Medicine Tradicional e Complementar.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n-253-20 de 02 de Outubro.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 277/20, de 26 de Outubro Aprova o Estatuto Orgânico do Ministério da Saúde.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n- 277-20 de 26 de Outubro.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 315/20, de 17 de Dezembro Aprova o Regulamento do Registo de Medicamentos.
                    <a href={'/decretos-e-despachos/Decreto-Presidencial-315.20-Regulamento-registo-medicamentos.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 202/19, de 25 de Junho Aprova o Regulamento da Lei da Administração Local do Estado. Revoga toda a legislação que contrarie o disposto no presente Diploma, nomeadamente o decreto Presidencial nº 208/17, de 22 de Setembro.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n- 202-19 de 25 de Junho.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 21/18, de 20 de Janeiro  Presidente da República Aprova o Estatuto Orgânico do Ministério da Saúde – Revoga toda a legislação que contrarie o dispositivo no presente Diploma, nomeadamente o decreto presidencial nº 178/13, de 6 de Novembro.
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 5/18, de 10 de Maio (Lei da Concorrência, que estabelece principios e regras reguladoras da Concorrência).
                </p>
                <p className={classes.root} >
                    Decreto Presidencial 269/14,  de 22 de Setembro Cria a Central de Compras e Aprovisionamento de Medicamentos e Meios Médicos de Angola.
                    <a href={'/decretos-e-despachos/Decreto Presidencial 269-14  de 22 de Setembro.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 275/11, de 28 de Outubro   Aprova, em anexo ao presente Decreto Presidencial e que dele é pate integrante, o Regulamento de Análises Laboratoriais de Mercadorias Importadas e de Produção Nacional, adiante designado por Regulamento.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n- 275-11 de 28 de Outubro.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                {/* <p className={classes.root} >
                    Decreto Presidencial nº 289/10, de         Aprova o Regulamento Sobre a Organização, Exercício e Funcionamento da Actividade de Comércio a retalho.
                </p> */}
                <p className={classes.root} >
                    Decreto Presidencial nº 289/10, de 30 de Novembro Aprova o Regulamento Sobre a Organização, Exercício e Funcionamento da Actividade de Comércio a Grosso, anexo a este Decreto Presidencial e que dele é parte integrante.
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 263/10, de 25 de Novembro Aprova o Regulamento Sobre a Organização, Exercício e Funcionamento do Comércio a Retalho, anexo ao presente diploma e que dele faz parte integrante.
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 262/10, de 24 de Novembro Aprova a Política Nacional de Saúde, anexa ao presente Decreto Presidencial e que dele é parte integrante.
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 262/10, de 1 de Setembro Aprovado o Regulamento do Exercício da Actividade Farmacêutica, anexo ao presente Decreto Presidencial e dele e parte integrante.
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 191/10, de 1 de Setembro Aprova o Regulamento da Actividade Farmacêutica.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n-191-10 de 1 de Setembro.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 180/10, de 18 de Agosto Estabelece as Bases Gerais da Política Nacional Farmacêutica.
                    <a href={'/decretos-e-despachos/Decreto Presidencial n-180-10 de 18 de Agosto.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 388/10 de, 30 de Novembro Regulamento sobre o Licenciamento e de Prestação de Serviços Mercantis.
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 54/03, de 5 de Agosto Aprova o regulamento geral das unidades sanitárias do Serviço Nacional de Saúde anexo ao presente decreto e que dele faz parte integrante.
                </p>
                <p className={classes.root} >
                    Decreto Presidencial nº 16/86, de 9 de Agosto Aprova o regulamento sobre a Assistência Médica e Medicamentosa na República Popular de Angola, que faz parte integrante do presente decreto.
                </p>
            </div>

            <br />
        </Container>
    )
}
