
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Button } from "@mui/material";
import CardNoticiaCaroussel from './cardNoticiaCaroussel';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState } from 'react';
import Loading from '../../../load/loading';
import api from '../../../services/api';
import 'swiper/css';
import 'swiper/css/pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 70,
    marginBottom: 20,
  },
}))


export default function CarousselNoticias() {
  const classes = useStyles();
  const navigate = useNavigate()
  const [noticias, setNoticias] = useState('')

  useEffect(() => {
    api.get(`/noticias?seccao=Notícia&perPage=10`)
      .then(response => {
        // console.log(response)
        setNoticias(response.data.noticias);
      }).catch(error => {
        // console.log(error)
      })
  }, [])

  return (
    <div className={classes.root} >

      <Container style={{ maxWidth: 800 }}>

        <Typography align="center" variant="h1" style={{ margin: '1rem', fontSize: 25, fontWeight: 'bold', }}>
          NOTÍCIAS
        </Typography>

        <Swiper
          breakpoints={{
            // Configurações quando a largura da tela for >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            // Configurações quando a largura da tela for >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            // Configurações quando a largura da tela for >= 768px
            768: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }
            // ... Adicione mais breakpoints conforme necessário
          }
          pagination={{
            clickable: true,
          }}

          modules={[Pagination]}
          className="mySwiper"
        >
          {noticias ?
            noticias.map((noticia) => (

              <SwiperSlide key={noticia._id}>
                <CardNoticiaCaroussel noticia={noticia} />
              </SwiperSlide>
            ))
            :
            <Loading />
          }
          <br />
          <br />
        </Swiper>

        <Box marginTop={3} sx={{ display: { md: 'none' } }} >
          <Button onClick={() => navigate(`/noticias`)} variant='outiline' fullWidth style={{ background: '#85287e', color: '#fff', }} >
            Ver mais notícias
          </Button>
        </Box>
      </Container>
    </div>
  )
}
