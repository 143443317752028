import { useEffect, useState } from 'react'
import { Avatar, Button, Card, DialogActions, Grid, MenuItem, Paper, TextField, Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import LoadingBackdrop from '../../load/loadingBackdrop'
import MessageError from '../../messages/messageError'
import apiCNP from '../../services/apiCNP'
import api from '../../services/api'
import { useSelector } from 'react-redux'
import MessageSuccess from '../../messages/messageSuccess'
import HeaderSession from '../../utils/headerSession'
import { useNavigate } from 'react-router-dom';
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'end',
    },

    gridItem: {
        padding: 5,
        // background: 'red'
    },
}))

export default function CadastroAdmin() {
    const navegate = useNavigate()
    const classes = useStyles()
    const [dadosAdmin, setDadosAdmin] = useState('')
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setErrorMessage] = useState('')
    const [load, setLoad] = useState(false)
    const user = useSelector(state => state.account.user);
    const [perfil, setPerfil] = useState('')

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            }).catch(err => '')
    }, [setProvincias])

    function HandleChange(e) {

        e.target.files ?
            setDadosAdmin({ ...dadosAdmin, [e.target.name]: e.target.files[0] })
            :
            setDadosAdmin({ ...dadosAdmin, [e.target.name]: e.target.value })

        e.target.name === 'provincia' &&
            apiCNP.get(`/municipios-by-provincia/${e.target.value._id}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                }).catch(err => '')
    }

    async function createAdmin() {

        const formData = new FormData();
        formData.append('foto', dadosAdmin?.foto ?? '');
        formData.append('nome', dadosAdmin?.nome ?? '');
        formData.append('numeroBI', dadosAdmin?.numeroBI ?? '');
        formData.append('email', dadosAdmin?.email ?? '');
        formData.append('tel1', dadosAdmin?.tel1 ?? '');
        formData.append('tel2', dadosAdmin?.tel2 ?? '');
        formData.append('genero', dadosAdmin?.genero ?? '');
        formData.append('provincia', dadosAdmin?.provincia.designacao ?? '');
        formData.append('municipio', dadosAdmin?.municipio.designacao ?? '');
        formData.append('bairro', dadosAdmin?.bairro ?? '');
        formData.append('rua', dadosAdmin?.rua ?? '');
        formData.append('userId', user?._id ?? '');
        formData.append('dataNascimento', dadosAdmin?.dataNascimento ?? '');
        formData.append('estadoCivil', dadosAdmin?.estadoCivil ?? '');
        formData.append('departamento', dadosAdmin?.departamento ?? '');
        formData.append('numeroAgente', dadosAdmin?.numeroAgente ?? '');
        formData.append('seccao', dadosAdmin?.seccao ?? '');
        formData.append('perfil', perfil?.perfil ?? dadosAdmin?.perfil);

        setLoad(true)
        setErrorMessage('')
        setMessageSuccess('')

        await api.post('/admin/register', formData)
            .then(res => {
                // console.log(res)
                setLoad(false)
                setMessageSuccess(res.data.msg)
                navegate('/admin/lista-de-usuarios-administradores')

            }).catch(error => {
                // console.log(error)
                setErrorMessage(error.response.data.message)
                setLoad(false)
            })
    }

    const departamentos = [
        { 'designacao': 'Apoio ao Director Geral', 'sigla': 'DADG', 'secoes': true },
        { 'designacao': 'Administração e Serviços Gerais', 'sigla': 'DASG', 'secoes': true },
        { 'designacao': 'Comunicação, Inovação Tecnológica e Modernização dos Serviços ', 'sigla': 'DCITMS', 'secoes': false },
        { 'designacao': 'Controlo de qualidade de Medicamentos e Tecnologias de Saúde', 'sigla': 'DCQMTS', 'secoes': true },
        { 'designacao': 'Director Geral', 'sigla': 'DG', 'secoes': true },
        { 'designacao': 'Farmacovigilância,  Tecnovigilância e Ensaios Clínicos', 'sigla': 'DFTEC', 'secoes': true },
        { 'designacao': 'Medicamentos e Tecnologias de Saúde', 'sigla': 'DMTS', 'secoes': true },
        { 'designacao': 'Licenciamento e Inspecção Farmacêutica', 'sigla': 'DLIF', 'secoes': true },
    ]

    const seccoes = ['Licenciamento', 'Inspecção']

    const perfils = [
        { 'designacao': 'Técnico(a)', 'perfil': 'Técnico', 'seccao': 'Licenciamento' },
        { 'designacao': 'Chefe de Secção', 'perfil': 'Chefe de Secção', 'seccao': 'Licenciamento' },
        { 'designacao': 'Chefe de Departamento', 'perfil': 'Chefe de Departamento', 'seccao': 'Licenciamento' },
        { 'designacao': 'Director(a) Geral', 'perfil': 'Director Geral', 'seccao': 'Licenciamento' },

        { 'designacao': 'inspector(a)', 'perfil': 'Inspector', 'seccao': 'Inspecção' },
        // { 'designacao': 'Sub-Inspector(a)', 'perfil': 'Sub-Inspector', 'seccao': 'Inspecção' },
        // { 'designacao': 'Inspector(a) Superior', 'perfil': 'Inspector Superior', 'seccao': 'Inspecção' },
        { 'designacao': 'Secretário(a)', 'perfil': 'Secretário(a)', 'seccao': 'Licenciamento' },
        // { 'designacao': 'Finança', 'perfil': 'Finança', 'seccao': 'Licenciamento' },
    ]

    return (
        <Container>
           <RegisterAccess page={'cadastro de admin'}/>
            <HeaderSession title={'CADASTRO DE ADMINISTRADOR'} />

            <Card style={{ padding: 20 }}>

                {messageSuccess && <MessageSuccess message={messageSuccess} />}
                {messageError && < MessageError message={messageError} />}
                <LoadingBackdrop open={load} text={`A cadastrar ${' ' + dadosAdmin?.nome}`} />

                <Grid container className={classes.root}>
                    <Grid xs={12} md={2} item className={classes.gridItem} >
                        <Paper style={{ border: '1px dashed #3e3d3f' }}>
                            <label htmlFor="foto" style={{ cursor: 'pointer', textAlign: 'center' }}>
                                {dadosAdmin?.foto ?
                                    <>
                                        {/* <img src={URL.createObjectURL(representante.foto)} alt="Imagem" width="100%" title="alterar foto.." /> */}
                                        <Avatar style={{ width: '100%', height: 145 }} variant="square" src={URL.createObjectURL(dadosAdmin?.foto)} title="Clique para carregar uma imagem" />
                                    </>
                                    :
                                    <div style={{ padding: 7 }}>
                                        <img style={{ height: 115 }} src='/img/avatar/avatarRepresentante.svg' title="Clique para carregar uma imagem" />
                                        <p>Foto</p>
                                    </div>
                                }
                                <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="foto" style={{ display: 'none' }} onChange={HandleChange} />
                            </label>
                        </Paper>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            required
                            label="Nome"
                            fullWidth
                            size="small"
                            name="nome"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.nome}
                        />

                        <TextField
                            style={{ marginTop: 15 }}
                            type="text"
                            required
                            label="Número de B.I"
                            fullWidth
                            size="small"
                            name="numeroBI"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.numeroBI}
                        />

                        <Grid container className={classes.root}>
                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    style={{ marginTop: 10, maxWidth: 300 }}
                                    type="text"
                                    label="Departamento de:"
                                    select
                                    fullWidth
                                    size="small"
                                    name="departamento"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={dadosAdmin?.departamento || ''}
                                >
                                    {departamentos.map((departamento) => (
                                        <MenuItem key={departamento.sigla} value={departamento.sigla}>
                                            {departamento.designacao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            < Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    // {/* CONDICAO ESPECIFICA DEPARTAMENTO DE DCITMS */}
                                    disabled={dadosAdmin.departamento === 'DCITMS'}
                                    style={{ marginTop: 10 }}
                                    type="text"
                                    label="Secção"
                                    select
                                    fullWidth
                                    size="small"
                                    name="seccao"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={dadosAdmin?.seccao || ''}
                                >
                                    {seccoes.map((seccao) => (
                                        <MenuItem key={seccao} value={seccao}>
                                            {seccao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </ Grid>

                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    style={{ marginTop: 10 }}
                                    type="text"
                                    label="Perfil"
                                    select
                                    fullWidth
                                    size="small"
                                    name="perfil"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={dadosAdmin?.perfil?.designacao || ''}
                                >
                                    {perfils.map((perfil) => (

                                        (dadosAdmin?.seccao === 'Inspecção' && perfil?.seccao === 'Inspecção') ?
                                            <MenuItem onClick={() => setPerfil(perfil)} key={perfil.designacao} value={perfil.designacao}>
                                                {perfil.designacao}
                                            </MenuItem>
                                            :
                                            dadosAdmin?.seccao === 'Licenciamento' && perfil?.seccao === 'Licenciamento') && (
                                            <MenuItem onClick={() => setPerfil(perfil)} key={perfil.designacao} value={perfil.designacao}>
                                                {perfil.designacao}
                                            </MenuItem>
                                        ))}

                                    {/* CONDICAO ESPECIFICA DEPARTAMENTO DE DCITMS */}
                                    {dadosAdmin.departamento === 'DCITMS' &&
                                        <MenuItem key={'Técnico de TI'} value={'Técnico de TI'}>
                                            Técnico(a) de TI
                                        </MenuItem>
                                    }
                                </TextField>
                            </Grid>

                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    style={{ marginTop: 15 }}
                                    type="number"
                                    required
                                    label="Número de Agente"
                                    fullWidth
                                    size="small"
                                    name="numeroAgente"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={dadosAdmin?.numeroBI}
                                />

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>
                    <Grid xs={12} md={4} item className={classes.gridItem}>
                        <TextField
                            type="text"
                            label="Genero"
                            select
                            fullWidth
                            size="small"
                            name="genero"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.genero || ''}
                        >
                            <MenuItem key={'M'} value={'M'} name="genero">
                                Masculino
                            </MenuItem>
                            <MenuItem key={'F'} value={'F'} name="genero">
                                Feminino
                            </MenuItem>
                        </TextField>
                    </Grid>

                    <Grid xs={12} md={4} item className={classes.gridItem}>
                        <TextField
                            fullWidth
                            label="Data de Nascimento"
                            type="date"
                            size="small"
                            name="dataNascimento"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.dataNascimento}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid xs={12} md={4} item className={classes.gridItem}>
                        <TextField
                            type="text"
                            label="Estado civil"
                            select
                            fullWidth
                            size="small"
                            name="estadoCivil"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.estadoCivil || ''}
                        >
                            <MenuItem key="s" value="Solteiro(a)">
                                Solteiro(a)
                            </MenuItem>
                            <MenuItem key="c" value="Casado(a)">
                                Casado(a)
                            </MenuItem>
                            <MenuItem key="d" value="Divorciado(a)">
                                Divorciado(a)
                            </MenuItem>
                            <MenuItem key="v" value=" Viúvo(a)">
                                Viúvo(a)
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            required
                            label="Telefone1"
                            fullWidth
                            size="small"
                            name="tel1"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.tel1}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            label="Telefone2"
                            fullWidth
                            size="small"
                            name="tel2"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.tel2}
                        />
                    </Grid>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="email"
                            required
                            label="Email"
                            fullWidth
                            size="small"
                            name="email"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.email}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Provincia"
                            select
                            fullWidth
                            size="small"
                            name="provincia"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.provincia || ''}
                        >
                            {provincias?.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        {dadosAdmin?.provincia ?
                            <TextField
                                required
                                type="text"
                                label="Municipio"
                                select
                                fullWidth
                                size="small"
                                name="municipio"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={dadosAdmin?.municipio || ''}
                            >
                                {municipios?.map((municipio) => (
                                    <MenuItem key={municipio._id} value={municipio}>
                                        {municipio.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                            :
                            <TextField
                                type="text"
                                disabled
                                label="Municipio"
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                        }
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Bairro"
                            fullWidth
                            size="small"
                            name="bairro"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.bairro}
                        />

                    </Grid>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Rua"
                            fullWidth
                            size="small"
                            name="rua"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosAdmin?.bairro}
                        />
                    </Grid>
                </Grid>

                <DialogActions>
                    <Button disabled={!dadosAdmin.nome} variant="contained" color='secondary' style={{ background: '#85287e' }} onClick={createAdmin}>
                        Cadastrar
                    </Button>
                </DialogActions>
            </Card>
        </Container >
    )
}
