
import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../utils/headerSession'
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 50,
        // textAlign: 'center',
        color: '#67348d'
    },
}))
export default function EnsaiosClinicos() {
    const classes = useStyles()
    return (
        <Container style={{ maxWidth: 800 }}>
            <RegisterAccess page={'ensaios clinicos'} />
            <HeaderSession title='ENSAIOS CLÍNICOS' />

            <p className={classes.root} >
                Os ensaios/ estudos clínicos devem ser realizados no estrito respeito pelo princípio
                da dignidade da pessoa humana e dos seus direitos fundamentais.
            </p>
            <div>
                <h1 className={classes.title}>
                    O que é um ensaio/ estudo clínico?
                </h1>
                <br />
                <p className={classes.root} >
                    Um estudo clínico com intervenção, qualquer investigação que preconize uma alteração, influência
                    ou programação dos cuidados de saúde, dos comportamentos ou dos conhecimentos dos
                    participantes ou cuidadores, com a finalidade de descobrir ou verificar efeitos na saúde, incluindo
                    a exposição a medicamentos, a utilização de dispositivos médicos, a execução de técnicas
                    cirúrgicas, a exposição a radioterapia, a aplicação de produtos cosméticos e de higiene corporal, a
                    intervenção de fisioterapia, a intervenção de psicoterapia, o uso de transfusão, a terapia celular, a
                    participação em sessões de educação individual ou em grupo, a intervenção com regime
                    alimentar, a intervenção no acesso ou organização dos cuidados de saúde ou a intervenção
                    designada como terapêutica não convencional.
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    As tecnologias de saúde também são sujeitas a estudos clínicos?
                </h1>
                <br />
                <p className={classes.root} >
                    Um estudo clínico de dispositivo médico/tecnologia de saúde, é qualquer estudo
                    com dispositivos médicos ou respectivos acessórios, e cujo objectivo inclua:
                </p>
                <p className={classes.root} >
                    a) Verificar o nível de desempenho do dispositivo;
                </p>
                <p className={classes.root} >
                    b) Determinar eventuais efeitos secundários indesejáveis em condições normais de
                    utilização e avaliar se constituem riscos em função da utilização prevista para
                    o dispositivo segundo a leges artis;
                </p>
                <p className={classes.root} >
                    c) Realizar o acompanhamento clínico pós-comercialização;
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    O que é um medicamento experimental?
                </h1>
                <br />
                <p className={classes.root} >
                    Um medicamento experimental, é uma forma farmacêutica de uma substância activa ou placebo,
                    testada ou utilizada como referência num ensaio clínico, incluindo os medicamentos cuja
                    introdução no mercado tenha sido autorizada, mas sejam utilizados ou preparados, quanto
                    à forma farmacêutica ou acondicionamento, de modo diverso da forma autorizada, ou sejam
                    utilizados para uma indicação não autorizada ou destinados a obter mais informações sobre
                    a forma autorizada.
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Quem autoriza a realização de ensaios clínicos em Angola?
                </h1>
                <br />
                <p className={classes.root} >
                    A ARMED regulamenta e autoriza a realização de ensaios clínicos de medicamentos
                    e tecnologias de saúde, trabalhando em conjunto com o Comité de Ética do Ministério
                    da Saúde, segundo o Decreto Presidencial nº 136/21 de 1 de Junho.
                    <a href={'/decretos-e-despachos/Decreto-Presidencial-No-136-21-de-1-de-Junho-sobre-ARMED.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Quem autoriza a importação de amostras de medicamentos para a realização de ensaios clínicos?
                </h1>
                <br />
                <p className={classes.root} >
                    A introdução no país de qualquer amostra de medicamentos, deve ser previamente autorizada pela ARMED
                    (Decreto Presidencial nº 180/10 de 18 de Agosto).
                    <a href={'/decretos-e-despachos/Decreto Presidencial n-180-10 de 18 de Agosto.pdf'} target={'_blank'} rel="noreferrer">Ver decreto</a>
                </p>
            </div>
            <br />
        </Container>
    )
}
