import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../utils/headerSession'
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
}))

export default function Outros() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
         <RegisterAccess page={'outros'} />
            <HeaderSession title='OUTROS' />

            <div>
                <p className={classes.root} >
                    Manual de Diagnóstico e Tratamento da Malária em Angola.
                </p>
                <p className={classes.root} >
                    CIRCULAR Nº 002/ARMED/MINSA/2022, de 14 de Abril- Sobre o Credenciamento Obrigatório para o atendimento dos Utentes.
                </p>
                <p className={classes.root} >
                    CIRCULAR 01/DNME/MINSA/2021, de 06 de Janeiro- Sobre requisitos para legalização de representação de Laboratório Farmacêutico.
                </p>
                <p className={classes.root} >
                    CIRCULAR 01/DNME/MINSA/2020, de 06 de Fevereiro- Sobre a  Lista dos Medicamentos e Produtos de Saúde sujeitos ao controlo pelo Ministério da Saúde.
                </p>
                <p className={classes.root} >
                    CIRCULAR 02/DNME/MNSA/2020, de 06 de Fevereiro-Sobre Requisitos para emissão de Certificados de  Importação de Medicamentos e Produtos de Saúde sujeitos a Controlo pelo Ministério da Saúde.
                </p>
                <p className={classes.root} >
                    CIRCULAR 03/DNME/MINSA/2019, de 25 de Junho- Sobre aquisicção de produtos farmacêuticos sujeitos às convenções internacionais.
                </p>
                <p className={classes.root} >
                    CIRCULAR 29/GIG /2019, de 12 de Dezembro-Sobre proibição de transporte de Produtos Farmacêuticos, Drogas e Plantas Medicinais, Dispositivos Médicos e Equipamentos de Protecção Individual.
                </p>
                <p className={classes.root} >
                    CIRCULAR 03/DNME/MINSA /2018, de 12 de Junho- Sobre Requisitos para a importação e comercialização de testes rápido no mercado nacional.
                </p>
                <p className={classes.root} >
                    CIRCULAR 04/DNME/MINSA /2016-Sobre instalação de uma fabrica de Medicamentos e outros produtos de Saúde.
                </p>
                <p className={classes.root} >
                    CIRCULAR 01/DNME/MINSA/2015, de 09 de Janeiro-Sobre importação e comercialização de Suplementos Alimentares, Dietéticos e Anabolizantes.
                </p>
                <p className={classes.root} >
                    CIRCULAR 13/DNME/MINSA/2014, de 08 de Setembro- Sobre Transportação de produtos Farmacêuticos.
                </p>
                <p className={classes.root} >
                    CIRCULAR 11/DNME/MINSA 2013, de 04 de Outubro- Sobre aviamento de Medicamentos.
                </p>
                <p className={classes.root} >
                    CIRCULAR 12/DNME/MINSA/2013,de 13 de Fevereiro-Sobre Inutilização de produtos Farmacêuticos.
                </p>
            </div>

            <br />
        </Container>
    )
}
