
import { makeStyles } from '@material-ui/core';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    root: {
        transition: '.3s',
        '&:hover': {
            width: 25
        },
    },
})

export default function ButtonEditar({ permitionEdit, setEdit, edit }) {
    const classes = useStyles()
    const user = useSelector(state => state.account.user);

    return (
        <>
            {/* {(user?.tipo === 'Admin' || permitionEdit) && */}

            {edit ?
                <IconButton onClick={() => { setEdit(false) }} size="small" title="Fechar" style={{ marginLeft: 'auto' }}>
                    <CloseIcon />
                    {/* <img src="/icones/menu/editar.svg" alt="" width={20} className={classes.root} /> */}
                </IconButton>
                :
                <IconButton onClick={() => { setEdit(true) }} size="small" title="Editar" style={{ marginLeft: 'auto' }}>
                    <img src="/icones/editar.svg" alt="" width={20} className={classes.root} />
                </IconButton>
            }

            {/* } */}
        </>
    )
}