
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from "@mui/material";
import CardNoticiaCaroussel from './cardNoticiaCaroussel';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState } from 'react';
import Loading from '../../../load/loading';
import api from '../../../services/api';
import 'swiper/css';
import 'swiper/css/pagination';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
    marginBottom: 20,
    padding: 20,
    // background:'#85287e',

    display: "flex",
    alignItems: "center",
    minHeight: '40vh',
    backgroundImage: 'url(/img/backGrounds/BgComprimido2.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    boxShadow: '1rem 1rem 1rem #e7f0ed',
  },
}))


export default function CarousselDestaques() {
  const classes = useStyles();
  const [noticias, setNoticias] = useState('')

  useEffect(() => {
    api.get(`/noticias?seccao=Destaque&perPage=10`)
      .then(response => {
        // console.log(response) 
        setNoticias(response.data.noticias);
      }).catch(error => {
        // console.log(error)
      })
  }, [])


  return (
    <div className={classes.root} >

      <Container style={{ maxWidth: 800 }}>

        <Typography align="center" variant="h1" style={{margin: '1rem', fontSize: 25, fontWeight: 'bold', color: '#fff' }}>
          DESTAQUES
        </Typography>

          <Swiper
            breakpoints={{
              // Configurações quando a largura da tela for >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 10
              },
              // Configurações quando a largura da tela for >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              // Configurações quando a largura da tela for >= 768px
              768: {
                slidesPerView: 3,
                spaceBetween: 20
              }
            }
              // ... Adicione mais breakpoints conforme necessário
            }
            pagination={{
              clickable: true,
            }}

            modules={[Pagination]}
            className="mySwiper"
          >
            {noticias ?
              noticias.map((noticia) => (
                <SwiperSlide key={noticia._id}>
                  <CardNoticiaCaroussel id={noticia._id} image={noticia.imagem} title={noticia.titulo} />
                </SwiperSlide>
              ))
              :
              <Loading />
            }
            <br />
            <br />
          </Swiper>
      </Container>
    </div>
  )
}
