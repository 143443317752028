import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import api from '../../../../../services/api';
import { useSelector } from 'react-redux';
import LoadingShort from '../../../../../load/loadingShort';

const DeleteImageInspeccao = (props) => {
    const [load, setLoad] = useState(false);
    const user = useSelector(state => state.account.user);

    const HandleDeleteAgenda = () => {
        props.setMessageSuccess('')
        props.setMessageError('')
        setLoad(true)

        api.patch('/inspeccao/remove-foto', { 'inspeccaoId': props.inspeccaoId, 'index': props.index, 'userId': user._id })
            .then(response => {
                console.log(response)
                setLoad(false)
                props.setMessageSuccess('Eliminado com sucesso.')
                props.setRefresh(response)

            }).catch(err => {
                console.log(err)
                setLoad(false)
                props.setMessageError('Erro ao eliminar')
            })
    }

    return (
        <span style={{ position: 'absolute', left: 10 }}>
            <IconButton size='large' disabled={load} title='Eliminar esta imagem' onClick={HandleDeleteAgenda} color="error">{load ? <LoadingShort /> : <DeleteIcon fontSize='large' />}</IconButton>
        </span>
    );
}

export default DeleteImageInspeccao;
