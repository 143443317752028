import { Button } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";


export default function DetalhesHistoricoActividade({ historicosActividade }) {
    const navigate = useNavigate()

    // console.log(historicosActividade)

    return (
        <>
            {historicosActividade.modelName === "Empresa"&&
                <Button color="inherit" style={{ fontSize: 11 }} onClick={() => { navigate(`/admin/get-empresa/${historicosActividade.modelId}`) }}>
                    <VisibilityIcon fontSize="small" />
                </Button>
            }
            {historicosActividade.modelName === "Estabelecimento" &&
                <Button color="inherit" style={{ fontSize: 11 }} onClick={() => { navigate(`/admin/gestor-licenciados/${historicosActividade.modelId}`) }}>
                    <VisibilityIcon fontSize="small" />
                </Button>
            }
            {historicosActividade.modelName === "SolicitacaoRenovacao" &&
                <Button color="inherit" style={{ fontSize: 11 }} onClick={() => { navigate(`/admin/gestor-de-renovacao/${historicosActividade.modelId}`) }}>
                    <VisibilityIcon fontSize="small" />
                </Button>
            }
            {historicosActividade.modelName === "Representante" &&
                <Button color="inherit" style={{ fontSize: 11 }} onClick={() => { navigate(`/admin/get-representante/${historicosActividade.modelId}`) }}>
                    <VisibilityIcon fontSize="small" />
                </Button>
            }
        </>
    )
}