
import api from "./api";

class CadastrosService {

    cadastroRepresentante = (dadosRepresentante, user) => {

        const formData = new FormData();
        formData.append('perfil', 'Representante');
        formData.append('foto', dadosRepresentante?.foto);
        formData.append('nome', dadosRepresentante?.nome);
        formData.append('numeroBI', dadosRepresentante?.numeroBI);
        formData.append('email', dadosRepresentante?.email);
        formData.append('tel1', dadosRepresentante?.tel1);
        formData.append('tel2', dadosRepresentante?.tel2);
        formData.append('genero', dadosRepresentante?.genero);
        formData.append('provincia', dadosRepresentante?.provincia ?? null);
        formData.append('municipio', dadosRepresentante?.municipio ?? null);
        formData.append('bairro', dadosRepresentante?.bairro);
        formData.append('rua', dadosRepresentante?.rua);
        formData.append('userId', user?._id ?? '');
        //  for (let dado of formData.values()) { console.log(dado); }

        return new Promise(async (resolve, reject) => {
            await api.post('/representante/register', formData)
                .then(res => {
                    resolve(res)

                }).catch(error => {
                    reject(error)
                })
        });
    };

    cadastroEmpresa = (dadosEmpresa, representanteId, user) => {
        // console.log(dadosEmpresa)

        const formData = new FormData();
        formData.append('logo', dadosEmpresa?.logo);
        formData.append('nome', dadosEmpresa?.nome);
        formData.append('nif', dadosEmpresa?.nif);
        formData.append('email', dadosEmpresa?.email);
        formData.append('tel1', dadosEmpresa?.tel1);
        formData.append('tel2', dadosEmpresa?.tel2 ?? '');
        formData.append('provincia', dadosEmpresa?.provincia);
        formData.append('municipio', dadosEmpresa?.municipio);
        formData.append('bairro', dadosEmpresa?.bairro);
        formData.append('rua', dadosEmpresa?.rua);
        formData.append('representanteId', representanteId);
        formData.append('userId', user?._id ?? '');

        //  for (let dado of formData.values()) { console.log(dado); }

        return new Promise(async (resolve, reject) => {
            await api.post('/empresa/register', formData)
                .then(res => {
                    resolve(res)

                }).catch(error => {
                    reject(error)
                })
        });
    };

    cadastroDirectorTecnico = (dadosdirectorTecnico, user, dadosRepresentante) => {

        const formData = new FormData();
        formData.append('perfil', 'Director Técnico');
        formData.append('foto', dadosdirectorTecnico?.foto ?? null);
        formData.append('nome', dadosdirectorTecnico?.nome ?? null);
        formData.append('numeroBI', dadosdirectorTecnico?.numeroBI ?? null);
        formData.append('email', dadosdirectorTecnico?.email ?? null);
        formData.append('tel1', dadosdirectorTecnico?.tel1 ?? null);
        formData.append('tel2', dadosdirectorTecnico?.tel2 ?? null);
        formData.append('genero', dadosdirectorTecnico?.genero ?? null);
        formData.append('provincia', dadosdirectorTecnico?.provincia ?? null);
        formData.append('municipio', dadosdirectorTecnico?.municipio ?? null);
        formData.append('bairro', dadosdirectorTecnico?.bairro ?? null);
        formData.append('rua', dadosdirectorTecnico?.rua ?? null);
        formData.append('categoriaId', dadosdirectorTecnico?.categoriaId);
        formData.append('ordem', dadosdirectorTecnico?.ordem);
        formData.append('numeroCarteira', dadosdirectorTecnico?.numeroCarteira);
        formData.append('userId', user?._id ?? '');

        // for (let dado of formData.values()) { console.log(dado); }

        return new Promise(async (resolve, reject) => {
            await api.post(
                dadosdirectorTecnico?.usedataRepresentante === 'nao'
                    ? '/director-tecnico/register'
                    : '/representante/become-tecnico',
                dadosdirectorTecnico?.usedataRepresentante === 'nao'
                    ? formData
                    : { ...dadosdirectorTecnico, representanteId: dadosRepresentante?._id ?? localStorage.getItem('representanteId') }
            ).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    };


    cadastroEstabelecimento = (tipoRegisto, dadosCadastroEstabelecimento, user, directorTecnicoId, dadosEmpresa, renovacao) => {
        // console.log(renovacao)

        const formData = new FormData();
        formData.append('userId', user?._id ?? '');
        formData.append('tipoRegisto', tipoRegisto ?? 'Inscrição');
        formData.append('nome', dadosCadastroEstabelecimento.nome);
        formData.append('email', dadosCadastroEstabelecimento.email ?? dadosEmpresa.email);
        formData.append('provincia', dadosCadastroEstabelecimento?.provincia);
        formData.append('municipio', dadosCadastroEstabelecimento?.municipio);
        formData.append('bairro', dadosCadastroEstabelecimento?.bairro);
        formData.append('rua', dadosCadastroEstabelecimento?.rua);
        formData.append('empresaId', dadosEmpresa?._id ?? localStorage.getItem('empresaId'));
        formData.append('directorTecnicoId', directorTecnicoId);
        formData.append('tipoId', dadosCadastroEstabelecimento?.tipoId);
        formData.append('classeId', dadosCadastroEstabelecimento?.classeId ?? '');
        formData.append('carteiraArquitecto', dadosCadastroEstabelecimento?.carteiraArquitecto ?? '');
        formData.append('numeroProcesso', renovacao?.numeroAutorizacao ?? '');
        formData.append('numeroAutorizacao', renovacao?.numeroAutorizacao ?? '');
        // DOCUMENTOS ANEXADOS
        formData.append('logo', dadosCadastroEstabelecimento?.logo);
        formData.append('requerimentoARMED', dadosCadastroEstabelecimento?.requerimentoARMED);
        formData.append('copiaDI', dadosCadastroEstabelecimento?.copiaDI);
        formData.append('escrituraCS', dadosCadastroEstabelecimento?.escrituraCS);
        formData.append('croquis', dadosCadastroEstabelecimento?.croquis);
        formData.append('termoRDT', dadosCadastroEstabelecimento?.termoRDT);
        formData.append('certificadoND', dadosCadastroEstabelecimento?.certificadoND);
        formData.append('comprovativoPE', dadosCadastroEstabelecimento?.comprovativoPE);

        // for (let dado of formData.values()) { console.log(dado); }

        return new Promise(async (resolve, reject) => {
            await api.post('/estabelecimento/register', formData)
                .then(res => {
                    resolve(res)

                }).catch(error => {
                    reject(error)
                })
        });
    };
}
const cadastrosService = new CadastrosService();

export default cadastrosService;
