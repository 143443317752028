import { combineReducers } from "redux";
// import authService from "../services/authService";
import accountReducer from "./accountReducer";
import DadosEmpresaReducer from "./dadosEmpresaReducer";
import DadosdirectorTecnicoReducer from "./dadosdirectorTecnicoReducer";
import DadosPessoalReducer from "./DadosPessoalReducer";
import RequerimentotReducer from "./RequerimentoReducer";
import DadosProfissionalReducer from "./DadosProfissionalReducer";
import FicheirostReducer from "./ficheirosReducer";
import CadastroEstabelecimentoReducer from "./cadastroEstabelecimentoReducer";
import DadosRepresentanteReducer from "./dadosRepresentanteReducer";
import EstabelecimentoLicenciadosReducer from "./estabelecimentoLicenciadosReducer";
import PedidosLicenciamentosReducer from "./pedidosLicenciamentosReducer";
import EmpresasReducer from "./empresasReducer";
import RepresentantesReducer from "./representantesReducer";
import DirectoresTecnicosReducer from "./directoresTecnicosReducer";
import alert from "./alertReducer";
import agenda from "./agendaReducer";
import inspeccao from "./inspecaoReducer";


const rootReducer = combineReducers({
    account: accountReducer,
    dadospessoal: DadosPessoalReducer,
    dadosEmpresa: DadosEmpresaReducer,
    dadosdirectorTecnico: DadosdirectorTecnicoReducer,
    dadosprofissional: DadosProfissionalReducer,
    requerimento: RequerimentotReducer,
    ficheiros: FicheirostReducer,
    dadosCadastroEstabelecimento: CadastroEstabelecimentoReducer, 
    dadosRepresentante: DadosRepresentanteReducer, 
    estabelecimentoLicenciados: EstabelecimentoLicenciadosReducer, 
    PedidosLicenciamentosReducer: PedidosLicenciamentosReducer, 
    empresas: EmpresasReducer, 
    representantes: RepresentantesReducer, 
    directoresTecnicos: DirectoresTecnicosReducer, 
    alert: alert, 
    agenda: agenda, 
    inspeccao: inspeccao, 
})

export default rootReducer 