// import CircularProgress from '@mui/material/CircularProgress';
import './Loading.css'; // Arquivo de estilos CSS para o componente de loading

export default function LoadingShort({ text }) {
    return (
        // <div align='center'>
        //     <CircularProgress size={15} disableShrink thickness={2} />
        //     <br/>
        //     {text && <small style={{ fontFamily: 'Work Sans' }} >{text}</small>}
        // </div>

        <div className="loading-container" >
        <div className="loaderShort"></div>
        {/* <p>Carregando...</p> */}

        {
            text && <p >{text}</p>
        }
    </div>
    )
}