import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../services/api'
import Loading from '../../../load/loading'
import { Container } from '@mui/material'
import QRCodeEstabelecimento from '../qrCodeEstabelecimento'


export default function ExportQRcodeEstabelecimento() {
    const { id } = useParams()
    const [estabelecimento, setestabelecimento] = useState('')
    const baseUrl = window.location.origin;
    

    useEffect(() => {
        api.get(`/estabelecimento/${id}`)
            .then(response => {
                // console.log(response)
                setestabelecimento(response.data.estabelecimento);
            }).catch(err => console.log(err))
    }, [id])


    return (
        <Container style={{textAlign:'center'}}>
            {
                estabelecimento
                    ? <QRCodeEstabelecimento link={`${baseUrl}/verifica-estabelecimento/${id}`} estabelecimento={estabelecimento} />
                    : <Loading />
            }
        </Container>
    )
}
