import { Card } from "@mui/material";
import { Button, DialogActions, Grid, TextField, MenuItem, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MessageAlert from "../../../../../messages/messageAlert";
import LoadingBackdrop from '../../../../../load/loadingBackdrop';
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../../services/api";
import HeaderGestorConfigs from "../../../../../utils/headerGestorConfigs";
import FazerObservacoes from "../../fazerObservacoes.";
import * as yup from 'yup'; // VALIDACAO
import CardInspeccao from "./cardInspeccao";
import RegisterAccess from "../../../../../utils/registerAccess";

export default function CreateInspecao() {
    const user = useSelector(state => state.account.user);
    const { id } = useParams()
    const navigate = useNavigate()
    const [admins, setAdmins] = useState('')
    const [inspecao, setInspecao] = useState({})
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false)
    const [obs, setObs] = useState('')
    const [inspeccaoCriada, setInspeccaoCriada] = useState('')
    const dispatch = useDispatch()


    useEffect(() => {
        api.get('/admins')
            .then(response => {
                setAdmins(response.data.Admins)
            }).catch(err => '')
    }, [])

    function HandleChange(e) {

        e.target.files ?
            setInspecao({ ...inspecao, [e.target.name]: e.target.files[0] })
            :
            setInspecao({ ...inspecao, [e.target.name]: e.target.value })
    }

    // const createInspeccao = () => {
    //     const [ano, mes, dia] = inspecao.data.split('-').map(Number);
    //     // const [hora, minuto] = inspecao.hora.split(':').map(Number);

    //     setMessageSuccess('')
    //     setMessageError('')
    //     setLoad('A marcar inspecção... Aguarde')

    //     api.post('/inspecao/open', {
    //         ...inspecao, ano, mes, dia, 'estabelecimentoId': id, 'userId': user._id,
    //     }).then(response => {
    //         // console.log(response)

    //         api.patch('/estabelecimento/change-status', { estabelecimentoId: id, 'status': 'Inspecção Agendada', userId: user._id })
    //             .then(res => {
    //                 obs && HandleCreateobservacao()
    //             }).catch(err => { '' })

    //         setLoad(false)
    //         setMessageSuccess(response.data.msg)

    //         dispatch({
    //             type: 'alert',
    //             payload: { alert: 'Inspecção criada com sucesso.' },
    //           });

    //         window.setTimeout(() => {
    //             navigate(`/admin/solicitacoes-de-licenciamento`)
    //         }, 3000);

    //     }).catch(err => {
    //         // console.log(err)
    //         setLoad(false)
    //         setMessageError(err.response.data.message)
    //     })
    // }


    const createInspeccao = async () => {
        const [ano, mes, dia] = inspecao.data.split('-').map(Number);

        setMessageSuccess('');
        setMessageError('');
        setLoad('A marcar inspecção... Aguarde');

        try {
            const response = await api.post('/inspeccao/create', {
                ...inspecao, ano, mes, dia, 'estabelecimentoId': id, 'userId': user._id,
            });
            // console.log(response)
            setMessageSuccess(response.data.msg);
            setInspeccaoCriada(response.data.inspeccao);
            setLoad(false);

            try {
                await api.patch('/estabelecimento/change-status', {
                    estabelecimentoId: id, 'status': 'Inspecção Agendada', userId: user._id
                });

                if (obs) {
                    HandleCreateobservacao();
                }

            } catch (err) {
                setMessageError('Erro ao atualizar o status do estabelecimento.');
            }

            dispatch({
                type: 'alert',
                payload: { alert: 'Inspecção criada com sucesso.' },
            });

        } catch (err) {
            // console.log(err)
            setLoad(false);
            setMessageError(err.response?.data?.message || 'Erro ao criar a inspecção.');
        }
    };


    async function HandleCreateobservacao() {

        await api.post('/observacao/create', {
            assunto: 'Aprovação',
            descricao: obs,
            userId: user._id,
            estabelecimentoId: id
        }).then(res => {
            // console.log(res)

        }).catch(error => {
            setMessageError(error.response.data.message)
        })
    }

    const inspecaoTipos = ['Licenciamento', '']

    async function validate() {
        setMessageError('');

        let schema = yup.object().shape({
            hora: yup.string("Informe a hora inspecção").required("Informe a hora inspecção"),
            data: yup.string("Informe a data da inspecção").required("Informe a data da inspecção"),
            inspectorId: yup.string("Selecione inspector responsavel pela inspecção").required("Selecione inspector responsavel pela inspecção"),
        })

        try {
            await schema.validate(inspecao)
            createInspeccao()
        } catch (error) {
            setMessageError(error.errors);
        }
        return false
    }

    return (
        messageSuccess
            ?
            <>
                {messageSuccess && <MessageAlert message={messageSuccess} type={'success'} />}
                <CardInspeccao thisInspeccao={inspeccaoCriada}/> 
            </>
            :
            <Card style={{ padding: 20, minHeight: '20vh', }}>
            <RegisterAccess page={'criar inspeccao'}/>
                <LoadingBackdrop open={load} text={load} />
                {messageError && <MessageAlert message={messageError} type={'error'} />}

                <HeaderGestorConfigs title={'MARCAÇÃO DE INSPECÇÃO'} />

                <Grid container>
                    <Grid m xs={12} md item >
                        <TextField
                            required
                            type="text"
                            label="Inspector responsavel"
                            select
                            fullWidth
                            size="small"
                            name="inspectorId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={inspecao?.userId || ''}
                        >
                            {admins &&
                                admins.map((admin) => (
                                    admin.dadosPessoais.perfil === 'Inspector' &&
                                    <MenuItem key={admin._id} value={admin._id}>
                                        <Avatar src={admin.dadosPessoais.fotoURL} style={{ marginRight: 5 }} />
                                        {admin.dadosPessoais.nome}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>

                    <Grid m xs={12} md item >
                        <TextField
                            required
                            type="text"
                            label="Tipo de inspecção"
                            select
                            fullWidth
                            size="small"
                            name="tipo"
                            variant="outlined"
                            onChange={HandleChange}
                            // defaultValue={inspecao.tipo || ''}
                            defaultValue={''}
                        >
                            {inspecaoTipos.map((inspecaoTipo) => (
                                <MenuItem key={inspecaoTipo} value={inspecaoTipo}>
                                    {inspecaoTipo}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container >
                    <Grid m xs={12} md item >
                        <TextField
                            required
                            type="date"
                            fullWidth
                            label="Data"
                            size="small"
                            name="data"
                            variant="outlined"
                            onChange={HandleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid m xs={12} md item >
                        <TextField
                            required
                            type="time"
                            label="Hora"
                            fullWidth
                            size="small"
                            name="hora"
                            variant="outlined"
                            onChange={HandleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid m>
                    <FazerObservacoes setObs={setObs} obs={obs} titulo={'(caso seja necessário fazer alguma observação)'} />
                </Grid>

                <DialogActions>
                    <Button color="error" onClick={() => navigate(-1)} >Cancelar</Button>
                    <Button onClick={validate} color="success" variant="contained">Criar Inspecção</Button>
                </DialogActions>
            </Card>
    )
}