import { Container } from '@mui/material'

const ViewDocDownload = ({src}) => {
    return (
        <Container>
            <iframe
                id="pdfIframe"
                title={`PDF Viewer`}
                src={src}
                width="100%"
                height="800px"
                style={{ border: 'none' }}
            />
        </Container>
    );
}

export default ViewDocDownload;
