import NavBar from '../components/navBar'
import Footer from '../components/footer'

export default function TemplateHome({ element: component, ...rest }) {
    return (
        <div style={{ marginTop: 100 }}>
            <NavBar />
            {component}
            <Footer />
        </div>
    )
}
