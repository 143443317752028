import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../utils/headerSession'
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
}))

export default function Despachos() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
            <RegisterAccess page={'despachos'} />
            <div>
                <HeaderSession title='DESPACHOS' />

                <p className={classes.root} >
                    Despacho nº 002/ARMED/MINSA/2023, Alerta sobre a proibição de importação, distribuição, comercialização e consumo de Medicamentos fabricados pela Maiden™ Pharmaceuticals.
                    <a href={'/decretos-e-despachos/Despacho n- 002-ARMED-MINSA-2023.pdf'} target={'_blank'} rel="noreferrer">Ver despacho</a>
                </p>
                <p className={classes.root} >
                    Despacho nº 001/ARMED/MINSA/2023, Proibição da importação, comercialização e consumo de Medicamentos Antimaláricos.
                    <a href={'/decretos-e-despachos/Despacho n- 001-ARMED-MINSA-2023.pdf'} target={'_blank'} rel="noreferrer">Ver despacho</a>
                </p>
                <p className={classes.root} >
                    Despacho nº 001/ARMED/MINSA/2022, Procedimentos de importação e comercialização de testes de diagnostico rápido para o rastreio e diagnostico de Doenças Infecciosas.
                </p>
                <p className={classes.root} >
                    Despacho nº 02/ARMED/MINSA/2022, Referente a recolha voluntária do Lote do Medicamento Antimalárico LUTHER JUNIOR 20/120MG, comprimidos dispersáveis.
                    <a href={'/decretos-e-despachos/Despacho n-02-ARMED-MINSA-2022.pdf'} target={'_blank'} rel="noreferrer">Ver despacho</a>
                </p>
                <p className={classes.root} >
                    Despacho nº 03/ARMED/MINSA/2022, Restrição de importação e comercialização de Autotestes para a SARS-COV-2.
                    <a href={'/decretos-e-despachos/Despacho n-03-ARMED-MINSA-2022.pdf'} target={'_blank'} rel="noreferrer">Ver despacho</a>
                </p>
                <p className={classes.root} >
                    Despacho nº 04/ARMED/MINSA/2022, Restrição de importação e comercialização de Medicamentos Antituberculosos, Antiretrovirais e Antimaláricos.
                </p>
                <p className={classes.root} >
                    Despacho nº 05/ARMED/MINSA/2022, Requisitos de Desalfandegamento.
                </p>
                <p className={classes.root} >
                    Despacho nº 06/ARMED/MINSA/2022, Referente a proibição de importação, comercialização, distribuição e consumo do produto Antimalárico LUTHER FORTE 40/240MG, comprimidos dispersíveis.
                </p>
                <p className={classes.root} >
                    Despacho nº 08/ARMED/MINSA/2022, Proibição de importação, desalfandegamento, distribuição, consumo e utilização de testes da covid-19 de marca Powery e de medicamentos de marca Luanhua Gingwen Jiaonang.
                </p>
                <p className={classes.root} >
                    Despacho nº 09/ARMED/MINSA/2022, Sobre a proibição de importação, distribuição, comercialização e utilização de xaropes contendo Dietileno Glicol.
                </p>
                <p className={classes.root} >
                    Despacho nº 11/ARMED/MINSA/2022, Sobre o início do Registo de Medicamentos em Angola.
                </p>
            </div>

            <br />
        </Container>

    )
}
