
// import Carrousel from '../../components/corousel/carousel';
import Footer from '../../components/footer';
import Links from './links/links';
import NavBar from '../../components/navBar';
import CarousselNoticias from './noticias/carousselNoticias';
import Servicos from './services';
import Slider from './slider/slider';
import Calendar from '../../admin/gestAgenda/calendario/calendar';
import CarousselDestaques from './destaques/carousselDestaques';
import RegisterAccess from '../../utils/registerAccess';
// import DocsProcurados from './docsProcurados';
// import PesquisarEstabelecimento from '../../admin/template/pesquisarEstabelecimento';

function Home() {


    return (
        <main>
            <RegisterAccess page={'pagina inicial'} />

            <NavBar />

            <Slider />

            {/* <PesquisarEstabelecimento/> */}

            <Servicos />

            <CarousselNoticias />

            <Calendar />

            <CarousselDestaques />

            {/* <CarousselNoticias /> */}

            <Links />

            {/* <DocsProcurados /> */}
            <Footer />
        </main>

    );
}

export default Home;
