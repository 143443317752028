
import { useState } from "react";
import LoadingBackdrop from "../load/loadingBackdrop";
import MessageSuccess from "../messages/messageSuccess";
import MessageError from "../messages/messageError";
import api from "../services/api";
import { Button, Card, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";


const useStyles = makeStyles({
    root: {
        margin: 10,
        padding: 10,
        transition: '.3s',
        //   '&:hover': {
        //     color: '#b1b7ea',
        //     borderRadius: 3,
        //   },
    },
})

export default function DocEdit({ nomeDoc, estabelecimentoId }) {
    const classes = useStyles()
    const [doc, setDoc] = useState('')
    const [open, setOpen] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const user = useSelector(state => state.account.user);
    // console.log(estabelecimentoId)

    const submitEdit = async () => {
        setOpen(true)
        setMessageSuccess('')
        setMessageError('')

        const formData = new FormData();
        formData.append('doc', doc);
        formData.append('nomeDoc', nomeDoc);
        formData.append('estabelecimentoId', estabelecimentoId);
        formData.append('userId', user?._id ?? '');
        //    for (let dado of formData.values()) { console.log(dado); }

        await api.put('/estabelecimento/edit-doc', formData)
            .then(res => {
                setOpen(false)
                setMessageSuccess(' Salvo com sucesso.')

                // console.log(res)
                 window.location.reload()
            }).catch(err => {
                setOpen(false)
                setMessageError(err.response.data.message)
                // console.log(err)
            })
    }

    return (
        <>
            <Card className={classes.root}>
                <LoadingBackdrop open={open} text={'A salvar.'} />
                {messageSuccess && <MessageSuccess message={messageSuccess} />}
                {messageError && <MessageError message={messageError} />}

                <input style={{ cursor: 'pointer' }} accept="application/pdf" type="file" name={nomeDoc} id="doc" onChange={(e) => setDoc(e.target.files[0])} />

                <div>
                    <Button disabled={!doc} variant="outlined" color="success" onClick={() => { submitEdit() }} size="small" style={{ marginTop: 20 }}>Salvar</Button>
                </div>
            </Card>
            <Divider />
            <br />
        </>
    )
}