
import { Container } from "@material-ui/core";
import RegisterAccess from "../../../../utils/registerAccess";
import HeaderGestorConfigs from "../../../../utils/headerGestorConfigs";

export default function ModoInspeccao({ element: component, ...rest }) {

    return (
        <Container style={{ maxWidth: 900, padding: 8 }}>
            <RegisterAccess page={'modo de inspeccao activo'} />
            <br />
            <HeaderGestorConfigs title={'MODO INSPECÇÃO'} />
            <br />
            {component}
        </Container>
    )
}