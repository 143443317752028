
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from "@mui/material";
import CardServices from "../../../utils/cardServices";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        minHeight: '40vh',
        backgroundImage: 'url(/img/backGrounds/BgComprimido.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        marginTop: -5,
        boxShadow: '1rem 1rem 1rem #e7f0ed',

    },
    
    containerCardesService: {
        // padding: '2rem',
        maxWidth:630,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        /* AFASTAR UM ITEM DO OUTRO */
        gap: '2.5rem',
        // border:'1px solid'

        // [theme.breakpoints.down('xs')]: {
        //     justifyContent: 'letf',
        // },

        // overflowX:'auto'
    }
}))

export default function Servicos() {
    const classes = useStyles();

    return (
        <div className={classes.root} >
            <Container >
                <Typography align="center" variant="h1" style={{margin:30, fontSize: 25, fontWeight: 'bold' }}>
                    SERVIÇOS
                </Typography>

                <Box margin={'auto'} className={classes.containerCardesService}>
                    <CardServices status={true} icone={'/icones/Informacoes.svg'} nomeService={'Informações'} link={'/informacoes'} />
                    <CardServices status={true} icone={'/icones/Agenda2.svg'} nomeService={'Marcação de agenda'} link={'/agenda-disponivel'} />
                    <CardServices status={true} icone={'/icones/renovacaoLicenciamento.svg'} nomeService={'Renovação da Autorização'} link={'/solicitacao-de-renovacao-de-estabelecimento'} />
                    <CardServices status={true} icone={'/icones/Medicamentos_autorizados.svg'} nomeService={'Autorizados'} link={'/autorizados'} />
                    <CardServices status={false} icone={'/icones/Desalfandegar.svg'} nomeService={'Importação & Exportação'} link={'/importacao-e-exportacao'} />
                    <CardServices status={true} icone={'/icones/Reclamacoes.svg'} nomeService={'Reclamação'} link={'/utente/fazer-reclamacao'} />
                </Box>
            </Container>
        </div>
    )
}





