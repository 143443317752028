import { Card, Grid, Typography } from "@mui/material";
import Loading from "../../load/loading";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
import DadosPessoalEdit from "../dadosGenericos/dadosPessoalEdit";
import { useState } from "react";
import FotoEdit from "../../utils/fotoEdit";
import Delete from "../../utils/delete";
import RepresentanteCreateDirectorTecnico from "./representanteCreateDirectorTecnico";


export default function FichaRepresentante({ representante, header, setRefresh }) {
    const [editDados, setEditDados] = useState(false)
    const [openEditFoto, setOpenEditFoto] = useState(false);
    const [dadosPessoalRepresentante, setDadosPessoalRepresentante] = useState(representante)
    const [open, setOpen] = useState(false);
    const [openRCDT, setOpenRCDT] = useState(false);

    // console.log(representante)

    return (
        <>
            {representante ?
                <Card style={{ padding: 20, minHeight: '20vh', marginBottom: 20 }}>

                    <Delete
                        open={open}
                        setOpen={setOpen}
                        url={'/representante/delete'}
                        msg={`Tem certeza que deseja eliminar Representante ${representante?.dadosPessoais?.nome}`}
                        id={representante._id}
                    />

                    <RepresentanteCreateDirectorTecnico
                        open={openRCDT}
                        setOpen={setOpenRCDT}
                        representante={representante}
                    />

                    {header &&
                        <HeaderGestorConfigs
                            menu={true}
                            configArea={'fichaRepresentante'}
                            title={editDados ? 'EDITAR DADOS' : 'FICHA DE REPRESENTANTE'}
                            representante={representante}
                            editDados={editDados}
                            setEditDados={setEditDados}
                            representanteCreateDirectorTecnico={setOpenRCDT}
                            delete={setOpen}
                        />
                    }

                    {!editDados ?
                        <Grid container>
                            <Grid item marginRight={2}>
                                <FotoEdit
                                    openEditFoto={openEditFoto}
                                    setOpenEditFoto={setOpenEditFoto}
                                    dadosPessoal={representante?.dadosPessoais} />
                            </Grid>

                            <Grid xs item >
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nome:</strong> {representante?.dadosPessoais?.nome ?? '####'}</Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>B.I:</strong> {representante?.dadosPessoais?.numeroBI ?? '####'}</Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>E-mail:</strong> {representante?.dadosPessoais?.email ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Telefone1:</strong> {representante?.dadosPessoais?.tel1 ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Telefone2:</strong> {representante?.dadosPessoais?.tel2 ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Bairro:</strong> {representante?.dadosPessoais?.bairro ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Rua:</strong> {representante?.dadosPessoais?.rua ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Município:</strong> {representante?.dadosPessoais?.municipio ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Provincia:</strong> {representante?.dadosPessoais?.provincia ?? '####'} </Typography>
                                <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nacionalidade:</strong> {representante?.dadosPessoais?.nacionalidade ?? '####'} </Typography>
                            </Grid>
                        </Grid>
                        :
                        <DadosPessoalEdit
                            dadosPessoais={representante.dadosPessoais}
                            setDadosPessoais={setDadosPessoalRepresentante}
                            cancelEditDados={setEditDados}
                            setRefresh={setRefresh}
                        />
                    }
                </Card>
                :
                <Loading />
            }
        </>
    )
}