
import { Typography, TextField, FormControlLabel, Checkbox, Grid, Divider } from '@mui/material'
import HeaderGestorConfigs from '../../../../../utils/headerGestorConfigs';

export default function AutoVistoriaConjunta({ disabled, HandleChange, setInspeccionar, inspeccao }) {

    return (
        <div>
            <HeaderGestorConfigs title={'AUTO DE VISTORIA CONJUNTA'} />
            <br />

            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: 15 }}>
                <FormControlLabel value={false} name='mindcom' control={<Checkbox checked={inspeccao?.mindcom ?? false} />} label={'MINDCOM'}
                    labelPlacement='start'
                    disabled={disabled}
                    onChange={(e) => setInspeccionar((prev) => ({ ...prev, [e.target.name]: !inspeccao?.mindcom }))}
                />
                <FormControlLabel value={false} name='minsa' control={<Checkbox checked={inspeccao?.minsa ?? false} />} label={'MINSA/ARMED'}
                    labelPlacement='start'
                    disabled={disabled}
                    onChange={(e) => setInspeccionar((prev) => ({ ...prev, [e.target.name]: !inspeccao?.minsa }))}
                />
                <FormControlLabel value={false} name='mirempet' control={<Checkbox checked={inspeccao?.mirempet ?? false} />} label={'MIREMPET'}
                    labelPlacement='start'
                    disabled={disabled}
                    onChange={(e) => setInspeccionar((prev) => ({ ...prev, [e.target.name]: !inspeccao?.mirempet }))}
                />
                <FormControlLabel value={false} name='mintrans' control={<Checkbox checked={inspeccao?.mintrans ?? false} />} label={'MINTRANS'}
                    labelPlacement='start'
                    disabled={disabled}
                    onChange={(e) => setInspeccionar((prev) => ({ ...prev, [e.target.name]: !inspeccao?.mintrans }))}
                />
                <FormControlLabel value={false} name='minint' control={<Checkbox checked={inspeccao?.minint ?? false} />} label={'MININT'}
                    labelPlacement='start'
                    disabled={disabled}
                    onChange={(e) => setInspeccionar((prev) => ({ ...prev, [e.target.name]: !inspeccao?.minint }))}
                />
            </div>

            <TextField
                disabled={disabled}
                type="text"
                required
                label="PORTA DE ENTRADA DO PROCESSO"
                fullWidth
                size="small"
                name="portaEntrada"
                variant="outlined"
                onChange={HandleChange}
            // defaultValue={}
            />

            <br />
            <br />
            <Divider />
            <br />
            <Typography style={{ fontWeight: 'bold' }}>
                Total do número de trabalhadores
            </Typography>


            <TextField
                margin='dense'
                disabled={disabled}
                required
                type="number"
                fullWidth
                label="Homens Nacionais"
                size="small"
                name="totalTrabalhadoresNacionaisHomens"
                variant="outlined"
                onChange={HandleChange}
                defaultValue={inspeccao?.totalTrabalhadores?.homens}
            />

            <TextField
                margin='dense'
                disabled={disabled}
                required
                type="number"
                fullWidth
                label="Mulhreres Nacionais"
                size="small"
                name="totalTrabalhadoresNacionaisMulheres"
                variant="outlined"
                onChange={HandleChange}
                defaultValue={inspeccao?.totalTrabalhadores?.mulheres}
            />

            <TextField
                margin='dense'
                disabled={disabled}
                required
                type="number"
                fullWidth
                label="Homens Estrangeiros"
                size="small"
                name="totalTrabalhadoresEstrangeirosHomens"
                variant="outlined"
                onChange={HandleChange}
                defaultValue={inspeccao?.totalTrabalhadores?.nacionais}
            />

            <TextField
                margin='dense'
                disabled={disabled}
                required
                type="number"
                fullWidth
                label="Mulhres Estrahgeiras"
                size="small"
                name="totalTrabalhadoresEstrangeirosMulheres"
                variant="outlined"
                onChange={HandleChange}
                defaultValue={inspeccao?.totalTrabalhadores?.estrangeiros}
            />

            <br />
            <br />
            <Typography style={{ fontWeight: 'bold' }}>
                Classificação do Estabelecimento Comercial
            </Typography>

            <div style={{ display: 'flex' }}>
                <div style={{ width: 300, }}>
                    <FormControlLabel value={'Centro Comercial'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Centro Comercial' ?? false} />} label={'Centro Comercial'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Comércio Grossista'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Comércio Grossista' ?? false} />} label={'Comércio Grossista'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Comércio a Retalho'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Comércio a Retalho' ?? false} />} label={'Comércio a Retalho'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Comércio a Geral'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Comércio a Geral' ?? false} />} label={'Comércio a Geral'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Comércio de Representação'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Comércio de Representação' ?? false} />} label={'Comércio de Representação'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                </div>

                <div style={{ width: 250 }}>
                    <FormControlLabel value={'Hipermecado'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Hipermecado' ?? false} />} label={'Hipermecado'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Supermercado'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Supermercado' ?? false} />} label={'Supermercado'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Minimercado'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Minimercado' ?? false} />} label={'Minimercado'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Loja de Conveniência'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Loja de Conveniência' ?? false} />} label={'Loja de Conveniência'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                    <FormControlLabel value={'Cash and Carry'} name='classificacaoEstabelecimento' control={<Checkbox checked={inspeccao?.classificacaoEstabelecimento === 'Cash and Carry' ?? false} />} label={'Cash and Carry'}
                        // labelPlacement='start'
                        disabled={disabled}
                        onChange={HandleChange}
                    />
                </div>
            </div>

            <br />
            <Typography style={{ fontWeight: 'bold' }}>
                Rede Comercial:
            </Typography>

            <label>
                Grossistas
            </label>
            <TextField
                disabled={disabled}
                type="number"
                fullWidth
                label="Número de Clientes"
                size="small"
                name="numeroClientes"
                variant="outlined"
                onChange={HandleChange}
                defaultValue={inspeccao?.redeComercial?.numeroClientes}
            />

            <br />
            <label>
                Retalhistas
            </label>
            <TextField
                disabled={disabled}
                type="number"
                fullWidth
                label="Número de Fornecedores"
                size="small"
                name="numeroFornecedores"
                variant="outlined"
                onChange={HandleChange}
                defaultValue={inspeccao?.redeComercial?.numeroFornecedores}
            />


            <br />
            <br />
            <Typography style={{ fontWeight: 'bold' }}>
                Classificação da Actividade Económica a Exercer:
            </Typography>
            <TextField
                disabled={disabled}
                type="text"
                required
                label="CAE"
                fullWidth
                size="small"
                name="cae"
                variant="outlined"
                onChange={HandleChange}
            // defaultValue={}
            />

            <br />
            <br />
            <Typography style={{ fontWeight: 'bold' }}>
                RESULTADO DA INSPECÇÃO A INFRA-ESTRUTURA COMÉRCIAL OU DE SERVIÇO:
            </Typography>

            <table className="tabela"  >
                <tbody >
                    <tr >
                        <td className="celulaCabecalho">ÁREAS</td>
                        <td className="celulaCabecalho">ESTADO DA FUNCIONALIDADE</td>
                        <td className="celulaCabecalho">CONDIÇÕES DE SAÚDE PUBLICA</td>
                        <td className="celulaCabecalho">CONDIÇÕES DE SEGURANÇA PUBLICA</td>
                    </tr>

                    <tr>
                        <td className="celulaCabecalho">
                            <TextField
                                disabled={disabled}
                                placeholder="m³"
                                type="number"
                                required
                                label="Exposição e Venda"
                                size="small"
                                name="resultadoIICSAreasExposicaoVenda"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.areas?.resultadoIICSAreasExposicaoVenda}
                            />
                            <br />
                            <br />
                            <TextField
                                disabled={disabled}
                                placeholder="m³"
                                type="number"
                                required
                                label="Bruta"
                                size="small"
                                name="resultadoIICSAreasBruta"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.areas?.resultadoIICSAreasBruta}
                            />
                        </td>

                        <td className="celulaCabecalho" >
                            <FormControlLabel value={'Bom'} name='resultadoIICSEstadoFuncionalidade' control={<Checkbox checked={(inspeccao?.resultadoIICSEstadoFuncionalidade || inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade) === 'Bom' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>BOM</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade}
                            />
                            <br />
                            <FormControlLabel value={'Regular'} name='resultadoIICSEstadoFuncionalidade' control={<Checkbox checked={(inspeccao?.resultadoIICSEstadoFuncionalidade || inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade) === 'Regular' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>REGULAR</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade}
                            />
                            <br />
                            <FormControlLabel value={'Mau'} name='resultadoIICSEstadoFuncionalidade' control={<Checkbox checked={(inspeccao?.resultadoIICSEstadoFuncionalidade || inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade) === 'Mau' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>MAU</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade}
                            />
                            <FormControlLabel value={'Categoria de Risco'} name='resultadoIICSEstadoFuncionalidade' control={<Checkbox checked={(inspeccao?.resultadoIICSEstadoFuncionalidade || inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade) === 'Categoria de Risco' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>CATEGORIA DE RISCO</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSEstadoFuncionalidade}
                            />
                        </td>

                        <td className="celulaCabecalho">
                            <FormControlLabel value={'Bom'} name='resultadoIICSCondicoesSaudePublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSaudePublica || inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica) === 'Bom' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>BOM</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica}
                            />
                            <br />
                            <FormControlLabel value={'Regular'} name='resultadoIICSCondicoesSaudePublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSaudePublica || inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica) === 'Regular' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>REGULAR</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica}
                            />
                            <br />
                            <FormControlLabel value={'Mau'} name='resultadoIICSCondicoesSaudePublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSaudePublica || inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica) === 'Mau' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>MAU</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica}
                            />
                            <FormControlLabel value={'Categoria de Risco'} name='resultadoIICSCondicoesSaudePublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSaudePublica || inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica) === 'Categoria de Risco' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>CATEGORIA DE RISCO</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSaudePublica}
                            />
                        </td>

                        <td className="celulaCabecalho">
                            <FormControlLabel value={'Bom'} name='resultadoIICSCondicoesSegurancaPublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSegurancaPublica || inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica) === 'Bom' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>BOM</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica}
                            />
                            <br />
                            <FormControlLabel value={'Regular'} name='resultadoIICSCondicoesSegurancaPublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSegurancaPublica || inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica) === 'Regular' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>REGULAR</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica}
                            />
                            <br />
                            <FormControlLabel value={'Mau'} name='resultadoIICSCondicoesSegurancaPublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSegurancaPublica || inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica) === 'Mau' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>MAU</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica}
                            />
                            <FormControlLabel value={'Categoria de Risco'} name='resultadoIICSCondicoesSegurancaPublica' control={<Checkbox checked={(inspeccao?.resultadoIICSCondicoesSegurancaPublica || inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica) === 'Categoria de Risco' ?? false} />}
                                label={<Typography style={{ fontSize: '14px' }}>CATEGORIA DE RISCO</Typography>}
                                // labelPlacement='start'
                                disabled={disabled}
                                onChange={HandleChange}
                                defaultValue={inspeccao?.resultado?.resultadoIICSCondicoesSegurancaPublica}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div>
                <br />
                <br />
                <Typography style={{ fontWeight: 'bold' }}>
                    APÓS DELIBERAÇÃO, A COMISSÃO DE VISTORIA, CONSIDERA QUE O ESTABELECIMENTO COMERCIAL ESTÁ:
                </Typography>
                <FormControlLabel value={'apto'} name='aposDCVCECE' control={<Checkbox checked={inspeccao?.aposDCVCECE === 'apto' ?? false} />} label={'Apto para Renovação / Renovção do Alvará Comércial / Autorização para exerciçio farmacêutico.'}
                    // labelPlacement='start'
                    disabled={disabled}
                    onChange={HandleChange}
                />
                <FormControlLabel value={'semAlvara'} name='aposDCVCECE' control={<Checkbox checked={inspeccao?.aposDCVCECE === 'semAlvara' ?? false} />} label={'Em funcionamento sem Alvará Comércial.'}
                    // labelPlacement='start'
                    disabled={disabled}
                    onChange={HandleChange}
                />
                <FormControlLabel value={'deficiente'} name='aposDCVCECE' control={<Checkbox checked={inspeccao?.aposDCVCECE === 'deficiente' ?? false} />} label={'Com deficiências, e recomendou-se a superação no prazo abaixo, a contar da presente data.'}
                    // labelPlacement='start'
                    disabled={disabled}
                    onChange={HandleChange}
                />
                {inspeccao?.aposDCVCECE === 'deficiente' &&
                    <TextField
                        disabled={disabled}
                        type="number"
                        label="Dias para superar as deficiências "
                        size="small"
                        name="diasSuperacao"
                        variant="outlined"
                        onChange={HandleChange}
                    // defaultValue={}
                    />
                }

                <FormControlLabel value={'naoApto'} name='aposDCVCECE' control={<Checkbox checked={inspeccao?.aposDCVCECE === 'naoApto' ?? false} />} label={'Não Apto para Renovação / Renovção do Alvará Comércial / Autorização para exerciçio farmacêutico.'}
                    // labelPlacement='start'
                    disabled={disabled}
                    onChange={HandleChange}
                />
            </div>

            <div style={{ marginTop: 20, textAlign: 'center' }}>
                <Divider />
                <br />
                <Typography align='center'>
                    <strong>
                        ASSINATURAS
                    </strong>
                </Typography>

                {/* <div style={{ marginTop: 20, display: 'flex', gap: '3rem', justifyContent: 'center' }}> */}
                <div>
                    <TextField
                        inputProps={{ style: { textAlign: 'center' } }}
                        disabled={disabled}
                        fullWidth
                        type="text"
                        label=""
                        size="small"
                        name="nomeInstituicao1"
                        variant="standard"
                        onChange={HandleChange}
                        defaultValue={inspeccao?.nomeInstituicao1}
                    />
                    <br />
                    <label >NOME / INSTITUIÇÃO</label>
                </div>

                <div>
                    <TextField
                        inputProps={{ style: { textAlign: 'center' } }}
                        disabled={disabled}
                        fullWidth
                        type="text"
                        label=""
                        size="small"
                        name="nomeInstituicaoCoordenador"
                        variant="standard"
                        onChange={HandleChange}
                        defaultValue={inspeccao?.nomeInstituicaoCoordenador}
                    />
                    <br />
                    <label >NOME / INSTITUIÇÃO (COORDENADOR)</label>
                </div>
                <div>
                    <TextField
                        inputProps={{ style: { textAlign: 'center' } }}
                        disabled={disabled}
                        fullWidth
                        type="text"
                        label=""
                        size="small"
                        name="nomeInstituicao2"
                        variant="standard"
                        onChange={HandleChange}
                        defaultValue={inspeccao?.nomeInstituicao2}
                    />
                    <br />
                    <label >NOME / INSTITUIÇÃO</label>
                </div>
                {/* </div> */}


                <div style={{ marginTop: 30 }}>
                    <label >O REQUERENTE</label>
                    <br />
                    <TextField
                        inputProps={{ style: { textAlign: 'center' } }}
                        disabled={disabled}
                        fullWidth
                        type="text"
                        label=""
                        size="small"
                        name="requerente"
                        variant="standard"
                        onChange={HandleChange}
                        defaultValue={inspeccao?.requerente}
                    />
                </div>
            </div>

            <br />
            <br />
            <ol type="a" style={{ paddingLeft: 15 }}>
                <li>
                    ) As falsas declarações são puníveis nos termos da Lei.
                </li>
                <li>
                    ) Este Auto de Vistoria permite a abertura do estabelecimento comercial enquanto aguarda a Emissão/Renovação do Alvará, exceto no caso da atividade farmacêutica.
                </li>
            </ol>
        </div>
    );
}
