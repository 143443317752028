import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../../../services/api';
import Loading from '../../../../../load/loading';
import { useDispatch } from 'react-redux';
import CardInspeccao from './cardInspeccao';

export default function InspeccaoOPen() {
    // status é uma variavel que indica se inspeccao esta aberta ou fechada
    const dispatch = useDispatch()
    const { id } = useParams()
    const [inspeccao, setInspeccao] = useState('')

    useEffect(() => {
        api.get(`/inspeccaos?estabelecimentoId=${id}`)
            .then(response => {
                // console.log(response)
                setInspeccao(response.data.inspeccaos[response.data.inspeccaos.length - 1]);

                dispatch({
                    type: 'inspeccao',
                    payload: {
                        inspeccao: response.data.inspeccaos[response.data.inspeccaos.length - 1]
                    }
                })
            }).catch(err => 'console.log(err)')
    }, [id])


    return (inspeccao ? <CardInspeccao thisInspeccao={inspeccao}/> : <Loading />);
}
