
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../../../services/api";
import HeaderSession from "../../../../../utils/headerSession";
import Loading from "../../../../../load/loading";
// import DateHora from "../../../utils/dateHora";
import { useDispatch, useSelector } from 'react-redux';
import SearshEstabelecimento from "../../../../../utils/searchEstabelecimento";
import FerramentaNavegacaoExportacao from "../../../../../utils/ferramentaNavegacaoExportacao";
import ListaSolicitacao from "../listaSolicitacao";
import RegisterAccess from "../../../../../utils/registerAccess";
// import ListaVazia from "../../utils/ListaVazia";
// import FerramentaNavegacaoExportacao from "./FerramentaNavegacaoExportacao";


export default function ChefedodepartamentoArea() {
  const user = useSelector(state => state.account.user);
  const navigate = useNavigate()
  const [tipo, setTipo] = useState('')
  const [estabelecimentos, setEstabelecimentos] = useState('')
  const [estabelecimentosInspeccionados, setEstabelecimentosInspeccionados] = useState('')
  const [estabelecimentosInspeccaoReprovada, setEstabelecimentosInspeccaoReprovada] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [, setOrder] = useState('')
  const [configLista, setConfigLista] = useState('')
  const dispatch = useDispatch()
  // const url = useLocation();
 


  useEffect(() => {
    setEstabelecimentos('')

    if (user?.funcao === 'Chefe de Departamento' || user?.funcao === 'Director Geral' || user?.funcao === 'CNP') {
    } else {
      dispatch({
        type: 'alert',
        payload: { alert: 'Acesso negado' },
      });
      navigate('/admin')
    }

    api.get(`/estabelecimentos?page=${page}&status=Analisado Chefe de Secção&perPage=${perPage}&tipoId=${tipo}`)
      .then(response => {
        setEstabelecimentos(response.data.estabelecimentos);
        setConfigLista(response.data);
      }).catch(err => '')

    api.get(`/estabelecimentos?page=${page}&status=Inspecção Realizada&perPage=${perPage}`)
      .then(response => {
        setEstabelecimentosInspeccionados(response.data.estabelecimentos);
        // setConfigLista(response.data);
      }).catch(err => '')

    api.get(`/estabelecimentos?page=${page}&statusInspeccao=Reprovada&perPage=${perPage}`)
      .then(response => {
        setEstabelecimentosInspeccaoReprovada(response.data.estabelecimentos);
        // setConfigLista(response.data);
      }).catch(err => '')

  }, [page, perPage, user?.funcao, tipo, user?.seccao, navigate, dispatch])


  return (
    <>
      <RegisterAccess page={'area de chefe de departamento'} />
      <HeaderSession title='PROCESSOS ANÁLIZADOS PELA CHEFE DA SECÇÃO' />
      <Card style={{ padding: 20 }}>

        <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={setTipo} placeholder={'Filtra por nome, número de entrada, data'} />

        {estabelecimentos ?
          <Card variant="outlined">
            <ListaSolicitacao estabelecimentos={estabelecimentos} />

            <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={estabelecimentos}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel} 
            />
          </Card>
          :
          <Loading />
        }
      </Card>

      <br />
      <br />
      {estabelecimentosInspeccionados &&
        <Card style={{ padding: 20 }}>
          <h1 style={{ margin: 20, fontSize: 23 }}>
            ESTABELECIMENTOS JÁ INSPECCIONADOS
          </h1>
          {/* use Ref */}
            {/* { url.pathname !== '' && <input autoFocus type="text" style={{ opacity: 0, height: 1 }} />} */}
          <Card variant="outlined">
            <br />
            <ListaSolicitacao estabelecimentos={estabelecimentosInspeccionados} />
          </Card>
        </Card>
      }

      <br />
      <br />
      {estabelecimentosInspeccaoReprovada &&
        <Card style={{ padding: 20 }}>
          <h1 style={{ margin: 20, fontSize: 23 }}>
            INSPECCŌES REPROVADAS
          </h1>
          {/* use Ref */}
            {/* { url.pathname !== '' && <input autoFocus type="text" style={{ opacity: 0, height: 1 }} />} */}
          <Card variant="outlined">
            <br />
            <ListaSolicitacao estabelecimentos={estabelecimentosInspeccaoReprovada} />
          </Card>
        </Card>
      }
    </>
  )
}
