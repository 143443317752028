import { Container, Button, TextField, MenuItem, Typography } from '@mui/material'
import HeaderSession from '../../../../../utils/headerSession'
import { useParams, useNavigate } from 'react-router-dom'
import api from '../../../../../services/api'
import { useEffect, useState } from 'react'
import Loading from '../../../../../load/loading'
import { useDispatch } from 'react-redux';
// import Disponibilidade from './disponibilidade'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function VerifyAgendamentos() {
    const navigate = useNavigate()
    const { agendaId } = useParams()
    const dispatch = useDispatch()
    const [tiposEstabelecimentos, setTiposEstabelecimentos] = useState('')
    // const dadosCadastroEstabelecimento = useSelector(state => state.dadosCadastroEstabelecimento.data)
    const [agendas, setAgendas] = useState('')
    const currentDate = new Date();
    const [selected, setSelected] = useState('')
    // console.log(dadosCadastroEstabelecimento)

    useEffect(() => {
        api.get(`/agendas?status=Indisponível&ano=${currentDate.getFullYear()}&mes=${currentDate.getMonth() + 2}&perPage=200&dataDisponivel=${currentDate}`)
            // api.get(`/agendas?status=Indisponível&ano=${currentDate.getFullYear()}&mes=${currentDate.getMonth() + 2}&perPage=200`)
            .then(response => {
                // console.log(response)
                setAgendas(response.data.agendas);
            }).catch(error => 'console.log(error)')
    }, [currentDate])

    useEffect(() => {
        api.get('/tipo-estabelecimentos')
            .then(res => {
                // console.log(res)
                setTiposEstabelecimentos(res.data.tipoEstabelecimentos);
            }).catch(err => '')
    }, [agendaId])


    const setTipoEstabelecimento = (tipo) => {
        setSelected(tipo.designacao)
        dispatch({
            type: 'cadastroEstabelecimento',
            payload: {
                dadosCadastroEstabelecimento: { tipoId: tipo._id }
            }})
    }

    return (
        <Container style={{ minHeight: '70vh' }}>

            <HeaderSession title='VERIFICA OS TIPOS ESTABELECIMENTOS COM VAGA  PARA ESTE DIA' />

            {(tiposEstabelecimentos && agendas && !selected) &&
                <div>
                    <TextField
                        required
                        type="text"
                        label="Tipos de Estabelecimentos"
                        select
                        fullWidth
                        size="small"
                        name="tipoId"
                        variant="outlined"
                        onChange={(e) => setTipoEstabelecimento(e.target.value)}
                        defaultValue={''}
                    >
                        <MenuItem disabled value=''>Selecione Tipo de Estabelecimento</MenuItem>
                        {tiposEstabelecimentos.map((estabelecimento) => (
                            (estabelecimento._id !== '65096f490a1e077d5cdc0dd1' && estabelecimento._id !== '6601640b64a30dae27720b28') &&
                            <MenuItem key={estabelecimento._id} value={estabelecimento}>
                                {estabelecimento.designacao.toUpperCase()}
                            </MenuItem>
                        ))}
                    </TextField>
                            {/* <Disponibilidade
                                value={estabelecimento._id}
                                key={estabelecimento._id}
                                agendas={agendas}
                                estabelecimento={estabelecimento}
                                setTipoEstabelecimento={setTipoEstabelecimento} >
                            </Disponibilidade> */}
                </div>
            }

            {selected &&
                <Typography align='center'>
                    <br />
                    <strong>
                        {selected.toUpperCase()}
                        <CheckCircleOutlineIcon style={{ padding: 3 }} />
                    </strong>
                    <br />
                    <br />
                    <Button color='error' variant='contained' style={{ margin: 10 }} onClick={() => setSelected('')}>Cancelar</Button>
                    <Button color='inherit' style={{ background: '#85287e', color: '#fff', margin: 10 }} onClick={() => navigate(`/licenciamento/${agendaId}`)}>Continuar</Button>
                </Typography>
            }

            {(!tiposEstabelecimentos && !agendas) &&
                <Loading />
            }

        </Container>
    )
}
