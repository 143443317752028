import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import Date from '../../../utils/date';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        minWidth: 180,

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },

    img: {
        transition: '.5s',
        // cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
        }
    }
}))

export default function CardNoticiaCaroussel({ noticia }) {
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <CardActionArea title='Saiba mais..' onClick={() => navigate(`/view-noticia/${noticia?._id}`)} className={classes.root} >
            <Card style={{ height: 350 }}>
                <CardMedia
                    className={classes.img}
                    component="img"
                    alt="img"
                    height="200"
                    image={noticia?.imagem}
                />
                <CardContent>
                    <Typography gutterBottom variant="subtitle2" component="div">
                    {noticia.titulo.length > 100 ? noticia.titulo.slice(0, 100) + '...' : noticia.titulo}
                    </Typography>
                </CardContent>

                <CardActions style={{ position: 'absolute', bottom: 5 }}>
                    <AccessTimeFilledIcon color='secondary' style={{ fontSize: '25px', padding: 2 }} />
                    <Date date={noticia.updatedAt} />
                </CardActions>
            </Card>
        </CardActionArea>
    );
}
