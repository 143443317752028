
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// import { useEffect, useState } from 'react';
import './styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import api from '../../../services/api';

// import { EffectFade } from 'swiper';

export default function Slider({ forceUpdate }) {
  const [banners, setBanners] = useState('')

  useEffect(() => {
    api.get(`/carousels`)
      .then(response => {
        // console.log(response)
        setBanners(response.data.carousels);
      }).catch(error => {
        // console.log(error)
      })
  }, [forceUpdate])


  return (
    banners ?
      <div style={{ marginTop: forceUpdate ? '' : 60, position: 'relative' }}>
        <Swiper
          // modules={[EffectFade]}
          // effect='fade'
          // slidesPerView={slidePreview}
          slidesPerView={1}
          pagination={{ clickable: true }}
          // navigation
          autoplay={{ delay: 5000 }}
        // zoom={{ maxRatio: 10 }}
        >
          {banners.map((item) => (
            <SwiperSlide key={item._id}>
              <Box style={{ display: 'flex', alignItems: 'center', height: '100%', position: 'absolute', padding: '3rem', color: item.titulo === 'MISSÃO:' ? '#85287e' : '#fff' }} sx={{ width: { xs: '90x', md: '60%' }, fontSize: { xs: '1.5rem', md: '2.5rem' } }}>
                <p>
                  <strong> {item.titulo} </strong>
                  <br />
                  {item.descricao}
                </p>
              </Box>
              <img src={item.imagem} alt="slider" className='slide-item' style={{ height: '64vh', objectFit: 'cover', objectPosition: 'center' }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      :
      ''
  )
}


