import { Button, DialogActions, TextField, Typography } from '@mui/material';
// import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import LoadingShort from '../../../../load/loadingShort';
import RegisterAccess from '../../../../utils/registerAccess';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const ReenviarEmail = (props) => {
    // const [open, ] = useState(props.load);

    const handleClose = () => {
        props.setOpenReenviarEmail(false);
    };

    return (
        <>
         <RegisterAccess page={'reenviar email'}/>
            <BootstrapDialog
                scroll='body'
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.openReenviarEmail}
            >
                <div style={{ padding: 20 }}>

                    <Typography>
                        <strong>Enviar por e-mail</strong>
                        <br />
                        <small>OBS: pode mudar o email</small>
                    </Typography>

                    <TextField
                        fullWidth
                        type='email'
                        size='small'
                        defaultValue={props.estabelecimento?.email?.toLowerCase()}
                        onChange={(e) => props.setNovoEmail(e.target.value)}
                    />

                    <DialogActions>
                        {/* <Button title='Cancelar' onClick={() => props.setOpenReenviarEmail(false)} size="small">Cancelar</Button> */}
                        <Button onClick={() => props.generatePDF('Enviando_email', props.autorizacao)} size="small">
                            {props.load ? <LoadingShort /> : 'Enviar'}
                        </Button>
                    </DialogActions>
                    {/* } */}
                </div>
            </BootstrapDialog>
        </>
    );
}

export default ReenviarEmail;
