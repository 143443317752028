
import { useEffect, useRef, useState, useCallback } from 'react';
import { Card, Typography, makeStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Avatar } from '@material-ui/core';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import api from "../../services/api";
import HeaderSession from "../../utils/headerSession";
import Loading from "../../load/loading";
import FerramentaNavegacaoExportacao from "../../utils/ferramentaNavegacaoExportacao";
import StatusEstabelecimento from '../../utils/statusEstabelecimento';
import RegisterAccess from '../../utils/registerAccess';
// import ListaVazia from "../../utils/ListaVazia";
// import FerramentaNavegacaoEexpot from "./FerramentaNavegacaoExportacao";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#d2d6ff' }, cursor: 'pointer' }
}))

export default function Estabelecimentosbytipo() {
    const classes = useStyles()
    const navigate = useNavigate()
    const { provincia, estabelecimentoTipoId } = useParams()

    // const [filter, setFilter] = useState('')
    const [estabelecimentos, setEstabelecimentos] = useState('')
    const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState(1);
    const feedRef = useRef(null);


    // const filtraEstabelecimento = async (valor) => {
    //     setEstabelecimentos(filter.filter((estabelecimento) => (
    //         estabelecimento?.nome?.toLowerCase().includes(valor.toLowerCase())
    //         || estabelecimento?.numeroProcesso?.toLowerCase().includes(valor.toLowerCase())
    //         || estabelecimento?.tipo?.designacao?.toLowerCase().includes(valor.toLowerCase())
    //     )))
    // }

    const fetchMoreData = useCallback(async () => {
        // setLoading(true);

        try {
            const newData = await fetchData(page);
            setConfigLista(newData);
            setEstabelecimentos((prevItems) => [...prevItems, ...newData.estabelecimentos]);
            // setFilter((prevItems) => [...prevItems, ...newData.estabelecimentos]);

            setPage(page + 1);
            // setLoading(false);
        } catch (error) {

            // return console.log(error)

        }
    }, [page, provincia]);

    const fetchData = async (currentPage) => {
        // const response = await api.get(`/estabelecimentos?page=${currentPage}&status=Activo`);
        const response = await api.get(`/estabelecimentos?provincia=${provincia}&tipoId=${estabelecimentoTipoId === 'geral' ? '' : estabelecimentoTipoId}&page=${currentPage}`)
        response.data.estabelecimentos.sort(function (a, b) {
            return a?.nome.localeCompare(b?.nome);
        })

        return response.data;
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchMoreData();
            }
        });

        if (feedRef.current) {
            observer.observe(feedRef.current);
        }

        return () => {
            if (feedRef.current) {
                observer.unobserve(feedRef.current);
            }
        };

    }, [feedRef, fetchMoreData]);


    return (
        <Container >
            <RegisterAccess page={'estabelecimento por tipo'} />
            <HeaderSession title='ESTABELECIMENTOS LICENCIADOS' />

            <Card style={{ padding: 20 }}>
                {/* <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={filtraEstabelecimento} /> */}

                {estabelecimentos &&
                    estabelecimentoTipoId === 'geral' ? `${provincia.toUpperCase()} - GERAL`
                    :
                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>
                        {provincia.toUpperCase()}  -  {estabelecimentos[0]?.tipo.designacao === undefined ? 'Nenhum estabelecimento encontrado' : estabelecimentos[0]?.tipo.designacao.toUpperCase()}
                    </Typography>
                }

                {estabelecimentos ?
                    <Card variant="outlined">
                        <TableContainer >
                            <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Tipo</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Número de Processo</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {estabelecimentos.map((estabelecimento) => (
                                        <TableRow key={estabelecimento?._id} className={classes.hover} onClick={() => navigate(`/admin/gestor-licenciados/${estabelecimento?._id}`)} title={estabelecimento?.nome}>

                                            <TableCell component="th" scope="row">
                                                <Avatar variant='square' src={estabelecimento?.logo} alt={estabelecimento?.nome} />
                                            </TableCell>

                                            <TableCell >{estabelecimento?.nome}</TableCell>
                                            <TableCell >{estabelecimento?.tipo?.designacao}</TableCell>
                                            <TableCell >{estabelecimento?.numeroProcesso}</TableCell>
                                            <TableCell >{estabelecimento?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                                            <TableCell >{estabelecimento?.provincia}</TableCell>
                                            <TableCell >  <StatusEstabelecimento status={estabelecimento?.status} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                        <FerramentaNavegacaoExportacao
                            configLista={configLista}
                            lista={estabelecimentos}
                            page={page}
                            setPage={setPage}
                        // exportarExcel={exportarExcel}
                        />
                    </Card>
                    :
                    <div ref={feedRef} >
                        <Loading />
                    </div>
                }
            </Card>

            {(configLista?.hasNextPage && estabelecimentos) &&
                <div ref={feedRef}>
                    <Loading />
                </div>
            }
        </Container>
    )
}
