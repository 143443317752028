import ServicesMenuProvincia from '../../utils/servicesMenuProvincia';
import { useParams, Link } from 'react-router-dom';
import { Typography, makeStyles } from "@material-ui/core";
import { Box, Container } from '@mui/system';
import HeaderSession from '../../utils/headerSession';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import CountUp from 'react-countup';
import Loading from '../../load/loading';
import RegisterAccess from '../../utils/registerAccess';

const useStyles = makeStyles((theme) => ({

    containerCardesService: {
        // height: '60vh',
        // padding: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'center',
        alignContent: 'center',
        gap: '1rem',
        // padding: 0,
    }
}))
export default function ResumoProvincia() {
    const classes = useStyles();
    const { provincia } = useParams()
    const [total, setTotal] = useState([])
    const [classeEstabelecimentos, setClasseEstabelecimentos] = useState('')


    useEffect(() => {
        Promise.all([
            api.get('/tipo-estabelecimentos'),
            api.get(`/estabelecimentos?provincia=${provincia}&count=true`),

        ]).then(response => {
            setClasseEstabelecimentos(response[0].data.tipoEstabelecimentos);
            setTotal(response[1].data.totalItems);

        }).catch(error => '')
    }, [provincia])


    return (
        <Container>
            <RegisterAccess page={`Resumo ${provincia}`}/>
            <HeaderSession title={'PROVINCIA SELECIONADA'} />

            <div style={{ marginTop: '5vh', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>

                <div style={{ position: 'relative' }}>
                    <Typography align='center' variant='h1' style={{ fontSize: 25, fontWeight: 'bolder', color: '#292b2b' }}>
                        {provincia.toUpperCase()}
                    </Typography>
                    <br />

                    <Link title='Ver Geral' style={{ textDecoration: 'none', color: '#292b2b' }} to={`/admin/provincia/${provincia}/geral`}>
                        <img src={'/icones/mapaProvinciasAngola/' + provincia + '.svg'} alt={provincia} width={300} />
                    </Link>

                    <Typography align='center' variant='h1' style={{ fontSize: 18, fontWeight: 'bolder', color: '#fff', position: 'absolute', top: '12em', left: '5em' }}>
                        Geral
                        <br />
                        <CountUp end={total} />
                    </Typography>
                </div>

                {classeEstabelecimentos ?

                    <Box className={classes.containerCardesService}>
                        {classeEstabelecimentos.map((estabelecimento) => (

                            <ServicesMenuProvincia
                                key={estabelecimento._id}
                                icone={''}
                                tipo={estabelecimento}
                                provincia={provincia}
                            />
                        ))}
                    </Box>
                    :
                    <Loading />
                }
            </div>
        </Container>
    )
}
