
import { useEffect, useState } from "react";
import { Card} from "@material-ui/core";
import api from "../../../../services/api";
import HeaderSession from "../../../../utils/headerSession";
import Loading from "../../../../load/loading";
import SearshEstabelecimento from "../../../../utils/searchEstabelecimento";
import FerramentaNavegacaoExportacao from "../../../../utils/ferramentaNavegacaoExportacao";
import ListaSolicitacao from "./listaSolicitacao";
import RegisterAccess from "../../../../utils/registerAccess";


export default function SolicitacoesReprovadas() {
  // const user = useSelector(state => state.account.user);
  const [tipo, setTipo] = useState('')
  const [estabelecimentos, setEstabelecimentos] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [order, setOrder] = useState('')
  const [loading, setLoading] = useState(false);
  const [configLista, setConfigLista] = useState('')

  useEffect(() => {
    setLoading(true)
    setEstabelecimentos('')
    api.get(`/estabelecimentos?page=${page}&status=Reprovado&perPage=${perPage}&tipoId=${tipo}&sortBy=nome:${order}`)
      .then(response => {
        // console.log(response)
        // setEstabelecimentos(response.data.estabelecimentos);
        setConfigLista(response.data);
        setEstabelecimentos((prevItems) => [...prevItems, ...response.data.estabelecimentos]);

        setLoading(false)
      }).catch(err => { setLoading(false) })

  }, [page, perPage, order, tipo]);


  return (
    <>
     <RegisterAccess page={'solicitações reprovadas'}/>
      <HeaderSession title='LISTA DE PROCESSOS REPROVADOS' />
      <Card style={{ padding: 20 }}>

        <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={setTipo} placeholder={'Filtra por nome, número de entrada, data'} />

        {estabelecimentos &&
          <Card variant="outlined">
          <ListaSolicitacao estabelecimentos={estabelecimentos}/>

             <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={estabelecimentos}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel} 
            />
          </Card>
        }

        {loading && <Loading />}
      </Card>
    </>
  )
}
