import { Typography } from '@mui/material';

export default function ListaVazia({ text }) {
    return (
        <div align='center' style={{ marginTop: 50, marginBottom: 10 }}>
            <Typography style={{ fontWeight: 'bold' }} >
                {
                    text
                        ? text
                        : 'A lista está vazia'
                }
            </Typography>
        </div>
    )
}
