import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/system';
import MessageError from '../../../../messages/messageError';
import HeaderSession from '../../../../utils/headerSession';
import RelatorioActividades from './relatorioActividades';
import RegisterAccess from '../../../../utils/registerAccess';
import RelatorioAutorizacoesExercicioEmitidas from './autorizacaoExercicio/relatorioAutorizacoesExercicioEmitidas';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        marginBottom: 20,
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'center',
        justifyItems: 'center',
        gap: '1rem'
    }
}))

export default function MenuRelatoriosDLIF() {
    const classes = useStyles();
    const [messageError, setMessageError] = useState('')


    return (
        <div >
            <RegisterAccess page={'menu de relatórios'} />
            <HeaderSession title={'RELATÓRIOS'} />

            {messageError && <MessageError message={messageError} />}

            <Box className={classes.root}>

                <RelatorioActividades />
                <RelatorioAutorizacoesExercicioEmitidas />

            </Box>
        </div>
    );
}


