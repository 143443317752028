
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import api from "../../../../../services/api";
import HeaderSession from "../../../../../utils/headerSession";
import Loading from "../../../../../load/loading";
import SearshEstabelecimento from "../../../../../utils/searchEstabelecimento";
import FerramentaNavegacaoExportacao from "../../../../../utils/ferramentaNavegacaoExportacao";
import ListaSolicitacao from "../listaSolicitacao";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RegisterAccess from "../../../../../utils/registerAccess";

export default function ChefedaseccaoArea() {
  const navigate = useNavigate()
  const user = useSelector(state => state.account.user);
  const dispatch = useDispatch()
  const [tipo, setTipo] = useState('')
  const [estabelecimentos, setEstabelecimentos] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [order, setOrder] = useState('')
  const [loading, setLoading] = useState(false);
  const [configLista, setConfigLista] = useState('')


  useEffect(() => {

    if (user?.funcao === 'Chefe de Secção' || user?.funcao === 'Director Geral' || user?.funcao === 'CNP') {
    } else {
      dispatch({
        type: 'alert',
        payload: { alert: 'Acesso negado' },
      });
      navigate('/admin')
    }

    setLoading(true)
    setEstabelecimentos('')
    api.get(`/estabelecimentos?page=${page}&status=Analisado&perPage=${perPage}&tipoId=${tipo}&sortBy=nome:${order}`)
      .then(response => {
        setConfigLista(response.data);
        setEstabelecimentos((prevItems) => [...prevItems, ...response.data.estabelecimentos]);

        setLoading(false)
      }).catch(err => { setLoading(false) })

  }, [page, perPage, order, tipo, dispatch, user?.funcao, navigate]);


  return (
    <>
      <RegisterAccess page={'area de chefe de secção'} />
      <HeaderSession title='PROCESSOS ANÁLIZADOS POR TECNICOS' />

      <Card style={{ padding: 20 }}>
        <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={setTipo} placeholder={'Filtra por nome, número de entrada, data'} />

        {estabelecimentos &&
          <Card variant="outlined">
            <ListaSolicitacao estabelecimentos={estabelecimentos} />

            <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={estabelecimentos}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel} 
            />
          </Card>
        }

        {loading && <Loading />}
      </Card>
    </>
  )
}
