import { Container } from '@mui/material'
import HeaderSession from '../../../../utils/headerSession'
import ListaVazia from '../../../../utils/listaVazia'

export default function DesalfandegarEquipamentos() {
    return (
        <Container style={{ minHeight: '70vh', maxWidth: 800 }}>

            <HeaderSession title='DESALFANDEGAR EQUIPAMENTOS' />
            <ListaVazia text={'SERVIÇO INDISPONÍVEL'}/>

        </Container>
    )
}
 