import { Container } from "@mui/material";
import { useSelector } from 'react-redux';
import InformacaoAdmin from "../InformacaoAdmin";
import HeaderSession from "../../../utils/headerSession";
import RegisterAccess from "../../../utils/registerAccess";


export default function PerfilAdmin() {
    const user = useSelector(state => state.account.user);

    return (
        <Container style={{ marginBottom: 10 }}>
            <RegisterAccess page={'perfil admin'} />
            <HeaderSession title={'MEU PERFIL'} />
            <InformacaoAdmin admin={user} />

        </Container>
    )
}
