import { DialogActions, Divider, Typography } from '@mui/material'
// import { makeStyles } from '@material-ui/core'
import Loading from '../../load/loading'
import QRCode from '../../qrCode/qrCode'
import HeaderGestorConfigs from '../../utils/headerGestorConfigs'
import printerService from '../../services/printer'
import PrintIcon from '@mui/icons-material/Print';
import { IconButton } from "@mui/material";


export default function FichaCadastramento({ estabelecimento, header }) {
    const baseUrl = window.location.origin;
    // console.log(estabelecimento)

    const HandlePrint = () => {
        var preview = document.getElementById('print');
        preview.style = "display: none"
        window.print()
        preview.style = "display:  "
    }
    const exportToPDF = () => {
        printerService.printer('ficha')
    }

    return (
        <>
            {estabelecimento ?

                <div id='ficha' style={{ fontSize: 12, position: 'relative', width: '21cm', height: '29.7cm', marginLeft: 'auto', marginRight: 'auto' }}>

                    {!header &&
                        <HeaderGestorConfigs
                            menu={true}
                            configArea={'fichaCadastramento'}
                            title={''}
                            estabelecimento={estabelecimento}
                            exportToPDF={exportToPDF}
                        />
                    }

                    <div style={{ position: 'absolute', }}>
                        <QRCode link={`${baseUrl}/verifica-estabelecimento/${estabelecimento._id}`} />
                    </div>

                    {header &&
                        <>
                            <DialogActions id="print" >
                                <IconButton onClick={() => HandlePrint()}> <PrintIcon /></IconButton>
                            </DialogActions>

                            <div align='center' style={{ marginBottom: 20 }} >
                                <img src="/img/logo/InsigniaAngola.png" width={80} alt="Insign" />
                                <Typography variant="h1" style={{ fontSize: 16, fontWeight: 'bold' }}>
                                    REPÚBLICA DE ANGOLA <br />
                                    MINISTÉRIO DA SAÚDE <br />
                                    AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIA DE SAÚDE
                                </Typography>
                            </div>
                        </>
                    }

                    <div style={{ display: 'flex', fontSize: 13 }}>
                        <div style={{ flexGrow: 1 }} />

                        {/* NUMERO DE PROCESSO */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Processo Nº
                            <div style={{ width: 300, margin: 10, background: '#eaa925', border: '1px solid', borderRadius: 3 }}>
                                <Typography align='center' style={{ fontWeight: 'bold', fontSize: 22, }} variant="subtitle1">
                                    {estabelecimento?.numeroProcesso}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <br />

                    <Typography style={{ fontWeight: 'bold', fontSize: 22, borderBottom: '1px solid ', display: 'inline', marginLeft: 170, }} variant="h1">
                        FICHA DE CADASTRAMENTO - ARMED
                    </Typography>

                    {/* ==============================DADOS DA EMPRESA? ================================== */}
                    <div className='container'>
                        <strong> DADOS DA EMPRESA </strong>

                        <table className="tabela" >

                            <tbody>
                                <tr >
                                    <td className="celulaCabecalho">Nome:</td><td className="celulaDado" colSpan="3"> {estabelecimento?.empresa?.nome}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">NIF:</td><td className="celulaDado" colSpan="3"> {estabelecimento?.empresa?.nif}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Representante:</td><td className="celulaDado" colSpan="3">  {estabelecimento?.empresa?.representante?.dadosPessoais?.nome}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Telefone:</td>
                                    <td className="celulaDado">  {estabelecimento?.empresa?.tel1}</td>
                                    <td className="celulaCabecalho">E-mail:</td>
                                    <td className="celulaDado">  {estabelecimento?.empresa?.email}</td>
                                </tr>

                                <tr>
                                    <td className="celulaCabecalho">Rua / Avenida:</td> <td className="celulaDado" colSpan="3">{estabelecimento?.empresa?.rua}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Bairro:</td> <td className="celulaDado" colSpan="3">{estabelecimento?.empresa?.bairro}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Distrito / Muniçipio:</td><td className="celulaDado" >{estabelecimento?.municipio}</td>
                                    <td className="celulaCabecalho">Província:</td><td className="celulaDado" >{estabelecimento?.provincia}</td>
                                </tr>
                                <tr>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* ============================= DADOS DO ESTABELECIMENTO ============================= */}

                    <div className='container'>
                        <strong> DADOS DO ESTABELECIMENTO </strong>

                        <table className="tabela" >

                            <tbody>
                                <tr >
                                    <td className="celulaCabecalho">Nome:</td><td className="celulaDado">   {estabelecimento?.nome}</td>
                                    <td className="celulaCabecalho">Solicitação:</td><td className="celulaDado" > ###</td>
                                </tr>

                                <tr>
                                    <td className="celulaCabecalho">Estabelecimento:</td><td className="celulaDado" colSpan="3"> {estabelecimento?.tipo?.designacao}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Rua / Avenida:</td><td className="celulaDado" colSpan="3"> {estabelecimento?.rua}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Bairro:</td><td className="celulaDado" colSpan="3">  {estabelecimento?.empresa?.tel1}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Distrito / Muniçipio:</td><td className="celulaDado">  {estabelecimento?.municipio}</td>
                                    <td className="celulaCabecalho">Província:</td><td className="celulaDado">{estabelecimento?.provincia}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho">Director Técnico:</td><td className="celulaDado">{estabelecimento?.directorTecnico?.dadosPessoais?.nome}</td>
                                    <td className="celulaCabecalho"> Nº de Registo (DT):</td><td className="celulaDado" >{estabelecimento?.directorTecnico?.numeroCarteira}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho"> Categoria:</td><td className="celulaDado" colSpan="3">{estabelecimento?.directorTecnico?.categoria?.designacao}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho"> Entidade (DT):</td><td className="celulaDado" colSpan="3"> {estabelecimento?.directorTecnico?.ordem}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalho"> telefone:</td><td className="celulaDado"> {estabelecimento?.directorTecnico?.dadosPessoais?.tel1}</td>
                                    <td className="celulaCabecalho"> E-mail:</td><td className="celulaDado" >{estabelecimento?.directorTecnico?.dadosPessoais?.email}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    {/* ==============================DOCUMENTOS ================================== */}
                    <div className='container'>
                        <strong> DOCUMENTOS </strong>

                        <table className="tabela" >

                            <tbody>
                                <tr >
                                    <td className="celulaCabecalhoDoc">
                                        {estabelecimento?.docs?.requerimentoARMED ?
                                            <a target="blank" style={{ textDecoration: 'none' }} href={estabelecimento?.docs?.requerimentoARMED}>
                                                Requerimento dirigido a Armed
                                            </a>
                                            : 'Requerimento dirigido a Armed'}
                                    </td>
                                    <td className="celulaDado"> {estabelecimento?.docs?.requerimentoARMED ? 'Sim' : 'Não'}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalhoDoc">
                                        {estabelecimento?.docs?.escrituraCS ?
                                            <a target="blank" style={{ textDecoration: 'none' }} href={estabelecimento?.docs?.escrituraCS}>
                                                Escritura de constituição de Sociedade (para Empresa)
                                            </a>
                                            : 'Escritura de constituição de Sociedade (para Empresa)'}
                                    </td>
                                    <td className="celulaDado">   {estabelecimento?.docs?.escrituraCS ? 'Sim' : 'Não'}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalhoDoc">
                                        {estabelecimento?.docs?.termoRDT ?
                                            <a target="blank" style={{ textDecoration: 'none' }} href={estabelecimento?.docs?.termoRDT}>
                                                Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)
                                            </a>
                                            : 'Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)'}
                                    </td>
                                    <td className="celulaDado">  {estabelecimento?.docs?.termoRDT ? 'Sim' : 'Não'}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalhoDoc">
                                        {estabelecimento?.docs?.copiaDI ?
                                            <a target="blank" style={{ textDecoration: 'none' }} href={estabelecimento?.docs?.copiaDI}>
                                                Cópia colorida do documento de identificação
                                            </a>
                                            : 'Cópia colorida do documento de identificação'}
                                    </td>
                                    <td className="celulaDado">  {estabelecimento?.docs?.copiaDI ? 'Sim' : 'Não'}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalhoDoc">
                                        {estabelecimento?.docs?.croquis ?
                                            <a target="blank" style={{ textDecoration: 'none' }} href={estabelecimento?.docs?.croquis}>
                                                Planta com quota das instalações e croquis de localização
                                            </a>
                                            : 'Planta com quota das instalações e croquis de localização'}

                                    </td>
                                    <td className="celulaDado">  {estabelecimento?.docs?.croquis ? 'Sim' : 'Não'}</td>
                                </tr>
                                <tr>
                                    <td className="celulaCabecalhoDoc">
                                        {estabelecimento?.docs?.comprovativoPE ?
                                            <a target="blank" style={{ textDecoration: 'none' }} href={estabelecimento?.docs?.comprovativoPE}>
                                                Comprovativo de Pagamento de emolumentos
                                            </a>
                                            : 'Comprovativo de Pagamento de emolumentos'}
                                    </td>
                                    <td className="celulaDado"> {estabelecimento?.docs?.comprovativoPE ? 'Sim' : 'Não'}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>

                        <Divider style={{ background: '#85287e', marginBottom: 10 }} />

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 11, lineHeight: 1.2, flexGrow: 1, marginRight: 50 }}>
                                Tel.(+244) 945 817 227 <br />
                                E-mail: expedientegeral@armed.gov.ao | www.armed.gov.ao <br />
                                Rua Cmdt, Che-Guevara nº 86/86A, Maculusso, <br />
                                Luanda - Angola
                            </Typography>

                            <img src="/img/logo/logoMinsa.svg" alt="Insign" />
                        </div>
                    </div>
                </div>
                :
                <Loading />
            }
        </>
    )
}
