
import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import NavLateral from "./navLateral";
import HeaderSession from "../../utils/headerSession";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../load/loading";
import api from "../../services/api";
import RegisterAccess from "../../utils/registerAccess";

const useStyles = makeStyles((theme) => ({
    grid1: {
        width: 300,
        marginBottom: 15,
        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up('md')]: {
            // width: '100%',
            height: '100%',
            position: 'sticky',
            top: 70,
        },
    },
    grid2: {
        flexGrow: 1,
        marginLeft: 15,
        // minHeight: '100vh',
        width: 500,
        zIndex: 1,
        // position: 'relative',
    }
}))

export default function GestLicenciados({ element: component, ...rest }) {
    const navigate = useNavigate()
    const classes = useStyles()
    const { id } = useParams()
    const [estabelecimento, setEstabelecimento] = useState('')

    useEffect(() => {
        setEstabelecimento('')

        api.get(`/estabelecimento/${id}`)
            .then(response => {
                // console.log(response)
                if (response.data.estabelecimento.approved === false || response.data.estabelecimento.status !== 'Activo') {
                    navigate(`/admin/analise/solicitacao-de-licenciamento/${id}`)
                }
                setEstabelecimento(response.data.estabelecimento);
            }).catch(err => '')
    }, [id])

    return (
        <Container>
            <RegisterAccess page={'gestão de estabelecimento licenciado'} />
            <HeaderSession title={'GESTÃO DE ESTABELECIMENTO LICENCIADO'} />

            {estabelecimento ?
                <Grid container>
                    <Grid item className={classes.grid1}  >
                        <NavLateral estabelecimento={estabelecimento} />
                    </Grid>

                    <Grid xs item className={classes.grid2}>

                        {React.cloneElement(component, { estabelecimento: estabelecimento })}

                    </Grid>
                </Grid>
                :
                <Loading />
            }
        </Container>
    )
}