import { Container, Grid, Box } from '@mui/material'
import ListaOpcoes from '../../../../utils/listaOpcoes'
import HeaderSession from '../../../../utils/headerSession'

export default function Agendamento() {
    return (
        <Container style={{ minHeight: '70vh' }}>
            <Box sx={{ display: { md: 'none' } }}>
                <HeaderSession title='AGENDAMENTO PARA:' />
            </Box>

            <Grid container>
                <Grid margin={1} xs={12} md={5.7} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ border: '2px solid #67348d', padding: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/img/icones/agendamento2.svg" alt="" width={100} />
                        <p style={{ fontSize: 14 }}>
                            AGENDAMENTO
                        </p>
                    </div>
                </Grid>
                <Grid margin={1} xs={12} md={5.7} item >

                    <Box width={'100%'} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <HeaderSession title='AGENDAMENTO PARA:'/>
                    </Box>

                    <ListaOpcoes nomeOpcao='Depósitos' link='/agenda-disponivel' />
                    <ListaOpcoes nomeOpcao='Ervanárias' link='/agenda-disponivel' />
                    <ListaOpcoes nomeOpcao='Fábricas' link='/agenda-disponivel' />
                </Grid>

            </Grid>
            <br />
        </Container>

    )
}
