import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingBackdrop from '../../../load/loadingBackdrop';
import { useState } from 'react';
import api from '../../../services/api';
import { useSelector } from 'react-redux';

const DeleteAgenda = (props) => {
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.account.user);

    const HandleDeleteAgenda = () => {
        props.setMessageSuccess('')
        props.setMessageError('')
        setOpen(true)


        api.delete('/agenda/delete', { data: { 'id': props.agendaId, 'userId': user._id } })
            .then(response => {
                // console.log(response)
                setOpen(false)
                props.setMessageSuccess('Eliminado com sucesso.')
                props.setForceUpdate(response)

            }).catch(err => {
                // console.log(err)
                setOpen(false)
                props.setMessageError('Erro ao eliminar')
            })
    }

    return (
        <>
            <LoadingBackdrop open={open} text={'Deletando agenda. Aguarde!'} />
            <Button title='Apagar' onClick={HandleDeleteAgenda} size="small" color="error"><DeleteIcon /></Button >
        </>
    );
}

export default DeleteAgenda;
