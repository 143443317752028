import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../services/api'
import Loading from '../../../load/loading'
import Ficha from '../fichaCadastramento'
import { Container } from '@mui/material'


export default function ExportFixaEstabelecimento() {
    const { id } = useParams()
    const [estabelecimento, setestabelecimento] = useState('')

    useEffect(() => {
        api.get(`/estabelecimento/${id}`)
            .then(response => {
                // console.log(response)
                setestabelecimento(response.data.estabelecimento);
            }).catch(err => console.log(err))
    }, [id])


    return (
        <Container>
            {estabelecimento ?
                <Ficha estabelecimento={estabelecimento} header={true} />
                :
                <Loading />
            }
        </Container>
    )
}
