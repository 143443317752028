
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DocumentViewer from "./documentViewer";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
import ButtonEditar from "../../utils/ButtonEditar";
import { useState } from "react";
import DocEdit from "../../utils/docEdit";
import { Divider, Typography, Card } from "@mui/material";


export default function DocumentosAnexados({ docs, estabelecimento }) {
    const [editRequerimentoARMED, setEditRequerimentoARMED] = useState(false)
    const [editEscrituraCS, setEditEscrituraCS] = useState(false)
    const [editTermoRDT, setEditTermoRDT] = useState(false)
    const [editCopiaDI, setEditCopiaDI] = useState(false)
    const [editCroquis, setEditCroquis] = useState(false)
    const [editComprovativoPE, setEditComprovativoPE] = useState(false)
    const [editCertificadoND, setEditCertificadoND] = useState(false)

    // RENOVAÇÃO
    const [editrenRequerimentoDG, setEditrenRequerimentoDG] = useState(false)
    const [editrenDeclaracaoOFA, setEditrenDeclaracaoOFA] = useState(false)
    const [editrenComprovativoPE, setEditrenComprovativoPE] = useState(false)

    return (
        <Card style={{ padding: 20, marginBottom: 15 }}>

            <HeaderGestorConfigs configArea={'documentos'} title={'DOCUMENTOS ANEXADOS'} />

            {/* VISUALIZADOR DE PDF */}
            {docs && <DocumentViewer pdfUrls={docs} />}

            {/* DOCUMENTOS GERAIS DO ESTABELECIMENTO*/}
            <br />
            <ul>
                <li style={{ display: 'flex' }}>
                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                        {docs?.requerimentoARMED ?
                            <>
                                <FilePresentIcon color='secondary' />
                                <a target="blank" style={{ textDecoration: 'none' }} href={docs?.requerimentoARMED}>
                                    Requerimento dirigido a Armed
                                </a>
                            </>
                            : '- Faltou Requerimento dirigido a Armed'}
                    </Typography>
                    <ButtonEditar setEdit={setEditRequerimentoARMED} edit={editRequerimentoARMED} />
                </li>
                {editRequerimentoARMED && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'requerimentoARMED'} />}


                <li style={{ display: 'flex' }}>
                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                        {docs?.escrituraCS ?
                            <>
                                <FilePresentIcon color='secondary' />
                                <a target="blank" style={{ textDecoration: 'none' }} href={docs?.escrituraCS}>
                                    Escritura de constituição de Sociedade (para Empresas)
                                </a>
                            </>
                            : '- Faltou Escritura de constituição de Sociedade (para Empresas)'}
                    </Typography>
                    <ButtonEditar setEdit={setEditEscrituraCS} edit={editEscrituraCS} />
                </li>
                {editEscrituraCS && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'escrituraCS'} />}


                <li style={{ display: 'flex' }}>
                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                        {docs?.termoRDT ?
                            <>
                                <FilePresentIcon color='secondary' />
                                <a target="blank" style={{ textDecoration: 'none' }} href={docs?.termoRDT}>
                                    Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)
                                </a>
                            </>
                            : '- Faltou Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)'}
                    </Typography>
                    <ButtonEditar setEdit={setEditTermoRDT} edit={editTermoRDT} />
                </li>
                {editTermoRDT && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'termoRDT'} />}


                <li style={{ display: 'flex' }}>
                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                        {docs?.copiaDI ?
                            <>
                                <FilePresentIcon color='secondary' />
                                <a target="blank" style={{ textDecoration: 'none' }} href={docs?.copiaDI}>
                                    Cópia colorida do documento de identificação
                                </a>
                            </>
                            : '- Faltou Cópia colorida do documento de identificação'}
                    </Typography>
                    <ButtonEditar setEdit={setEditCopiaDI} edit={editCopiaDI} />
                </li>
                {editCopiaDI && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'copiaDI'} />}


                <li style={{ display: 'flex' }}>
                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                        {docs?.croquis ?
                            <>
                                <FilePresentIcon color='secondary' />
                                <a target="blank" style={{ textDecoration: 'none' }} href={docs?.croquis}>
                                    Planta com quota das instalações e croquis de localização
                                </a>
                            </>
                            : '- Faltou Planta com quota das instalações e croquis de localização'}
                    </Typography>
                    <ButtonEditar setEdit={setEditCroquis} edit={editCroquis} />
                </li>
                {editCroquis && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'croquis'} />}


                <li style={{ display: 'flex' }}>
                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                        {docs?.certificadoND ?
                            <>
                                <FilePresentIcon color='secondary' />
                                <a target="blank" style={{ textDecoration: 'none' }} href={docs?.certificadoND}>
                                    Certificado de não devedor (AGT)
                                </a>
                            </>
                            : '- Faltou Certificado de não devedor (AGT)'}
                    </Typography>
                    <ButtonEditar setEdit={setEditCertificadoND} edit={editCertificadoND} />
                </li>
                {editCertificadoND && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'certificadoND'} />}

                <li style={{ display: 'flex' }}>
                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                        {docs?.comprovativoPE ?
                            <>
                                <FilePresentIcon color='secondary' />
                                <a target="blank" style={{ textDecoration: 'none' }} href={docs?.comprovativoPE}>
                                    Comprovativo de pagamento de emolumentos
                                </a>
                            </>
                            : '- Faltou Comprovativo de pagamento de emolumentos'}
                    </Typography>
                    <ButtonEditar setEdit={setEditComprovativoPE} edit={editComprovativoPE} />
                </li>
                {editComprovativoPE && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'comprovativoPE'} />}
            </ul>


            {/* DOCUMENTOS APENAS PARA SOLICITAÇÃO DE RENOVAÇÃO */}
            <br />
            {estabelecimento?.tipoEntrada === 'Renovação' &&

                <ul style={{ padding: 1 }}>
                    <Divider />
                    <Typography m variant="subtitle1">
                        Documentos para renovação
                    </Typography>

                    <li style={{ display: 'flex' }}>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                            {docs?.renRequerimentoDG ?
                                <>
                                    <FilePresentIcon color='secondary' />
                                    <a target="blank" style={{ textDecoration: 'none' }} href={docs?.renRequerimentoDG}>
                                        Requerimento dirigido a Armed
                                    </a>
                                </>
                                : '- Faltou Faltou Requerimento dirigido a Armed'}
                        </Typography>
                        <ButtonEditar setEdit={setEditrenRequerimentoDG} edit={editrenRequerimentoDG} />
                    </li>
                    {editrenRequerimentoDG && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'renRequerimentoDG'} />}


                    <li style={{ display: 'flex' }}>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                            {docs?.renDeclaracaoOFA ?
                                <>
                                    <FilePresentIcon color='secondary' />
                                    <a target="blank" style={{ textDecoration: 'none' }} href={docs?.renDeclaracaoOFA}>
                                        Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)
                                    </a>
                                </>
                                : '- Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)'}
                        </Typography>
                        <ButtonEditar setEdit={setEditrenDeclaracaoOFA} edit={editrenDeclaracaoOFA} />
                    </li>
                    {editrenDeclaracaoOFA && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'renDeclaracaoOFA'} />}


                    <li style={{ display: 'flex' }}>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle2">
                            {docs?.renComprovativoPE ?
                                <>
                                    <FilePresentIcon color='secondary' />
                                    <a target="blank" style={{ textDecoration: 'none' }} href={docs?.renComprovativoPE}>
                                        Comprovativo de pagamento de emolumentos
                                    </a>
                                </>
                                : '- Faltou Comprovativo de pagamento de emolumentos'}
                        </Typography>
                        <ButtonEditar setEdit={setEditrenComprovativoPE} edit={editrenComprovativoPE} />
                    </li>
                    {editrenComprovativoPE && <DocEdit estabelecimentoId={estabelecimento?._id} nomeDoc={'renComprovativoPE'} />}

                </ul>
            }

        </Card>
    )
}