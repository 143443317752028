
import { Card, makeStyles } from "@material-ui/core";
import { useEffect, useState, } from "react";
import { Divider, Grid, MenuItem, Typography, Button, TextField, DialogActions } from "@mui/material";
import Loading from "../../../load/loading";
import api from "../../../services/api";
import { useSelector } from "react-redux";
import MessageAlert from "../../../messages/messageAlert";
import DeleteCategoria from "./deleteCategoria";
import RegisterAccess from "../../../utils/registerAccess";

const useStyles = makeStyles({
    container: {
        // display: 'flex',
        justifyContent: 'center',
        boxShadow: 'none',
        // padding: 10,
        margin: 10
    },
})

export default function CategoriasDT() {
    const classes = useStyles()
    const [categorias, setCategorias] = useState('');
    const [load, setLoad] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [forceUpdate, setForceUpdate] = useState('')
    const user = useSelector(state => state.account.user);

    const [designacao, setDesignacao] = useState('')

    useEffect(() => {
        // FAZER BUSCA DOS categorias
        api.get('/categoria-tecnico')
            .then(response => {
                // console.log(response)
                setCategorias(response.data.categoriaTecnicos)

            }).catch(err => {
                // console.log(err)
            })
    }, [messageSuccess, forceUpdate])

    const createcategoria = () => {
        setMessageSuccess('')
        setMessageError('')
        setLoad(true)

        api.post('/categoria-tecnico/create', { designacao, 'userId': user._id })
            .then(response => {
                // console.log(response)
                setLoad(false)
                setMessageSuccess(response.data.msg)
                setDesignacao(null)

            }).catch(err => {
                setLoad(false)
                setMessageError(err.response.data.message)
            })
    }


    return (
        <Card style={{ boxShadow: 'none', minHeight: '50vh' }}>
   <RegisterAccess page={'categorias de directores técnicos'}/>
            {messageError && <MessageAlert message={messageError} type={'error'} />}
            {messageSuccess && <MessageAlert message={messageSuccess} type={'success'} />}

            <Grid container className={classes.container}>
                <Grid xs md={5} item m>
                    <Typography style={{ fontWeight: 'bold' }} variant="body1">CATEGORIAS </Typography>
                    <Divider />

                    {categorias ?
                        categorias.map((categoria) => (
                            <div key={categoria._id} style={{ display: 'flex' }}>
                                <DeleteCategoria
                                    categoriaId={categoria._id}
                                    setForceUpdate={setForceUpdate}
                                    userId={user._id}
                                    setMessageSuccess={setMessageSuccess}
                                    setMessageError={setMessageError}
                                />
                                <MenuItem>
                                    <Typography variant="body1">{categoria.designacao.toUpperCase()} </Typography>
                                </MenuItem>
                            </div>
                        ))
                        :
                        <Loading text={'A carregar'} />
                    }
                </Grid>

                <Grid xs md={5} item m>
                    <Typography style={{ fontWeight: 'bold' }} variant="body1">ADD CATEGORIA</Typography>
                    <Divider />

                    {!load ?
                        <Card style={{ padding: 10 }} >
                            <Grid m xs={12} md item >
                                <TextField
                                    type="text"
                                    fullWidth
                                    // required
                                    label="Nova Categoria"
                                    size="small"
                                    name="designacao"
                                    variant="outlined"
                                    onChange={(e) => setDesignacao(e.target.value)}
                                />
                            </Grid>

                            <DialogActions>
                                <Button disabled={!designacao} style={{ backgroundColor: '#67348d' }} variant="contained" onClick={createcategoria}>
                                    Salvar
                                </Button>
                            </DialogActions>
                        </Card>
                        :
                        <Loading text={'A Salvar'} />
                    }
                </Grid>
            </Grid>
        </Card>
    )
}

