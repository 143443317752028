
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { Button, DialogActions, Grid, MenuItem, TextField } from "@mui/material";
import api from "../../services/api";
import LoadingBackdrop from "../../load/loadingBackdrop";
import { useSelector } from "react-redux";
import apiCNP from "../../services/apiCNP";
import RegisterAccess from "../../utils/registerAccess";
import AlertEdit from "../../utils/alertEdit";
// import { validacaoEditDadosPessoal } from "../../validacoes";

export default function EmpresaEdit({ dadosEmpresa, cancelEditDados, setDadosEmpresa, setMessageSuccess, setMessageError, setRefresh }) {
    const [editDados, setEditDados] = useState(dadosEmpresa)
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.account.user);
    // console.log(editDados)

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);

            }).catch(err => '')

    }, [])

    function HandleChange(e) {

        setEditDados((prev) => ({ ...prev, [e.target.name]: e.target.value }));

        e.target.name === 'provinciaId' &&
            apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                    setEditDados((prev) => ({ ...prev, provincia: res.data.provincia.designacao }));
                }).catch(err => 'console.log(err)')
    }

    const submitEdit = async () => {

        setOpen(true)
        setMessageSuccess('')
        setMessageError('')

        await api.put('/empresa/edit', { ...editDados, 'empresaId': dadosEmpresa._id, 'userId': user?._id ?? '' })
            .then(res => {
                setDadosEmpresa(res.data.empresa)
                setRefresh(res.data.empresa)
                setOpen(false)
                setMessageSuccess(res.data.msg)
                cancelEditDados(false)

            }).catch(err => {
                // console.log(err)
                setOpen(false)
                setMessageError(err.response.data.message)
            })
    }

    return (
        <>
            <RegisterAccess page={'editar dados de empresa'} />
            <LoadingBackdrop open={open} />

            <Card style={{ border: '1px solid orange' }}>

                <AlertEdit />

                <Grid container >
                    <Grid xs={12} md item m>
                        <TextField
                            type="text"
                            required
                            label="Nome Empresa"
                            fullWidth
                            size="small"
                            name="nome"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.nome}
                        />
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            // margin='normal'
                            type="text"
                            required
                            label="NIF"
                            fullWidth
                            size="small"
                            name="nif"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.nif}
                        />
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            type="email"
                            required
                            label="E-mail"
                            fullWidth
                            size="small"
                            name="email"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.email}
                        />
                    </Grid>
                </Grid>

                <Grid container >

                    <Grid xs={12} md item m>
                        <TextField
                            type="number"
                            required
                            label="Telefone1"
                            fullWidth
                            size="small"
                            name="tel1"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.tel1}
                        />
                    </Grid>
                    <Grid xs={12} md item m>
                        <TextField
                            type="number"
                            label="Telefone2"
                            fullWidth
                            size="small"
                            name="tel2"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.tel2}
                        />
                    </Grid>
                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Endereço"
                            fullWidth
                            size="small"
                            name="rua"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.rua}
                        />
                    </Grid>
                </Grid>

                <Grid container >

                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Províncía"
                            select
                            fullWidth
                            size="small"
                            name="provinciaId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.provincia ?? ''}
                        >
                            <MenuItem disabled value={editDados?.provincia}>{editDados?.provincia}</MenuItem>
                            {provincias?.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia._id}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Município"
                            select
                            fullWidth
                            size="small"
                            name="municipio"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.municipio ?? ''}
                        >
                            <MenuItem disabled value={editDados?.municipio}>{editDados?.municipio}</MenuItem>
                            {municipios?.map((municipio) => (
                                <MenuItem key={municipio._id} value={municipio.designacao}>
                                    {municipio.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Bairro"
                            fullWidth
                            size="small"
                            name="bairro"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.bairro}
                        />
                    </Grid>
                </Grid>

                <DialogActions >
                    <Button onClick={() => { cancelEditDados(false); setMessageError('') }} color="warning" size="small" style={{ marginTop: 20, marginRight: 10 }}>Cancelar</Button>
                    <Button variant="contained" onClick={() => { submitEdit() }} size="small" style={{ marginTop: 20, background: '#85287e' }}>Salvar</Button>
                </DialogActions>
            </Card>
        </>
    )
}