import { Container, Divider, Typography, Button } from '@mui/material'
import HeaderSession from '../../../utils/headerSession'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../services/api'
import TextFormatado from '../../../utils/TextFormatado'
import Date from '../../../utils/date'
import Loading from '../../../load/loading'
import RegisterAccess from '../../../utils/registerAccess'

export default function ViewDestaque() {
    const { id } = useParams()
    const [noticia, setNoticia] = useState('')
    const [noticias, setNoticias] = useState('')

    useEffect(() => {
        api.get(`/noticia/${id}`)
            .then(response => {
                // console.log(response)
                setNoticia(response.data.noticia);
            }).catch(error => {
                // console.log(error)
            })

        api.get(`/noticias?seccao=Destaque`)
            .then(response => {
                // console.log(response)
                setNoticias(response.data.noticias.filter((noticia) => noticia._id !== id));
            }).catch(error => {
                // console.log(error)
            })
    }, [id])

    const CardNoticia = ({ noticia }) => {
        return (
            <div >
                <Typography variant="subtitle1" style={{ lineHeight: 1.3, fontSize: 20 }}>
                    <strong> {noticia.titulo} </strong>
                </Typography>
                <br />
                <img src={noticia?.imagem} alt="..." width={'100%'} style={{ borderRadius: 5, marginBottom: 5 }} />

                {noticia?.categoria &&
                    <Typography style={{ color: '#fff', marginBottom: 10, background: '#85287e', display: 'inline-block', padding: 3, borderRadius: 2 }}>
                        <span translate="no">{noticia.categoria}</span>
                    </Typography>
                }
                <Typography variant="subtitle2" style={{ lineHeight: 1, }}>
                    Data: <Date date={noticia.updatedAt} />
                    <br />
                    Fonte: <span translate="no">{noticia.fonte}</span>
                    {(noticia[id]?.link && noticia.fonte !== 'ARMED') &&
                        <a target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }} href={noticia.link}> Clique aquí para visitar </a>
                    }
                </Typography>

                <TextFormatado texto={noticia?.conteudo} />

                <br />
                {noticia?.anexo &&
                    <Button size='small' style={{ background: '#85287e', marginBottom:20 }} >
                        {/* <img src='/img/icones/view.svg' alt="" /> */}
                        <a style={{ textDecoration: 'none', color: '#fff' }} href={noticia?.anexo} target="_blank">
                            <img src='/img/icones/downloadicon.svg' alt="" />
                        </a>
                    </Button>
                }
                <Divider style={{marginBottom:30}}/>
            </div>
        );
    };

    return (
        <Container style={{ maxWidth: 800, minHeight: '70vh' }}>
         <RegisterAccess page={'visualizar de destaque'} />
            <HeaderSession title='DESTAQUE' />

            {noticia ?
                <>
                    {/* INPUT PARA DAR FOCO NO COMPONENTE ASSIM QUE FOR EXIBIDO */}
                    <input autoFocus type="text" style={{ opacity: 0, height: 1 }} />
                    <CardNoticia noticia={noticia} />
                </>
                :
                <Loading />
            }

            {noticias &&
                <>
                    <Typography variant="h1" style={{ color: '#a0a39e', lineHeight: 1, fontSize: 30, marginTop: 40, marginBottom: 20 }}>
                        <strong >Outros Destaques </strong>
                    </Typography>

                    {noticias.map((noticia) => (
                        noticia.status === "Activo" &&
                        <CardNoticia key={noticia._id} noticia={noticia} />
                    ))}
                </>
            }
            <br />
        </Container>
    )
}
