
import { useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton } from "@mui/material";

function DocumentViewer({ pdfUrls }) {
  // console.log(pdfUrls)
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const arrayPdfUrls = Object.entries(pdfUrls).map(([name, url]) => ({ name, url }));
  const handleSelectPdf = (index) => {
    setSelectedPdfIndex(index);
  };

  return (
    <div>
      <select
        value={selectedPdfIndex}
        onChange={(e) => handleSelectPdf(parseInt(e.target.value, 10))}
        style={{
          padding: '8px',
          fontSize: '16px',
          borderRadius: '4px',
          maxWidth: '80%'
          // margin: '10px',
        }}
      >
        {arrayPdfUrls.map((pdf, index) => (
          <option key={index} value={index}>
            {pdf.name === "copiaDI" && 'Cópia colorida do documento de identificação'}
            {pdf.name === "requerimentoARMED" && 'Requerimento dirigido a Armed'}
            {pdf.name === "renRequerimentoDG" && 'Requerimento dirigido a Armed (renovação)'}
            {pdf.name === "escrituraCS" && 'Escritura de constituição de Sociedade (para Empresas)'}
            {/* {pdf.name === "termoRDT" && 'Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)'} */}
            {pdf.name === "termoRDT" && 'Termo de Responsabilidade do Director Técnico e da Declaração...'}
            {pdf.name === "renDeclaracaoOFA" && 'Termo de Responsabilidade do Director Técnico e da Declaração... (renovação)'}
            {pdf.name === "croquis" && 'Planta com quota das instalações e croquis de localização'}
            {pdf.name === "certificadoND" && 'Certificado de não devedor (AGT)'}
            {pdf.name === "comprovativoPE" && 'Comprovativo de pagamento de emolumentos'}
            {pdf.name === "renComprovativoPE" && 'Comprovativo de pagamento de emolumentos (renovação)'}
          </option>
        ))}
      </select>


      <IconButton size='large' disabled={selectedPdfIndex < 1} type="submiy" onClick={() => setSelectedPdfIndex(selectedPdfIndex - 1)}>
        <NavigateBeforeIcon />
      </IconButton>

      <IconButton size='large' disabled={selectedPdfIndex >= arrayPdfUrls.length - 1}  type="submiy" onClick={() => setSelectedPdfIndex(selectedPdfIndex + 1)}>
        <NavigateNextIcon />
      </IconButton>

      <iframe
        id="pdfIframe"
        title={`PDF Viewer`}
        src={arrayPdfUrls[selectedPdfIndex].url}
        width="100%"
        height="800px"
        style={{ border: 'none' }}
      />
    </div>
  );
}

export default DocumentViewer;





