
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions, Card, Typography, FormControlLabel, Checkbox, Alert, AlertTitle } from "@mui/material";
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import LoadingBackdrop from "../../../../load/loadingBackdrop";
import { useNavigate, useParams } from "react-router-dom";
import FazerObservacoes from "../fazerObservacoes.";
import api from "../../../../services/api";
import RegisterAccess from "../../../../utils/registerAccess";


export default function EmissaoAutorizacaoExercicio({ estabelecimento }) {
    const { id } = useParams()
    const { encaminhar } = useParams()
    const navigate = useNavigate()
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [open, setOpen] = useState(false);
    const [obs, setObs] = useState('')
    const user = useSelector(state => state.account.user);
    const [approveData, setApproveData] = useState('')
    const dispatch = useDispatch()
    // console.log(approveData)

    function handleChange(e) {
        e.target.files ?
            setApproveData((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }))
            :
            setApproveData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const approveEstabelecimento = async () => {
        setOpen(true)
        setMessageSuccess('')
        setMessageError('')

        await api.patch('/estabelecimento/approve', { ...approveData, estabelecimentoId: id, userId: user._id })
            .then(res => {
                // console.log(res)

                setOpen(false)
                setMessageSuccess(res.data.msg)

                obs && HandleCreateobservacao()

                dispatch({
                    type: 'alert',
                    payload: { alert: 'O ESTABELECIMENTO FOI APROVADO E EMITIDA A SUA AUTORIZAÇÃO DE EXERCÍCIO.' },
                });

                window.setTimeout(() => {
                    navigate(`/admin/autorizacao-de-exercicio/${id}`)
                    // navigate(`/admin/gestor-licenciados/${id}`)
                }, 3000);

            }).catch(err => {
                setOpen(false)
                setMessageError(err.response.data.message)
            })
    }

    async function HandleCreateobservacao() {
        setOpen(true)
        setMessageError('')
        setMessageSuccess('')

        await api.post('/observacao/create', {
            assunto: encaminhar === 'Aprovação',
            descricao: obs,
            userId: user._id,
            estabelecimentoId: id
        }).then(res => {
            // console.log(res)
            // setobservacao({ ...observacao, descricao: '', assunto: '' })
            setOpen(false)
            setMessageSuccess(res.data.msg)

        }).catch(error => {
            setMessageError(error.response.data.message)
            setOpen(false)
        })
    }

    async function senEmail(email) {

        const msg = {
            to: email,
            subject: 'Estado da solicitação de licenciamento de estabelecimento faramacêutico',
            html: `<h1>O processo está no estado:</h1>
            <p>
            Este é um email de acompanhamento da solicitação 
            de lienciamento do estabelecimento <b> ${estabelecimento?.nome} </b>
            com o número de entrada <b> ${estabelecimento?.numeroEntrada} </b>
            que submeteu na <b>ARMED</b> 
            </p>

            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };

        api.post('/email/send', msg)
            .then(response => {
                // console.log(response)
            }).catch(err => {
                // console.log(err) 
            })
    };


    return (
        <Card style={{ padding: 20, paddingBottom: 30 }}>
            <RegisterAccess page={'emissão de autorização'} />
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={open} text={'AGUARDE O ESTABELECIMENTO ESTA A SER APROVADO E AUTORIZADO.'} />

            {/* <br />
            <div style={{ display: 'flex', Content: "center" }}>
                <img src="/img/icones/licenciamentoicon.svg" alt="" width={150} />
            </div> */}

            <Alert severity="info">
                <AlertTitle>Selecionar Opção:</AlertTitle>
                Deve Selecionar o tipo de actividade que se adequou com o estabelecimento.
            </Alert>

            {/*  OPCAO 1 */}
            <Typography m marginBottom={2}>
                <FormControlLabel label={'1 -'} value={'a'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'a' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    actividade de venda a {estabelecimento?.tipo?.venda} de medicamentos essênciais de uso humano e materiais gastáveis,
                </span>
            </Typography>

            {/*  OPCAO 2 */}
            <Typography m={2}>
                <FormControlLabel label={'2 -'} value={'b'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'b' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    actividade de venda a {estabelecimento?.tipo?.venda} de medicamentos essênciais de uso humano incluido medicamentos de especialidade e medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial,
                </span>
            </Typography>

            {/*  OPCAO 3 */}
            <Typography m={2}>
                <FormControlLabel label={'3 -'} value={'c'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'c' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    actividade de Importação e Distribuição de equipamentos médicos, meios de diagnósticos e matérias hospitalares,
                </span>
            </Typography>

            {/*  OPCAO 4 */}
            <Typography m={2}>
                <FormControlLabel label={'4 -'} value={'d'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'd' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    actividade de Importação e Distribuição de medicamentos essênciais de uso humano, incluido medicamentos de especialidade e medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, matériais gastáveis e cosméticos,
                </span>
            </Typography>

            {/*  OPCAO 5 */}
            <Typography m={2}>
                <FormControlLabel label={'5 -'} value={'e'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'e' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    actividade de Importação de equipamentos médicos, meios de diagnósticos e matérias hospitalares,
                </span>
            </Typography>

            {/*  OPCAO 6 */}
            <Typography m={2}>
                <FormControlLabel label={'6 -'} value={'f'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'f' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                actividade de Importação de medicamentos essênciais de uso humano, incluido medicamentos de especialidade de medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, matériais gastáveis e cosméticos,
                </span>
            </Typography>

            {/*  OPCAO 7 */}
            <Typography m={2}>
                <FormControlLabel label={'7 -'} value={'g'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'g' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    actividade de Distribuição de equipamentos médicos, meios de diagnósticos e matérias hospitalares,
                </span>
            </Typography>

            {/*  OPCAO 8 */}
            <Typography m={2}>
                <FormControlLabel label={'8 -'} value={'h'} name='tipoAutorizacao' control={<Checkbox checked={approveData?.tipoAutorizacao === 'h' ? true : false} />} onChange={handleChange} />
                Autoriza a empresa
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                    {estabelecimento?.empresa.nome}
                </span>
                a exercer a
                <span style={{ fontWeight: 'bold', margin: 5 }}>
                actividade de Distribuição de medicamentos essênciais de uso humano, incluido medicamentos de especialidade de medicamentos contendo substâncias psicotrópicas e estupefacientes sujeitos à legislação especial, matériais gastáveis e cosméticos,
                </span>
            </Typography>




            <br />
            <Alert severity="info">
                <AlertTitle>Restrições:</AlertTitle>
            </Alert>
            <Typography m>
                <FormControlLabel label={'1 -'} value={'a'} name='restricaoAutorizacao' control={<Checkbox checked={approveData?.restricaoAutorizacao === 'a' ? true : false} />} onChange={handleChange} />
                Nenhuma
            </Typography>

            <Typography m={2}>
                <FormControlLabel label={'2 -'} value={'b'} name='restricaoAutorizacao' control={<Checkbox checked={approveData?.restricaoAutorizacao === 'b' ? true : false} />} onChange={handleChange} />
                Psicotrópicos, Estupefacientes e outros medicamentos sujeitos à legislação especial.
            </Typography>

            <Typography m={2}>
                <FormControlLabel label={'3 -'} value={'c'} name='restricaoAutorizacao' control={<Checkbox checked={approveData?.restricaoAutorizacao === 'c' ? true : false} />} onChange={handleChange} />
                Medicamentos, matériais gastáveis e outros produtos de saude não descrito no paragrafo terçeiro.
            </Typography>

            <Typography m={2}>
                <FormControlLabel label={'4 -'} value={'d'} name='restricaoAutorizacao' control={<Checkbox checked={approveData?.restricaoAutorizacao === 'd' ? true : false} />} onChange={handleChange} />
                Medicamentos, matériais gastáveis, equipamentos medicos, matériais hospitalares e outros produtos de saude não descrito no paragrafo terçeiro.
            </Typography>

            <Typography m={2}>
                <FormControlLabel label={'5 -'} value={'e'} name='restricaoAutorizacao' control={<Checkbox checked={approveData?.restricaoAutorizacao === 'e' ? true : false} />} onChange={handleChange} />
               Equipamentos médicos, meios de diagnósticos e outros produtos de saúde não descritos no parágrafo terceiro. 
            </Typography>
            <br />

            <FazerObservacoes setObs={setObs} obs={obs} titulo={'(caso seja necessário fazer alguma observação)'} />
            <DialogActions>
                <Button color="error" onClick={() => navigate(`/admin/analise/solicitacao-de-licenciamento/${id}`)} >Cancelar</Button>
                <Button disabled={!approveData?.tipoAutorizacao} color="success" variant="contained" onClick={approveEstabelecimento}>Confirmar</Button>
            </DialogActions>
        </Card>
    )
}