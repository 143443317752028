import { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import './CustomLoading.css'; // Arquivo de estilos CSS para o componente de loading

const CustonLoadingAremedGEstor = () => {

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRefresh(true);
    }, 30000);
  }, []);


  return (
    <div className="custom-loading-container">
      <img src={"/img/logo/logoArmed.svg"} width={200} alt="CPL" />
      <div className="armed">
        <span>G</span>
        <span>E</span>
        <span>S</span>
        <span>T</span>
        <span>O</span>
        <span>R</span>
        <span>-</span>
        <span>A</span>
        <span>R</span>
        <span>M</span>
        <span>E</span>
        <span>D</span>
      </div>
      <p>Carregando...</p>

      {refresh &&
        <>
          <br />
          <small>
            Está demorando mais que o normal..
          </small>

          <Button size="small" onClick={() => window.location.reload()}>Clique aquí </Button>
        </>
      }
    </div>
  );
};

export default CustonLoadingAremedGEstor;

