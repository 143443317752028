import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },

    img: {
        transition: '.5s',
        // cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
        }
    }
}))

export default function CardNoticiaCaroussel({ image, title, id }) {
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <CardActionArea title='Saiba mais..' onClick={() => id ? navigate(`/view-destaque/${id}`) : ''} className={classes.root} >
                <Card style={{ minHeight: 420 }}>
                    <CardMedia
                        className={classes.img}
                        component="img"
                        alt="img"
                        // height="200"
                        height="250"
                        image={image}
                    />
                    <CardContent>
                        <Typography style={{lineHeight:1.4, }} variant="subtitle1" component="div">
                            <strong>
                                {title.length > 100 ? title.slice(0, 100) + '...' : title}
                            </strong>
                        </Typography>
                    </CardContent>
                </Card>
            </CardActionArea>
            );
}
