
import { useEffect, useState } from "react";
import HeaderSession from "../../../../utils/headerSession";
import { Alert, Avatar, Button, Card, Container, Typography } from "@mui/material";
import api from "../../../../services/api";
import { useParams } from "react-router-dom";
import Loading from "../../../../load/loading";
import FichaEstabelecimento from "../../../gestLicenciados/fichaEstabelecimento";
import HeaderGestorConfigs from "../../../../utils/headerGestorConfigs";
import DocumentViewer from "./documentViewer";
import ReciboRenovacao from "./utente/reciboRenovacao";
import Approve from "./Approve";
import ShortNome from "../../../../utils/ShortNome";
import DateHora from "../../../../utils/dateHora";
import TextFormatado from "../../../../utils/TextFormatado";
import LoadingShort from "../../../../load/loadingShort";
import Cadastro from "../licenciamento/cadastroEstabelecimento";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RegisterAccess from "../../../../utils/registerAccess";


export default function GestSolicitacaoRenavacao({ element: component, ...rest }) {
    const { id } = useParams()
    const [solicitacaoRenovacao, setSolicitacaoRenovacao] = useState('')
    const [observacaoFeita, setobservacoesFeitas] = useState('')
    const [activeInserir, setActiveInserir] = useState(false)
    const [estabelecimento, setEstabelecimento] = useState('')
    const [openRecibo, setOpenRecibo] = useState(false);

    useEffect(() => {
        api.get(`/solicitacao-renovacao/${id}`)
            .then(response => {
                // console.log(response)
                setSolicitacaoRenovacao(response.data.solicitacaoRenovacao);

                api.get(`/observacaos?estabelecimentoId=${response.data.solicitacaoRenovacao?.estabelecimento._id}`)
                    .then(response => {
                        // console.log(response)
                        // setobservacoesFeitas(response.data.observacaos[response.data.observacaos.length - 1]);
                        setobservacoesFeitas(response.data.observacaos[0]);
                    }).catch(err => '')

            }).catch(err => '')
    }, [id])

    // componente
    const ProcessoReprovado = ({ status }) => {
        return (
            <Alert severity={status === 'Reprovado' ? 'warning' : 'success'}>

                {status === 'Reprovado' ? 'Solicitação reprovada por:' : 'Solicitação Aprovada por:'}

                {/* <TextoFormatado texto={observacaoFeita?.descricao} /> */}

                {observacaoFeita ?
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar src={observacaoFeita?.admin?.dadosPessoais.fotoURL} style={{ marginRight: 5 }} />

                            <Typography noWrap style={{ lineHeight: 1 }} variant="body1">
                                <ShortNome nome={observacaoFeita?.admin?.dadosPessoais.nome} />
                                <br />
                                <small>
                                    <DateHora date={observacaoFeita?.createdAt} />
                                </small>
                            </Typography>
                        </div>
                        <div style={{ borderRadius: 5, padding: 5, fontSize: 14, margin: 2 }}>
                            <TextFormatado texto={observacaoFeita.descricao} />
                        </div>
                    </>
                    :
                    <LoadingShort />
                }
            </Alert>
        );
    };

    return (
        <Container>
            <RegisterAccess page={'analise de solicitação de renovação'} />
            <HeaderSession title={'ANÁLISE DE SOLITAÇÃO DE RENOVAÇÃO'} />

            {solicitacaoRenovacao ?
                < >
                    {solicitacaoRenovacao?.status === 'Análise' ?
                        <Approve renovacao={solicitacaoRenovacao} />
                        :
                        <ProcessoReprovado status={solicitacaoRenovacao?.status} />
                    }

                    {solicitacaoRenovacao?.estabelecimento ?
                        <FichaEstabelecimento estabelecimento={solicitacaoRenovacao?.estabelecimento} />
                        :
                        <Card style={{ padding: 20, marginBottom: 10, border: '1px solid red' }}>

                            {!estabelecimento &&
                                <>
                                    {solicitacaoRenovacao?.numeroAutorizacao ?
                                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                                            O estabelecimento com o número de autorização ou processo <b>{solicitacaoRenovacao?.numeroAutorizacao}</b> solicitado para renovação não foi encontrado no sistema.
                                            <br />
                                            É necessário fazer a inserção dos dados do estabelecimento no sistema.
                                        </Typography>
                                        :
                                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1">
                                            O estabelecimento solicitado para renovação não foi encontrado no sistema.
                                            <br />
                                            É necessário fazer a inserção dos dados do estabelecimento no sistema.
                                        </Typography>
                                    }

                                    <br />
                                    <Button  onClick={() => setActiveInserir(!activeInserir)} >
                                        {activeInserir ? 'Cancelar' : 'Inserir Agora'}
                                    </Button>
                                </>
                            }

                            {activeInserir &&
                                <Cadastro tipoRegisto={'Inserção'} setEstabelecimento={setEstabelecimento} renovacaoResponse={solicitacaoRenovacao}/>
                            }
                        </Card>
                    }

                    {/* RECIBO */}
                    <List
                        sx={{ width: '100%', minWidth: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton onClick={() => setOpenRecibo(!openRecibo)}>
                            <ListItemIcon>
                                <ReceiptIcon />
                            </ListItemIcon>
                            <ListItemText primary="RECIBO" />
                            {openRecibo ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={openRecibo} timeout="auto" unmountOnExit>
                            <ReciboRenovacao renovacao={solicitacaoRenovacao} />
                        </Collapse>

                    </List>
                    <br />

                    {/* DADOS DE SOLICITANTE' */}
                    <Card style={{ padding: 20 }}>
                        <HeaderGestorConfigs
                            title={'DADOS DE SOLICITANTE'}
                        />

                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Tipo de ligação com o estabelecimento:</strong> {solicitacaoRenovacao?.ligacaoEstabelecimento}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nome:</strong> {solicitacaoRenovacao?.nome}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>BI:</strong> {solicitacaoRenovacao?.numeroBI}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>E-mail:</strong> {solicitacaoRenovacao?.email} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Telefone:</strong> {solicitacaoRenovacao?.tel} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Rua:</strong> {solicitacaoRenovacao?.rua} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Bairro:</strong> {solicitacaoRenovacao?.bairro} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Município:</strong> {solicitacaoRenovacao?.municipio} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Provincia:</strong> {solicitacaoRenovacao?.provincia} </Typography>
                    </Card>

                    <Card style={{ padding: 20, marginTop: 15 }}>
                        <HeaderGestorConfigs configArea={'documentos'} title={'DOCUMENTOS ANEXADOS'} />

                        <DocumentViewer pdfUrls={solicitacaoRenovacao.docs} />
                    </Card>
                </>
                :
                <Loading />
            }
        </Container>
    )
}