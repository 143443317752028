
import { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { Card, Container, Grid, Typography, makeStyles } from "@material-ui/core"
import api from "../../../../../services/api";
import MessageSuccess from "../../../../../messages/messageSuccess";
import MessageError from "../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Alert from '@mui/material/Alert';
import RegisterAccess from "../../../../../utils/registerAccess";
import HeaderSession from "../../../../../utils/headerSession";
import ButtonLeft from "../../../../../utils/buttonLeft";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { CountryDropdown } from 'react-country-region-selector';

const useStyles = makeStyles((theme) => ({

    inputPais: {
        marginTop: 8,
        padding: 10,
        borderRadius: 4,
        borderColor: '#e1e1e1',
        width: '100%'
    },

    inputFile:{
        border: '1px dashed',
         borderRadius: 5, 
         marginTop: 10
    }

}))

export default function FormSoliciteImportacao() {
    const classes = useStyles()
    const [solicitacao, setSolicitacao] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')
    const [response, setResponse] = useState(false);
    const [open, setOpen] = useState(false)

    function handleChange(e) {
        e.target.files ?
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.files[0] })
            :
            setSolicitacao({ ...solicitacao, [e.target.name]: e.target.value })
    }

    // console.log(solicitacao)

    async function createReclamacao() {
        const formData = new FormData();
        formData.append('anexo1', solicitacao?.anexo1);
        formData.append('anexo2', solicitacao?.anexo2);
        formData.append('nome', solicitacao?.nome ? solicitacao.nome : '');
        formData.append('email', solicitacao?.email ? solicitacao.email : '');
        formData.append('tel', solicitacao?.tel ? solicitacao.tel : '');
        formData.append('assunto', solicitacao?.assunto ? solicitacao?.assunto : '');
        formData.append('descricao', solicitacao?.descricao ? solicitacao?.descricao : '');

        setOpen(true)
        seterrorMessage('')
        setMessage('')

        await api.post('/solicitacao/create', formData)
            .then(res => {
                setOpen(false)
                setMessage(res.data.msg)
                // console.log(res)
            }).catch(error => {
                seterrorMessage(error.response.data.message)
                setOpen(false)
                // console.log(error)
            })
    }

    return (
        <div>
            <RegisterAccess page={'solicitação de importação'} />
            <LoadingBackdrop open={open} text={'Enviando reclamação.!'} />

            <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
                <HeaderSession title='SOLICITAÇÃO DE IMPORTAÇÃO' />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}

                {!message &&
                    <Card style={{ padding: 20 }}>

                        <Grid xs={12} md item >

                            <FormControl fullWidth>
                                <InputLabel id="tipoCertificado">Tipo Certificado</InputLabel>
                                <Select
                                    margin="dense"
                                    labelId="tipoCertificado"
                                    id="tipoCertificado"
                                    size="small"
                                    label="Tipo Certificado"
                                    name="tipoCertificado"
                                    value={solicitacao.tipoCertificado ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value='Productos Especiais'>Productos Especiais</MenuItem>
                                    <MenuItem value='Psicotrópicos'>Psicotrópicos</MenuItem>
                                    <MenuItem value='Estupefacientes'>Estupefacientes</MenuItem>
                                    <MenuItem value='Precursores'>Precursores</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="text"
                                label="Endereço"
                                fullWidth
                                size="small"
                                name="endereco"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="text"
                                label="Fatura Proforma Nº"
                                fullWidth
                                size="small"
                                name="fatProforma"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="text"
                                label="Nº da Factura"
                                fullWidth
                                size="small"
                                name="nFatura"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="text"
                                label="Nº de autorizacao (Opcional)"
                                fullWidth
                                size="small"
                                name="numeroAutorizacao"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Grid>

                        <CountryDropdown
                            className={classes.inputPais}
                            value={solicitacao?.paisOrigem ?? ''}
                            // onChange={handleChange}
                            onChange={(pais) => setSolicitacao({ ...solicitacao, paisOrigem: pais })}
                            defaultOptionLabel='Pais De Origem'
                        />


                        {/* ANEXAR DOCUMENTOS */}

                        <div align="center" className={classes.inputFile}> 
                            <label htmlFor="anexo1" style={{ margin: 5, cursor: 'pointer' }} >

                                {solicitacao?.anexo1 ?
                                    <>
                                        <Typography variant="subtitle2" title="Alterar">
                                            <Alert severity="success">
                                                {solicitacao?.anexo1?.name}
                                            </Alert>
                                        </Typography>
                                        <img src={URL.createObjectURL(solicitacao?.anexo1)} alt="" width={'25%'} />
                                    </>
                                    :
                                    <Typography variant="subtitle2" >
                                        {/* <CloudUploadIcon fontSize="large" /> <br /> */}
                                        Adicionar Certificado 
                                    </Typography>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="anexo1" id="anexo1" style={{ display: 'none' }} onChange={handleChange} />
                        </div>
                        <div align="center" className={classes.inputFile}> 
                            <label htmlFor="anexo1" style={{ margin: 5, cursor: 'pointer' }} >

                                {solicitacao?.anexo1 ?
                                    <>
                                        <Typography variant="subtitle2" title="Alterar">
                                            <Alert severity="success">
                                                {solicitacao?.anexo1?.name}
                                            </Alert>
                                        </Typography>
                                        <img src={URL.createObjectURL(solicitacao?.anexo1)} alt="" width={'25%'} />
                                    </>
                                    :
                                    <Typography variant="subtitle2" >
                                        {/* <CloudUploadIcon fontSize="large" /> <br /> */}
                                        Adicionar Autorização
                                    </Typography>
                                }
                            </label>       
                            <input accept="application/pdf" type="file" name="anexo1" id="anexo1" style={{ display: 'none' }} onChange={handleChange} />
                        </div>

                        <br />
                        <ButtonLeft title={'Adicionar itens da factura'} funcao={createReclamacao} disabled={false} />
                    </Card>
                }

            </Container>
            <br />
        </div>
    );
}
