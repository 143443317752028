import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@material-ui/core";
import { Badge, Button, Divider } from "@mui/material";
import ShortNome from '../../../utils/ShortNome';
import TextFormatado from '../../../utils/TextFormatado';
// import TempoPassado from '../../../utils/tempoPassado';
import printerService from '../../../services/printer';
import PrintIcon from '@mui/icons-material/Print';
import { IconButton } from "@mui/material";
import DateHora from '../../../utils/dateHora';
import RegisterAccess from '../../../utils/registerAccess';


export default function VerTodasObservacoes(props) {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    const exportToPDF = () => {
        printerService.printer('scroll-dialog-description')
    }


    return (
        <div>
            <Button onClick={handleClickOpen('body')} size="small">ver todas</Button>

            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <RegisterAccess page={'ver todas observações'} />

                <DialogTitle id="scroll-dialog-title">
                    {/* IMPRIMIR OBSERVACOES FEITAS  */}
                    <IconButton onClick={() => exportToPDF()}> <PrintIcon /></IconButton>

                    Todas observações
                    <Badge style={{ padding: 7 }} badgeContent={props.observacoesFeitas.length}
                        color="error"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                    </Badge>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>

                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    // style={{ minWidth: 500 }}
                    >
                        {props.observacoesFeitas.map((observacaoFeita) => (
                            <div key={observacaoFeita._id} style={{ padding: 10, marginBottom: 10 }}>

                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    {/* {props.img ? */}
                                    <img alt='img' src={observacaoFeita?.admin?.dadosPessoais.fotoURL} style={{ marginRight: 5, width: 40, height: 40 }} />

                                    {/* <Avatar src={observacaoFeita?.admin?.dadosPessoais.fotoURL} style={{ marginRight: 5 }} /> */}
                                    {/* } */}

                                    <Typography noWrap style={{ lineHeight: 1 }} variant="body1">
                                        <ShortNome nome={observacaoFeita?.admin?.dadosPessoais.nome} />
                                        <br />
                                        <small>{observacaoFeita?.admin?.dadosPessoais?.perfil}</small>
                                        <br />
                                        <small>
                                            {/* {props.img ? */}
                                            <DateHora date={observacaoFeita?.updatedAt} />
                                            {/* : */}
                                            {/* <TempoPassado date={observacaoFeita?.updatedAt} /> */}
                                            {/* } */}
                                        </small>
                                    </Typography>
                                </div>
                                <div style={{ borderRadius: 5, padding: 5, fontSize: 14, margin: 2 }}>
                                    <TextFormatado texto={observacaoFeita.descricao} />
                                </div>
                                <Divider />
                            </div>
                        ))}

                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
