
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card } from "@material-ui/core"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Alert from '@mui/material/Alert';
// import { useSelector } from 'react-redux';
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    card: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 8,
        minHeight: 90,
        padding: 5,
        border: '1px dashed #3e3d3f',
        fontSize: 11
    }
})

export default function FormAnexarDocumentos({ HandleChange, tipoRegisto }) {
    const classes = useStyles()
    const ficheiros = useSelector(state => state.dadosCadastroEstabelecimento.data)

    return (
        <Box>
            <Typography variant="h5" style={{ margin: 20 }}>
                ANEXAR DOCUMENTOS
            </Typography>
            <div style={{ margin: 10 }}>
                <span variant="body2" style={{ marginBottom: 20 }}>
                    Ficheiro permitido: <strong>  PDF </strong> (tamanho máximo: 2mb)
                </span>
            </div>


            {/* ############################################################################################# */}
            <Grid container>
                {tipoRegisto === 'Inscrição' &&
                    <Grid xs={12} md item >
                        <Card className={classes.card}>
                            <label htmlFor="ficheiros1" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    ficheiros?.requerimentoARMED ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {ficheiros.requerimentoARMED.name}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Requerimento dirigido a ARMED
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="requerimentoARMED" id="ficheiros1" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>
                }
                {/* ############################################################################################# */}
                <Grid xs={12} md item >
                    <Card className={classes.card}>
                        <label htmlFor="ficheiros2" style={{ margin: 10, cursor: 'pointer' }} >
                            {
                                ficheiros?.escrituraCS ?
                                    <span title="Alterar">
                                        <Alert severity="success">
                                            {ficheiros.escrituraCS.name}
                                        </Alert>
                                    </span>
                                    :
                                    <span>
                                        <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                        Escritura de constituição de Sociedade (para Empresas)
                                    </span>
                            }
                        </label>
                        <input accept="application/pdf" type="file" name="escrituraCS" id="ficheiros2" style={{ display: 'none' }} onChange={HandleChange} />
                    </Card>

                </Grid>
                {tipoRegisto === 'Inscrição' &&
                    <Grid xs={12} md item >
                        {/* ############################################################################################# */}
                        <Card className={classes.card}>
                            <label htmlFor="ficheiros3" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    ficheiros?.termoRDT ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {ficheiros.termoRDT.name}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Termo de Responsabilidade do Director Técnico e da Declaração Original da OFA (ou ASSOFARMA)
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="termoRDT" id="ficheiros3" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>
                }
            </Grid>


            <Grid container>
                {tipoRegisto === 'Inscrição' &&
                    <Grid xs={12} md item >
                        <Card className={classes.card}>
                            <label htmlFor="ficheiros4" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    ficheiros?.copiaDI ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {ficheiros.copiaDI.name}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Cópia colorida do documento de identificação
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="copiaDI" id="ficheiros4" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>
                }
                {/* ############################################################################################# */}

                <Grid xs={12} md item >
                    <Card className={classes.card}>
                        <label htmlFor="ficheiros5" style={{ margin: 10, cursor: 'pointer' }} >
                            {
                                ficheiros?.croquis ?
                                    <span title="Alterar">
                                        <Alert severity="success">
                                            {ficheiros.croquis.name}
                                        </Alert>
                                    </span>
                                    :
                                    <span>
                                        <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                        Planta com quota das instalações e croquis de localização
                                    </span>
                            }
                        </label>
                        <input accept="application/pdf" type="file" name="croquis" id="ficheiros5" style={{ display: 'none' }} onChange={HandleChange} />
                    </Card>
                </Grid>
                {tipoRegisto === 'Inscrição' &&
                    <Grid disabled xs={12} md item >
                        {/* ############################################################################################# */}
                        <Card disabled className={classes.card}>
                            <label htmlFor="ficheiros6" style={{ margin: 10, cursor: 'pointer' }} >
                                {
                                    ficheiros?.comprovativoPE ?
                                        <span title="Alterar">
                                            <Alert severity="success">
                                                {ficheiros.comprovativoPE.name}
                                            </Alert>
                                        </span>
                                        :
                                        <span>
                                            <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                            Comprovativo de Pagamento de emolumentos
                                        </span>
                                }
                            </label>
                            <input accept="application/pdf" type="file" name="comprovativoPE" id="ficheiros6" style={{ display: 'none' }} onChange={HandleChange} />
                        </Card>
                    </Grid>
                }

                <Grid disabled xs={12} md item >
                    {/* ############################################################################################# */}
                    <Card disabled className={classes.card}>
                        <label htmlFor="ficheiros7" style={{ margin: 10, cursor: 'pointer' }} >
                            {
                                ficheiros?.certificadoND ?
                                    <span title="Alterar">
                                        <Alert severity="success">
                                            {ficheiros.certificadoND.name}
                                        </Alert>
                                    </span>
                                    :
                                    <span>
                                        <CloudUploadIcon color='secondary' fontSize="large" /> <br />
                                        Certificado de não devedor (AGT)
                                    </span>
                            }
                        </label>
                        <input accept="application/pdf" type="file" name="certificadoND" id="ficheiros7" style={{ display: 'none' }} onChange={HandleChange} />
                    </Card>
                </Grid>

            </Grid>
        </Box>
    )
}


