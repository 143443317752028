import { useNavigate } from 'react-router-dom'
import { IconButton } from "@mui/material";
// import { useNavigate } from "react-router-dom";

export default function HeaderSession({ title }) {
    const navigate = useNavigate()

    return (
        <div>
            {/* color: '#85287e', */}
            <h1 style={{ margin: 20, fontSize: 23, display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => navigate(-1)} style={{ marginRight: 15, }} title='Voltar'>
                    <img width={20} src="/img/icones/setaback1.svg" alt="" />
                </IconButton>
                {/* <Link style={{ textDecoration: 'none' }} to={'/'}>
                    <img style={{ marginRight: 20 }} src="/img/icones/home.svg" alt="" title='Início' />
                </Link> */}
                <span translate="no">
                    {title}
                </span>
            </h1>
            <hr style={{ border: '1px solid #85287e' }} />
            <br />
        </div>
    )
}
