import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../utils/headerSession'
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
}))

export default function Leis() {
    const classes = useStyles()
    return (
        <Container style={{ maxWidth: 800 }}>
             <RegisterAccess page={'leis'} />
            <HeaderSession title='LEIS' />

            <div>
                <p className={classes.root} >
                    Lei nº 29/21 de 9 de Dezembro – Lei Sobre a Reprodução Humana Medicamentosa.
                </p>
                <p className={classes.root} >
                    Lei nº 9/17 de 13 de Março – Lei Geral da Publicidade.
                </p>
                <p className={classes.root} >
                    Lei nº 10/16 de 27 Junho – Lei das Acessibilidades.
                </p>
                <p className={classes.root} >
                    Lei nº 21-B/92 de 28 de Agosto- Lei de Bases do Sistema Nacional de Saúde Publica.
                </p>
                <p className={classes.root} >
                    Lei nº 4/99 de 6 de Agosto O presente diploma estabelece as regras relativas ao controlo do mercado lícito de estupefacientes, substâncias psicotrópicas, precursores e outros produtos químicos susceptíveis.
                </p>
                <p className={classes.root} >
                    Lei nº 3/99 de 6 de Agosto de 1999 O presente diploma tem por objecto a definição do regime jurídico aplicável ao tráfico e consumo de estupefacientes, substâncias psicotrópicas, precursores e outros produtos químicos susceptíveis de utilização no fabrico de droga.
                </p>
            </div>

            <br />
        </Container>
    )
}
