// import { IconButton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import api from '../../../services/api';
import { useSelector } from 'react-redux';
import LoadingShort from '../../../load/loadingShort';

const DeleteBanner = (props) => {
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.account.user);

    const HandleDeleteNoticia = () => {
        setOpen(true)
        props.setMessageSuccess('')
        props.setMessageError('')

        api.delete('/carousel/delete', { data: { 'id': props.bannerId, 'userId': user._id } })
            .then(response => {
                // console.log(response)
                setOpen(false)
                props.setMessageSuccess('Eliminado com sucesso.')
                props.setForceUpdate(response)

            }).catch(err => {
                console.log(err)
                setOpen(false)
                props.setMessageError('Erro ao eliminar')
            })
    }

    return (<IconButton title='Apagar' onClick={HandleDeleteNoticia} color="error">{open ? <LoadingShort /> : <DeleteIcon />}</IconButton>);
}

export default DeleteBanner;
