
import * as React from 'react';

export default function ShortNome({ nome }) {

    // {nome?.substring(0, 10).split(' ').reverse().join('/')}
    const shortNome = nome?.split(' ')

    // console.log(shortNome)

    return nome && shortNome[0] + ' ' + shortNome[shortNome.length - 1]
}