import { Container } from '@mui/material'
import RegisterAccess from '../../../../../utils/registerAccess'
import AutorizacoesExercicioEmitidas from '../../../../gestLicenciados/autorizacoesExercicioEmitidas'
import HeaderSession from '../../../../../utils/headerSession'

export default function TemplateRelatorioAutorizacoesExercicioEmitidas() {
  return (
    <Container style={{ maxWidth: 900, padding: 8 }}>
      <RegisterAccess page={'Relatório de Autorizações de Exercicio Emitidas'} />
      <br />
      <HeaderSession title={'AUTORIZAÇŌES DE EXERCICIOS EMITIDAS'} />
      <br />
      {/* {component} */}

      <AutorizacoesExercicioEmitidas geral={true} />
    </Container>
  )
}
