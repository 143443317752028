
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { Button, DialogActions, Grid, MenuItem, TextField } from "@mui/material";
import api from "../../services/api";
import LoadingBackdrop from "../../load/loadingBackdrop";
import MessageSuccess from "../../messages/messageSuccess";
import MessageError from "../../messages/messageError";
import { useSelector } from "react-redux";
import apiCNP from "../../services/apiCNP";
import RegisterAccess from "../../utils/registerAccess";
import AlertEdit from "../../utils/alertEdit";
// import { validacaoEditDadosPessoal } from "../../validacoes";


export default function DadosPessoalEdit({ dadosPessoais, cancelEditDados, setDadosPessoais, setRefresh }) {
    const [editDados, setEditDados] = useState(dadosPessoais)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [open, setOpen] = useState(false);
    const dadosPessoaisId = dadosPessoais?._id
    const user = useSelector(state => state.account.user);
    // console.log(editDados)

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);

            }).catch(err => '')
    }, [])

    function HandleChange(e) {

        setEditDados((prev) => ({ ...prev, [e.target.name]: e.target.value }));

        e.target.name === 'provincia' &&
            apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                    setEditDados((prev) => ({ ...prev, provincia: res.data.provincia.designacao }));
                }).catch(err => 'console.log(err)')
    }

    const submitEdit = async () => {

        setOpen(true)
        setMessageSuccess('')
        setMessageError('')
        // console.log(editDados)

        await api.put('/dados-pessoais/edit', { ...editDados, dadosPessoaisId, 'userId': user?._id ?? '' })
            .then(res => {
                setMessageSuccess(res.data.msg)
                setDadosPessoais(res.data)
                setRefresh && setRefresh(res.data)
                setOpen(false)

                setTimeout(() => {
                    cancelEditDados(false)
                }, 3000);

            }).catch(err => {
                setOpen(false)
                setMessageError(err.response.data.message)
            })
    }

    const handleSubmitEdit = async e => {
        setMessageError('');
        if (!(await validate())) return
    };
    async function validate() {
        try {
            // await validacaoEditDadosPessoal(editDados)
            submitEdit()
        }
        catch (error) {
            setMessageError(error);
        }
    }

    return (
        <>
            <RegisterAccess page={'editar dados pessoais'} />
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={open} />

            <Card style={{border: '1px solid orange' }}>

                <AlertEdit />

                <Grid container >
                    <Grid xs={12} md item m={1}>
                        <TextField
                            type="text"
                            label="Nome Completo"
                            fullWidth
                            size="small"
                            name="nome"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.nome}
                        />
                    </Grid>

                    <Grid xs={12} md item m={1}>
                        <TextField
                            type="text"
                            label="Nº do BI"
                            fullWidth
                            size="small"
                            name="numeroBI"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.numeroBI}
                        />
                    </Grid>

                    <Grid xs={12} md item m={1}>
                        <TextField
                            type="text"
                            label="Provincia"
                            select
                            fullWidth
                            size="small"
                            name="provincia"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.provincia || ''}
                        >
                            <MenuItem key={editDados?.provincia} value={editDados?.provincia}>
                                {editDados?.provincia}
                            </MenuItem>
                            {provincias.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia._id}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                </Grid>

                <Grid container >

                    <Grid xs={12} md item m={1}>
                        <TextField
                            style={{ marginTop: 10 }}
                            type="text"
                            label="Município"
                            select
                            fullWidth
                            size="small"
                            name="municipio"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.municipio || ''}
                        >
                            <MenuItem key={editDados?.municipio} value={editDados?.municipio}>
                                {editDados?.municipio}
                            </MenuItem>
                            {municipios?.map((municipio) => (
                                <MenuItem key={municipio._id} value={municipio.designacao}>
                                    {municipio.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item m={1}>
                        <TextField
                            style={{ marginTop: 10 }}
                            type="text"
                            label="Morada (Bairro)"
                            fullWidth
                            size="small"
                            name="bairro"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.bairro}
                        />
                    </Grid>

                    <Grid xs={12} md item m={1}>
                        <TextField
                            style={{ marginTop: 10 }}
                            type="text"
                            label="Morada (Rua)"
                            fullWidth
                            size="small"
                            name="rua"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.rua}
                        />
                    </Grid>
                </Grid>

                {/* <Grid container >
                  
                </Grid> */}

                <Grid container >
                    <Grid xs={12} md item m={1}>
                        <TextField
                            style={{ marginTop: 10 }}
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            label="Telefone 1"
                            fullWidth
                            size="small"
                            name="tel1"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.tel1}
                        />
                    </Grid>
                    <Grid xs={12} md item m={1}>
                        <TextField
                            style={{ marginTop: 10 }}
                            type="number"
                            label="Telefone 2"
                            fullWidth
                            size="small"
                            name="tel2"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.tel2}
                        />
                    </Grid>

                    <Grid xs={12} md item m={1}>
                        <TextField
                            style={{ marginTop: 10 }}
                            type="email"
                            label="Email"
                            fullWidth
                            size="small"
                            name="email"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.email}
                        />
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid xs={12} md item m={1}>
                        <TextField
                            style={{ marginTop: 10 }}
                            type="text"
                            label="Nacionalidade"
                            fullWidth
                            size="small"
                            name="nacionalidade"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.nacionalidade}
                        />
                    </Grid>
                    <Grid xs={12} md item m={1}>
                    </Grid>

                    <Grid xs={12} md item m={1}>

                    </Grid>
                </Grid>

                <DialogActions >
                    <Button onClick={() => cancelEditDados(false)} color="warning" size="small" style={{ marginTop: 20, marginRight: 10 }}>Cancelar</Button>
                    <Button variant="contained" onClick={() => { handleSubmitEdit() }} size="small" style={{ marginTop: 20, background: '#85287e' }}>Salvar</Button>
                </DialogActions>
            </Card>
        </>
    )
}