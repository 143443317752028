
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import api from "../../../../services/api";
import HeaderSession from "../../../../utils/headerSession";
import Loading from "../../../../load/loading";
// import DateHora from "../../../utils/dateHora";
import { useSelector } from 'react-redux';
import SearshEstabelecimento from "../../../../utils/searchEstabelecimento";
import FerramentaNavegacaoExportacao from "../../../../utils/ferramentaNavegacaoExportacao";
import ListaSolicitacao from "./listaSolicitacao";
import RegisterAccess from "../../../../utils/registerAccess";


export default function SolicitacoesDeLicenciamento() {
  const user = useSelector(state => state.account.user);
  const navigate = useNavigate()
  const [tipo, setTipo] = useState('')
  const [estabelecimentos, setEstabelecimentos] = useState('')
  const [estabelecimentosInspeccionados, setEstabelecimentosInspeccionados] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [, setOrder] = useState('')
  const [configLista, setConfigLista] = useState('')
  
  useEffect(() => {
    setEstabelecimentos('')

    if (user?.seccao === 'Licenciamento' && user?.funcao === 'Técnico') {
      navigate('?admin/recepcao/solicitacoes-de-licenciamento')
    }

    if (user?.seccao === 'Licenciamento' && user?.funcao === 'Chefe de Secção') {
      api.get(`/estabelecimentos?page=${page}&status=Analisado&perPage=${perPage}&tipoId=${tipo}`)
        .then(response => {

          // console.log(response)
          setEstabelecimentos(response.data.estabelecimentos);
          setConfigLista(response.data);
        }).catch(err => '')
    }

    if (user?.seccao === 'Licenciamento' && user?.funcao === 'Chefe de Departamento') {
      api.get(`/estabelecimentos?page=${page}&status=Analisado Chefe de Secção&perPage=${perPage}&tipoId=${tipo}`)
        .then(response => {
          setEstabelecimentos(response.data.estabelecimentos);
          setConfigLista(response.data);
        }).catch(err => '')

      api.get(`/estabelecimentos?page=${page}&status=Inspecção Realizada&perPage=${perPage}&tipoId=${tipo}`)
        .then(response => {
          setEstabelecimentosInspeccionados(response.data.estabelecimentos);
          // setConfigLista(response.data);
        }).catch(err => '')
    }

    if (user?.seccao === 'Inspecção' && user?.funcao === 'Inspector') {
      api.get(`/estabelecimentos?page=${page}&status=Inspecção Agendada&perPage=${perPage}&tipoId=${tipo}`)
        .then(response => {

          // console.log(response)
          setEstabelecimentos(response.data.estabelecimentos);
          setConfigLista(response.data);
        }).catch(err => '')
    }

    if (user?.seccao === 'Licenciamento' && user?.funcao === 'Director Geral') {
      api.get(`/estabelecimentos?page=${page}&status=Deferimento&perPage=${perPage}&tipoId=${tipo}`)
        .then(response => {

          // console.log(response)
          setEstabelecimentos(response.data.estabelecimentos);
          setConfigLista(response.data);
        }).catch(err => '')
    }

  }, [page, tipo, perPage, user?.funcao, user?.seccao, navigate])


  return (
    <>
      <RegisterAccess page={'solicitações de licenciamento'} />
      <HeaderSession title='LISTA DE SOLICITAÇÕES DE LICENCIAMENTO' />
      <Card style={{ padding: 20 }}>

        <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={setTipo} placeholder={'Filtra por nome, número de entrada, data'} />

        {estabelecimentos ?
          <Card variant="outlined">
            <ListaSolicitacao estabelecimentos={estabelecimentos} />

            <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={estabelecimentos}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel} 
            />
          </Card>
          :
          <Loading />
        }
      </Card>


      {/* VER ESTABELECIMENTOS QUE JA FORAM INSPECIONADOS VISAO APENAS PARA CHEFE DE DEPARTAMENTO */}
      {estabelecimentosInspeccionados &&
        <Card style={{ padding: 20 }}>
          <h1 style={{ margin: 20, fontSize: 23 }}>
            ESTABELECIMENTO JÁ INSPECIONADOS
          </h1>
          <Card variant="outlined">
            <br />
            <ListaSolicitacao estabelecimentos={estabelecimentosInspeccionados} />
          </Card>
        </Card>
      }
    </>
  )
}
