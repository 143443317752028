// export default function AutoVistoriaParaFarmaciaDeposito({ disabled, HandleChange, setInspeccionar, inspeccao }) {
import { useState, useEffect } from 'react';
import { IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import api from '../../../../../services/api';
import HeaderGestorConfigs from '../../../../../utils/headerGestorConfigs';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import compressImage from '../../../../../services/compressImage';
import { useDispatch, useSelector } from 'react-redux';
import LoadingShort from '../../../../../load/loadingShort';

const useStyles = makeStyles((theme) => ({
    list: {
        display: 'flex', alignItems: 'center', gap: '1rem',
        '& strong': { width: 17 },
        '& p': { width: 17 }
    }
}))

const AutoVistoriaParaFarmaciaDeposito = ({ inspeccao, setInspeccionar }) => {
    const classes = useStyles();
    const [tipoEstabelecimentos, setTipoEstabelecimentos] = useState([])
    const [load, setLoad] = useState(false)
    const user = useSelector(state => state.account.user);
    const dispatch = useDispatch()

    const [location, setLocation] = useState({ latitude: null, longitude: null });

    useEffect(() => {
        // setLoading(true);
        // if (navigator.geolocation & !isDevelopment) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
                },
                (error) => {
                    // setError(error.message);
                    // setLoading(false);
                    // console.log(error)
                }
            );
        } else {

            // setError('Geolocalização não é suportada pelo seu navegador.');
            // setLoading(false);
        }
    }, [])


    const [formData, setFormData] = useState({
        coordenadas: '',
        infraestrutura: '',
        agua: '',
        esgoto: '',
        condicoesHS: '',
        medidasCP: '',

        // FACHADA PRINCIPAL
        fachaPrincipalLetreiroAPPEAvaliacao: '',
        fachaPrincipalLetreiroAPPEObservacoes: '',

        fachaPrincipalPinturaESIGPAvaliacao: '',
        fachaPrincipalPinturaESIGPObservacoes: '',

        fachaPrincipalPlacaCNDPPAvaliacao: '',
        fachaPrincipalPlacaCNDPPObservacoes: '',

        fachaPrincipalCondicoesHSAvaliacao: '',
        fachaPrincipalCondicoesHSObservacoes: '',

        fachaPrincipalRampaACM10Avaliacao: '',
        fachaPrincipalRampaACM10Observacoes: '',

        // ATENDIMENTO
        atendimentoDimensoesM35MAvaliacao: '',
        atendimentoDimensoesM35MObservacoes: '',

        atendimentoPinturaCQAvaliacao: '',
        atendimentoPinturaCQObservacoes: '',

        atendimentoInexistenciaIGMPPPBVAvaliacao: '',
        atendimentoInexistenciaIGMPPPBVObservacoes: '',

        atendimentoChaoAvaliacao: '',
        atendimentoChaoObservacoes: '',

        atendimentoTectoAvaliacao: '',
        atendimentoTectoObservacoes: '',

        atendimentoBalcoesAtendimentoAvaliacao: '',
        atendimentoBalcoesAtendimentoObservacoes: '',

        atendimentoClimatizacaoACAvaliacao: '',
        atendimentoClimatizacaoACObservacoes: '',

        atendimentoIluminacaoAdequadaAvaliacao: '',
        atendimentoIluminacaoAdequadaObservacoes: '',

        atendimentoMobiliarioAdequadoAvaliacao: '',
        atendimentoMobiliarioAdequadoObservacoes: '',

        atendimentoExtintoresAvaliacao: '',
        atendimentoExtintoresObservacoes: '',

        // ARMAZEM
        armazemDimensoesM300M20MAvaliacao: '',
        armazemDimensoesM300M20MObservacoes: '',

        armazemPavimentoLEAvaliacao: '',
        armazemPavimentoLEObservacoes: '',

        armazemPinturaCQAvaliacao: '',
        armazemPinturaCQObservacoes: '',

        armazemPinturaCQAvaliacao: '',
        armazemPinturaCQbservacoes: '',

        armazemInexistenciaIGMPPPBVAvaliacao: '',
        armazemInexistenciaIGMPPPBVObservacoes: '',

        armazemChaoAvaliacao: '',
        armazemChaoObservacoes: '',

        armazemTectoAvaliacao: '',
        armazemTectoObservacoes: '',

        armazemClimatizacaoACAvaliacao: '',
        armazemClimatizacaoACObservacoes: '',

        armazemIluminacaoAdequadaAvaliacao: '',
        armazemIluminacaoAdequadaObservacoes: '',

        armazemMobiliarioAvaliacao: '',
        armazemMobiliarioObservacoes: '',

        armazemExtintoresAvaliacao: '',
        armazemExtintoresObservacoes: '',

        armazemTermohigrometrosACAvaliacao: '',
        armazemTermohigrometrosACObservacoes: '',

        armazemCortinasPZCDAvaliacao: '',
        armazemCortinasPZCDObservacoes: '',

        armazemEstanteriaAvaliacao: '',
        armazemEstanteriaObservacoes: '',

        armazemCamaraFrigorificaAvaliacao: '',
        armazemCamaraFrigorificaObservacoes: '',

        armazemAreaControladosAvaliacao: '',
        armazemAreaControladosObservacoes: '',

        // SALA DO PESSOAL
        salaDoPessoalCacifoAvaliacao: '',
        salaDoPessoalCacifoObservacoes: '',

        salaDoPessoalCopaAvaliacao: '',
        salaDoPessoalCopaObservacoes: '',

        // INSTALACOES SANITARIAS
        instalacoesSanitariasCondicoesHigieneAvaliacao: '',
        instalacoesSanitariasCondicoesHigieneObservacoes: '',

        instalacoesSanitariasSanitaLavatorioAvaliacao: '',
        instalacoesSanitariasSanitaLavatorioObservacoes: '',

        instalacoesSanitariasPavimentoParedeAvaliacao: '',
        instalacoesSanitariasPavimentoParedeObservacoes: '',

        // ####################################
        totalGeralAvaliacaoAvaliacao: '',
        totalGeralClassificacaoFinal: '',
        recomendacoes: '',
    });

    // console.log(inspeccao)

    useEffect(() => {
        const storedData = localStorage.getItem('formData');
        if (storedData) {
            setFormData(JSON.parse(storedData));
        }

        api.get('/tipo-estabelecimentos')
            .then(res => {
                // console.log(res)
                setTipoEstabelecimentos(res.data.tipoEstabelecimentos);
            }).catch(err => '')
    }, []);

    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));

        // passar as infprmacoes para o componente pai
        setInspeccionar && setInspeccionar((prev) => ({ ...prev, ...formData }))
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');

        if (field) {
            setFormData((prevData) => ({
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [field]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    async function getImage(e) {
        setLoad(true)
        let imageComprimida = await compressImage(e.target.files[0])

        const formData = new FormData();
        formData.append('fotos', imageComprimida);
        formData.append('inspeccaoId', inspeccao?._id);
        formData.append('userId', user?._id);

        await api.patch(`/inspeccao/add-fotos`, formData)
            .then(response => {
                setLoad(false)
                // console.log(response)

                dispatch({
                    type: 'alert',
                    payload: { alert: 'Imagem guardada.!' },
                });

            }).catch(err => {
                setLoad(false)
                // console.log(err)
            })
    }

    const CaptureImage = () => {
        return (
            <>
                <IconButton title='Adicionar imagens' size='large' style={{ marginLeft: '95%' }} >
                    {load ? <LoadingShort />
                        :
                        <label htmlFor="capitureImage" >
                            <CameraAltIcon />
                        </label>
                    }
                </IconButton>
                <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="capitureImage" style={{ display: 'none' }} onChange={getImage} />
            </>
        )
    }

    return (
        <div>
            <HeaderGestorConfigs
                menu={true}
                configArea={'AutoVistoria'}
                title={'AUTO DE VISTORIA PARA FARMÁCIAS /DEPÓSITOS DE MEDICAMENTOS E DE EQUIPAMENTOS'}
                inspeccao={inspeccao}
            />

            <br />
            <h2>1. Instruções de preenchimento:</h2>
            <Typography>1.1. Selecionar a cada item, consoante o cumprimento dos requisitos: Mau, suficiente e bom;</Typography>
            <Typography>1.2. Preencher na coluna das observações eventuais inconformidades observadas no terreno;</Typography>
            <Typography>1.3. Somar o total dos pontos;</Typography>
            <Typography>1.4. Avaliação final da entidade vistoriada:</Typography>
            <Typography> 1.4.1. Mau - indeferido</Typography>
            <Typography>1.4.2. Suficiente - recomendações a cumprir (prazo de 6 meses)</Typography>
            <Typography>1.4.3. Bom - deferido para licenciamento</Typography>

            <br />
            <br />
            <h2>2. Dados do Estabelecimento:</h2>
            <div className={classes.list} >
                <strong>2.1</strong>
                <TextField disabled size='small' fullWidth margin='dense' type="text" name="nomeFarmacia" defaultValue={inspeccao?.estabelecimento.nome} onChange={handleChange} label="Nome do Depósito / Farmácia" />
            </div>
            <div className={classes.list} >
                <strong>2.2</strong>
                <TextField disabled size='small' fullWidth margin='dense' type="text" name="proprietario" defaultValue={inspeccao?.estabelecimento?.empresa.nome} onChange={handleChange} label="Proprietário" />
            </div>
            <div className={classes.list} >
                <strong>2.3</strong>
                <TextField disabled size='small' fullWidth margin='dense' type="text" name="diretorTecnico" defaultValue={inspeccao?.estabelecimento?.directorTecnico?.dadosPessoais.nome} onChange={handleChange} label="Diretor Técnico" />
            </div>
            <div className={classes.list} >
                <strong>2.4</strong>
                <TextField disabled size='small' fullWidth margin='dense' type="text" name="contato" defaultValue={inspeccao?.estabelecimento?.empresa.tel1} onChange={handleChange} label="Contato" />
            </div>
            <div className={classes.list} >
                <strong>2.5</strong>
                <TextField disabled size='small' fullWidth margin='dense' type="email" name="contato" defaultValue={inspeccao?.estabelecimento.email} onChange={handleChange} label="E-mail" />
            </div>
            <div className={classes.list} >
                <strong>2.6</strong> <h3>Localização:</h3>
            </div>

            <TextField disabled size='small' fullWidth margin='dense' type="text" name="rua" defaultValue={inspeccao?.estabelecimento.rua} onChange={handleChange} label="Rua" />
            <TextField disabled size='small' fullWidth margin='dense' type="text" name="bairro" defaultValue={inspeccao?.estabelecimento.bairro} onChange={handleChange} label="Bairro" />
            <TextField disabled size='small' fullWidth margin='dense' type="text" name="municipio" defaultValue={inspeccao?.estabelecimento.municipio} onChange={handleChange} label="Município" />
            <TextField disabled size='small' fullWidth margin='dense' type="text" name="provincia" defaultValue={inspeccao?.estabelecimento.provincia} onChange={handleChange} label="Província" />
            <TextField disabled size='small' fullWidth margin='dense' type="text" name="coordenadas" defaultValue={location.latitude + '_' + location.longitude} onChange={handleChange} label="Coordenadas GPS" />

            <br />
            <br />
            <h2>3. Finalidade da Vistoria</h2>
            <div className={classes.list} >
                <strong>3.1</strong>
                {/* label='Selecionar Finalidade' */}
                <TextField disabled select size='small' fullWidth margin='dense' name="finalidade" defaultValue={inspeccao?.estabelecimento.tipoEntrada} onChange={handleChange}>
                    <MenuItem value="Licenciamento">Licenciamento</MenuItem>
                    <MenuItem value="Renovacao">Renovação</MenuItem>
                    <MenuItem value="Monitorizacao">Monitorização</MenuItem>
                    <MenuItem value="Outros">Outros</MenuItem>
                </TextField>
            </div>


            <br />
            <h2>4. Tipo de Estabelecimento</h2>
            <div className={classes.list} >
                <strong>4.1</strong>
                {/* label='Selecionar Tipo de Estabelecimento' */}
                <TextField disabled select size='small' fullWidth margin='dense' name="tipoEstabelecimento" defaultValue={inspeccao?.estabelecimento.tipo} onChange={handleChange}>
                    {tipoEstabelecimentos?.map((estabelecimento) => (
                        <MenuItem key={estabelecimento._id} value={estabelecimento._id}>
                            {estabelecimento.designacao}
                        </MenuItem>
                    ))}
                    {/* <MenuItem value="DepositoMedicamentos">Depósito de Medicamentos</MenuItem>
                            <MenuItem value="DepositoEquipamentos">Depósito de Equipamentos</MenuItem>
                            <MenuItem value="Farmacia">Farmácia</MenuItem> */}
                </TextField>
            </div>

            <br />
            <br />
            <h2>5. Infraestrutura</h2>
            <div className={classes.list} >
                <strong>5.1</strong>
                <TextField error={!formData.infraestrutura} select size='small' fullWidth label='Tipo de Infraestrutura' margin='dense' name="infraestrutura" value={formData.infraestrutura} onChange={handleChange}>
                    <MenuItem value="Alvenaria">Alvenaria</MenuItem>
                    <MenuItem value="Cachilharia">Cachilharia</MenuItem>
                    <MenuItem value="Construção em madeira">Construção em madeira</MenuItem>
                    <MenuItem value="Vidrado">Vidrado</MenuItem>
                </TextField>
            </div>

            {/* <h2>Água</h2> */}
            <div className={classes.list} >
                <strong>5.2</strong>
                <TextField error={!formData.agua} select size='small' fullWidth label='Água' margin='dense' name="agua" value={formData.agua} onChange={handleChange}>
                    <MenuItem value="Corrente">Corrente</MenuItem>
                    <MenuItem value="Reservatorio">Reservatório</MenuItem>
                    <MenuItem value="Corrente e Reservatório<">Corrente e Reservatório</MenuItem>
                </TextField>

            </div>

            {/* <h2>Esgoto</h2> */}
            <div className={classes.list} >
                <strong>5.3</strong>
                <TextField error={!formData.esgoto} select size='small' fullWidth margin='dense' name="esgoto" label='Esgoto' value={formData.esgoto} onChange={handleChange}>
                    <MenuItem value="Rede Geral">Rede Geral</MenuItem>
                    <MenuItem value="Fossa Septica">Fossa Séptica</MenuItem>
                    <MenuItem value="Rede Geral e Fossa Séptica<"> Rede Geral e Fossa Séptica</MenuItem>
                </TextField>
            </div>

            {/* <h2>Condições de Higiene e Segurança</h2> */}
            <div className={classes.list} >
                <strong>5.4</strong>
                <TextField error={!formData.condicoesHS} select size='small' fullWidth margin='dense' name="condicoesHS" label='Condições de Higiene e Segurança' value={formData.condicoesHS} onChange={handleChange}>
                    <MenuItem value="Sim">Sim</MenuItem>
                    <MenuItem value="Não">Não</MenuItem>
                </TextField>
            </div>


            {/* <h2>Medidas de Combate a Pragas</h2> */}
            <div className={classes.list} >
                <strong>5.5</strong>
                <TextField error={!formData.medidasCP} select size='small' fullWidth margin='dense' name="medidasCP" label='Medidas de Combate a Pragas' value={formData.medidasCP} onChange={handleChange}>
                    <MenuItem value="Sim">Sim</MenuItem>
                    <MenuItem value="Não">Não</MenuItem>
                </TextField>
            </div>
            {/* Adicionar imagens */}
            <CaptureImage />

            {/* <br /> */}
            <h2>6. Fachada Principal</h2>
            <div className={classes.list} >
                <strong>6.1</strong>
                <TextField error={!formData.fachaPrincipalLetreiroAPPEAvaliacao} select size='small' fullWidth margin='dense' name="fachaPrincipalLetreiroAPPEAvaliacao" label='Letreiro afixado perpendicular à parede do edifício' title='Letreiro afixado perpendicular à parede do edifício' value={formData.fachaPrincipalLetreiroAPPEAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Letreiro afixado</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="fachaPrincipalLetreiroAPPEObservacoes" defaultValue={inspeccao?.fachaPrincipalLetreiroAPPEObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>6.2</strong>
                <TextField error={!formData.fachaPrincipalPinturaESIGPAvaliacao} select size='small' fullWidth margin='dense' name="fachaPrincipalPinturaESIGPAvaliacao" label='Pintura exterior sem imagens/gravuras nas paredes' title='Pintura exterior sem imagens/gravuras nas paredes' value={formData.fachaPrincipalPinturaESIGPAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Pintura exterior</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="fachaPrincipalPinturaESIGPObservacoes" defaultValue={inspeccao?.fachaPrincipalPinturaESIGPObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>6.3</strong>
                <TextField error={!formData.fachaPrincipalPlacaCNDPPAvaliacao} select size='small' fullWidth margin='dense' name="fachaPrincipalPlacaCNDPPAvaliacao" label='Placa com nome do Director Técnico na porta principal (« facultativo ) ou interior' title='Placa com nome do Director Técnico na porta principal (« facultativo ) ou interior' value={formData.fachaPrincipalPlacaCNDPPAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Placa do Diretor Técnico</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="fachaPrincipalPlacaCNDPPObservacoes" defaultValue={inspeccao?.fachaPrincipalPlacaCNDPPObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>6.4</strong>
                <TextField error={!formData.fachaPrincipalCondicoesHSAvaliacao} select size='small' fullWidth margin='dense' name="fachaPrincipalCondicoesHSAvaliacao" label='Condições de higiene e de segurança' value={formData.fachaPrincipalCondicoesHSAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Condições de higiene e segurança</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="fachaPrincipalCondicoesHSObservacoes" defaultValue={inspeccao?.fachaPrincipalCondicoesHSObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>6.5</strong>
                <TextField error={!formData.fachaPrincipalRampaACM10Avaliacao} select size='small' fullWidth margin='dense' name="fachaPrincipalRampaACM10Avaliacao" label='Rampa de acesso com máximo de 10º (se aplicável)' value={formData.fachaPrincipalRampaACM10Avaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Rampa de acesso</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="fachaPrincipalRampaACM10Observacoes" defaultValue={inspeccao?.fachaPrincipalRampaACM10Observacoes} onChange={handleChange} label="Observações" />
            </div>
            {/* Adicionar imagens */}
            <CaptureImage />


            <h2>7. Sala de Atendimento</h2>
            <div className={classes.list} >
                <strong>7.1</strong>
                <TextField error={!formData.atendimentoDimensoesM35MAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoDimensoesM35MAvaliacao" label='Dimensões mínimas de 35m²' value={formData.atendimentoDimensoesM35MAvaliacao} onChange={handleChange} placeholder="Dimensões" >
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoDimensoesM35MObservacoes" defaultValue={inspeccao?.atendimentoDimensoesM35MObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.2</strong>
                <TextField error={!formData.atendimentoPinturaCQAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoPinturaCQAvaliacao" label='Pintura clara de qualidade' value={formData.atendimentoPinturaCQAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Pintura</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoPinturaCQObservacoes" defaultValue={inspeccao?.atendimentoPinturaCQObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.3</strong>
                <TextField error={!formData.atendimentoInexistenciaIGMPPPBVAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoInexistenciaIGMPPPBVAvaliacao" label='Inexistência de imagens/gravuras ou materiais publicitários pintados nas paredes, balcões e/ou vitrinas' title='Inexistência de imagens/gravuras ou materiais publicitários pintados nas paredes, balcões e/ou vitrinas' value={formData.atendimentoInexistenciaIGMPPPBVAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Publicidade</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoInexistenciaIGMPPPBVObservacoes" defaultValue={inspeccao?.atendimentoInexistenciaIGMPPPBVObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.4</strong>
                <TextField error={!formData.atendimentoChaoAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoChaoAvaliacao" label='Chão' value={formData.atendimentoChaoAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Chão</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoChaoObservacoes" defaultValue={inspeccao?.atendimentoChaoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.5</strong>
                <TextField error={!formData.atendimentoTectoAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoTectoAvaliacao" label='Tecto' value={formData.atendimentoTectoAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Teto</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoTectoObservacoes" defaultValue={inspeccao?.atendimentoTectoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.6</strong>
                <TextField error={!formData.atendimentoBalcoesAtendimentoAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoBalcoesAtendimentoAvaliacao" label='Balcões de atendimento ' value={formData.atendimentoBalcoesAtendimentoAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Balcões de atendimento</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoBalcoesAtendimentoObservacoes" defaultValue={inspeccao?.atendimentoBalcoesAtendimentoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.7</strong>
                <TextField error={!formData.atendimentoClimatizacaoACAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoClimatizacaoACAvaliacao" label='Climatização com A/C ' value={formData.atendimentoClimatizacaoACAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Climatização</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoClimatizacaoACObservacoes" defaultValue={inspeccao?.atendimentoClimatizacaoACObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.8</strong>
                <TextField error={!formData.atendimentoIluminacaoAdequadaAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoIluminacaoAdequadaAvaliacao" label='Iluminação adequada' value={formData.atendimentoIluminacaoAdequadaAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Iluminação</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoIluminacaoAdequadaObservacoes" defaultValue={inspeccao?.atendimentoIluminacaoAdequadaObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.9</strong>
                <TextField error={!formData.atendimentoMobiliarioAdequadoAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoMobiliarioAdequadoAvaliacao" label='Mobiliário/cadeiras adequado' value={formData.atendimentoMobiliarioAdequadoAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Mobiliário</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoMobiliarioAdequadoObservacoes" defaultValue={inspeccao?.atendimentoMobiliarioAdequadoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>7.9</strong>
                <TextField error={!formData.atendimentoExtintoresAvaliacao} select size='small' fullWidth margin='dense' name="atendimentoExtintoresAvaliacao" label='Extintores (quantificar)' value={formData.atendimentoExtintoresAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Extintores</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="atendimentoExtintoresObservacoes" defaultValue={inspeccao?.atendimentoExtintoresObservacoes} onChange={handleChange} label="Observações" />
            </div>
            {/* Adicionar imagens */}
            <CaptureImage />


            <h2>8. Armazém</h2>
            <div className={classes.list} >
                <strong>8.1</strong>
                <TextField error={!formData.armazemDimensoesM300M20MAvaliacao} select size='small' fullWidth margin='dense' name="armazemDimensoesM300M20MAvaliacao" label='Dimensões mínimas de 300m²/ 20m²' value={formData.armazemDimensoesM300M20MAvaliacao} onChange={handleChange} placeholder="Dimensões" >
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemDimensoesM300M20MObservacoes" defaultValue={inspeccao?.armazemDimensoesM300M20MObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.2</strong>
                <TextField error={!formData.armazemPavimentoLEAvaliacao} select size='small' fullWidth margin='dense' name="armazemPavimentoLEAvaliacao" label='Pavimento ladrilhado/ epoxy (especificar)' value={formData.armazemPavimentoLEAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Pavimento</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemPavimentoLEAObservacoes" defaultValue={inspeccao?.armazemPavimentoLEAObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.3</strong>
                <TextField error={!formData.armazemPinturaCQAvaliacao} select size='small' fullWidth margin='dense' name="armazemPinturaCQAvaliacao" label='Pintura clara de qualidade' value={formData.armazemPinturaCQAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Pintura</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemPinturaCQObservacoes" defaultValue={inspeccao?.armazemPinturaCQObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.4</strong>
                <TextField error={!formData.armazemInexistenciaIGMPPPBVAvaliacao} select size='small' fullWidth margin='dense' name="armazemInexistenciaIGMPPPBVAvaliacao" label='Inexistência de imagens/gravuras ou material publicitário nas paredes e/ou vitrinas' title='Inexistência de imagens/gravuras ou material publicitário nas paredes e/ou vitrinas' value={formData.armazemInexistenciaIGMPPPBVAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Publicidade</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemInexistenciaIGMPPPBVObservacoes" defaultValue={inspeccao?.armazemInexistenciaIGMPPPBVObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.5</strong>
                <TextField error={!formData.armazemChaoAvaliacao} select size='small' fullWidth margin='dense' name="armazemChaoAvaliacao" label='Chão' value={formData.armazemChaoAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Teto</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemChaoObservacoes" defaultValue={inspeccao?.armazemChaoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.5</strong>
                <TextField error={!formData.armazemTectoAvaliacao} select size='small' fullWidth margin='dense' name="armazemTectoAvaliacao" label='Tecto' value={formData.armazemTectoAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Teto</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemTectoObservacoes" defaultValue={inspeccao?.armazemTectoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.6</strong>
                <TextField error={!formData.armazemClimatizacaoACAvaliacao} select size='small' fullWidth margin='dense' name="armazemClimatizacaoACAvaliacao" label='Climatização com A/C' value={formData.armazemClimatizacaoACAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Climatização</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemClimatizacaoACObservacoes" defaultValue={inspeccao?.armazemClimatizacaoACObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.7</strong>
                <TextField error={!formData.armazemIluminacaoAdequadaAvaliacao} select size='small' fullWidth margin='dense' name="armazemIluminacaoAdequadaAvaliacao" label='Iluminação adequada' value={formData.armazemIluminacaoAdequadaAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Iluminação</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemIluminacaoAdequadaObservacoes" defaultValue={inspeccao?.armazemIluminacaoAdequadaObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.8</strong>
                <TextField error={!formData.armazemTermohigrometrosACAvaliacao} select size='small' fullWidth margin='dense' name="armazemTermohigrometrosACAvaliacao" label='Termohigrómetros' value={formData.armazemTermohigrometrosACAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Termohigrômetros</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemTermohigrometrosACObservacoes" defaultValue={inspeccao?.armazemTermohigrometrosACObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.9</strong>
                <TextField error={!formData.armazemEstanteriaAvaliacao} select size='small' fullWidth margin='dense' name="armazemEstanteriaAvaliacao" label='Estanteria/ prateleiras adequadas' value={formData.armazemEstanteriaAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Estante</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemEstanteriaObservacoes" defaultValue={inspeccao?.armazemEstanteriaObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.10</strong>
                <TextField error={!formData.armazemCamaraFrigorificaAvaliacao} select size='small' fullWidth margin='dense' name="armazemCamaraFrigorificaAvaliacao" label='Câmara frigorífica com termóstato (para vacinas)' title='Câmara frigorífica com termóstato (para vacinas)' value={formData.armazemCamaraFrigorificaAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Câmara Frigorífica</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemCamaraFrigorificaObservacoes" defaultValue={inspeccao?.armazemCamaraFrigorificaObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.11</strong>
                <TextField error={!formData.armazemMobiliarioAvaliacao} select size='small' fullWidth margin='dense' name="armazemMobiliarioAvaliacao" label='Mobiliário/cadeiras de escritório ' value={formData.armazemMobiliarioAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Mobiliário</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemMobiliarioAvaliacaoObservacoes" defaultValue={inspeccao?.armazemMobiliarioAvaliacaoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.12</strong>
                <TextField error={!formData.armazemAreaControladosAvaliacao} select size='small' fullWidth margin='dense' name="armazemAreaControladosAvaliacao" label='Área para materiais controlados' value={formData.armazemAreaControladosAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Área para materiais controlados</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemAreaControladosObservacoes" defaultValue={inspeccao?.armazemAreaControladosObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.13</strong>
                <TextField error={!formData.armazemExtintoresAvaliacao} select size='small' fullWidth margin='dense' name="armazemExtintoresAvaliacao" label='Extintores (quantificar)' value={formData.armazemExtintoresAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Extintores</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemExtintoresObservacoes" defaultValue={inspeccao?.armazemExtintoresObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>8.14</strong>
                <TextField error={!formData.armazemCortinasPZCDAvaliacao} select size='small' fullWidth margin='dense' name="armazemCortinasPZCDAvaliacao" label='Cortinas plásticas na zona de carga e descarga' title='Cortinas plásticas na zona de carga e descarga' value={formData.armazemCortinasPZCDAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Cortinas plásticas</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="armazemCortinasPZCDObservacoes" defaultValue={inspeccao?.armazemCortinasPZCDObservacoes} onChange={handleChange} label="Observações" />
            </div>
            {/* Adicionar imagens */}
            <CaptureImage />

            <h2>9. Sala do Pessoal</h2>
            <div className={classes.list} >
                <strong>9.1</strong>
                <TextField error={!formData.salaDoPessoalCacifoAvaliacao} required select size='small' fullWidth margin='dense' name="salaDoPessoalCacifoAvaliacao" label='Cacifo (obrigatório)' value={formData.salaDoPessoalCacifoAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Cacifo</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="salaDoPessoalCacifoObservacoes" defaultValue={inspeccao?.salaDoPessoalCacifoObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>9.2</strong>
                <TextField error={!formData.salaDoPessoalCopaAvaliacao} select size='small' fullWidth margin='dense' name="salaDoPessoalCopaAvaliacao" label='Copa (se aplicável)' value={formData.salaDoPessoalCopaAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Copa</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                    {/* <MenuItem value="Inexistente">Inexistente</MenuItem> */}
                </TextField>

                <TextField size='small' margin='dense' type="text" name="salaDoPessoalCopaObservacoes" defaultValue={inspeccao?.salaDoPessoalCopaObservacoes} onChange={handleChange} label="Observações" />
            </div>
            {/* Adicionar imagens */}
            <CaptureImage />


            <h2>10. Instalações Sanitárias</h2>
            <div className={classes.list} >
                <strong>10.1</strong>
                <TextField error={!formData.instalacoesSanitariasCondicoesHigieneAvaliacao} select size='small' fullWidth margin='dense' name="instalacoesSanitariasCondicoesHigieneAvaliacao" label='Condições de higiene' value={formData.instalacoesSanitariasCondicoesHigieneAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Higiene</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="instalacoesSanitariasCondicoesHigieneObservacoes" defaultValue={inspeccao?.instalacoesSanitariasCondicoesHigieneObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>10.2</strong>
                <TextField error={!formData.instalacoesSanitariasSanitaLavatorioAvaliacao} select size='small' fullWidth margin='dense' name="instalacoesSanitariasSanitaLavatorioAvaliacao" label='Sanita e lavatório' value={formData.instalacoesSanitariasSanitaLavatorioAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Sanita</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="instalacoesSanitariasSanitaLavatorioObservacoes" defaultValue={inspeccao?.instalacoesSanitariasSanitaLavatorioObservacoes} onChange={handleChange} label="Observações" />
            </div>

            <div className={classes.list} >
                <strong>10.3</strong>
                <TextField error={!formData.instalacoesSanitariasPavimentoParedeAvaliacao} select size='small' fullWidth margin='dense' name="instalacoesSanitariasPavimentoParedeAvaliacao" label='Pavimento e paredes' value={formData.instalacoesSanitariasPavimentoParedeAvaliacao} onChange={handleChange}>
                    {/* <MenuItem value="">Pavimento</MenuItem> */}
                    <MenuItem value="Mau">Mau</MenuItem>
                    <MenuItem value="Suficiente">Suficiente</MenuItem>
                    <MenuItem value="Bom">Bom</MenuItem>
                </TextField>

                <TextField size='small' margin='dense' type="text" name="instalacoesSanitariasSanitaLavatorioObservacoes" defaultValue={inspeccao?.instalacoesSanitariasSanitaLavatorioObservacoes} onChange={handleChange} label="Observações" />
            </div>
            {/* Adicionar imagens */}
            <CaptureImage />


            <h2>TOTAL GERAL</h2>
            <TextField error={!formData?.totalGeralAvaliacao} select size='small' fullWidth margin='dense' name="totalGeralAvaliacao" label='Total Geral' value={formData?.totalGeralAvaliacao} onChange={handleChange}>
                <MenuItem value="Mau">Mau</MenuItem>
                <MenuItem value="Suficiente">Suficiente</MenuItem>
                <MenuItem value="Bom">Bom</MenuItem>
            </TextField>

            <br />
            <br />
            <h2>CLASSIFICAÇÃO FINAL</h2>
            <TextField error={!formData?.totalGeralClassificacaoFinal} size='small' fullWidth margin='dense' name="totalGeralClassificacaoFinal" label='Classificação Final' value={formData?.totalGeralClassificacaoFinal} onChange={handleChange} />
            {/* <TextField error={!formData?.totalGeralClassificacaoFinal} select size='small' fullWidth margin='dense' name="totalGeralClassificacaoFinal" label='Classificação Final' value={formData?.totalGeralClassificacaoFinal} onChange={handleChange}>
                        <MenuItem value="Mau">Mau</MenuItem>
                        <MenuItem value="Suficiente">Suficiente</MenuItem>
                        <MenuItem value="Bom">Bom</MenuItem>
                    </TextField> */}

            <br />
            <br />
            <h2>RECOMENDAÇÕES</h2>
            <TextField error={!formData?.recomendacoes} type='text' multiline minRows={3} size='small' fullWidth margin='dense' name="recomendacoes" label='Descrever' value={formData?.recomendacoes} onChange={handleChange} />

            {/* <button type="submit">Enviar</button> */}
        </div>
    );
};

export default AutoVistoriaParaFarmaciaDeposito;
