import { QRCodeSVG } from 'qrcode.react';
import HeaderGestorConfigs from '../../utils/headerGestorConfigs';
import printerService from '../../services/printer';

function QRCodeEstabelecimento({ link, header, estabelecimento }) {
    const dadosParaQRCode = link;

    const exportQrCode = () => {
        printerService.printer('qrCode')
    }

    return (
        <>
            {header &&
                <HeaderGestorConfigs
                menu={true}
                    configArea={'qrCode'}
                    title={'CÓDIGO QR DO ESTABELECIMENTO'}
                    estabelecimento={estabelecimento}
                    exportQrCode={exportQrCode}
                />
            }
            
            <div id='qrCode' style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center', border: '1px solid', borderRadius: 5, display: 'inline-block' }}>

                    <QRCodeSVG value={dadosParaQRCode} size={150} includeMargin />
                    <br />
                    <img src="/img/logo/logoArmed.svg" alt="ARMED" width={130} style={{ marginBottom: 20 }} />
                </div>
            </div>
        </>
    );
}

export default QRCodeEstabelecimento;
