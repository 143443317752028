import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../../../utils/headerSession'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        // textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 10,
        // textAlign: 'center',
        color: '#67348d'
    },
}))

export default function InfoPedidoAIM() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
            <HeaderSession title='PEDIDOS DE AIM' />

            <div>
                <h1 className={classes.title}>
                    O que é AIM?
                </h1>
                <p className={classes.root} >
                    <strong>Autorização de Introdução no Mercado, abreviadamente designado por “AIM”</strong>,
                    é a autorização de comercialização de determinado medicamento no mercado nacional,
                    após a sua avaliação pela Agência Reguladora de Medicamentos e Tecnologias de Saúde,
                    culminando com a emissão de um Certificado de Autoriozação de Introdução no Mercado
                    (CAIM), válido por cinco anos. (Decreto Presidencial nº 315/20 de 17 de Dezembro).
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    O que é o registo de Medicamentos
                </h1>
                <p className={classes.root} >
                    O Registo de medicamentos é um procedimento adoptado pelas Autoridades
                    Reguladoras de Medicamentos, que consiste na avaliação da documentação
                    técnica do medicamento, devendo incluir também a inspecção às instalações
                    de fabrico e avaliação laboratorial de amostras do medicamento proposto
                    para o registo, incluindo a avaliação farmaco-económica, permitindo regular
                    o preço dos medicamentos no mercado.
                </p>
            </div>
            <div>
                <h1 className={classes.title}>
                    Qual é o objectivo do registo de Medicamentos
                </h1>
                <p className={classes.root} >
                    O objectivo do registo de medicamentos é garantir que apenas medicamentos eficazes,
                    seguros e de qualidade circulem no território nacional.
                </p>
            </div>
            <div>
                <h1 className={classes.title}>
                    Como solicitar AIM?
                </h1>
                <p className={classes.root} >
                    Os  procedimentos para a solicitação de AIM constam no artigo 10º/anexo III do Decreto Presidencial nº 315/20 de 17 de Dezembro.
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos de submissão do Dossier para o Registo de Medicamentos
                </h1>

                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento dirigido ao Director (a) Geral da Agência Reguladora de Medicamentos e
                            Tecnologia de Saúde, incluindo (nome do medicamento proposto, substância activa,
                            forma farmacêutica, dosagem, apresentação, folheto informativo, numero da autorização
                            de exercício farmacêutico e o NIF), (<a href="/#">Ver modelo</a>).
                        </li>
                        <li>
                            Submissão virtual do dossier do software Sistema de Registo de Medicamentos de Angola (SIREMA) URL por indicar.
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                        <li>
                            Duas cópias em suporte electrónico Pen-drive (Word e PDF) e um no formato
                            físico do CTD em suporte de papel, em pasta de arquivo rotulada na lombarda
                            com o nome e endereço da entidade.
                        </li>
                        <li>
                            Apresentação de amostra do produto segundo o anexo IV do Decreto nº315/20.
                        </li>
                        <li>
                            O dossier do produto a registar deve ser submetido e assinado pelo Director Técnico da entidade.
                        </li>
                    </ol>
                </p>
            </div>


            <br />
            <br />
            <div>
                <h1 style={{ fontSize: 25 }}>
                    Ver Calendário de atendimento
                </h1>
            </div>
            <p className={classes.root} >
                Documentos a anexar
            </p>
            <p className={classes.root} >
                <ul>
                    <li>
                        Carta de Consentimento.
                    </li>
                    <li>
                        Declaração de Compromisso e Assinatura.
                    </li>
                    <li>
                        Formulário de pedido de AIM.
                    </li>
                    <li>
                        Taxas e Emolumentos.
                    </li>
                </ul>
            </p>

            <br />
        </Container>
    )
}
