
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CountUp from 'react-countup';
import api from "../services/api";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '35%',
        minWidth: 180,
        // height: 120,
        margin: 10,
        border: '1px solid #e0e0e0',
        borderRadius: 10,
        transition: '.5s',
        '&:hover': { background: '#e0e0e0', transform: 'scale(1.1)', },

        [theme.breakpoints.down('xs')]: { width: '100%' },
    },

    count: {
        fontFamily: 'Work Sans',
        fontSize: 22,
        fontWeight: 'bold',
        // color: '#284a62',
        transition: '.5s',
        // '&:hover': { fontSize: 40 }
    },
}))

export default function ServicesMenuProvincia(props) {
    const classes = useStyles()
    const [estabelecimento, setEstabelecimento] = useState([])

    useEffect(() => {

        api.get(`/estabelecimentos?provincia=${props.provincia}&tipoId=${props.tipo._id}&count=true`)
            .then(response => {
                setEstabelecimento(response.data.totalItems);

            }).catch(error => '')
    }, [props.provincia, props.tipo._id])


    return (
        <CardActionArea className={classes.root} >
            <Link style={{ textDecoration: 'none', color: '#292b2b' }} to={`/admin/provincia/${props.provincia}/${props.tipo._id}`}>
                <CardContent style={{ display: 'flex', paddingBottom: 0 }}>
                    {/* <div >
                        <img src={props.icone} alt={props.provincia} width={50} />
                    </div> */}

                    <div style={{ marginLeft: 20, width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ flexGrow: 1, }}>
                                {props.tipo?.designacao.toUpperCase()}
                            </Typography>
                        </div>

                        <hr style={{ border: '1px solid #85287e', margin: '5px 0' }} />

                        <div >
                            <Typography className={classes.count}>
                                {/* BIBLIOTECA PARA APLICAR EFEITO DE CONTAGEM CRESCENTE */}
                                <CountUp end={estabelecimento} />
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Link>
        </CardActionArea>
    )
}