import { useEffect, useState } from "react";
import InformacaoAdmin from "./InformacaoAdmin";
import api from "../../services/api";
import Loading from "../../load/loading";
import { Container } from "@mui/material";
import HeaderSession from "../../utils/headerSession";
import RegisterAccess from "../../utils/registerAccess";

export default function ListaFuncionarios() {
    const [admins, setAdmins] = useState('')

    useEffect(() => {
        api.get('/admins')
            .then(response => {
                setAdmins(response.data.Admins)
            }).catch(err => '')
    }, [])

    // console.log(admins)

    return (
        <Container>
           <RegisterAccess page={'lista de funcionarios'}/>
            <HeaderSession title={'USUARIOS ADMINISTRADORES DO SISTEMA'} />

            {admins ?
                    admins.map((admin) => <InformacaoAdmin key={admin._id} admin={admin} />)
                    :
                    < Loading />
            }
        </Container>
    )
}
