import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../actions/accountActions';
// import { setMembroData } from '../../actions/accountActions';
import authService from '../../services/authService';

function Auth({ children }) {
  const dispatch = useDispatch();
  // const tipo = JSON.parse(localStorage.getItem("tipo"))

  const initAuth = useCallback(async () => {
    if (authService.isAuthenticated()) {
      // recuperar os dados novamente do usuario logado
      // if (tipo === 'Admin') {
      dispatch(setUserData());
      // } else { await dispatch(setMembroData()); }
    }
  }, [dispatch]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  return children;
}

export default Auth;
