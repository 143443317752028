import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../../../../services/api';
import { useSelector } from 'react-redux';

const RemoverInspector = (props) => {
    const user = useSelector(state => state.account.user);

    const handleRomove = () => {
        props.setMessageSuccess('')
        props.setMessageError('')

        api.patch('/inspeccao/remove-inspector', { 'inspectorId': props.inspectorId, 'inspeccaoId': props.inspeccaoId, 'userId': user._id })
            .then(response => {
                // console.log(response)
                props.setMessageSuccess('Removido com sucesso.')
                props.setRefresh(response)

            }).catch(err => {
                // console.log(err)
                props.setMessageError('Erro ao remover')
            })
    }

    return (<Button title='Remover da inspecção' onClick={handleRomove} size="small" color="error"><DeleteIcon /></Button>);
}

export default RemoverInspector;
