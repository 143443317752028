
import { useState } from "react";
import { Card } from "@material-ui/core";
import { Avatar, Button, DialogActions, Divider, TextField, Typography } from "@mui/material";
import MessageSuccess from "../../messages/messageSuccess";
import MessageError from "../../messages/messageError";
import api from "../../services/api";
import LoadingBackdrop from "../../load/loadingBackdrop";
import DateHora from "../../utils/dateHora";
import { useNavigate } from "react-router-dom";
import ShortNome from "../../utils/ShortNome";
import TextoFormatado from "../../utils/textoFormatado";


export default function ResponseReclamacao({ id, userId, respostas, setUpdate }) {
    const navigate = useNavigate()
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [resposta, setResposta] = useState('')
    const [open, setOpen] = useState(false)

    const sendResposta = async () => {
        setUpdate(false)
        setOpen(true)
        setMessageSuccess('')
        setMessageError('')

        await api.post('/reclamacao/response', { id, userId, 'content': resposta })
            .then(response => {
                setOpen(false)
                setMessageSuccess('Resposta enviada com sucesso e a Reclamação foi concluída')

                api.patch('/reclamacao/change-status', { id, userId, 'status': 'Concluído' })
                setUpdate(true)
                clearInput()

                window.setTimeout(() => {
                    navigate(-1)
                }, 5000);

            }).catch(error => {
                setOpen(false)
                setMessageError(error.response.data.message)
            })
    }
    const clearInput = () => {
        document.getElementById('input').value = '';
        setResposta('')
    }

    return (
        <>
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={open} text={'A enviar resposta'} />

            <Card style={{ padding: 20, position: 'relative', marginTop: 10 }}>

                {respostas.length > 0 &&
                    <>
                        <Typography variant="subtitle1" style={{ fontFamily: 'Work Sans' }}>
                            Foram enviado os seguintes email de resposta:
                        </Typography>

                        {respostas.map(resposta =>
                            <div key={resposta._id}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar src={resposta?.admin?.dadosPessoais.fotoURL} style={{ marginRight: 5 }} />
                                    <Typography lineHeight={1} noWrap variant="body1">
                                        <ShortNome nome={resposta?.admin?.dadosPessoais?.nome} />
                                        <br />
                                        <small><DateHora date={resposta?.data} /></small>
                                    </Typography>
                                </div>
                                <TextoFormatado texto={resposta.content} />
                                <Divider />
                            </div>
                        )}
                    </>
                }

                <br />
                <TextField
                    id="input"
                    style={{ marginBottom: 30, }}
                    type="text"
                    label="Mensagem"
                    placeholder="Descrever "
                    fullWidth
                    size="small"
                    multiline
                    minRows={3}
                    name="resumo"
                    variant="outlined"
                    onChange={(e) => setResposta(e.target.value)}
                />

                <DialogActions>
                    <Button disabled={!resposta} onClick={() => { sendResposta() }} size="small" variant="contained" style={{ background: '#85287e', position: 'absolute', right: 20, bottom: 10 }}>
                        Responder
                    </Button>
                </DialogActions>

            </Card>
        </>
    )
}