
class Printer {

    printer = (id) => {

        const janelaImpressao = window.open('url:www.armed.cnp.ao');
        // const janelaImpressao = window.open('about:_blank');
        // janelaImpressao.document.write('<html><title>Documento gerado pela plataforma ARMED</title>');
        janelaImpressao.document.write('<html><title></title>');
        janelaImpressao.document.write('<link rel="stylesheet" href="./index.css" />'); // Inclua seus estilos aqui
        janelaImpressao.document.write('<style>');
        janelaImpressao.document.write('body{ font-family: Arial, sans-serif; line-height:1.2; textAlign: justify; font-size: 16}');
        janelaImpressao.document.write('table, th, td{border: 1px solid #ddd;  border-collapse: collapse}}');
        // janelaImpressao.document.write('@print{table{back-ground: yellow; color: red; font-size: 100px}}');
        janelaImpressao.document.write('</style>');
        janelaImpressao.document.write('</head><body>');
        janelaImpressao.document.write(document.getElementById(id).innerHTML);
        janelaImpressao.document.write('</body></html>');
        janelaImpressao.print();
        // janelaImpressao.window.close();
        // window.location.reload();

        // return tela_impressao
        // return new Promise(async (resolve, reject) => {
        //     return resolve(tela_impressao)
        //     // reject(error)
        // });
    };

    printerQrcode = (id) => {
        var container = document.getElementById(id);
        container.style = "dispalay: flex"
        container.style = "flexWrap: wrap"
        container.style = "gap: 1rem"

        const janelaImpressao = window.open('url:www.armed.cnp.ao');
        // const janelaImpressao = window.open('about:_blank');
        // janelaImpressao.document.write('<html><title>Documento gerado pela plataforma ARMED</title>');
        janelaImpressao.document.write('<html><title></title>');
        janelaImpressao.document.write('<link rel="stylesheet" href="./index.css" />'); // Inclua seus estilos aqui
        janelaImpressao.document.write('<style>');
        janelaImpressao.document.write('body{ font-family: Arial, sans-serif; line-height:1.2; textAlign: center; font-size: 16}');
        janelaImpressao.document.write('</style>');
        janelaImpressao.document.write('</head><body>');
        janelaImpressao.document.write(document.getElementById(id).innerHTML);
        janelaImpressao.document.write('</body></html>');
        janelaImpressao.print();
        janelaImpressao.window.close();
        window.location.reload();

    };

    printerAutorizacao = (id) => {
        var assinatura = document.getElementById('assinatura');
        assinatura.style = "display:  "

        const janelaImpressao = window.open('url:www.armed.cnp.ao');
        // const janelaImpressao = window.open('about:_blank');
        // janelaImpressao.document.write('<html><title>Documento gerado pela plataforma ARMED</title>');
        janelaImpressao.document.write('<html><title></title>');
        janelaImpressao.document.write('<style>');
        janelaImpressao.document.write('body{ font-family: Arial, sans-serif; line-height:1.2; textAlign: justify; font-size: 16}');
        janelaImpressao.document.write('table, th, td{border-collapse: collapse}');
        // janelaImpressao.document.write('@print{table{back-ground: yellow; color: red; font-size: 100px}}');
        janelaImpressao.document.write('</style>');
        janelaImpressao.document.write('</head><body>');
        janelaImpressao.document.write(document.getElementById(id).innerHTML);
        janelaImpressao.document.write('</body></html>');
        janelaImpressao.print();
        assinatura.style = "display: none"
        janelaImpressao.window.close();
        window.location.reload();
    };
}
const printerService = new Printer();

export default printerService;
