
import { Typography, makeStyles, Avatar } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import StatusEstabelecimento from "../../../../utils/statusEstabelecimento";
import ListaVazia from "../../../../utils/listaVazia";
import DateHora from "../../../../utils/dateHora";
import TempoPassado from "../../../../utils/tempoPassado";
import StatusEntrada from "../../../../utils/statusEntrada";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' }
}))

export default function ListaSolicitacao({ estabelecimentos }) {
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <TableContainer >
            <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                <TableHead >
                    <TableRow>
                        <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Tipo</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Nº de Entrada</strong></Typography></TableCell>
                        {/* <TableCell ><Typography noWrap> <strong>Número de Processo</strong></Typography></TableCell> */}
                        <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Solicitação</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Data de inscrição</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Ultima intervenção</strong></Typography></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {estabelecimentos.map((estabelecimento) => (
                        <TableRow key={estabelecimento?._id} className={classes.hover} onClick={() => navigate(`/admin/analise/solicitacao-de-licenciamento/${estabelecimento?._id}`)} title={estabelecimento?.nome}>

                            <TableCell component="th" scope="row">
                                <Avatar variant="square" src={estabelecimento?.logo} alt={estabelecimento?.nome} />
                            </TableCell>

                            <TableCell >{estabelecimento?.nome}</TableCell>
                            <TableCell >{estabelecimento?.tipo?.designacao}</TableCell>
                            <TableCell >{estabelecimento?.numeroEntrada}</TableCell>
                            {/* <TableCell >{estabelecimento?.numeroProcesso}</TableCell> */}
                            <TableCell >{estabelecimento?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                            <TableCell ><StatusEntrada status={estabelecimento?.tipoEntrada}/>  </TableCell>
                            <TableCell >  <StatusEstabelecimento status={estabelecimento?.status} /></TableCell>
                            <TableCell ><DateHora date={estabelecimento.createdAt} /> </TableCell>
                            <TableCell ><TempoPassado date={estabelecimento.updatedAt} /> </TableCell>
                            {/* <TableCell > <Typography noWrap> {estabelecimento?.email}</Typography></TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {estabelecimentos.length < 1 && <ListaVazia text={'DE MOMENTO NÃO HÁ SOLICITACÕES PARA O SEU PERFIL INTERVIR'} />}
        </TableContainer>
    )
}
