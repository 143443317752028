import { Box, Container } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import CardOpcoes from '../../utils/cardOpcoes';
import HeaderSession from '../../utils/headerSession';
import RegisterAccess from '../../utils/registerAccess';

const useStyles = makeStyles((theme) => ({

    containerCardesService: {
        padding: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        //  flexDirection: 'wrap',
        /* ALINHAR OS ITENS NA HORIZONTAL E NA VERTIVACL */
        justifyContent: 'center',
        alignItems: 'center',
        /* AFASTAR UM ITEM DO OUTRO */
        gap: '1rem',
    }

}))


export default function LegislacaoDocumentos() {
    const classes = useStyles()
    return (
        <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
            <RegisterAccess page={'legislação e documentos'} />
            <HeaderSession title='LEGISLAÇÃO E DOCUMENTOS' />

            <Box className={classes.containerCardesService}>
                <CardOpcoes nomeService={'Decretos'} link={'/decretos'} />
                <CardOpcoes nomeService={'Despachos'} link={'/despachos'} />
                <CardOpcoes nomeService={'Leis'} link={'/leis'} />
                <CardOpcoes nomeService={'Outros'} link={'/outros'} />
            </Box>
        </Container>
    )
}
