const INITIAL_STATE = {
    data: null
}

const RequerimentotReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'requerimento': {

            return {
                ...state,
                data: action.payload.requerimento
            }
        }
        default: {
            return state
        }
    }
}

export default RequerimentotReducer