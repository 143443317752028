import { Container, Grid } from '@mui/material'
import ListaOpcoes from '../../../../utils/listaOpcoes'
import HeaderSession from '../../../../utils/headerSession'

export default function Informacoes() {
    return (
        <Container style={{ minHeight: '70vh' }}>
            <HeaderSession title='INFORMAÇÕES'/>

            <Grid container>
                <Grid margin={1} xs={12} md={5.7} item >
                    <ListaOpcoes
                        view={true}
                        nomeOpcao='Desalfandegamento'
                        link='/informacoes-sobre-desalfandegamento'
                    />

                    <ListaOpcoes
                        view={true}
                        nomeOpcao='Licenciamento de Fábricas'
                        link='/informacoes-sobre-licenciamento-de-fabricas'
                    />

                    <ListaOpcoes
                        view={true}
                        nomeOpcao='Licenciamento de Fármacias / Ervanárias'
                        link='/informacoes-sobre-licenciamento-de-farmacias-e-ervanarias'
                    />

                    <ListaOpcoes
                        view={true}
                        nomeOpcao='Importadores'
                        link='/informacoes-sobre-importadores'
                    />
                </Grid>
                
                <Grid margin={1} xs={12} md={5.7} item >
                    <ListaOpcoes
                        view={true}
                        nomeOpcao='Representantes de Laboratórios Farmacêuticos'
                        link='/informacoes-sobre-representante-e-laboratorio-farmaceutico'
                    />

                    <ListaOpcoes
                        view={true}
                        nomeOpcao='Produtos Controlados'
                        link='/informacoes-sobre-produtos-controlados'
                    />

                    <ListaOpcoes
                        view={true}
                        nomeOpcao='Pedidos de AIM / Registo de Medicamentos'
                        link='/informacoes-sobre-pedido-de-aim'
                    />

                </Grid>

            </Grid>
            <br
            />

        </Container>
    )
}
