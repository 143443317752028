import { Container } from '@mui/material'
import HeaderSession from '../../../../utils/headerSession'
import Calendar from '../../../../admin/gestAgenda/calendario/calendar'


export default function AgendaDisponivelParaMarcacao() {
    return (
        <Container style={{ minHeight: '70vh', maxWidth: 800 }}>

            <HeaderSession title='AGENDA DISPÓNIVEL' />

            <Calendar />
            <br/>
            <br/>
        </Container>
    )
}
