
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { Button, DialogActions, Grid, MenuItem, TextField } from "@mui/material";
import api from "../../services/api";
import LoadingBackdrop from "../../load/loadingBackdrop";
import { useSelector } from "react-redux";
import apiCNP from "../../services/apiCNP";
import RegisterAccess from "../../utils/registerAccess";
import AlertEdit from "../../utils/alertEdit";
// import { validacaoEditDadosPessoal } from "../../validacoes";

export default function EstabelecimentoEdit({ dadosEstabelecimento, cancelEditDados, setDadosEstabelecimento, setMessageSuccess, setMessageError }) {
    const [editDados, setEditDados] = useState(dadosEstabelecimento)
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.account.user);
    const [tipoEstabelecimentos, setTipoEstabelecimentos] = useState([])
    // const [classeEstabelecimentos, setClasseEstabelecimentos] = useState([])

    // console.log(editDados)

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);

            }).catch(err => '')

        api.get('/tipo-estabelecimentos')
            .then(res => {
                // console.log(res)
                setTipoEstabelecimentos(res.data.tipoEstabelecimentos);
            }).catch(err => '')

        // api.get('/classificacao-estabelecimentos')
        //     .then(res => {
        //         // console.log(res)
        //         setClasseEstabelecimentos(res.data.classificacaoEstabelecimentos);
        //     }).catch(err => '')
    }, [])

    function HandleChange(e) {

        setEditDados((prev) => ({ ...prev, [e.target.name]: e.target.value }));

        e.target.name === 'provinciaId' &&
            apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                    setEditDados((prev) => ({ ...prev, provincia: res.data.provincia.designacao }));
                }).catch(err => 'console.log(err)')
    }

    const submitEdit = async () => {

        setOpen(true)
        setMessageSuccess('')
        setMessageError('')

        await api.put('/estabelecimento/edit', { ...editDados, 'estabelecimentoId': dadosEstabelecimento._id, 'userId': user?._id ?? '' })
            .then(res => {
                setDadosEstabelecimento(res.data.estabelecimento)
                setOpen(false)
                setMessageSuccess(res.data.msg)
                cancelEditDados(false)

            }).catch(err => {
                // console.log(err)
                setOpen(false)
                setMessageError(err.response.data.message)
            })
    }

    return (
        <>
            <RegisterAccess page={'editar dados de estabelecimento'} />
            <LoadingBackdrop open={open} />

            <Card style={{ border: '1px solid orange' }}>
               
                <AlertEdit />

                <Grid container>

                    <Grid xs={12} md item m>
                        <TextField
                            type="text"
                            label="Nome "
                            fullWidth
                            size="small"
                            name="nome"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.nome}
                        />
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            type="email"
                            label="E-mail "
                            fullWidth
                            size="small"
                            name="email"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.email}
                        />
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Tipo de Estabelecimento"
                            select
                            fullWidth
                            size="small"
                            name="tipoId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.tipo?._id ?? ''}
                        >
                            <MenuItem disabled value=''>Selecione Tipo de Estabelecimento</MenuItem>
                            {tipoEstabelecimentos?.map((estabelecimento) => (
                                <MenuItem key={estabelecimento._id} value={estabelecimento._id}>
                                    {estabelecimento.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/* <Grid xs={12} md item >
                                <TextField
                                    required
                                    type="text"
                                    label="Classe de Estabelecimento"
                                    select
                                    fullWidth
                                    size="small"
                                    name="classeId"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={editDados?.classeId ?? ''}
                                >
                                    <MenuItem disabled value=''>Selecione Classe de Estabelecimento</MenuItem>
                                    {classeEstabelecimentos?.map((classeEstabelecimento) => (
                                        <MenuItem key={classeEstabelecimento._id} value={classeEstabelecimento._id}>
                                            {classeEstabelecimento.designacao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}
                </Grid>

                <Grid container >
                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Províncía"
                            select
                            fullWidth
                            size="small"
                            name="provinciaId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.provincia ?? ''}
                        >
                            <MenuItem disabled value={editDados?.provincia}>{editDados?.provincia}</MenuItem>
                            {provincias?.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia._id}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Município"
                            select
                            fullWidth
                            size="small"
                            name="municipio"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.municipio ?? ''}
                        >
                            <MenuItem disabled value={editDados?.municipio}>{editDados?.municipio}</MenuItem>
                            {municipios?.map((municipio) => (
                                <MenuItem key={municipio._id} value={municipio.designacao}>
                                    {municipio.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item m>
                        <TextField
                            required
                            type="text"
                            label="Endereço"
                            fullWidth
                            size="small"
                            name="rua"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={editDados?.rua}
                        />
                    </Grid>
                </Grid>
                <Grid xs={12} md={4} item m>
                    <TextField
                        required
                        type="text"
                        label="Bairro"
                        fullWidth
                        size="small"
                        name="bairro"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={editDados?.bairro}
                    />
                </Grid>

                <DialogActions >
                    <Button onClick={() => { cancelEditDados(false); setMessageError('') }} color="warning" size="small" style={{ marginTop: 20, marginRight: 10 }}>Cancelar</Button>
                    <Button variant="contained" onClick={() => { submitEdit() }} size="small" style={{ marginTop: 20, background: '#85287e' }}>Salvar</Button>
                </DialogActions>
            </Card>
        </>
    )
}