import React from 'react'
import { Container } from './styles'

interface ISelectInputProps {
    setSelectedYear: React.Dispatch<React.SetStateAction<string | number>>;
    selected: string | number;
    options: {
        value: string | number;
        label: string | number;
    }[],
}

const SelectInput: React.FC<ISelectInputProps> = ({ options, selected, setSelectedYear }) => {

    return (
        <Container>
            <select value={selected} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
                {options.map((option, index) => (
                    <option key={index} value={option?.value}>{option?.label}</option>
                ))}
            </select>
        </Container>
    )
}
export default SelectInput