
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import { makeStyles } from '@material-ui/core';
import {Avatar, Box, Card, IconButton, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../../load/loading';
import MessageError from '../../messages/messageError';
import StatusEstabelecimento from '../../utils/statusEstabelecimento';
// import FerramentaNavegacaoEexpot from '../../secretariado_areaTecnica/listas/FerramentaNavegacaoEexpot';

const useStales = makeStyles({
    icone: {
        margin: 7,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#b1b7ea',
            borderRadius: 3
        },
    },
    hover: { '&:hover': { backgroundColor: '#d2d6ff', cursor: 'pointer', } }
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

export default function PesquisarEstabelecimento(props) {
    const navigate = useNavigate()
    const classes = useStales()
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [pesquisa, setPesquisa] = useState('');
    const [openLoad, setOpenLoad] = useState(false);
    const [messageError, setMessageError] = useState('');
    // const [configLista, setConfigLista] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const searchEstabelecimento = async (e) => {
        e.preventDefault()
        setPesquisa('')
        setMessageError('')
        setOpenLoad(true)

        try {
            const response = await api.post(`/estabelecimento/search?perPage=20`, { search })
            setPesquisa(response.data.estabelecimentos);
            setOpenLoad(false)

        } catch (error) {
            if (error.message === "Network Error") {
                setMessageError(error.message)
            }
            setOpenLoad(false)
            setPesquisa('');
            setMessageError(error.response.data.message);
        }
    }

    return (
        <div>
            <ContentPasteSearchOutlinedIcon onClick={handleClickOpen} titleAccess='Pesquisar Estabelecimento' className={classes.icone} />

            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogContent >
                    {messageError && <MessageError message={messageError} />}

                    <Box m={2}>
                        <form onSubmit={searchEstabelecimento} style={{ display: 'flex' }}>
                            <InputBase
                                style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }}
                                required
                                autoFocus
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Pesquisar estabelecimento"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <IconButton type="submiy" aria-label="search">
                                <ContentPasteSearchOutlinedIcon />
                            </IconButton>
                        </form>
                    </Box>

                    {openLoad && <Loading text={'A pesquisar estabelecimento'} />}

                    {pesquisa &&
                        <Card variant="outlined">
                            <TableContainer >
                                <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell ><Typography  noWrap> <strong>Imagem</strong></Typography></TableCell>
                                            <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                            <TableCell ><Typography noWrap> <strong>Número de Processo</strong></Typography></TableCell>
                                            <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                                            <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {pesquisa.map((pesquisa) => (
                                            <TableRow key={pesquisa?._id} className={classes.hover} onClick={() => navigate(`/admin/gestor-licenciados/${pesquisa?._id}`)} title={pesquisa?.nome}>
                                                <TableCell component="th" scope="row">
                                                    <Avatar variant='square' src={pesquisa?.logo} alt={pesquisa?.nome} />
                                                </TableCell>
                                                <TableCell  >{pesquisa?.nome}</TableCell>
                                                <TableCell  >{pesquisa?.numeroProcesso}</TableCell>
                                                <TableCell  >{pesquisa?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                                                <TableCell  ><StatusEstabelecimento status={pesquisa?.status}/></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    }
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
