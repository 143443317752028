import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { Card, ListItemIcon, MenuItem } from "@mui/material";
import Loading from "../../../../../load/loading";
import { useNavigate } from "react-router-dom";


export default function RelatorioAutorizacoesExercicioEmitidas() {
    const [autorizacoesEmitidas, setAutorizacoesEmitidas] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        api.get(`/autorizacaos`)
            .then(response => {
                // console.log(response)
                setAutorizacoesEmitidas(response.data.totalItems);
                // setConfigLista(response.data);

            }).catch(err => '')
    }, []);


    return (
        <Card style={{ padding: 20 }}>

            <Typography style={{ fontWeight: 'bold' }}>AUTORIZAÇÕES DE EXERCÍCIO EMITIDAS</Typography>
            {autorizacoesEmitidas ?
                <div >
                    <MenuItem size='small' onClick={() => navigate('/autorizacoes-de-exercicio-emitidas/geral')}> <ListItemIcon> {autorizacoesEmitidas} </ListItemIcon> Geral </MenuItem>
                    {/* <MenuItem size='small' onClick={() => generatePDF('save')}> <ListItemIcon> <DownloadIcon color='secondary' />  </ListItemIcon>  Baixar PDF </MenuItem> */}
                    {/* <MenuItem size='small' onClick={() => setOpenReenviarEmail(true)}> <ListItemIcon> <AttachEmailIcon color='secondary' />  </ListItemIcon>  Enviar por email </MenuItem> */}
                </div>
                :
                <Loading />
            }
        </Card>
    )
}