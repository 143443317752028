
import { Typography, Card, Divider, Avatar } from "@material-ui/core";
import ShortNome from "../../../utils/ShortNome";
import VerTodasObservacoes from "./verTodasObservacoes";
import { Badge } from "@mui/material";
import TextFormatado from "../../../utils/TextFormatado";
import TempoPassado from "../../../utils/tempoPassado";

export default function ObservacoesFeitas(props) {

    return (
        <Card style={{ padding: 10, marginBottom: 15 }}>
            <Typography align="center" style={{ fontSize: 22 }} variant="h1">
                Observações
                <Badge style={{ padding: 7 }} badgeContent={props.observacoesFeitas.length}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                </Badge>
            </Typography>
            <Divider />
            <br />

            {props.observacoesFeitas.map((observacaoFeita, index) => (
                index < 4 &&
                <div key={observacaoFeita._id} style={{ padding: 10, marginBottom: 10, }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={observacaoFeita?.admin?.dadosPessoais.fotoURL} style={{ marginRight: 5 }} />
                        <Typography noWrap style={{ lineHeight: 1 }} variant="body1">
                            <ShortNome nome={observacaoFeita?.admin?.dadosPessoais.nome} />
                            <br />
                            <small>{observacaoFeita?.admin?.dadosPessoais?.perfil}</small>
                            <br />
                            <small>
                                <TempoPassado date={observacaoFeita?.updatedAt} />
                            </small>
                        </Typography>
                    </div>
                    <div style={{ borderRadius: 5, padding: 5, fontSize: 14, margin: 2 }}>
                        <TextFormatado texto={observacaoFeita.descricao.length > 70 ? observacaoFeita.descricao.slice(0, 70) + '...' : observacaoFeita.descricao} />
                    </div>
                    <Divider />
                </div>
            ))
            }

            {props.observacoesFeitas.length > 0 &&
                <div style={{ display: "flex", justifyContent: 'center' }}>
                    <VerTodasObservacoes observacoesFeitas={props.observacoesFeitas} />
                </div>
            }
        </Card>
    )
}