import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FichaRepresentante from "./fichaRepresentante";
import api from "../../services/api";
import FichaEmpresa from "../gestEmpresas/fichaEmpresa";
import Loading from "../../load/loading";
import HistoricoActividadesByModel from "../departamentos/dlif/licenciamento/historicoActividadesByModel.jsx";

export default function GetRepresentante() {
    const { id } = useParams()
    const [representante, setRepresentante] = useState('')

    useEffect(() => {
        api.get(`/representante/${id}`)
            .then(response => {
                // console.log(response)
                setRepresentante(response.data);
            }).catch(err => '')
    }, [id])

    return (
        representante ?
            <>
                <FichaRepresentante representante={representante?.representante} header={true} />
                <FichaEmpresa empresa={representante?.empresa} header={true}/>
                <HistoricoActividadesByModel model={representante?.representante}/>
            </>
            :
            <Loading />
    )
}