
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { Link, useNavigate } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { signIn } from '../../actions/accountActions';
import { Paper } from '@mui/material';
import MessageError from '../../messages/messageError';
import { FormControl, IconButton, InputAdornment, InputLabel } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import LoadingShort from '../../load/loadingShort';
import RegisterAccess from '../../utils/registerAccess';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' ©  ARMED - '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '90vh'

    },
    paper: {
        // marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 30,

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        color: '#fff',
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#67348d',
        '&:hover': { backgroundColor: '#67348d', },
    },
}));

export default function Login() {
    const classes = useStyles();
    const [openLoad, setOpenLoad] = React.useState(false);
    const [login, setLogin] = useState([])
    const [errorMessage, seterrorMessage] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function HandleChange(e) {
        setLogin({ ...login, [e.target.name]: e.target.value })
    }

    const entrar = async (e) => {
        e.preventDefault()
        setOpenLoad(true)
        seterrorMessage('')

        try {
            const user = await dispatch(signIn(login));
            // console.log(user)

            if (user.departamento === 'CNP' || user.departamento === 'DG') {
                navigate('/admin/dlif');
                // <Navigate to={'/admin/dlif'} replace />
            }

            if (user.departamento === 'DLIF') {
                navigate('/admin/dlif');
                // <Navigate to={'/admin/dlif'} replace />
            }
            if (user.departamento === 'DMTS') {
                navigate('/admin/dmts');
            }

            window.location.reload()

        } catch (error) {
            // console.log(error)
            setOpenLoad(false)
            seterrorMessage(error.response?.data.message)
        }
    }

    return (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <RegisterAccess page={'login'} />
            {errorMessage && <MessageError message={errorMessage} />}

            <Paper className={classes.paper}>
                <Link title='Volta para o inicio' to={'/'}>
                    <img src="/img/logo/logoArmed.svg" alt="" width={150} />
                </Link>
                <Typography align='center' style={{ fontFamily: 'Work Sans', lineHeight: 2 }} variant="body1">
                    <strong>ARMED - Gestor</strong>
                </Typography>

                <form className={classes.form} noValidate>
                    <TextField
                        type="email"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={HandleChange}
                    />

                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput
                            required
                            size="small"
                            name="password"
                            onChange={HandleChange}
                            id="outlined-adornment-password"
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Senha"
                        />
                    </FormControl>
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        disabled={!login.password || openLoad}
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={entrar}
                    >
                        {openLoad ? <LoadingShort /> : 'Entrar'}
                    </Button>

                    {/* 
                    <Link href="#" variant="body2">
                        Esqueçeu a senha?
                    </Link> */}

                </form>
                {/* <Box mt={8}> */}
                <Box >
                    <br />
                    <br />
                    <Copyright />
                </Box>
            </Paper>
        </Container>
    );
}