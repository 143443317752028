
import { useState } from "react";
import { Typography, Card, Grid } from "@mui/material";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
import EstabelecimentoEdit from "../dadosGenericos/estabelecimentoEdit";
import MessageSuccess from "../../messages/messageSuccess";
import MessageError from "../../messages/messageError";
import ImagemEdit from "../../utils/imagemEdit";
import DateHora from "../../utils/dateHora";
import Delete from "../../utils/delete";
import ChangeDirectorTecnico from "../gestDirectorTecnico/changeDirectorTecnico";
import ReenviarLinkReprovacao from "../departamentos/dlif/licenciamento/reenviarLinkReprovacao";

export default function FichaEstabelecimento(props) {
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [editDados, setEditDados] = useState(false)
    const [dadosEstabelecimento, setDadosestabelecimento] = useState(props.estabelecimento)
    const [openEditLogo, setOpenEditLogo] = useState(false);
    const [open, setOpen] = useState(false);
    const [changeDirectorTecnico, setChangeDirectorTecnico] = useState(false);
    const [openReenviarLinkReprovacao, setOpenReenviarLinkReprovacao] = useState(false);
    // console.log(dadosEstabelecimento)

    return (
        <Card style={{ padding: 20}}>
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}

            <HeaderGestorConfigs
                menu={props?.menu ?? true}
                configArea={'fichaEstabelecimento'}
                title={editDados ? 'EDITAR DADOS': 'DADOS DO ESTABELECIMENTO'}
                estabelecimento={dadosEstabelecimento}
                editDados={editDados}
                setEditDados={setEditDados}
                delete={setOpen}
                setChangeDirectorTecnico={setChangeDirectorTecnico}
                setOpenReenviarLinkReprovacao={setOpenReenviarLinkReprovacao}
            />

            <Delete
                open={open}
                setOpen={setOpen}
                url={'/estabelecimento/delete'}
                msg={`Tem certeza que deseja eliminar o Estabelecimento ${dadosEstabelecimento?.nome}`}
                id={dadosEstabelecimento._d}
            />

            <ChangeDirectorTecnico
                estabelecimento={dadosEstabelecimento}
                open={changeDirectorTecnico}
                setOpen={setChangeDirectorTecnico}
            />


            <ReenviarLinkReprovacao
                estabelecimento={dadosEstabelecimento}
                openReenviarEmail={openReenviarLinkReprovacao}
                setOpenReenviarEmail={setOpenReenviarLinkReprovacao}  
                setMessageSuccess={setMessageSuccess}
            />

            {!editDados ?
                <Grid container>
                    <Grid item marginRight={2}>
                        <ImagemEdit
                            data={dadosEstabelecimento}
                            url={'/estabelecimento/change-logo'}
                            local={'estabelecimentoId'}
                            openEditLogo={openEditLogo}
                            setOpenEditLogo={setOpenEditLogo}
                        />
                    </Grid>

                    <Grid xs item>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Estado:</strong> {dadosEstabelecimento?.status ?? '####'}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nome:</strong> {dadosEstabelecimento?.nome ?? '####'}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>E-mail:</strong> {dadosEstabelecimento?.email ?? '####'} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Estabelecimento:</strong> {dadosEstabelecimento?.tipo?.designacao ?? '####'}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Carteira do Arquitecto:</strong> {dadosEstabelecimento?.carteiraArquitecto ?? '####'}</Typography>
                        {/* <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Tipo de entrada:</strong> {dadosEstabelecimento?.tipoEntrada ?? '####'}</Typography> */}
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Número de processo:</strong> {dadosEstabelecimento?.numeroProcesso ?? '####'}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Número de entrada:</strong> {dadosEstabelecimento?.numeroEntrada ?? '####'}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Data de entrada:</strong> {dadosEstabelecimento?.dataEntrada ?? <DateHora date={dadosEstabelecimento?.createdAt} />}</Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Rua / Avenida:</strong> {dadosEstabelecimento?.rua ?? '####'} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Bairro:</strong> {dadosEstabelecimento?.bairro ?? '####'} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Município:</strong> {dadosEstabelecimento?.municipio ?? '####'} </Typography>
                        <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Provincia:</strong> {dadosEstabelecimento?.provincia ?? '####'} </Typography>
                    </Grid>
                </Grid>
                :
                <EstabelecimentoEdit
                    dadosEstabelecimento={dadosEstabelecimento}
                    setDadosEstabelecimento={setDadosestabelecimento}
                    cancelEditDados={setEditDados}
                    setMessageSuccess={setMessageSuccess}
                    setMessageError={setMessageError}
                />
            }
        </Card>
    )
}