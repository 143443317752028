
import { Container } from "@material-ui/core";
import HeaderSession from "../../utils/headerSession";
import RegisterAccess from "../../utils/registerAccess";

export default function GestEmpresas({ element: component, ...rest }) {

    return (
        <>
            <RegisterAccess page={'gestão de empresa'} />
            <Container>
                <HeaderSession title={'GESTÃO DE EMPRESAS'} />
                {component}
            </Container>
        </>
    )
}