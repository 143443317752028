
import { Typography, Card, Divider } from '@mui/material'
import { useEffect, useState } from 'react'
import HeaderGestorConfigs from '../../../../../utils/headerGestorConfigs';
import jsPDF from 'jspdf';
import { format, parseISO } from 'date-fns';
import api from '../../../../../services/api';
import LoadingBackdrop from '../../../../../load/loadingBackdrop';
import MessageSuccess from '../../../../../messages/messageSuccess';
import DateHora from '../../../../../utils/dateHora';
import ReenviarEmail from '../../licenciamento/reenviarEmail';
import { useSelector } from 'react-redux';

export default function ReciboRenovacao({ renovacao }) {
  const user = useSelector(state => state.account.user);
  const currentYear = new Date().getFullYear();
  const [load, setLoad] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [openReenviarEmail, setOpenReenviarEmail] = useState(false);
  const [novoEmail, setNovoEmail] = useState('');
  // const baseUrl = window.location.origin;
  // console.log(renovacao) 
  // const [agendas, setAgendas] = useState('')


  const generatePDF = (action) => {
    setLoad(action)
    setMessageSuccess('')

    // Criar um novo pdfumento PDF
    // const pdf = new jsPDF();

    const pdf = new jsPDF({
      orientation: 'landscape',
      format: 'a5'
    });

    // const pdf = new jsPDF({
    //   orientation: 'portrait',
    //   unit: 'mm',
    //   format: [210, 297] // A4 size in mm
    // });

    const larguraMaxima = 165;
    const fonteSansSerif = 'Arial, sans-serif';

    // const { jsPDF } = window.jspdf;
    // const pdf = new jsPDF();

    pdf.addImage('/img/logo/InsigniaAngola.png', 'PNG', 40, 10, 15, 15); 

    // Título
    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'bold');
    // pdf.text("MINISTÉRIO DA SAÚDE", 10, 20);
    pdf.text('MINISTÉRIO DA SAÚDE', 50, 30, null, null, 'center');
    pdf.text('AGÊNCIA REGULADORA DE MEDICAMENTOS', 50, 35, null, null, 'center');
    pdf.text('E TECNOLOGIA DE SAÚDE', 50, 40, null, null, 'center');

    pdf.addImage('/img/logo/logoArmed.png', 'PNG', 20, 50, 50, 20, 'FAST', 0.5);

    // Informações de contato
    pdf.setLineWidth(1);
    pdf.setDrawColor(133, 40, 126);
    pdf.line(10, 85, 90, 85);

    pdf.setFontSize(8);
    pdf.setFont('helvetica', 'normal');
    pdf.text("Tel.(+244) 945 817 227", 10, 90);
    pdf.text("E-mail: expedientegeral@armed.gov.ao | www.armed.gov.ao", 10, 95);
    pdf.text("Rua Cmdt, Che-Guevara nº 86/86A, Maculusso,", 10, 100);
    pdf.text("Luanda - Angola", 10, 105);


    // Informação do processo
    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'bold');
    pdf.text(`PROCESSO DE RENOVAÇÃO Nº ${renovacao.numero}/ARMED/${currentYear}`, 100, 20);

    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'normal');
    pdf.text(`Solicitação do(a) Senhor(a) ${renovacao.nome}`, 100, 30);

    pdf.text(`Para renovação da Autorização nº ${renovacao?.numeroAutorizacao ?? '????'}`, 100, 35);
    pdf.text(`concedida ao estabelecimento denominado: `, 100, 40);

    pdf.setFont('helvetica', 'bold');
    pdf.text(`${renovacao.estabelecimento?.nome ?? '????'}`, 100, 45);
    pdf.setFont('helvetica', 'normal');

    pdf.text(`Sito na rua: ${renovacao.estabelecimento?.rua ?? '????'}`, 100, 50);
    pdf.text(`casa:`, 100, 55);
    pdf.text(`Bairro: ${renovacao.estabelecimento?.bairro ?? '????'}`, 100, 60);
    pdf.text(`Município: ${renovacao.estabelecimento?.municipio ?? '????'}`, 100, 65);
    pdf.text(`Província: ${renovacao.estabelecimento?.provincia ?? '????'}`, 100, 70);

    // Informações adicionais
    pdf.text('Gerado pela plataforma ARMED', 190, 80, null, null, 'right');
    pdf.text(`Data: ${format(parseISO(renovacao?.createdAt), 'dd-MM-yyyy HH:mm:ss')}`, 190, 85, null, null, 'right');

    pdf.setFont('helvetica', 'bold');
    pdf.text("Observações:", 100, 90);
    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(8);
    pdf.text("1. Este recibo é valido por um periodo de (30) dias, a contar", 100, 95);
    pdf.text("da validação dos dados do requerente;", 100, 100);
    pdf.text("2. Deve aguardar pela inspecção;", 100, 105);
    pdf.text("3. O mesmo não substitui Autorização de Exercicio", 100, 110);
    pdf.text("4. SÓ É VALIDO O ORIGINAL", 100, 115);


    if (action === 'print') {
      // imprimir PDF
      const pdfBlob = pdf.output('blob');
      // Criar um URL para o Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      // Abrir o PDF em uma nova aba
      window.open(pdfUrl, '_blank');
      setLoad(false)
      setMessageSuccess('Sucesso')
    }

    if (action === 'save') {
      // Salvar PDF
      pdf.save(`recibo-de-renovação-${renovacao?.estabelecimento?.nome ?? ''}.pdf`);
      setLoad(false)
      setMessageSuccess('Sucesso')
    }

    // FUNCAO PARA ENVIAR EMAIL
    if (action === 'Enviando_email') {
      const pdfBlob = pdf.output('blob');
      // Criar um objeto FormData para enviar o Blob como um arquivo

      // <h1>Renovação '${renovacao?.nome}'</h1
      const msg = {
        to: novoEmail?.toLowerCase() ?? renovacao?.email?.toLowerCase(),
        subject: `Recibo de Solicitação de Renovação`,
        html:
                  `
                    <p>
                      A Solicitação de renovacao do estabelecimento <b>${renovacao?.estabelecimento?.nome}</b> com 
                      Recibo nº ${renovacao?.numero} está em análise, cumprindo todos os
                      requisitos, em altura oportuna enviaremos um email a confirmar a aprovação.
                    </p>
                    <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
                    `,
      };

      const formData = new FormData();
      formData.append('to', msg.to); //
      formData.append('subject', msg.subject); //
      formData.append('html', msg.html); //
      formData.append('attachment', pdfBlob, `recibo-de-renovação-${renovacao?.nome}.pdf`); // O terceiro argumento é o nome do arquivo

      // for (let dado of formData.values()) { console.log(dado); }

      api.post('/email/send', formData)
        .then(response => {
          // console.log(response)
          setOpenReenviarEmail(false)
          setLoad(false)
          setMessageSuccess('E-mail enviado com Sucesso')
        }).catch(err => {
          // console.log(err) 
          setLoad(false)
        })
    }
    // });
  };

  // ENVIAR EMAIL APENAS QUANDO O USER NAO FOR ADMIN
  useEffect(() => {
      !user?.isAdmin &&
          setTimeout(function () {
              generatePDF('Enviando_email');
          }, 5000);
  }, []);


  return (
    <Card style={{ padding: 20, marginBottom: 10 }}>
      <LoadingBackdrop open={load} text={load} />
      {messageSuccess && <MessageSuccess message={messageSuccess} />}
      {/* {messageError && <MessageError message={messageError} />} */}

      <ReenviarEmail
        // estabelecimento={estabelecimento}
        setNovoEmail={setNovoEmail}
        generatePDF={generatePDF}
        openReenviarEmail={openReenviarEmail}
        setOpenReenviarEmail={setOpenReenviarEmail}
        load={load}
      />

      <HeaderGestorConfigs
        menu={true}
        generatePDF={generatePDF}
        setOpenReenviarEmail={setOpenReenviarEmail}
        configArea={'recibo'}
        title={'RECIBO'} 
      />

      <div id='reciboRenovacao' style={{ padding: 20}}>

        <div style={{ display: 'flex', gap: '3rem' }}>

          <div align='center' style={{ position: 'relative' }}>
            <img src="/img/logo/InsigniaAngola.png" width={70} alt="Insign" />
            <Typography variant="h1" style={{ fontSize: 12, fontWeight: 'bold' }}>
              MINISTÉRIO DA SAÚDE <br />
              AGÊNCIA REGULADORA DE MEDICAMENTOS E TECNOLOGIA DE SAÚDE
            </Typography>
            <img src="/img/logo/logoArmed.svg" width={'80%'} style={{ marginTop: 50 }} alt="logoArmed" />


            <div style={{ bottom: 0, position: 'absolute', bottom: 0 }}>
              <Divider style={{ background: '#85287e', marginTop: 80, marginBottom: 10 }} />

              <div align='start'>
                <Typography style={{ fontSize: 11, lineHeight: 1.2, flexGrow: 1, marginRight: 50 }}>
                  Tel.(+244) 945 817 227 <br />
                  E-mail: expedientegeral@armed.gov.ao | www.armed.gov.ao <br />
                  Rua Cmdt, Che-Guevara nº 86/86A, Maculusso, <br />
                  Luanda - Angola
                </Typography>

              </div>
            </div>
          </div>


          <div style={{ position: 'relative' }}>
            <Typography style={{ fontSize: 15, fontWeight: 'bold' }} variant="subtitle1">
              PROCESSO DE RENOVAÇÃO Nº
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.numero}
              </span>
              /ARMED/{currentYear}
            </Typography>
            <br />

            <Typography >
              Solicitação do(a) Senhor(a)
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.nome}
              </span>
            </Typography>

            <Typography >
              Para renovação da Autorização nº
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.numeroAutorizacao ?? '????'}
              </span>
              concedida ao estabelecimento denominado:
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.estabelecimento?.nome ?? '????'}
              </span>
            </Typography>


            <Typography >
              Sito na rua
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.estabelecimento?.rua ?? '????'}
              </span>
              casa
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {''}
              </span>
              <br />

              Bairro
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.estabelecimento?.bairro ?? '????'}
              </span>

              Município
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.estabelecimento?.municipio ?? '????'}
              </span>

              Província
              <span style={{ fontWeight: 'bold', borderBottom: '1px solid', margin: 5 }}>
                {renovacao?.estabelecimento?.provincia ?? '????'}
              </span>

            </Typography>

            <div align='end' style={{ marginTop: 50 }}>
              <Typography  >
                Gerado pela plataforma ARMED
                <br />
                <DateHora date={renovacao.updatedAt} />
              </Typography>

              <div align='start' style={{ marginTop: 50 }}>
                <b>Observações:</b>
                <br />
                <ul>
                  <li>
                    Este recibo é valido por um periodo de (30) dias, a contar da data da validação dos dados do requerente;
                  </li>
                  <li>
                    Deve aguardar pela inspecção;
                  </li>
                  <li>
                    o mesmo não substitui Autorização de Exercicio
                  </li>
                  <li>
                    SÓ É VALIDO O ORIGINAL
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
