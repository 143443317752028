import { useEffect, useState } from "react";
import { InputBase, IconButton } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LoadingShort from "../load/loadingShort";
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@mui/material';
// import MessageError from "../messages/messageError";
import api from "../services/api";

const SearchEstabelecimento = ({ setEstabelecimentos, functionFilter, placeholder }) => {
    // const [messageError, setMessageError] = useState('');
    const [classeEstabelecimentos, setClasseEstabelecimentos] = useState([])
    const [load, setLoad] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        api.get('/tipo-estabelecimentos')
            .then(res => {
                // console.log(res)
                setClasseEstabelecimentos(res.data.tipoEstabelecimentos);
            }).catch(err => '')
    }, [])

    const searchEstabelecimento = async (e) => {
        e.preventDefault()
        setEstabelecimentos('')
        // setMessageError('')
        setLoad(true)

        try {
            const response = await api.post(`/estabelecimento/search`, { search });
            // console.log(response)
            setEstabelecimentos(response.data.estabelecimentos);
            setLoad(false)

        } catch (error) {
            // if (error.message === "Network Error") {
            //     setMessageError(error.message)
            // }
            setLoad(false)
            setEstabelecimentos('');
            // setMessageError(error.response.data.message);
        }
    }

    return (
        <>
            <form onSubmit={searchEstabelecimento} style={{ border: '1px solid #85287e', borderRadius: 5, display: 'flex', marginBottom: 25 }}>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    autoFocus
                    placeholder={placeholder}
                    inputProps={{ 'aria-label': 'Filtrar lista' }}
                    onChange={(e) => { setSearch(e.target.value) }}
                />

                <IconButton style={{ marginLeft: -40 }} align='center' type="submit" aria-label="search">
                    {load ?
                        <LoadingShort />
                        :
                        <SearchOutlinedIcon />
                    }
                </IconButton>

                <TextField
                    style={{ width: 200 }}
                    type="text"
                    placeholder="Categoria"
                    select
                    size="small"
                    variant="outlined"
                    onChange={(e) => { functionFilter(e.target.value === 'Geral' ? '' : e.target.value) }}
                    defaultValue={'Geral'}
                >
                    <MenuItem key='geral' value='Geral'>
                        Geral
                    </MenuItem>
                    {classeEstabelecimentos?.map((classeEstabelecimento) => (
                        <MenuItem key={classeEstabelecimento._id} value={classeEstabelecimento._id}>
                            {classeEstabelecimento.designacao}
                        </MenuItem>
                    ))}
                </TextField>
            </form>
        </>
    );
}

export default SearchEstabelecimento;
