import * as React from 'react';
import FichaEstabelecimento from './fichaEstabelecimento'
import DocumentosAnexados from './documentosAnexados'
import { useParams } from 'react-router-dom'
import FichaCadastramento from './fichaCadastramento'
import QRCodeEstabelecimento from './qrCodeEstabelecimento'
import FichaEmpresa from '../gestEmpresas/fichaEmpresa'
import FichaDirectorTecnico from '../gestDirectorTecnico/fichaDirectorTecnico'
import { Card } from '@mui/material'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


export default function Combine({ estabelecimento }) {
    const { id } = useParams()
    const baseUrl = window.location.origin;
    // console.log(`${baseUrl}/verifica-estabelecimento/${id}`);

    const [openFichaCadastramento, setOpenFichaCadastramento] = React.useState(false);
    const [openQRCodeEstabelecimento, setOpenQRCodeEstabelecimento] = React.useState(false);

    return (
        <>
            <FichaEstabelecimento estabelecimento={estabelecimento} />
            <FichaDirectorTecnico header={true} directorTecnico={estabelecimento?.directorTecnico} />
            <FichaEmpresa header={true} empresa={estabelecimento?.empresa} local={'analiseSolicitacao'}/>
            <DocumentosAnexados estabelecimento={estabelecimento} docs={estabelecimento?.docs} />

            <List
                sx={{ width: '100%', minWidth: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={() => setOpenFichaCadastramento(!openFichaCadastramento)}>
                    <ListItemIcon>
                        {/* <ReceiptIcon /> */}
                    </ListItemIcon>
                    <ListItemText primary="FICHA DE CADASTRAMENTO" />
                    {openFichaCadastramento ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openFichaCadastramento} timeout="auto" unmountOnExit>
                    <Card style={{ padding: 20 }}>
                        <FichaCadastramento estabelecimento={estabelecimento} />
                    </Card>
                </Collapse>

                <ListItemButton onClick={() => setOpenQRCodeEstabelecimento(!openQRCodeEstabelecimento)}>
                    <ListItemIcon>
                        {/* <ReceiptIcon /> */}
                    </ListItemIcon>
                    <ListItemText primary="CÓDIGO QR DO ESTABELECIMENTO" />
                    {openQRCodeEstabelecimento ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openQRCodeEstabelecimento} timeout="auto" unmountOnExit>
                    <Card style={{ marginTop: 20, padding: 20 }}>
                        <QRCodeEstabelecimento header={true} link={`${baseUrl}/verifica-estabelecimento/${id}`} estabelecimento={estabelecimento} />
                    </Card>
                </Collapse>
            </List>
        </>
    )
}
