import { Container, Grid, Box } from '@mui/material'
import ListaOpcoes from '../../../../utils/listaOpcoes'
import HeaderSession from '../../../../utils/headerSession'

export default function Desalfandegar() {
    return (
        <Container style={{ minHeight: '70vh' }}>
            <Box sx={{ display: { md: 'none' } }}>
                <HeaderSession title='DESALFANDEGAR' />
            </Box>

            <Grid container>
                <Grid margin={1} xs={12} md={5.7} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ border: '2px solid #85287e', padding: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/img/icones/desalfandegar.svg" alt="" width={100} />
                        <p style={{ fontSize: 14 }}>
                            DESALFANDEGAR
                        </p>
                    </div>
                </Grid>
                <Grid margin={1} xs={12} md={5.7} item >

                    <Box width={'100%'} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <HeaderSession title='DESALFANDEGAR' />
                    </Box>

                    <ListaOpcoes nomeOpcao='Medicamentos e outros produtos farmacêuticos' link='/desalfandegar-medicamentos-outros-produto-farmaceuticos' />
                    <ListaOpcoes nomeOpcao='Equipamentos' link='/desalfandegar-equipamentos' />
                    <ListaOpcoes nomeOpcao='Medicamentos recepcionados' link='/desalfandegar-medicamentos-recepcionados' />
                </Grid>

            </Grid>
            <br />
        </Container>
    )
}
