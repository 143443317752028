import { useEffect, useState } from "react";
import api from "../../services/api";
import Loading from "../../load/loading";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DateHora from "../../utils/dateHora";
import FerramentaNavegacaoExportacao from "../../utils/ferramentaNavegacaoExportacao";
import HeaderSession from "../../utils/headerSession";
import RegisterAccess from "../../utils/registerAccess";

export default function WebAccess() {
    const [webAccesses, setWebAccesses] = useState('')
    const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState('')

    useEffect(() => {
        api.get('/accesses?perPage=1000')
            .then(response => {
                // console.log(response)
                setWebAccesses(response.data.accesses)
                setConfigLista(response.data)
            }).catch(err => console.log(err))
    },[page])


    return (
        <Container>
            <RegisterAccess page={'ver accesses'}/>
            <HeaderSession title={'ACCESSES'} />

            {webAccesses ?
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell> <strong>Usuário</strong></TableCell>
                                <TableCell> <strong>Pagina</strong></TableCell>
                                <TableCell> <strong>Pais</strong></TableCell>
                                <TableCell> <strong>Província</strong></TableCell>
                                <TableCell> <strong>Cidade</strong></TableCell>
                                <TableCell> <strong> Data</strong></TableCell>
                                <TableCell> <strong> Dispositivo</strong></TableCell>
                                <TableCell> <strong> Resumido</strong></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {webAccesses.map((webAccess) => (
                                <TableRow key={webAccess?._id} >
                                    <TableCell  ><Typography > {webAccess?.user?.dadosPessoais?.nome ?? 'Anônimo'}</Typography></TableCell>
                                    <TableCell  ><Typography >{webAccess?.page}</Typography></TableCell>
                                    <TableCell  ><Typography >{webAccess?.country}</Typography></TableCell>
                                    <TableCell  ><Typography >{webAccess?.state}</Typography></TableCell>
                                    <TableCell  ><Typography >{webAccess?.city}</Typography></TableCell>
                                    <TableCell  ><Typography ><DateHora date={webAccess?.createdAt} /></Typography></TableCell>
                                    <TableCell  ><Typography noWrap >{webAccess?.device}</Typography></TableCell>
                                    <TableCell  ><Typography noWrap >{webAccess?.formatted}</Typography></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <FerramentaNavegacaoExportacao 
                        configLista={configLista}
                        lista={webAccesses}
                        setPage={setPage}
                        page={page}
                    />
                </TableContainer>
                :
                < Loading />
            }
        </Container>
    )
}
