import { useParams, useNavigate } from 'react-router-dom'
import { Card, DialogActions, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import api from '../../../../../services/api';
import { useSelector } from 'react-redux';
import MessageAlert from "../../../../../messages/messageAlert";
import LoadingBackdrop from '../../../../../load/loadingBackdrop';
import FazerObservacoes from '../../fazerObservacoes.';
// import { validacaoInspeccao } from '../../../../../validacoes'; 
import AutoVistoriaConjunta from '../avc/autoVistoriaConjunta';
import AutoVistoriaParaFarmaciaDeposito from './autoVistoriaParaFarmaciaDeposito';
import RegisterAccess from '../../../../../utils/registerAccess';

export default function InspeccionarEstabelecimento() {
    const navigate = useNavigate()
    const { id } = useParams()
    const inspeccao = useSelector(state => state.inspeccao.data)
    const [inspeccionar, setInspeccionar] = useState({})
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const user = useSelector(state => state.account.user);
    const [load, setLoad] = useState(false)
    const [obs, setObs] = useState('')

    // console.log(inspeccionar)

    useEffect(() => {
        // if (!inspeccao) { navigate(-1) }
        if (!inspeccao) {
            navigate(-1)
        } else {

            setLoad('A iniciar inspecção... Aguarde')
            api.patch('/inspeccao/start', { 'inspeccaoId': inspeccao._id, 'userId': user._id }

            ).then(res => {
                setLoad('')
                // console.log(res)

            }).catch(error => {
                // console.log(error)
                setLoad('')
                setMessageError(error.response.data.message)
            })
        }
    }, [navigate, inspeccao, user])


    function HandleChange(e) {
        e.target.files ?
            setInspeccionar((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }))
            :
            setInspeccionar((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const inspeccaoClose = () => {
        // console.log(inspeccionar)
        //  console.log(inspeccao._id,)

        setMessageSuccess('')
        setMessageError('')
        setLoad('A salvar inspecção... Aguarde')

        api.post('/inspeccao/close', {
            ...inspeccionar,
            'inspeccaoId': inspeccao._id,
            'userId': user._id,
            artigo: '37',
            decretoPresidencial: '193/17',
            approved: true,

        }).then(response => {
            // console.log(response)

            api.patch('/estabelecimento/change-status', { estabelecimentoId: id, 'status': 'Inspecção Realizada', userId: user._id })
                .then(res => {
                    obs && HandleCreateobservacao()
                }).catch(err => { '' })

            setLoad(false)
            setMessageSuccess(response.data.msg)

            window.setTimeout(() => {
                // navigate(-1)

                if (user?.funcao === 'Inspector') {
                    navigate(`/admin/minhas-inspeccoes/`)
                } else {
                    navigate(`/admin/solicitacoes-de-licenciamento`)
                }

            }, 3000);

        }).catch(err => {
            // console.log(err)
            setLoad(false)
            setMessageError(err.response.data.message)
        })
    }

    async function HandleCreateobservacao() {

        await api.post('/observacao/create', {
            assunto: 'Inspecção',
            descricao: obs,
            userId: user._id,
            estabelecimentoId: id
        }).then(res => {
            // console.log(res)

        }).catch(error => {
            setMessageError(error.response.data.message)
        })
    }


    const validate = async e => {
        setMessageError('');
        if (!(await valida())) return
    };

    async function valida() {
        try {
            // await validacaoInspeccao(inspeccionar)
            inspeccaoClose()
        }
        catch (error) {
            setMessageError(error);
        }
    }

    return (
        <>
            <RegisterAccess page={'inspeccionar estabelecimento'} />
            <LoadingBackdrop open={load} text={load} />
            {messageSuccess && <MessageAlert message={messageSuccess} type={'success'} />}
            {messageError && <MessageAlert message={messageError} type={'error'} />}

            <Card style={{ padding: 30 }}>
                <AutoVistoriaParaFarmaciaDeposito
                    inspeccao={inspeccao}
                    setInspeccionar={setInspeccionar}
                />
            </Card>

            <Card style={{ padding: 30, marginTop: 20 }}>
                <AutoVistoriaConjunta
                    HandleChange={HandleChange}
                    inspeccao={inspeccionar}
                    setInspeccionar={setInspeccionar}
                />
            </Card>

            <Card style={{ padding: 30, marginTop: 20 }}>
                <FazerObservacoes setObs={setObs} obs={obs} titulo={'(caso seja necessário fazer alguma observação)'} />

                <DialogActions>
                    {/* <Button color="error" onClick={() => navigate(-1)} >Cancelar</Button> */}
                    <Button onClick={validate} style={{ background: '#85287e' }} variant="contained">Salvar Inspecção</Button>
                </DialogActions>
            </Card>
        </>
    );
}
