
import { useEffect, useState } from "react";
import { Card, makeStyles } from "@material-ui/core";
import api from "../../../../services/api";
import HeaderSession from "../../../../utils/headerSession";
import Loading from "../../../../load/loading";
// import SearshEstabelecimento from "../../../../utils/searchEstabelecimento";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Avatar, MenuItem, TextField, Typography } from "@mui/material";
import StatusEstabelecimento from "../../../../utils/statusEstabelecimento";
import DateHora from "../../../../utils/dateHora";
import ListaVazia from "../../../../utils/listaVazia";
import FerramentaNavegacaoExportacao from "../../../../utils/ferramentaNavegacaoExportacao";
import Tooltip from '@mui/material/Tooltip';
import RegisterAccess from "../../../../utils/registerAccess";


const useStyles = makeStyles((theme) => ({
  hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' }
}))


export default function ListaSolicitacaoRenovacao() {
  const classes = useStyles()
  const navigate = useNavigate()
  // const user = useSelector(state => state.account.user);
  // const [filter, setFilter] = useState('')
  const [solicitacoesRenovacao, setsolicitacoesRenovacao] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [order, setOrder] = useState('')
  const [loading, setLoading] = useState(false);
  const [configLista, setConfigLista] = useState('')
  const [status, setStatus] = useState('Análise')

  useEffect(() => {

    // if (user?.funcao === 'Chefe de Secção' || user?.funcao === 'Director Geral' || user?.funcao === 'CNP') {
    // } else {
    //   dispatch({
    //     type: 'alert',
    //     payload: { alert: 'Acesso negado' },
    //   });
    //   navigate('/admin')
    // }
    
    setsolicitacoesRenovacao('')
    setLoading(true)
    api.get(`/solicitacao-renovacaos?status=${status}&page=${page}&perPage=${perPage}`)
      .then(response => {
        // console.log(response)
        setConfigLista(response.data);
        setsolicitacoesRenovacao((prevItems) => [...prevItems, ...response.data.solicitacaoRenovacaos]);
        // setFilter(response.data.solicitacaoRenovacaos);

        setLoading(false)
      }).catch(err => { setLoading(false) })

  }, [page, perPage, status, order]);


  const Notfound = () => {
    return (
      <Tooltip title="Necessário inserir este Estabelecimento no Sistema">
        <span style={{ color: 'red' }} > Estabelecimento não encontrado</span>
      </Tooltip>
    );
  };

  return (
    <>
      <RegisterAccess page={'lista de solicitaçōes de renovação'} />
      <HeaderSession title='PROCESSOS ANÁLIZADOS POR TECNICOS' />

      <Card style={{ padding: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <TextField
            style={{ width: 200 }}
            type="text"
            label="Estado"
            select
            size="small"
            variant="outlined"
            onChange={(e) => { setStatus(e.target.value) }}
            defaultValue={'Análise'}
          >
            <MenuItem key='Análise' value='Análise'> Análise </MenuItem>
            <MenuItem key='Reprovado' value='Reprovado'> Reprovado </MenuItem>
            <MenuItem key='Aprovado' value='Aprovado'> Aprovado </MenuItem>

          </TextField>
        </div>

        {solicitacoesRenovacao &&
          <Card variant="outlined">

            <TableContainer >
              <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                <TableHead >
                  <TableRow>
                    <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Tipo</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Solicitação de renovação</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Solicitante</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Vinculo</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Data</strong></Typography></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {solicitacoesRenovacao.map((solicitacao) => (
                    <TableRow key={solicitacao?._id} className={classes.hover} onClick={() => navigate(`/admin/gestor-de-renovacao/${solicitacao?._id}`)} title={solicitacao?.nome}>

                      <TableCell component="th" scope="row">
                        <Avatar variant="square" src={solicitacao?.estabelecimento?.logo} alt={solicitacao?.estabelecimento?.nome ?? '?'} />
                      </TableCell>

                      <TableCell >{solicitacao?.estabelecimento?.nome ?? <Notfound />}</TableCell>
                      <TableCell >{solicitacao?.estabelecimento?.tipo?.designacao}</TableCell>
                      <TableCell >{solicitacao?.numero}</TableCell>
                      <TableCell >{solicitacao?.nome}</TableCell>
                      <TableCell >{solicitacao?.ligacaoEstabelecimento}</TableCell>
                      <TableCell >  <StatusEstabelecimento status={solicitacao?.status} /></TableCell>
                      <TableCell ><DateHora date={solicitacao.updatedAt} /> </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {solicitacoesRenovacao.length < 1 && <ListaVazia text={'DE MOMENTO NÃO HÁ SOLICITACÕES PARA O SEU PERFIL INTERVIR'} />}
            </TableContainer>

            <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={solicitacoesRenovacao}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel} 
            />
          </Card>
        }

        {loading && <Loading />}
      </Card>
    </>
  )
}
