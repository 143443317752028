
import { Typography } from "@mui/material";

export default function StatusEstabelecimento({ status }) {

    return (
        <>
            {status === 'Activo' &&
                <Typography noWrap style={{borderRadius:5, background: '#7ebf42', color: '#fff', padding: 5 }} align="center" variant="subtitle2">
                   AUTORIZADO
                </Typography>
            }

            {status === 'Inactivo' &&
                <Typography noWrap style={{borderRadius:5,  background: 'red', color: '#fff', padding: 5 }} align="center" variant="subtitle2">
                    NÃO AUTORIZADO
                </Typography>
            }

            {status === 'Aprovado' &&
                <Typography noWrap style={{borderRadius:5, background: '#7ebf42', color: '#fff', padding: 5 }} align="center" variant="subtitle2">
                   Aprovado
                </Typography>
            }


            {status === 'Reprovado' &&
                <Typography noWrap style={{borderRadius:5,  background: 'red', color: '#fff', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Suspenso' &&
                <Typography noWrap style={{borderRadius:5,  background: 'red', color: '#fff', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Análise' &&
                <Typography noWrap style={{borderRadius:5,  background: '#fce056', color: '#000', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Analisado' &&
                <Typography noWrap style={{borderRadius:5,  background: '#fce056', color: '#000', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Analisado Chefe de Secção' &&
                <Typography noWrap style={{borderRadius:5,  background: '#fce056', color: '#000', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Inspecção Agendada' &&
                <Typography noWrap style={{borderRadius:5,  background: '#fce056', color: '#000', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Inspecção Realizada' &&
                <Typography noWrap style={{borderRadius:5,  background: '#fce056', color: '#000', padding: 5 }} align="center" variant="subtitle2">
                    {status}
                </Typography>
            }

            {status === 'Deferimento' &&
                <Typography noWrap style={{borderRadius:5,  background: '#fce056', color: '#000', padding: 5 }} align="center" variant="subtitle2">
                    {/* {status} */}
                    Aguardando emissão de autorização de exerciçio
                </Typography>
            }
        </>
    )
}