
import HeaderSession from '../../utils/headerSession';
import RegisterAccess from '../../utils/registerAccess';
import MenuControlGeral from './menuControlGeral';

export default function Dashboard() {

    return (
        <>
            <RegisterAccess page={'dashboard'} />
            {/* <h1> Dashboard</h1> */}
            <HeaderSession title={'DASHBOARD'}/>

            <br />

            <MenuControlGeral />

            {/* <iframe title="estudo armed" width="100%" height="95%" src="https://app.powerbi.com/view?r=eyJrIjoiNTJmYjc4NWYtYmNkYy00OGMwLWFiNmYtYTc3NzMzMzFkMjI3IiwidCI6ImFlZmVhNzQ5LTkxOTQtNGJiMS1hMjBkLTkyN2EzZDk0YTU1MiJ9" frameborder="0" allowFullScreen="true"></iframe> */}
            <iframe title="dashboard ARMED" width="100%" height="80%" src="https://app.powerbi.com/view?r=eyJrIjoiOWU3ZDAzOWYtMWI1NS00NjQ3LWI2MmEtYWI2MDJhZGY2ZTE0IiwidCI6ImFlZmVhNzQ5LTkxOTQtNGJiMS1hMjBkLTkyN2EzZDk0YTU1MiJ9" frameborder="0" allowFullScreen="true"></iframe>
        </>
    )
}
