import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

function Editor({ setEditorData, editorData, }) {
    // console.log(editorData)

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData((prev) => ({ ...prev, conteudo: data }));
        // setEditorData({ ...editorData, 'descricao': data })
    };

    function handlePrint() {
        // const editorData = editor.getData(); // Supondo que "editor" seja a instância do CKEditor
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write('<div id="print-content">' + editorData + '</div>');
        printWindow.document.write('</body></html>');

        printWindow.document.getElementById('print-content').style.display = 'block';
        printWindow.print();
        printWindow.close();
    }


    return (
        <div>
            {/* <button onClick={handlePrint}>print</button> */}
            <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
            />
            {/* <h2>Conteúdo do Editor:</h2>
            <div dangerouslySetInnerHTML={{ __html: editorData }} /> */}
        </div>
    );
}

export default Editor;


