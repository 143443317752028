
import * as React from 'react';
import { Typography } from '@mui/material';

export default function TextoFormatado({ texto }) {
    const textFormatado = texto.split('\n')

    return (
        textFormatado.map((textFormatado, index) =>
            <Typography key={index} variant="body1" style={{marginTop: 10, lineHeight: 1.5,  }}>
                {textFormatado}
            </Typography>
        )
    )
}


