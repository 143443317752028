import { useState } from "react";
import { InputBase, IconButton } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LoadingShort from "../../load/loadingShort";
// import TextField from '@material-ui/core/TextField';
// import {MenuItem } from '@mui/material';
// import MessageError from "../messages/messageError";
import api from "../../services/api";

const SearchRepresentante = ({ setRepresentante, setPesquisaError }) => {
    // const [messageError, setMessageError] = useState('');
    const [load, setLoad] = useState(false);
    const [search, setSearch] = useState(false);


    const searchRepresentante = async (e) => {
        e.preventDefault()
        setLoad(true)
        setPesquisaError(null)
        setRepresentante([])

        try {
            const response = await api.post(`/representante/search`, { search });
            setLoad(false)
            setRepresentante(response.data.representantes)
            // console.log(response)

        } catch (error) {
            // console.log(error)
            setPesquisaError(error.response.data.message);
            setLoad(false)
        }
    }

    return (
        <form onSubmit={searchRepresentante} style={{ border: '1px solid #85287e', borderRadius: 5, display: 'flex', marginBottom: 25 }}>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                autoFocus
                placeholder="Pequisa pelo B.I, telefone, email.."
                inputProps={{ 'aria-label': 'Filtrar lista' }}
                onChange={(e) => { setSearch(e.target.value) }}
            />

            <IconButton style={{ marginLeft: -40 }} align='center' type="submit" aria-label="search">
                {load ?
                    <LoadingShort />
                    :
                    <SearchOutlinedIcon />
                }
            </IconButton>
        </form>
    );
}

export default SearchRepresentante;
