import { Card, Grid, Typography } from "@mui/material";
import Loading from "../../load/loading";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
import FichaRepresentante from "../gestRepresentantes/fichaRepresentante";
import MessageSuccess from "../../messages/messageSuccess";
import MessageError from "../../messages/messageError";
import EmpresaEdit from "../dadosGenericos/empresaEdit";
import { useState } from "react";
import ImagemEdit from "../../utils/imagemEdit";
import Delete from "../../utils/delete";
import ChangeRepresentante from "./changeRepresentante";

export default function FichaEmpresa({ empresa, header, setRefresh, local }) {
    const [editDados, setEditDados] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [dadosEmpresa, setDadosEmpresa] = useState(empresa)
    const [openEditLogo, setOpenEditLogo] = useState(false);
    const [open, setOpen] = useState(false);
    const [changeRepresentante, setChangeRepresentante] = useState(false);
    // console.log(empresa)

    return (
        <>
            {empresa ?
                <>
                    <Card style={{ padding: 20, minHeight: '20vh', marginBottom: 20, marginTop: 20 }}>
                        {messageSuccess && <MessageSuccess message={messageSuccess} />}
                        {messageError && <MessageError message={messageError} />}

                        <Delete
                            open={open}
                            setOpen={setOpen}
                            url={'/empresa/delete'}
                            msg={`Tem certeza que deseja eliminar Empresa ${empresa?.nome}`}
                        />

                        <ChangeRepresentante
                            empresa={empresa}
                            open={changeRepresentante}
                            setOpen={setChangeRepresentante}
                        />

                        {header &&
                            <HeaderGestorConfigs
                                menu={true}
                                title={editDados ? 'EDITAR DADOS' : 'FICHA DA EMPRESA'}
                                configArea={'dadosempresa'}
                                empresa={empresa}
                                editDados={editDados}
                                setEditDados={setEditDados}
                                setChangeRepresentante={setChangeRepresentante}
                                delete={setOpen}
                            />
                        }

                        {!editDados ?
                            <Grid container>
                                <Grid item marginRight={2}>
                                    <ImagemEdit
                                        data={empresa}
                                        url={'/empresa/change-logo'}
                                        local={'empresaId'}
                                        openEditLogo={openEditLogo}
                                        setOpenEditLogo={setOpenEditLogo}
                                    />
                                </Grid>

                                <Grid xs item>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Nome:</strong> {empresa?.nome ?? '####'}</Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>NIF:</strong> {empresa?.nif ?? '####'}</Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Representante:</strong> {empresa?.representante?.dadosPessoais?.nome ?? '####'}</Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>E-mail:</strong> {empresa?.email ?? '####'} </Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Telefone1:</strong> {empresa?.tel1 ?? '####'} </Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Telefone2:</strong> {empresa?.tel2 ?? '####'} </Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Bairro:</strong> {empresa?.bairro ?? '####'} </Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Rua:</strong> {empresa?.rua ?? '####'} </Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Município:</strong> {dadosEmpresa?.municipio ?? '####'} </Typography>
                                    <Typography style={{ lineHeight: 1.5 }} variant="subtitle1"><strong>Provincia:</strong> {dadosEmpresa?.provincia ?? '####'} </Typography>
                                </Grid>
                            </Grid>
                            :
                            <EmpresaEdit
                                dadosEmpresa={empresa}
                                setDadosEmpresa={setDadosEmpresa}
                                cancelEditDados={setEditDados}
                                setMessageSuccess={setMessageSuccess}
                                setMessageError={setMessageError}
                                setRefresh={setRefresh}
                            />
                        }
                    </Card>

                    {/* EXIBIR FICHA DO REPRESENTANTE JUNTAMENTE COM A FICHA DA EMPRESA */}
                    {(local === 'gestEmpresa' || local === 'analiseSolicitacao') &&
                        <FichaRepresentante header={header} representante={empresa?.representante} setRefresh={setRefresh} />
                    }
                </>
                :
                <Loading />
            }
        </>
    )
}