
import { Typography } from "@mui/material";

export default function StatusEstabelecimentoBusca({ status }) {

    return (
        <>
            {status === 'Activo' &&
                <Typography style={{ padding: 10, background: '#7ebf42', color: '#fff', fontSize: 30, lineHeight: 1.5 }} align="center" variant="body1">
                    AUTORIZADO
                </Typography>
            }

            {status === 'Inactivo' &&
                <Typography style={{ padding: 10, background: 'red', color: '#fff', fontSize: 30, lineHeight: 1.5 }} align="center" variant="body1">
                    NÃO AUTORIZADO
                </Typography >
            }

            {status === 'Reprovado' &&
                <Typography noWrap style={{ borderRadius: 5, background: 'red', color: '#fff', padding: 10 }} align="center" variant="subtitle1">
                    {status}
                </Typography>
            }

            {status === 'Suspenso' &&
                <Typography noWrap style={{ borderRadius: 5, background: 'red', color: '#fff', padding: 10 }} align="center" variant="subtitle1">
                    {status}
                </Typography>
            }

            {status === 'Análise' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#fce056', color: '#000', padding: 10 }} align="center" variant="subtitle1">
                    Em análise
                </Typography>
            }

            {status === 'Analisado' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#fce056', color: '#000', padding: 10 }} align="center" variant="subtitle1">
                    Em análise 2
                </Typography>
            }

            {status === 'Analisado Chefe de Secção' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#fce056', color: '#000', padding: 10 }} align="center" variant="subtitle1">
                    Em análise 3
                </Typography>
            }

            {status === 'Inspecção Agendada' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#fce056', color: '#000', padding: 10 }} align="center" variant="subtitle1">
                    {status}
                </Typography>
            }

            {status === 'Inspecção Realizada' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#fce056', color: '#000', padding: 10 }} align="center" variant="subtitle1">
                    {status}
                </Typography>
            }

            {status === 'Deferimento' &&
                <Typography noWrap style={{ borderRadius: 5, background: '#fce056', color: '#000', padding: 10 }} align="center" variant="subtitle1">
                    {/* {status} */}
                    Aguardando emissão de autorização de exerciçio
                </Typography>
            }
        </>
    )
}