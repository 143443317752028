import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FichaEmpresa from "./fichaEmpresa";
import api from "../../services/api";
import EstabelecimentosAssociados from "./estabelecimentosAssociados";
import HistoricoActividadesByModel from "../departamentos/dlif/licenciamento/historicoActividadesByModel.jsx";


export default function GetEmpresa() {
    const { id } = useParams()
    const [empresa, setEmpresa] = useState('')
    const [refresh, setRefresh] = useState('')

    useEffect(() => {
        api.get(`/empresa/${id}`)
            .then(response => {
                // console.log(response)
                setEmpresa(response.data.empresa);
            }).catch(err => '')
    }, [id, refresh])


    return (
        <>
            <FichaEmpresa empresa={empresa} header={true} setRefresh={setRefresh} local={'gestEmpresa'} />

            {empresa &&
                <EstabelecimentosAssociados parametro={'empresaId'} />
            }

            <HistoricoActividadesByModel model={empresa} />
        </>
    )
}