import { useEffect, useState } from 'react';
import FichaEstabelecimento from '../gestLicenciados/fichaEstabelecimento';
import { useParams } from "react-router-dom";
import api from '../../services/api';
import Loading from '../../load/loading';
import { Typography, Card } from "@mui/material";

const EstabelecimentosAssociados = ({ parametro }) => {
    const { id } = useParams()
    const [estabelecimentos, setestabelecimentos] = useState('')

    useEffect(() => {
        api.get(`/estabelecimentos?${parametro}=${id}`)
            .then(response => {
                setestabelecimentos(response.data.estabelecimentos);
            }).catch(err => 'console.log(err)')
    }, [id])


    return (
        <>
            <Card style={{ padding: 20, marginBottom: 15, boxShadow: 'none' }}>
                <Typography style={{ fontSize: 20 }}>
                    ESTABELECIMENTOS ASSOCIADOS <strong>( {estabelecimentos.length}  )</strong>
                </Typography>
                <br />
                {estabelecimentos ?

                    estabelecimentos.map((estabelecimento) => (
                        <FichaEstabelecimento key={estabelecimento._id} estabelecimento={estabelecimento} />
                    ))
                    :
                    <Loading />
                }
            </Card>
        </>
    );
}

export default EstabelecimentosAssociados;
