// import { useState } from "react";
// import { useSelector } from 'react-redux';
// import CloseIcon from '@mui/icons-material/Close';
// import LoadingBackdrop from "../../load/loadingBackdrop";
// import MessageSuccess from "../../messages/messageSuccess";
// import MessageError from "../../messages/messageError";
// import api from "../../services/api";
import RegisterAccess from "../../utils/registerAccess";
import FichaAdmin from "./fichaAdmin";
// import { format, parseISO } from 'date-fns';


export default function InformacaoAdmin(props) {
    // const user = useSelector(state => state.account.user);
    // const [, setEditPassword] = useState()
    // const [messageSuccess, setMessageSuccess] = useState('')
    // const [messageError, setMessageError] = useState('')
    // const [openHandle, setOpenHandle] = useState(false);

    // const [historicosActividades, setHistoricosActividades] = useState()



    // useEffect(() => {
    //     // api.get(`/historico-actividades?cnp=false&userId=${props.user.dadosPessoais._id}`)
    //     api.get(`/historico-actividades?userId=${props.user._id}`)
    //         .then(response => {
    //             // console.log(response)
    //             setHistoricosActividades(response.data.historicoActividades)
    //             setSegunda(response.data.historicoActividades.filter((historicoActividades => parseISO(historicoActividades.createdAt))))

    //         }).catch(err => console.log(err))
    // }, [])

    // useEffect(() => {
    //     api.get(`/historico-actividades?userId=${props.user._id}`)
    //         .then(response => {
    //             // console.log(response)
    //             setHistoricosActividades(response.data.totalItems)

    //         }).catch(err => console.log(err))
    // }, [])

    return (
        <>
         <RegisterAccess page={'Informação de admin'}/>

            {/* <LoadingBackdrop open={openHandle} text={'Alterando senha... Aguadre.'} /> */}
            {/* {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />} */}

            {/*    
            <Card className={classes.card}>
                <Grid container className={classes.container}>
                    <Grid item style={{ width: 230, }}>
                        {!editPassword &&
                            <>
                                <img className={classes.foto} src={props.user?.dadosPessoais?.fotoURL} alt="" />
                                <div style={{ background: '#fcdd06', borderRadius: 10, padding: 5 }}>
                                    <Typography align="center" style={{ fontFamily: 'Work Sans', lineHeight: 1.5 }} variant="body1">
                                        <strong>Função</strong> <br />
                                        {props.user?.dadosPessoais?.perfil}
                                    </Typography>
                                </div>
                            </>
                        }

                        <div style={{ marginTop: 10 }}>
                            {!editPassword ?
                                <Button size="small" onClick={() => { setEditPassword(true) }} fullWidth>Editar Senha</Button>
                                :
                                <Card style={{ marginTop: 20, position: 'relative' }}>
                                    <DialogTitle>
                                        <Button color='error' title="Cancelar" style={{ position: 'absolute', right: 5, fontWeight: 'bold' }} onClick={() => setEditPassword(false)}> <CloseIcon /></Button>
                                    </DialogTitle>
                                    <Typography style={{ fontFamily: 'Work Sans', margin: 20, fontSize: 20, fontWeight: 'bold' }} align="center" variant="h1">
                                        Alterar senha
                                    </Typography>

                                    <div style={{ margin: 10 }}>
                                        <Grid container >
                                            <Grid item m>
                                                <TextField
                                                    type="password"
                                                    label="Senha Actual"
                                                    fullWidth
                                                    size="small"
                                                    name="oldPassword"
                                                    variant="outlined"
                                                    onChange={HandleChange}
                                                />
                                            </Grid>

                                            <Grid item m>
                                                <TextField
                                                    type="password"
                                                    label="Nova senha "
                                                    fullWidth
                                                    size="small"
                                                    name="newPassword"
                                                    variant="outlined"
                                                    onChange={HandleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div style={{ margin: 10 }}>
                                        <Button onClick={alterarPassword} fullWidth color="inherit" style={{ background: '#b1b7ea' }}>Salvar</Button>
                                    </div>
                                </Card>
                            }
                        </div>
                    </Grid> */}

            {/* <Grid item style={{ padding: 10 }}>
                        <FichaAdmin admin={props.admin} />
                    </Grid> */}

            {/* <Grid item style={{ padding: 10 }}>
                        <Typography align="center" style={{ fontFamily: 'Work Sans', lineHeight: 1.5 }} variant="subtitle1">
                            Pontuação
                        </Typography>
                        
                        {
                            historicosActividades &&
                            <GraficoMeuDePontuacao
                                NIntervensoes={historicosActividades}
                            />
                        }
                    </Grid>
                </Grid>

            </Card>
                     */}

            <br />
            <FichaAdmin admin={props.admin} local={'perfilAdmin'}/>
        </>
    )
}
