
import { useEffect, useState } from "react";
import { Avatar, Typography, makeStyles } from "@material-ui/core";
import { Divider, Grid, Card, Container, DialogActions } from "@mui/material";
import Loading from "../../../load/loading";
import Date from "../../../utils/date";
import api from "../../../services/api";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateBanner from "./createBanner";
import MessageSuccess from '../../../messages/messageSuccess';
import MessageError from "../../../messages/messageError";
import HeaderSession from "../../../utils/headerSession";
import HeaderGestorConfigs from "../../../utils/headerGestorConfigs";
import DeleteBanner from "./deleteBanner";
import { IconButton } from "@mui/material";
import RegisterAccess from "../../../utils/registerAccess";
import Slider from "../../../pages/home/slider/slider";
// import RegisterAccess from "../../../utils/registerAccess";

const useStyles = makeStyles({
    img: {
        borderRadius: 5,
        transition: '1s',
        '&:hover': { opacity: 0.7, }
    },

    root: {
        transition: '.3s',
        '&:hover': { width: 25 },
    },
})

export default function GestBanner() {
    const classes = useStyles()
    const [title, setTitle] = useState('Criação')
    // const [banners, setNoticias] = useState('')
    const [editeBanner, setEditarNoticia] = useState(false)
    const [createBanner, setCreateBanner] = useState(false)
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')
    const [forceUpdate, setForceUpdate] = useState('')
    const [banners, setBanners] = useState('')

    useEffect(() => {
        api.get(`/carousels`)
            .then(response => {
                // console.log(response)
                setBanners(response.data.carousels);
            }).catch(error => {
                // console.log(error)
            })
    }, [createBanner, message, messageError])

    const HandleEdit = (banner) => {
        setEditarNoticia(banner)
    }

    const GridItem = ({ banner }) => {
        return (
            <div key={banner._id}  >
                <RegisterAccess page={'banners'} />
                <Grid container >

                    <Grid item md={'auto'} >
                        <Avatar src={banner.imagem} style={{ width: 300, height: 150 }} variant="square" className={classes.img} />
                    </Grid>

                    <Grid item md={8} m style={{ position: 'relative' }}>
                        {banner?.titulo &&
                            <Typography variant="subtitle1" style={{ lineHeight: 1, fontSize: 16, marginBottom: 5 }}>
                                <strong>{banner.titulo} </strong>
                            </Typography>
                        }
                        {banner?.descricao &&
                            <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: 15, marginBottom: 5, maxWidth: '70vw' }}>
                                <br />
                                {banner.descricao}
                            </Typography>
                        }

                        <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: 10 }}>
                            Data: <Date date={banner.createdAt} />
                            <br />
                            criador:
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 0, top: 0 }}>
                            {banner.status === "Inactivo" &&
                                <small style={{ color: 'red' }}> Não publicado</small>
                            }
                            <HeaderGestorConfigs
                                menu={false}
                                configArea={'banner'}
                                title={''}
                                banner={banner}
                                HandleEdit={HandleEdit}
                            />
                            <DeleteBanner
                                bannerId={banner._id}
                                setMessageSuccess={setMessage}
                                setMessageError={seterrorMessage}
                                setForceUpdate={setForceUpdate}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Divider />
                <br />
            </div>
        );
    };

    return (
        <Container>
            <HeaderSession title={'GESTÃO DO BANNER'} />

            {message && <MessageSuccess message={message} />}
            {messageError && < MessageError message={messageError} />}

            <Card style={{ minHeight: '95vh', padding: 20 }}>

                {/* COMPONENTE DE CRIACAO DE NOTICIAS  */}
                {createBanner &&
                    <CreateBanner
                        title={title}
                        setTitle={setTitle}
                        setCreateBanner={setCreateBanner}
                        setMessage={setMessage}
                        seterrorMessage={seterrorMessage}
                    />
                }

                {(banners.length > 0 && !createBanner) &&
                    <>
                        <div >
                            <Slider forceUpdate={forceUpdate} />

                            <DialogActions>
                                ADICIONAR NOVO SLIDER
                                <IconButton onClick={() => { setCreateBanner(true) }}>
                                    <AddCircleOutlineIcon style={{ fontSize: 30 }} color="secondary" />
                                </IconButton>
                            </DialogActions>
                        </div>

                        <Divider />
                        <br />

                        <Grid m xs={12} md item >
                            {banners.map((banner) => (
                                <GridItem key={banner._id} banner={banner} />
                            ))}
                        </Grid>
                    </>
                }

                {!banners &&
                    <DialogActions>
                        ADICIONAR SLIDER
                        <IconButton onClick={() => { setCreateBanner(true) }}>
                            <AddCircleOutlineIcon style={{ fontSize: 30 }} color="secondary" />
                        </IconButton>
                    </DialogActions>
                }

                {!banners && <Loading text={'A carregar carrousel'} />}
            </Card>

        </Container>
    )
}