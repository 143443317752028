import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../../../utils/headerSession'
import { Link } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        // textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 50,
        // textAlign: 'center',
        color: '#67348d'
    },
}))

export default function InfoImportadores() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
            <div>
                <HeaderSession title='REQUISITOS ADMINISTRATIVOS PARA ABERTURA DE DEPOSITO DE MEDICAMENTOS/EQUIPAMENTOS' />


                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde incluindo o NIF ( <Link to={'/_REQUERIMENTO-PARA-LICENCIAMENTO'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Cópia colorida do documento de identificação do requerente.
                        </li>
                        <li>
                            Escritura de Constituição de Sociedade (para empresas).
                        </li>
                        <li>
                            Planta com quotas das instalações, acompanhado com croquis de Localização.
                        </li>
                        <li>
                            Termo de Responsabilidade do Director Técnico autenticado e a Declaração original  da OFA.
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                    </ol>
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos Técnicos para Abertura de Deposito de Medicamentos/Equipamentos
                </h1>

                <p className={classes.root} >
                    A fachada principal deve obedecer o seguinte:
                </p>
                <p className={classes.root} >
                    a) Letreiro com a designação do depósito de medicamentos, colocado em paralelo ou perpendicular ao edifício ou na parede, sem imagens de medicamentos ou outros produtos de saúde e não deve ser em lona;
                </p>
                <p className={classes.root} >
                    b) As paredes e/ou os vidros não devem conter imagens ou gravuras ou materiais publicitários;
                </p>
                <p className={classes.root} >
                    c) Possuir condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    d) Rampa de acesso com elevação ou ângulo máximo de 10º para pessoal com deficiência de locomoção;
                </p>

                <br />
                <p className={classes.root} >
                    <strong>2. A sala de atendimento ao público deve obedecer o seguinte:</strong>
                </p>
                <p className={classes.root} >
                    a) Área com superfície com mínimo de 35m2;
                </p>
                <p className={classes.root} >
                    b) Pavimento ladrilhado;
                </p>
                <p className={classes.root} >
                    c) Tecto falso ou placa de betão;
                </p>
                <p className={classes.root} >
                    d) Balcão para o atendimento ao público;
                </p>
                <p className={classes.root} >
                    e) Climatização com AC;
                </p>
                <p className={classes.root} >
                    f) Iluminação adequada;
                </p>
                <p className={classes.root} >
                    g) Mobiliário / cadeiras (material não plástico);
                </p>
                <p className={classes.root} >
                    h) Possuir condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    i) Medidas de combate às pragas;
                </p>
                <p className={classes.root} >
                    j) Medidas de prevenção contra incêndios (extintores de incêndio).
                </p>

                <br />
                <p className={classes.root} >
                    <strong>3. O armazém deve obedecer o seguinte:</strong>
                </p>
                <p className={classes.root} >
                    a) Área com superfície igual ou superior a 300m2;
                </p>
                <p className={classes.root} >
                    b) Pavimento ladrilhado ou pintado com epoxy;
                </p>
                <p className={classes.root} >
                    c) Tecto falso ou placa de betão;
                </p>
                <p className={classes.root} >
                    d) Estantes / prateleiras adequadas para o stock de grandes cargas;
                </p>
                <p className={classes.root} >
                    e) Climatização com AC;
                </p>
                <p className={classes.root} >
                    f) Iluminação adequada;
                </p>
                <p className={classes.root} >
                    g) Possuir condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    h) Medidas de combate às pragas;
                </p>
                <p className={classes.root} >
                    i) Medidas de prevenção contra incêndios (extintores de incêndio);
                </p>
                <p className={classes.root} >
                    j) Termohigrómetros;
                </p>
                <p className={classes.root} >
                    k) Cadeiras (em material não plásticos);
                </p>
                <p className={classes.root} >
                    l) Os armazéns com mais de um andar, devem possuir meios adequados para transportação de carga (rampa/elevador de carga);
                </p>
                <p className={classes.root} >
                    m) Zona de quarentena com superfície mínimo de 15m2;
                </p>
                <p className={classes.root} >
                    n) Sala de produtos sujeitos ao controlo especial com superfície mínima de 15m2;
                </p>
                <p className={classes.root} >
                    o) Câmara frigorifica com termóstato;
                </p>

                <br />
                <p className={classes.root} >
                    <strong>4. A sala de pessoal deve obedecer o seguinte:</strong>
                </p>
                <p className={classes.root} >
                    a) Cacifo de acordo o nº de funcionários;
                </p>
                <p className={classes.root} >
                    b) Mesa com cadeiras;
                </p>
                <p className={classes.root} >
                    c) Climatização com AC e iluminação adequada;
                </p>
                <p className={classes.root} >
                    d) Tecto falso ou placa de betão;
                </p>
                <p className={classes.root} >
                    e) Pavimento ladrilhado.
                </p>

                <br />
                <p className={classes.root} >
                    <strong>5. Instalações sanitárias (WC):</strong>
                </p>
                <p className={classes.root} >
                    a) Condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    b) Sanita e lavatório;
                </p>
                <p className={classes.root} >
                    c) Pavimento ladrilhado.
                </p>

                <br />
                <p className={classes.root} >
                    <strong>6. Gabinete do Director Técnico:</strong>
                </p>
                <p className={classes.root} >
                    a) Climatização com AC;
                </p>
                <p className={classes.root} >
                    b) Iluminação adequada;
                </p>
                <p className={classes.root} >
                    c) Mobiliário de escritório (em material não plástico);
                </p>
                <p className={classes.root} >
                    d) Armário com chaves para produtos controlados (quando aplicável);
                </p>
                <p className={classes.root} >
                    e) Livro de registo de produtos controlados (quando aplicável);
                </p>
                <p className={classes.root} >
                    f) Literatura técnica;
                </p>
                <p className={classes.root} >
                    g) Pavimento ladrilhado;
                </p>
                <p className={classes.root} >
                    h) Tecto falso ou placa de betão.
                </p>
                <br />
                <p style={{ fontSize: 20 }} >
                    <strong>Obs.:</strong> O não cumprimento dos requisitos técnicos acima, condicionará o licenciamento do estabelecimento. Para mais esclarecimentos, dirigir-se ao balcão de atendimento.
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos para a Renovação da Autorização de Exercício da Actividade Farmacêutica de Depósitos de Medicamentos
                </h1>
                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde ( <Link to={'/_REQUERIMENTO-PARA-LICENCIAMENTO'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Cópia colorida do documento de identificação do requerente.
                        </li>
                        <li>
                            Declaração original e actualizada  da OFA.
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                    </ol>
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos para Mudança de Denominação de Depósito de Medicamentos/Equipamentos (Sendo o mesmo proprietário)
                </h1>
                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde  ( <Link to={'/_REQUERIMENTO-PARA-LICENCIAMENTO'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Termo de responsabilidade do Director técnico.
                        </li>
                        <li>
                            Comprovativo de pagamento de emolumentos
                        </li>
                        <strong>
                            N.B.: O estabelecimento não requer nova vistoria.
                        </strong>
                    </ol>
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos para Mudança de Denominação de Depósito de Medicamentos/Equipamentos (Novo Proprietário)
                </h1>
                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento a solicitar a mudança de Nome  dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde incluindo o NIF(<a href="/#">Ver modelo</a>).
                        </li>
                        <li>
                            Trespasse reconhecido pelo cartório notarial ou conservatória do registo comercial(caso for sociedade).
                        </li>
                        <li>
                            Cópia  do documento de Identidade do novo proprietário.
                        </li>
                        <li>
                            Escritura da Empresa da nova entidade. (em caso de Sociedade).
                        </li>
                        <li>
                            Termo de responsabilidade do Director técnico  autenticado.
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                    </ol>
                    <strong>
                        N.B.: O estabelecimento requer nova vistoria..
                    </strong>
                </p>
            </div>
            <div>
                <h1 className={classes.title}>
                    Requisitos para Mudança de Denominação de Depósito de Medicamentos/Equipamentos (Sendo o mesmo proprietário)
                </h1>
                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde  (<a href="/#">Ver modelo</a>).
                        </li>
                        <li>
                            Termo de responsabilidade do Director técnico.
                        </li>
                        <li>
                            Comprovativo de pagamento de emolumentos
                        </li>
                        <strong>
                            Obs.: Este procedimento é semelhante a instalação de um novo estabelecimento; para o efeito, implica uma vistoria.
                        </strong>
                    </ol>
                </p>
            </div>

            <br />
        </Container>
    )
}
