import { Button, DialogActions, Typography } from '@mui/material';
import { useState } from 'react';
import api from '../../services/api';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MessageSuccess from '../../messages/messageSuccess';
import MessageError from '../../messages/messageError';
import LoadingShort from '../../load/loadingShort';
import FichaRepresentante from '../gestRepresentantes/fichaRepresentante';
import StepCadastroRepresentante from '../departamentos/dlif/licenciamento/cadastroEstabelecimento/stepsFormularios/stepCadastroRepresentante';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const ChangeRepresentante = (props) => {
    const userId = useSelector(state => state.account.user?._id);
    const representante = useSelector(state => state.dadosRepresentante?.data)
    const [open, setOpen] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleChange = () => {
        setMessageSuccess('')
        setMessageError('')
        setOpen(true)

        api.put('/empresa/edit',  { 
            'empresaId': props.empresa?._id, 
            'representanteId': representante?._id, 
            userId 
        })
            .then(response => {
                // console.log(response) 
                setOpen(false)
                setMessageSuccess('Mudança feita com sucesso.')

                setTimeout(() => {
                    // navigate(-1)
                    window.location.reload()
                }, 5000);

            }).catch(err => {
                // console.log(err)
                setOpen(false)
                setMessageError('Erro ao salvar')
            })
    }

    return (
        <>
            <BootstrapDialog
                scroll='body'
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <div style={{ padding: 20 }}>

                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {messageError && <MessageError message={messageError} />}
                    <Typography>
                       <strong>REPRESENTANTE ACTUAL</strong>
                    </Typography>

                    <FichaRepresentante representante={props.empresa?.representante}/>

                    <Typography >
                     <strong> NOVO REPRESENTANTE </strong>
                    </Typography>

                    <StepCadastroRepresentante changeRepresentante={true}/>

                    {/* {!messageSuccess && */}
                        <DialogActions>
                            {/* <Button title='Cancelar' onClick={() => props.setOpen(false)} size="small">Cancelar</Button> */}
                            <Button disabled={!representante} onClick={handleChange} size="small">
                                {open ? <LoadingShort /> : 'Salvar'}
                            </Button>
                        </DialogActions>
                    {/* } */}
                </div>
            </BootstrapDialog>
        </>
    );
}

export default ChangeRepresentante;
