import { Card, } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import FichaAdmin from "../gestAdmin/fichaAdmin";
import Loading from "../../load/loading";
import ListaVazia from "../../utils/listaVazia";


export default function ResponsavelDeRegisto() {

    const { id } = useParams()
    const [admin, setadmin] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.get(`/admin/${id}`)
            .then(response => {
                setLoading(false)
                setadmin(response.data.admin);
            }).catch(err => { setLoading(false) })
    }, [id])

    return (
        <Card style={{ padding: 10, minHeight: '20vh', }}>
            {loading ?
                <Loading />
                :
                <ListaVazia text={'Admin não encontrado!'} />
            }
            {admin && <FichaAdmin admin={admin} />}
        </Card>
    )
}