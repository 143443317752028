import { Container } from '@mui/material'
import CadastroEstabelecimento from '../cadastroEstabelecimento'
import HeaderSession from '../../../../../utils/headerSession'
import { useParams, useNavigate } from 'react-router-dom'
import api from '../../../../../services/api'
import { useEffect, useState } from 'react'
import Mes from '../../../../../utils/mes'
import Loading from '../../../../../load/loading'
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import { useDispatch } from 'react-redux';
import RegisterAccess from '../../../../../utils/registerAccess'

const styles = {
    container: {
        textAlign: 'center',
        padding: '20px',
        border: '2px solid #ccc',
        borderRadius: '8px',
        maxWidth: '200px',
        margin: 'auto',
    },
    tempo: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '10px 0',
    },
    texto: {
        fontSize: '14px',
        color: '#555',
    },
};

export default function Licenciamento() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { agendaId } = useParams()
    const [agenda, setAgenda] = useState('')
    const [tempoRestante, setTempoRestante] = useState(
        parseInt(localStorage.getItem(agendaId), 10) || 1800 //30 minutos para fazer o cadastro
    );

    // TEMPORIZADOR
    useEffect(() => {
        const intervalo = setInterval(() => {
            if (tempoRestante === 0) {
                clearInterval(intervalo);
                //REMOVER O CONTROLE DE TEMPO DA localStorage
                // agendaChangeStatus('Disponivel')
                localStorage.removeItem(agendaId);
            } else {
                setTempoRestante((prevTempo) => {
                    const novoTempo = prevTempo - 1;
                    localStorage.setItem(agendaId, novoTempo);
                    return novoTempo;
                });
            }
        }, 1000);

        return () => clearInterval(intervalo);
    }, [tempoRestante]);

    const minutos = Math.floor(tempoRestante / 60);
    const segundos = tempoRestante % 60;

    async function agendaChangeStatus(status) {
        await api.patch(`/agenda/change-status`, { agendaId, status })
            .then(response => {
                // console.log(response)
                setAgenda(response.data.agenda);

                dispatch({
                    type: 'agenda',
                    payload: {
                        agenda: response.data.agenda
                    }
                })

            }).catch(error => {
                // console.log(error)
                navigate('/')
            })
    }

    useEffect(() => {
        agendaChangeStatus('Ocupado')
    }, [agendaId])


    return (
        <Container style={{ minHeight: '70vh' }}>
            <RegisterAccess page={'licenciamento de estabelecimento'} />
            <HeaderSession title='CADASTRO DE ESTABELECIMENTO' />
            {agenda ?
                <>
                    <div style={{ padding: 15, backgroundColor: '#51e26e', borderRadius: 5, display: 'flex', alignItems: 'center' }}>
                        <LockClockOutlinedIcon style={{ marginLeft: 20 }} fontSize="medium" />
                        <strong style={{ fontSize: 18, marginLeft: 10 }}>
                            Agendamento para o dia {agenda?.dia} de <Mes mes={agenda?.mes} /> as {agenda?.hora < 10 && 0}{agenda?.hora}:{agenda?.minuto < 10 && 0}{agenda?.minuto}
                        </strong>
                    </div>

                    {/* TEMPORIZADOR */}
                    <br />
                    <div style={styles.container}>
                        <div style={styles.tempo}>
                            {minutos < 10 ? `0${minutos}` : minutos}:{segundos < 10 ? `0${segundos}` : segundos}
                        </div>
                        <p style={styles.texto}>Tempo restante</p>
                    </div>

                    <br />
                    <CadastroEstabelecimento tipoRegisto={'Inscrição'} agenda={agenda} setTempoRestante={setTempoRestante} />
                </>
                :
                <Loading />
            }
            <br />
        </Container>
    )
}
