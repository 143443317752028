const INITIAL_STATE = {
    data: null
}

const DirectoresTecnicosReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'directoresTecnicos': {

            return {
                ...state,
                data: action.payload.directoresTecnicos,
                config: action.payload.config
            }
        }
        default: {
            return state
        }
    }
}

export default DirectoresTecnicosReducer
