import { useEffect, useState } from "react";
import api from "../../services/api";
import Loading from "../../load/loading";
import { Avatar, Box, Card, Divider} from "@mui/material";
import GraficoHistorico from "./graficoHistorico";
import HeaderSession from "../../utils/headerSession";
import * as C from "./styles";
import SelectInput from "./SelectInput"
import Mes from "../../utils/mes";
import TabelaActividades from "./tabelaActividades";
import RegisterAccess from "../../utils/registerAccess";


export default function HistoricosActividades() {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [historicosActividades, setHistoricosActividades] = useState('')
    const [admins, setAdmins] = useState('')
    const [victorinaBarros, setvictorinaBarros] = useState('')
    const [miusaVeloso, setmiusaVeloso] = useState('')
    const [iracelmaCosta, setiracelmaCosta] = useState('')
    const [alcidesAlmeida, setalcidesAlmeida] = useState('')
    const [margaridaAniceto, setmargaridaAniceto] = useState('')
    const [pauloJamba, setpauloJamba] = useState('')
    const [joseQuicomba, setjoseQuicomba] = useState('')
    const [pombalMayembe, setpombalMayembe] = useState('')
    const [mariaSimao, setmariaSimao] = useState('')
    const [joseChocolate, setjoseChocolate] = useState('')
    const [etelvinaMiguel, setEtelvinaMiguel] = useState('')
    const [aveiSilva, setAveiSilva] = useState('')

    const [reload, setReload] = useState('')
    const [stapReload, setStapReload] = useState(true)
    const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState('')
    const [perPage, setPerPage] = useState(100)

    useEffect(() => {
        setHistoricosActividades('')

        Promise.all([
            api.get(`/historico-actividades?cnp=false&page=${page}&perPage=${perPage}&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66003aaf60034b0107648996&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66003bad60034b01076489b4&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66003dc760034b01076489e8&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66003e6760034b01076489ff&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66003ffb64a30dae2772054f&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=6600417164a30dae27720567&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66004b2864a30dae27720585&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66004e0d64a30dae277205a8&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66004c9c64a30dae27720594&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66004e0d64a30dae277205a8&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=66059a8457b360adf9402dde&ano=${selectedYear}&mes=${selectedMonth}`),
            api.get(`/historico-actividades?cnp=false&userId=6626564c6c64f65edac92c40&ano=${selectedYear}&mes=${selectedMonth}`),

        ]).then(response => {
            setHistoricosActividades(response[0].data.historicoActividades);
            setConfigLista(response[0].data);
            setvictorinaBarros(response[1].data);
            setiracelmaCosta(response[2].data);
            setalcidesAlmeida(response[3].data);
            setmargaridaAniceto(response[4].data);
            setpauloJamba(response[5].data);
            setjoseQuicomba(response[6].data);
            setpombalMayembe(response[7].data);
            setjoseChocolate(response[8].data);
            setmariaSimao(response[9].data);
            setmiusaVeloso(response[10].data);
            setEtelvinaMiguel(response[11].data);
            setAveiSilva(response[12].data);

            // console.log(response[0].data);
            // stapReload &&
            //     window.setTimeout(() => {
            //         setReload(response)
            //     }, 10000);

        }).catch(error => {
            // console.log(error)
        })
    }, [page, reload, perPage, selectedMonth, selectedYear])


    useEffect(() => {
        api.get('/admins')
            .then(response => {
                // console.log(response.data.Admins)
                setAdmins(response.data.Admins)
            }).catch(err => console.log(err))
    })

    const objectMonths = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Desembro' }
    ]

    // Gera uma lista de anos a partir do ano atual até anos passados até 2024
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= 2024; year--) {
            years.push({ value: year, label: year });
        }
        return years;
    };

    const generateMonthOptions = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const months = [];

        // Se o ano selecionado for o ano atual, exibe apenas os meses que já se passaram
        // Caso contrário, exibe todos os meses do ano
        const endMonth = selectedYear === currentYear ? currentMonth : 12;

        for (let month = 0; month <= endMonth; month++) {
            months.push(objectMonths[month]);
        }
        return months;
    };

    return (
        <Card style={{ padding: 20, marginTop: 20 }}>
           <RegisterAccess page={'historico de actividades no sistema'}/>
            <HeaderSession title={'HISTÓRICOS DE ACTIVIDADES NO SISTEMA'} />

            <C.Container>
                <C.Header>
                    <div style={{ flexGrow: 1, }}>
                        <C.Title color="#8c8c98">
                            Mês de <Mes mes={selectedMonth} />
                        </C.Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '1rem' }}>
                        {/* estou a usar o setSelectedYear para selectedYear selectedMonth porque o estou a mesmo componente para exibir os meses e os anos */}
                        <SelectInput options={generateYearOptions()} selected={selectedYear} setSelectedYear={setSelectedYear} />
                        <SelectInput options={generateMonthOptions()} selected={selectedMonth} setSelectedYear={setSelectedMonth} />
                    </div>
                </C.Header>
            </C.Container>

            {historicosActividades ?
                <>
                    <GraficoHistorico
                        victorinaBarros={victorinaBarros.totalItems}
                        iracelmaCosta={iracelmaCosta.totalItems}
                        alcidesAlmeida={alcidesAlmeida.totalItems}
                        margaridaAniceto={margaridaAniceto.totalItems}
                        pauloJamba={pauloJamba.totalItems}
                        joseQuicomba={joseQuicomba.totalItems}
                        pombalMayembe={pombalMayembe.totalItems}
                        joseChocolate={joseChocolate.totalItems}
                        mariaSimao={mariaSimao.totalItems}
                        miusaVeloso={miusaVeloso.totalItems}
                        etelvinaMiguel={etelvinaMiguel.totalItems}
                        aveiSilva={aveiSilva.totalItems}
                    />

                    <Box ml={10} style={{ display: 'flex', justifyContent: 'spaceBetween', marginTop: -10, gap: '1rem' }} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {admins &&
                            admins.map((admin, index) => (
                                index < 12 &&
                                <div key={admin._id} style={{ flex: 1, }}>
                                    <Avatar
                                        title={'Ver historico individual'}
                                        style={{ cursor: 'pointer' }}
                                        src={admin.dadosPessoais?.fotoURL}
                                        onClick={() => {
                                            setStapReload(false);
                                            index === 0 && setHistoricosActividades(victorinaBarros.historicoActividades);
                                            index === 1 && setHistoricosActividades(iracelmaCosta.historicoActividades);
                                            index === 2 && setHistoricosActividades(alcidesAlmeida.historicoActividades);
                                            index === 3 && setHistoricosActividades(margaridaAniceto.historicoActividades);
                                            index === 4 && setHistoricosActividades(pauloJamba.historicoActividades);
                                            index === 5 && setHistoricosActividades(joseQuicomba.historicoActividades);
                                            index === 6 && setHistoricosActividades(pombalMayembe.historicoActividades);
                                            index === 7 && setHistoricosActividades(joseChocolate.historicoActividades);
                                            index === 8 && setHistoricosActividades(mariaSimao.historicoActividades);
                                            index === 9 && setHistoricosActividades(miusaVeloso.historicoActividades);
                                            index === 10 && setHistoricosActividades(etelvinaMiguel.historicoActividades);
                                            index === 11 && setHistoricosActividades(aveiSilva.historicoActividades);
                                        }}
                                    />
                                </div>
                            ))}
                    </Box>

                    <br />
                    <Divider />
                    <br />

                    <TabelaActividades
                        historicosActividades={historicosActividades}
                        configLista={configLista}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        page={page}
                    />
                </>
                :
                <Loading />
            }
        </Card>
    )
}
