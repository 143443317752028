import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ShortNome from '../../utils/ShortNome'
import DetalhesHistoricoActividade from '../../utils/detalhesHistoricoActividade'
import FerramentaNavegacaoExportacao from '../../utils/ferramentaNavegacaoExportacao'
import DateHora from '../../utils/dateHora'

export default function TabelaActividades(
    {
        historicosActividades,
        configLista,
        setPage,
        setPerPage,
        page,
    }) {

    return (
        <TableContainer>
            <Table size="small" aria-label="a dense table">
                
                <TableHead>
                    <TableRow>
                        <TableCell align="center"> <strong>Admin</strong></TableCell>
                        <TableCell align="center"> <strong>Função</strong></TableCell>
                        <TableCell align="center"> <strong> {/* Ação */} Fez</strong></TableCell>
                        <TableCell align="center"> <strong> {/* Local */} O que?</strong></TableCell>
                        <TableCell align="center"> <strong> {/* Data - Hora */} Quando</strong></TableCell>
                        <TableCell align="center" style={{ fontFamily: 'Work Sans' }}> <strong>Detalhes</strong></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {historicosActividades.map((historicosActividade) => (
                        <TableRow key={historicosActividade?._id} >
                            <TableCell align="center" component="th" scope="row">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar src={historicosActividade?.user?.dadosPessoais?.fotoURL} />
                                    <Typography variant="body1">
                                        <ShortNome nome={historicosActividade?.user?.dadosPessoais?.nome} />
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell  ><Typography noWrap> {historicosActividade?.user?.funcao}</Typography></TableCell>
                            <TableCell  ><Typography noWrap>{historicosActividade?.action}</Typography></TableCell>
                            <TableCell  ><Typography noWrap>{historicosActividade?.modelName}</Typography></TableCell>
                            <TableCell  ><Typography noWrap><DateHora date={historicosActividade?.updatedAt} /></Typography></TableCell>
                            <TableCell  ><Typography noWrap><DetalhesHistoricoActividade historicosActividade={historicosActividade} /></Typography></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <FerramentaNavegacaoExportacao
                configLista={configLista}
                lista={historicosActividades}
                setPage={setPage}
                setPerPage={setPerPage}
                page={page}
            />
        </TableContainer>
    )
}
