import { InputBase, IconButton } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@mui/material';


const SearchAgenda = ({ functionFilter, placeholder }) => {
    const agendasTipo = [
        { 'tipo': 'Geral' },
        { 'tipo': 'Renovação' },
        { 'tipo': 'Licenciamento' }
    ]

    return (
        <>
            <div style={{ border: '1px solid #85287e', borderRadius: 5, display: 'flex', marginBottom: 25 }}>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    autoFocus
                    placeholder={placeholder}
                    inputProps={{ 'aria-label': 'Filtrar lista' }}
                    onChange={(e) => { functionFilter(e.target.value) }}
                />

                <IconButton style={{ marginLeft: -40 }} align='center' type="submit" aria-label="search">
                    <SearchOutlinedIcon />
                </IconButton>

                <TextField
                    style={{ width: 200 }}
                    type="text"
                    placeholder="Categoria"
                    select
                    size="small"
                    variant="outlined"
                    onChange={(e) => { functionFilter(e.target.value === 'Geral' ? '' : e.target.value) }}
                    defaultValue={'Geral'}
                >
                    {agendasTipo?.map((agenda) => (
                        <MenuItem key={agenda.tipo} value={agenda.tipo}>
                            {agenda.tipo}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </>
    );
}

export default SearchAgenda;
