import { Button, DialogActions, Typography } from '@mui/material';
import { useState } from 'react';
import api from '../../services/api';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MessageSuccess from '../../messages/messageSuccess';
import MessageError from '../../messages/messageError';
import LoadingShort from '../../load/loadingShort';
import { useNavigate } from 'react-router-dom';
import FichaDirectorTecnico from './fichaDirectorTecnico';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const DirectorTecnicoCreateRepresentante = (props) => {
    const userId = useSelector(state => state.account.user?._id);
    const [load, setLoad] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const navigate = useNavigate()


    const handleClose = () => {
        props.setOpen(false);
    };

    const handleCreate = () => {
        setMessageSuccess('')
        setMessageError('')
        setLoad(true)

        api.post('/director-tecnico/become-representante', { 'directorTecnicoId': props.directorTecnico?._id, userId })
            .then(response => {
                // console.log(response) 
                setLoad(false)
                setMessageSuccess('sucesso.')
                navigate(`/admin/get-representante/${response.data.representante._id}`)

            }).catch(err => {
                // console.log(err)
                setLoad(false)
                setMessageError('Erro ao salvar')
            })
    }

    return (
            <BootstrapDialog
                scroll='body'
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <div style={{ padding: 20 }}>

                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {messageError && <MessageError message={messageError} />}
                    <Typography>
                        <strong>CRIAR UM REPRESENTANTE COM SEGUITES DADOS:</strong>
                    </Typography>

                    <FichaDirectorTecnico directorTecnico={props?.directorTecnico} />

                    <DialogActions>
                        {/* <Button title='Cancelar' onClick={() => props.setOpen(false)} size="small">Cancelar</Button> */}
                        <Button onClick={handleCreate} size="small">
                            {load ? <LoadingShort /> : 'Salvar'}
                        </Button>
                    </DialogActions>
                </div>
            </BootstrapDialog>
    );
}

export default DirectorTecnicoCreateRepresentante;
