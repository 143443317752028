import * as React from 'react';
import FichaEstabelecimento from '../../../gestLicenciados/fichaEstabelecimento'
import DocumentosAnexados from '../../../gestLicenciados/documentosAnexados'
import FichaEmpresa from '../../../gestEmpresas/fichaEmpresa'
import Recibo from './cadastroEstabelecimento/recibo'
import FichaDirectorTecnico from '../../../gestDirectorTecnico/fichaDirectorTecnico'
import InspeccaoOPen from '../inspeccao/avfd/inspeccaoOpen'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TimelineIcon from '@mui/icons-material/Timeline';
import UltimaIntervencao from './ultimaIntervencao';
import HistoricoActividadesByModel from './historicoActividadesByModel.jsx.jsx';
import GetInspeccoes from '../inspeccao/getInspeccoes';


export default function CombineSolicitacoes({ estabelecimento }) {
    const [openRecibo, setOpenRecibo] = React.useState(false);
    const [openUltimaIntervencao, setOpenUltimaIntervencao] = React.useState(false);
    const [openHistoricoActividade, setOpenHistoricoActividade] = React.useState(false);

    return (
        < >
            {estabelecimento.status === 'Inspecção Agendada' ?
                <InspeccaoOPen status={true} />
                :
                <GetInspeccoes />
            }
            
            <br />
            <List
                sx={{ width: '100%', minWidth: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={() => setOpenRecibo(!openRecibo)}>
                    <ListItemIcon>
                        <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="RECIBO" />
                    {openRecibo ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openRecibo} timeout="auto" unmountOnExit>
                    <Recibo estabelecimento={estabelecimento} />
                </Collapse>

                <ListItemButton onClick={() => setOpenUltimaIntervencao(!openUltimaIntervencao)}>
                    <ListItemIcon>
                        <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary="ULTIMA INTERVENÇÃO" />
                    {openUltimaIntervencao ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openUltimaIntervencao} timeout="auto" unmountOnExit>
                    <UltimaIntervencao estabelecimento={estabelecimento} />
                </Collapse>

                <ListItemButton onClick={() => setOpenHistoricoActividade(!openHistoricoActividade)}>
                    <ListItemIcon>
                        <TimelineIcon />
                    </ListItemIcon>
                    <ListItemText primary="HISTÓRICO DO PROCESSO" />
                    {openHistoricoActividade ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openHistoricoActividade} timeout="auto" unmountOnExit>
                    <HistoricoActividadesByModel model={estabelecimento} />
                </Collapse>
            </List>

            <br />
            <FichaEstabelecimento header={true} estabelecimento={estabelecimento} />
            <FichaDirectorTecnico header={true} directorTecnico={estabelecimento.directorTecnico} />
            <FichaEmpresa header={true} empresa={estabelecimento.empresa} local={'analiseSolicitacao'} />
            <DocumentosAnexados estabelecimento={estabelecimento} docs={estabelecimento.docs} />
        </>
    )
}
