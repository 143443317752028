import { Container, Grid, Box } from '@mui/material'
import ListaOpcoes from '../../../../utils/listaOpcoes'
import HeaderSession from '../../../../utils/headerSession'

export default function ImportacaoExportacao() {
    return (
        <Container style={{ minHeight: '70vh' }}>
            <Box sx={{ display: { md: 'none' } }}>
                <HeaderSession title='DESALFANDEGAR' />
            </Box>

            <Grid container>
                <Grid margin={1} xs={12} md={5.7} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ border: '2px solid #85287e', padding: 30, borderRadius: 5, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/icones/desalfandegar.svg" alt="" width={100} />
                        {/* <p style={{ fontSize: 14 }}>
                            IMPORTAÇÃO E EXPORTAÇÃO
                        </p> */}
                    </div>
                </Grid>

                <Grid margin={1} xs={12} md={5.7} item >

                    <Box width={'100%'} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <HeaderSession title='IMPORTAÇÃO E EXPORTAÇÃO' />
                    </Box>

                    <ListaOpcoes nomeOpcao='Solicitar declaração para importãçao' link='/solicitar/importacao' />
                    <ListaOpcoes nomeOpcao='Consultar estado da solicitação de importãçao' link='/' />
                    <ListaOpcoes nomeOpcao='Solicitar desalfangegamento' link='/' />
                    <ListaOpcoes nomeOpcao='Medicamentos recepcionados' link='/desalfandegar-medicamentos-recepcionados' />
                </Grid>

            </Grid>
            <br />
        </Container>
    )
}
