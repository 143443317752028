
// import { Typography } from '@mui/material';

import { useState } from "react"

export default function TextFormatado({ texto }) {
    // const textFormatado = texto?.split('\n')

    const [vertTudo, setVerTudo] = useState(400)

    const textFormatado = texto.slice(0, vertTudo)

    const handleVertTudo = () => {
        vertTudo === 400 ? setVerTudo(texto.length) : setVerTudo(400)
    }

    return (
        // textFormatado?.map((textFormatado, index) =>
        //     <Typography key={index} variant="body1" style={{ marginTop: 10, lineHeight: 1.5, }}>
        //         {textFormatado}
        //     </Typography>
        //     )

        <>
        <div style={{ marginTop: 20, lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: textFormatado }} />
        {(texto.length > 200 ) && <p onClick={handleVertTudo} style={{ color: 'GrayText', cursor: 'pointer'}}> {vertTudo === 400 ? ' ...Ver mais' : ' Ver menos'}</p>}
        </>
    )
}


