
import { Container, Divider, Typography } from "@mui/material";
import RegisterAccess from "../../utils/registerAccess";

export default function PoliticasServicos() {


    return (

        <Container style={{ minHeight: '90vh', maxWidth: 800, marginTop: 100, marginBottom: 50 }}>
            <RegisterAccess page={'termos e condições'} />

            <Typography align="center" style={{ marginBottom: 50, fontSize: '1.5rem' }} variant="h1">
                {/* Politicas e Servicos */}
                <strong>
                    Termos e Condições (contrato de licença de uso da Plataforma GESTOR ARMED)
                </strong>
                <Divider />
            </Typography>

            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                a) Se está aqui é porque quer saber mais sobre as obrigações e direitos que lhe correspondem como um usuário da Plataforma GESTOR ARMED, disponível em: https://armed.cnp.ao.
                Nosso dever é informar-lhe e o seu é estar devidamente informado.
                Os presentes Termos e condições servem para lhe informar com total transparência sobre a finalidade desta plataforma e tudo o que afecta os dados que nos forneça, bem como as obrigações e direitos que lhe correspondem.
                Para começar, deve saber que esta plataforma respeita integralmente o quadro legal angolano vigente em matéria de proteção de dados, o que afecta os dados pessoais que nos forneça com o seu consentimento expresso e os cookies que utilizamos para que a plataforma funcione correctamente e possa desenvolver sua actividade.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">

                b) Identificação da proprietária da plataforma:
                <br />
                A Firma: VAM2 Soluções – Comercio e Prestação de Serviços, Lda
                <br />
                o NIF: 5001838334
                <br />
                o Matrícula: 5950-24/240206
                <br />
                o E-mail: geral@vam.com
                <br />
                o Publicada em: www.gue.gov.ao
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                c) Todos os dados pessoais que nos fornecer, sempre com o seu consentimento expresso, serão guardados e tratados para os fins previstos a seguir descritos nestes Termos de Uso, Política de Privacidade e Confidencialidade, até o momento em que nos solicitar a remoção ou cancelamento.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                d) Informamos que os presentes Termos e Condições podem ser alterados a qualquer momento, com a finalidade de adaptá-la a novidades legislativas ou mudanças em nossas actividades, sendo vigente a cada momento a que estiver publicada em https://armed.cnp.ao. Em caso de alteração o usuário será notificado antes da sua aplicação.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                e) Informamos-lhe, para sua tranquilidade, que sempre será solicitado o seu consentimento expresso para obter seus dados com a respectiva finalidade especificada em cada caso, o que implica que, em caso de dar esse consentimento, leu e concorda com estes Termos de Uso, Política de Privacidade e Confidencialidade.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                f) No momento em que acesse e utiliza a plataforma e todo o seu conteúdo está a assumir a sua condição de usuário com seus correspondentes direitos e obrigações e, desde logo, que é maior de 18 anos de idade.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                g) Cancelamento de Inscrição e o Direito de Revogação
                Como titular dos dados que nos tenha fornecido, pode exercer em qualquer momento os seus direitos de acesso, retificação, cancelamento e oposição, enviando-nos um e-mail para geral@vam.com, anexando uma fotocópia do seu documento de identidade como prova válida.
                Igualmente, poderá cancelar a qualquer momento para deixar de receber a nossa newsletter ou qualquer outra comunicação comercial, enviando-nos um e-mail para geral@vam.com
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                h) Compromisso de honra do Usuário:
                <br />
                I. Ao aceitar, o usuário reconhece que é o único responsável pela veracidade e alteração dos dados que nos enviar, pelo que a VAM2 SOLUÇÕES - COMÉRCIO E PRESTAÇÃO DE SERVIÇOS, LDA exonera-se de qualquer responsabilidade a respeito. Isto quer dizer que como usuário a si cabe garantir e responder em qualquer caso, a exactidão, vigência e autenticidade dos dados pessoais fornecidos, e se comprometer a manter esses dados devidamente actualizados.
                <br />
                II. Reconhece, ainda, que, de acordo com o expressado nesta Política de Privacidade, concorda em fornecer informação completa e correcta no formulário de conctato ou licenciamento.
                <br />
                III. O Usuário compromete-se a fazer uso adequado dos conteúdos e da informação que a plataforma oferece e com caráter enunciativo, mas não limitativo:
                <br />
                a. não se envolver em actividades que sejam ilegais ou contrárias à boa fé e à ordem pública;
                <br />
                b. não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou sobre casinos, casas de apostas, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
                <br />
                c. não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Gestor Armed, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">


                i) Política de Privacidade
                A sua privacidade e a dos seus clientes são muito importante para nós.
                A VAM2 SOLUÇÕES - COMÉRCIO E PRESTAÇÃO DE SERVIÇOS, LDA respeita plenamente a sua privacidade e a dos seus clientes em relação a qualquer informação disponibilizada na Plataforma Gestor ARMED, bem como noutros sites e softwares que possuímos e operamos.
                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo de forma transparente e por meios justos e legais, sempre com o seu conhecimento e consentimento; também lhe informamos por que estamos recolhendo e como será usado.
                Apenas mantemos as informações recolhidas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
                É nossa política não compartilharmos as informações de identificação pessoal nem quaisquer outras que recolhamos publicamente ou com terceiros.
                Como usuário é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
                O uso continuado da nossa aplicação será considerada como aceitação de nossas práticas em torno de privacidade e informações pessoais. Caso tenha alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">


                j) <b>Cookies</b>
                <br />
                O que são cookies?
                <br />
                Como é prática comum em quase todos os sites profissionais, a Plataforma Gestor ARMED usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também o manteremos informado de que pode impedir que esses cookies sejam armazenados, todavia isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade da plataforma.
                <br />
                Como usamos os cookies?
                <br />
                Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a esta plataforma. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que usa.
                <br />
                Desativar cookies
                <br />
                O Usuário pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja, entretanto, ciente de que a desativação de cookies afetará a funcionalidade desta e de muitos outras plataformas que visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos desta plataforma. Portanto, é recomendável que não desative os cookies.
                <br />
                Cookies que definimos:
                <br />
                a. Cookies relacionados com a conta: se criar uma conta connosco (registando-se, para o efeito, na nossa plataforma), usaremos cookies para o gerenciamento do processo de licenciamento e administração geral. Esses cookies geralmente serão excluídos quando o Usuário sair da plataforma, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.
                <br />
                b. Cookies relacionados com o login: utilizamos cookies quando o Usuário está logado, para que possamos lembrar dessa acção. Isso evita que precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando efetuar logout para garantir que possa acessar apenas a recursos e áreas restritas ao efetuar login.
                <br />
                c. Pedidos processando cookies relacionados: este site oferece facilidades de comércio e ou pagamento eletrônicos e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.
                <br />
                d. Cookies relacionados com pesquisas: periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.
                <br />
                e. Cookies relacionados com formulários: quando envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.
                <br />
                f. Cookies de preferências do site: para proporcionar uma óptima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando o utiliza. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas de modo que a sua interação com uma página seja afectada ou determinada por suas preferências.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                i) <b>Confidencialidade e protecção de dados</b>
                <br />
                As informações e dados que inserir na plataforma Gestor ARMED, são da sua inteira responsabilidade e ficam armazenados na plataforma, não sendo acessíveis por terceiros.
                A única forma de os dados serem disponibilizados a terceiros será pelo adicionamento de usuários (advogados, consultores, assistentes administrativos e clientes) a um processo em concreto.
            </Typography>

            <br />
            <Typography align="justify" style={{ fontSize: '1.3rem' }} variant="subtitle2">
                p) <b>Mais informações</b>
                <br />
                Esperamos que esteja esclarecido e, como mencionado anteriormente, se houver algo que não tenha certeza se precisa ou não, geralmente é mais seguro deixar os cookies activados, caso interaja com um dos recursos que usa em nossa plataforma.
                Esta política é efectiva a partir de Abril de 2024.
                A Gerência da VAM
            </Typography>

        </Container>
    )
}
