import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../utils/headerSession'
import RegisterAccess from '../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 20,
        textAlign: 'center',
        color: '#67348d'
    },
}))

export default function SobreNos() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
           <RegisterAccess page={'sobre nós'} />
            <HeaderSession title='SOBRE' />

            <div>
                <h1 className={classes.title}>
                    QUEM SOMOS
                </h1>
                <br />
                <p className={classes.root} >
                    Alínea b do artigo 2.° do Decreto Presidencial nº 180/10 de 18 de Agosto,
                    que aprova a Política Nacional Farmacêutica, definiu a Direcção Nacional
                    de Medicamentos e Equipamentos (DNME) como a Autoridade Reguladora do Sector
                    Farmacêutico em Angola, sendo responsável pela gestão da aplicação das
                    disposições administrativas, técnicas legais e as normativas do exercício
                    farmacêutico no território nacional.
                </p>

                <p className={classes.root}>
                    No entanto, face à necessidade do reforço dos sistemas regulatórios do medicamento
                    conforme normas da Organização Mundial da Saúde  (OMS) e da adequação da legislação
                    farmacêutica nacional à Lei Modelo da União Africana (UA) sobre a Regulação dos Produtos
                    Farmacêuticos, a DNME foi transformada em Agência Reguladora de Medicamentos e Tecnologias
                    de Saúde.
                </p>

                <p className={classes.root}>
                    A Agência Reguladora de Medicamentos e Tecnologias de Saúde, abreviadamente designada por
                    «ARMED» é um estabelecimento público com personalidade e capacidade jurídicas, dotada de
                    autonomia administrativa, patrimonial e financeira, encarregue de desenvolver ações de
                    regulação, regulamentação, orientação, licenciamento, fiscalização e controlo das atividades
                    no domínio dos medicamentos de uso humano e das tecnologias de saúde, visando garantir a sua
                    qualidade, eficácia e segurança. ( artigo 1º do Decreto Presidencial nº 136/21 de 1 de Junho).
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    MISSÃO
                </h1>

                <p className={classes.root}>
                    Desenvolver acções de regulação, regulamentação, orientação, licenciamento, fiscalização e
                    controlo das actividades no domínio dos medicamentos de uso humano e das tecnologias de saúde,
                    visando garantir a sua qualidade e segurança.
                </p>
            </div>
            <div>
                <h1 className={classes.title}>
                    VISÃO
                </h1>

                <p className={classes.root}>
                    Transformar a ARMED numa Autoridade Reguladora com recursos materiais, humanos(<i>know-how</i>)
                    e financeiros a altura a fim de se afirmar como membro activo da estrutura centralizada da
                    regulamentação farmacêutica a nível de África.
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    VALORES
                </h1>

                <p className={classes.root}>
                    A ARMED prima pela responsabilidade, disciplina, transparência, imparcialidade,
                    integridade e a busca permanente da excelência.
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    ORGANIGRAMA
                </h1>

                <img width={800} style={{ width: '100%', objectFit: 'cover', }} src="/img/ORGANIGRAMA.png" alt="ORGANIGRAMA" />
            </div>
            <br />
        </Container>
    )
}
