
import { useEffect, useState } from "react";
import { Avatar, Typography, makeStyles } from "@material-ui/core";
import { Divider, Grid, Card, Container } from "@mui/material";
import Loading from "../../../load/loading";
import Date from "../../../utils/date";
import api from "../../../services/api";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CreateNoticia from "./createNoticia";
import MessageSuccess from '../../../messages/messageSuccess';
import MessageError from "../../../messages/messageError";
import HeaderSession from "../../../utils/headerSession";
import HeaderGestorConfigs from "../../../utils/headerGestorConfigs";
import DeleteNoticia from "./deleteNoticia";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ViewNoticia from '../../../pages/home/noticias/viewNoticia';
import RegisterAccess from "../../../utils/registerAccess";
// import RegisterAccess from "../../../utils/registerAccess";

const useStyles = makeStyles({
    img: {
        borderRadius: 5,
        transition: '1s',
        '&:hover': { opacity: 0.7, }
    },

    root: {
        transition: '.3s',
        '&:hover': { width: 25 },
    },
})

export default function GestNoticias() {
    const classes = useStyles()
    const [title, setTitle] = useState('Criação')
    const [noticias, setNoticias] = useState('')
    const [editarNoticia, setEditarNoticia] = useState(false)
    const [createNoticia, setCreateNoticia] = useState(false)
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')
    const [forceUpdate, setForceUpdate] = useState('')
    const [preview, setPreview] = useState(false)


    useEffect(() => {
        api.get(`/noticias?perPage=100`)
            .then(response => {
                // console.log(response)
                setNoticias(response.data.noticias);
            }).catch(error => {
                // console.log(error)
            })
    }, [editarNoticia, createNoticia, forceUpdate])

    const HandleEdit = (noticia) => {
        setEditarNoticia(noticia)
        setTitle('Editar notícia')
    }

    const GridItem = ({ noticia }) => {
        return (
            <div key={noticia._id}  >
                <RegisterAccess page={'noticias'} />
                <Grid container >

                    <Grid item md={'auto'} onClick={() => setPreview(noticia)}>
                        <p>{noticia?.seccao}</p>
                        <Avatar src={noticia.imagem} style={{ width: 300, height: 150 }} variant="square" className={classes.img} />
                    </Grid>

                    <Grid item md={8} m style={{ position: 'relative' }}>
                        <br />
                        {noticia?.categoria &&
                            <Typography style={{ color: '#fff', marginBottom: 10, background: '#85287e', display: 'inline-block', padding: 3, borderRadius: 2 }}>
                                {noticia.categoria}
                            </Typography>
                        }
                        <Typography variant="subtitle1" style={{ lineHeight: 1, fontSize: 16, marginBottom: 5 }}>
                            <strong>{noticia.titulo} </strong>
                        </Typography>

                        {noticia?.subTitulo &&
                            <Typography noWrap variant="subtitle2" style={{ lineHeight: 1, fontSize: 15, marginBottom: 5, maxWidth: '70vw' }}>
                                {noticia.subTitulo}
                            </Typography>
                        }

                        <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: 10 }}>
                            Data: <Date date={noticia.createdAt} />
                            <br />
                            Fonte: {noticia.fonte}
                        </Typography>
                        <br />
                        <Typography noWrap variant="body2" style={{ lineHeight: 1, fontSize: 13, maxWidth: '70vw' }}>
                            {noticia.conteudo}
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 0, top: 0 }}>
                            {noticia.status === "Inactivo" &&
                                <small style={{ color: 'red' }}> Não publicado</small>
                            }
                            <HeaderGestorConfigs
                                menu={true}
                                configArea={'noticia'}
                                title={''}
                                noticia={noticia}
                                HandleEdit={HandleEdit}
                            />
                            <DeleteNoticia
                                noticiaId={noticia._id}
                                setMessageSuccess={setMessage}
                                setMessageError={seterrorMessage}
                                setForceUpdate={setForceUpdate}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Divider />
                <br />
            </div>
        );
    };

    return (
        <Container>
            <HeaderSession title={'GESTÃO DE NOTICIAS'} />

            {message && <MessageSuccess message={message} />}
            {messageError && < MessageError message={messageError} />}

            {!preview ?
                <Card style={{ minHeight: '95vh', padding: 20 }}>

                    {/* COMPONENTE DE CRIACAO DE NOTICIAS  */}
                    {createNoticia &&
                        <CreateNoticia
                            title={title}
                            setTitle={setTitle}
                            setCreateNoticia={setCreateNoticia}
                            setMessage={setMessage}
                            seterrorMessage={seterrorMessage}
                        />
                    }
                    {/*USANDO O MESMO COMPONENTE DE CRIACAO DE NOTICIAS PARA FAZER EDIÇOES DE NOTICIAS */}
                    {editarNoticia &&
                        <CreateNoticia
                            title={title}
                            setTitle={setTitle}
                            noticia={editarNoticia}
                            setEditarNoticia={setEditarNoticia}
                            setMessage={setMessage}
                            seterrorMessage={seterrorMessage}
                        />
                    }

                    {(noticias.length > 0 && !editarNoticia && !createNoticia) &&
                        <>
                            <Grid container >
                                <Grid m xs={12} md item >
                                    <Typography align="center" style={{ fontSize: 18, margin: 10 }} noWrap variant='h1'>
                                        Ultima publicação
                                    </Typography>

                                    <Avatar src={noticias[0].imagem} style={{ width: 'auto', height: '19em' }} variant="square" className={classes.img} />
                                    {noticias[0]?.categoria &&
                                        <Typography style={{ color: '#fff', marginTop: 10, background: '#85287e', display: 'inline-block', padding: 3, borderRadius: 2 }}>
                                            {noticias[0].categoria}
                                        </Typography>
                                    }
                                    <Typography variant="subtitle1" style={{ lineHeight: 1, fontSize: 20 }}>
                                        <strong> {noticias[0].titulo} </strong>
                                    </Typography>
                                    {noticias[0]?.subTitulo &&
                                        <Typography noWrap variant="subtitle2" style={{ lineHeight: 1, fontSize: 15, marginBottom: 5, maxWidth: '70vw' }}>
                                            {noticias[0].subTitulo}
                                        </Typography>
                                    }

                                    <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: 10 }}>
                                        Data: <Date date={noticias[0].createdAt} />
                                        <br />
                                        Fonte: {noticias[0].fonte}
                                    </Typography>
                                </Grid>

                                <Grid xs={12} md={4} item   alignContent={'center'}>
                                    <Typography align="center" variant="subtitle1" >
                                        <IconButton onClick={() => { setCreateNoticia(true) }}>
                                            <AddCircleOutlineIcon style={{ fontSize: 70 }} color="secondary" />
                                        </IconButton>
                                        <br />
                                        ADICIONAR NOVA NOTICIA
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider />
                            <br />

                            <Grid m xs={12} md item >
                                {noticias.map((noticia, index) => (
                                    // (index < (noticias.length / 2)) &&
                                    <GridItem key={noticia._id} noticia={noticia} />
                                ))}
                            </Grid>
                        </>
                    }

                    {!noticias && <Loading text={'A carregar noticias'} />}
                </Card>
                :
                //  PREVIEW 
                <Card style={{ padding: 10 }}>
                    <IconButton size='small' color='error' style={{ marginRight: 10 }} onClick={() => setPreview(false)}>
                        <CloseIcon />
                    </IconButton>

                    <ViewNoticia preview={preview} />
                </Card>
            }
        </Container>
    )
}