const INITIAL_STATE = {
    data: { usedataRepresentante: 'nao' }
}

const dadosdirectorTecnicoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'dadosdirectorTecnico': {

            return {
                ...state,
                data: action.payload.dadosdirectorTecnico,
                agenda: action.payload.agenda,
            }
        }
        default: {
            return state
        }
    }
}

export default dadosdirectorTecnicoReducer