
import { Typography } from "@mui/material";

export default function StatusAdmin({ status }) {

    return (
        <>
            {status === 'Activo' &&
                <Typography style={{ padding: 10, background: '#7ebf42', color: '#fff', fontSize: 30, lineHeight: 1.5 }} align="center" variant="body1">
                 TECNICO AUTORIZADO
                </Typography>
            }

            {status === 'Inactivo' &&
                <Typography style={{ padding: 10, background: 'red', color: '#fff', fontSize: 30, lineHeight: 1.5 }} align="center" variant="body1">
                   NÃO AUTORIZADO
                </Typography>
            }
        </>
    )
}