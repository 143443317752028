import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useNavigate } from 'react-router-dom';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Date from '../../../utils/date';


export default function CardNoticias({ noticia}) {
    const navigate = useNavigate()

    return (
        <CardActionArea onClick={() => navigate(`/view-noticia/${noticia?._id}`)} >
            <Card >
                <CardMedia
                    component="img"
                    alt="img"
                    image={noticia?.imagem}
                />
                <CardContent>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        <strong>
                            {noticia?.titulo}
                        </strong>
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
          
                </Typography> */}

                    <CardActions style={{ position: 'absolute' }}>
                        <AccessTimeFilledIcon color='secondary' style={{ fontSize: '25px', padding: 2 }} />
                        <Date date={noticia.updatedAt} />
                    </CardActions>

                    <CardActions style={{ position: 'relative', marginTop: 10 }}>
                        <Button style={{ position: 'absolute', right: 5, bottom: 5, background: '#85287e', color: '#fff', fontSize: 10 }} >
                            Saiba mais
                        </Button>
                    </CardActions>
                </CardContent>
            </Card>
        </CardActionArea>
    );
}
