
import { Container, Grid } from '@mui/material'
import HeaderSession from '../utils/headerSession'
import RegisterAccess from '../utils/registerAccess'

export default function Contactos() {
    return (
        <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
               <RegisterAccess page={'contactos'} />
            <HeaderSession title={'CONTACTOS'} />

            <Grid container>
                {/* <Grid margin={1} xs={12} md={5.7} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}> */}
                    <img src="/img/calendarioAtendimento.jpg" alt="" width={"100%"} />

                {/* </Grid> */}
                {/* <Grid margin={1} xs={12} md={5.7} item >
                    <h1 style={{ color: '#67348d', marginBottom: 20, fontSize: 20 }}>

                        FORM
                    </h1>
                    <hr style={{ border: '1px solid #67348d', width: '97%' }} />

                </Grid> */}

            </Grid>
            <br />
        </Container>
    )
}
