import { Button, DialogActions, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import api from '../services/api';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MessageSuccess from '../messages/messageSuccess';
import MessageError from '../messages/messageError';
import LoadingShort from '../load/loadingShort';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const Delete = (props) => {
    const userId = useSelector(state => state.account.user?._id);
    const [open, setOpen] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const navigate = useNavigate()

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleDelete = () => {
        setMessageSuccess('')
        setMessageError('')
        setOpen(true)

        api.delete(`${props.url}/`, { data: { 'id': props.id, userId } })
            .then(response => {
                // console.log(response) 
                setOpen(false)
                setMessageSuccess('Eliminado com sucesso.')

                setTimeout(() => {
                    navigate(-1)
                }, 5000);

            }).catch(err => {
                // console.log(err)
                setOpen(false)
                setMessageError('Erro ao eliminar')
            })
    }

    return (
        <>
            <BootstrapDialog
                scroll='body'
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <div style={{ padding: 20 }}>

                    <Typography>
                        {props.msg} ?
                    </Typography>

                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {messageError && <MessageError message={messageError} />}

                    {!messageSuccess &&
                        <DialogActions>
                            <Button title='Cancelar' onClick={() => props.setOpen(false)} size="small" >Cancelar</Button>
                            <Button title='Apagar' onClick={handleDelete} size="small" color="error">
                                {open ? <LoadingShort /> : <DeleteIcon />}
                            </Button>
                        </DialogActions>
                    }
                </div>
            </BootstrapDialog>
        </>
    );
}

export default Delete;
