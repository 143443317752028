import { useEffect, useState } from 'react';
import { Avatar, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import Loading from "../../load/loading";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import FerramentaNavegacaoExportacao from '../../utils/ferramentaNavegacaoExportacao';
import SearshEmpresa from './searshEmpresa';
import RegisterAccess from '../../utils/registerAccess';


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#d2d6ff' }, cursor: 'pointer' }
}))

export default function Empresas() {
    const classes = useStyles()
    const navigate = useNavigate()
    const [empresas, setEmpresas] = useState(useSelector(state => state?.empresas?.data) ?? '')
    const [configLista, setConfigLista] = useState(useSelector(state => state?.empresas?.config) ?? '')
    const [page, setPage] = useState(useSelector(state => state?.empresas.data !== null ? state?.empresas?.config?.previousPage + 1 : 1));
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(20)
    const [order, setOrder] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.get(`/empresas?page=${page}&perPage=${perPage}&sortBy=nome:${order}`)
            .then(response => {
                // console.log(response)
                setConfigLista(response.data);
                setEmpresas((prevItems) => [...prevItems, ...response.data.empresas]);
                // setFilter(response.data.empresas);

                setLoading(false)
            }).catch(err => { setLoading(false) })

        dispatch({
            type: 'empresas',
            payload: { empresas: empresas, config: configLista },
        }); //dispatch para por as empresas no state da aplicacao e evitar recarregar desnecessariamente

    }, [page, perPage, order]);

    return (
        <>
           <RegisterAccess page={'lista de empresas registadas'}/>
            {empresas &&

                <Card style={{ padding: 20, minHeight: '20vh', }}>
                    <Typography style={{ fontSize: 20, marginBottom: 20 }}>
                        EMPRESAS RESGISTADAS
                    </Typography>

                    <SearshEmpresa setEmpresas={setEmpresas} />

                    <Card variant="outlined">
                        <TableContainer variant='outline' style={{ overflowX: 'auto' }} sx={{ width: '100%', overflow: 'hidden' }}>
                            <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell  ><Typography noWrap> <strong>Logotipo</strong></Typography></TableCell>
                                        <TableCell  ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                        {/* <TableCell align="center" ><Typography noWrap> <strong>Registo</strong></Typography></TableCell> */}
                                        {/* <TableCell align="center" ><Typography noWrap> <strong>Processo</strong></Typography></TableCell> */}
                                        {/* <TableCell align="center" ><Typography noWrap> <strong>Telefone</strong></Typography></TableCell> */}
                                        <TableCell  ><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                                        <TableCell  ><Typography noWrap> <strong>Municipio</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {empresas.map((empresa => (
                                        <TableRow key={empresa?._id} onClick={() => navigate(`/admin/get-empresa/${empresa._id}`)} className={classes.hover}>

                                            <TableCell align="center" >
                                                <Avatar variant="square" src={empresa?.logo} />
                                            </TableCell>

                                            <TableCell >{empresa?.nome}</TableCell>
                                            {/* <TableCell > <Typography noWrap> {empresa?.numeroRegisto}</Typography></TableCell> */}
                                            {/* <TableCell > <Typography noWrap> {empresa?.numeroProcesso}</Typography></TableCell> */}
                                            {/* <TableCell > <Typography noWrap> {empresa?.tel1}</Typography></TableCell> */}
                                            <TableCell > <Typography noWrap> {empresa?.provincia}</Typography></TableCell>
                                            <TableCell > <Typography noWrap> {empresa?.municipio}</Typography></TableCell>

                                        </TableRow>
                                    )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>

                    <FerramentaNavegacaoExportacao
                        configLista={configLista}
                        lista={empresas}
                        page={page}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        setOrder={setOrder}
                    // exportarExcel={exportarExcel}
                    />
                </Card>
            }

            {loading && <Loading /> }
        </>

    )
}