import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


const LoginRoute = ({isAuthenticated, children }) => {

    const user = useSelector(state => state.account.user);
    const departamento = user?.departamento ?? localStorage.getItem('dep')


    if (isAuthenticated && departamento ==='DLIF') {
        return <Navigate to={'/admin/dlif'} replace />;
    }
    
    if (isAuthenticated && departamento ==='DMTS') {
        return <Navigate to={'/admin/dmts'} replace />;
    }
    

    return children ;
};

export default LoginRoute