
import { useState } from "react"
import { Card } from "@material-ui/core";
import { Container, DialogActions, Typography, Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FormRepresentante from "../departamentos/dlif/licenciamento/cadastroEstabelecimento/stepsFormularios/stepCadastroRepresentante";
import { useSelector } from "react-redux";
import LoadingBackdrop from "../../load/loadingBackdrop";
import MessageError from "../../messages/messageError";
import FichaEmpresa from "./fichaEmpresa";
import cadastrosService from "../../services/cadastroServices";
import FormCadastroEmpresa from "./formCadastroEmpresa";
// validate
import { validacaoDadosEmpresa, validacaoDadosRepresentante } from "../../validacoes"
import RegisterAccess from "../../utils/registerAccess";


export default function Cadastro() {
    const [errorMessage, setErrorMessage] = useState('')
    // const [messageSuccess, setMessageSuccess] = useState('')
    const [cadastroSuccess, setCadastroSuccess] = useState('')
    const [load, setLoad] = useState(false)
    const dadosRepresentante = useSelector(state => state.dadosRepresentante.data)
    const dadosEmpresa = useSelector(state => state.dadosEmpresa.data)
    const user = useSelector(state => state.account.user);
    const dispatch = useDispatch()
    // console.log(dadosEmpresa)


    // ###################################################################
    const validate = async e => {
        setErrorMessage('');
        if (!(await validate0())) return
    };
    async function validate0() {
        try {
            await validacaoDadosEmpresa(dadosEmpresa)
            await validacaoDadosRepresentante(dadosRepresentante)
            submitCadastro()
        }
        catch (error) {
            setErrorMessage(error);
        }
    }

    async function createRepresentante() {
        setLoad(true)

        try {
            const result = await cadastrosService.cadastroRepresentante(dadosRepresentante, user)
            localStorage.setItem('representanteId', result.data.representante._id);
            setLoad(false)

            // console.log(result)

            dispatch({
                type: 'dadosRepresentante',
                payload: { dadosRepresentante: result.data.representante }
            })

            // CRIAR A EMPRESA APOS OBTER O RESULTADO SUCCESS DA CRIACAO DO REPRESENTANTE
            await createEmpresa(result.data.representante._id);

            return result;
        } catch (error) {

            // console.log(error)
            setErrorMessage(error.response.data.message)
            setLoad(false)

            throw error;
        }
    }

    async function createEmpresa(representanteId) {
        setLoad(true)

        try {
            const result = await cadastrosService.cadastroEmpresa(dadosEmpresa, representanteId, user)

            // console.log(result)
            setLoad(false)
            setCadastroSuccess(result.data.empresa)
            
            dispatch({
                type: 'dadosEmpresa',
                payload: { dadosEmpresa: result.data.empresa }
            })
            // setMessageSuccess(result.data?.message)

            return result;
        } catch (error) {

            // console.log(error)
            setLoad(false)
            setErrorMessage(error.response.data.message)

            // Lançar erro se desejar propagá-lo
            throw error;
        }
    }

    async function submitCadastro() {
        setErrorMessage('')

        try {
            if (!dadosRepresentante?._id) {
                await createRepresentante();
            }

            if (!localStorage.getItem('empresaId')) {
                await createEmpresa(dadosRepresentante._id);
            }

        } catch (error) {
            // console.error('Ocorreu um erro:', error);
        }
    }


    return (
        <Container >
            {/* MESSAGEM DE ERROS  */}
            <RegisterAccess page={'cadastrar empresa'}/>
            {errorMessage && <MessageError message={errorMessage} />}

            <LoadingBackdrop open={load} />

            <Card style={{ padding: 20 }}>
                {cadastroSuccess ?
                    <>
                        <Typography variant="body1" style={{ marginBottom: 20 }} align="center">
                            <CheckCircleOutlineIcon fontSize="large" color="success" />
                            <br />
                            Cadastro de empresa realizado com sucesso.!
                        </Typography>

                        <FichaEmpresa empresa={cadastroSuccess} />
                    </>
                    :
                    <>
                        <Typography style={{ fontSize: 20, paddingLeft: 20 }}>
                            CADASTRO DE EMPRESA
                        </Typography>
                        <FormCadastroEmpresa />
                        <FormRepresentante />

                        <DialogActions>
                            <Button disabled={!dadosEmpresa} variant="contained" style={{ background: '#85287e' }} onClick={validate}>
                                Cadastrar
                            </Button>
                        </DialogActions>
                    </>
                }
            </Card>
        </Container>
    )
}