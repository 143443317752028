import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../../../utils/headerSession'
import { Link } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 50,
        textAlign: 'center'
    },
}))

export default function InfoLicenciamentoFabrica() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800,minHeight:'80vh' }}>
            <HeaderSession title='REQUISITOS PARA AUTORIZAÇÃO DO EXERCÍCIO DA ACTIVIDADE FABRIL' />


            <p className={classes.root} >
                Na submissão do dossier, apenas será remetida a documentação referida no ponto I e os pontos II a V serão avaliados no acto da vistoria das instalações.
            </p>
            <p className={classes.root} >
                I.  DOCUMENTAÇÃO NECESSÁRIA PARA A IMPLANTAÇÃO DA UNIDADE FABRIL
            </p>
            <p className={classes.root} >
                II. PROJECTO DE IMPLANTAÇÃO DA UNIDADE FABRIL
            </p>
            <p className={classes.root} >
                III. UNIDADE FABRIL
            </p>
            <p className={classes.root} >
                IV. PROCEDIMENTOS ESCRITOS
            </p>
            <p className={classes.root} >
                V. PROJECTO RELATIVO AO QUADRO DO PESSOAL
            </p>
            <p className={classes.root} >
                Para ver o documento completo <Link to={'/_requisitosFABRICAS(F10)'}>clique aqui… </Link>

            </p>

            <br />
        </Container>
    )
}
