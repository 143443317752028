import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, children, redirectPath, departamentoAutorizado }) => {
    const user = useSelector(state => state.account.user);
    const url = useLocation();
    // const departamento = user?.departamento ?? localStorage.getItem('dep')
    const departamento = user?.departamento ?? JSON.parse(localStorage.getItem("dep"));
    // const departamento =  JSON.parse(localStorage.getItem("dep"))
    // const accessToken = localStorage.getItem("accessToken")
    // console.log(departamento)
    //  console.log(user)
    // const { id } = useParams()

    
    if (!departamentoAutorizado.includes(departamento)) {
        // window.location.reload()
        return <Navigate to={`/admin/${departamento}`} replace />;
        // return <Navigate to={`/admin/dlif`} replace />;
    }


    // useEffect(() => {
    //     if (user.departamento === 'DLIF' || user?.funcao === 'Director Geral' || user?.funcao === 'CNP') {
    //         console.log('entrou')
    //     } else {navegate(-1) }
    // }, [user, url])


    // if (isAuthenticated && user?.funcao === 'Inspector' && url.pathname !== '/admin/minhas-inspeccoes/') {

    //     if (url.pathname === `/admin/modo-inspeccao/${id}`) {
    //         return children;
    //     }

    //     window.location.reload()
    //     return (<Navigate to={'/admin/minhas-inspeccoes/'} replace />)
    // }


    if (isAuthenticated && user?.funcao === 'Gestor de Conteúdo' && url.pathname !== '/admin/gestor-noticias/') {

        window.location.reload()
        return (<Navigate to={'/admin/gestor-noticias/'} replace />)
    }


    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

export default ProtectedRoute