import { useState } from "react";
import { InputBase, IconButton } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LoadingShort from "../../load/loadingShort";
// import TextField from '@material-ui/core/TextField';
// import {MenuItem } from '@mui/material';
// import MessageError from "../messages/messageError";
import api from "../../services/api";

const SearshEmpresa = ({ setEmpresas }) => {
    // const [messageError, setMessageError] = useState('');
    const [load, setLoad] = useState(false);
    const [search, setSearch] = useState(false);


    const searchEmpresa = async (e) => {
        e.preventDefault()
        // setPesquisaError(null)
        setLoad(true)
        setEmpresas([])
        // setPesquisaError('')

        try {
            const response = await api.post(`/empresa/search`, { search });
            setLoad(false)
            setEmpresas(response.data.empresas)

        } catch (error) {
            // setPesquisaError(error.response.data.message);
            setLoad(false)
        }
    }

    return (
        <form onSubmit={searchEmpresa} style={{ border: '1px solid #85287e', borderRadius: 5, display: 'flex', marginBottom: 25 }}>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                autoFocus
                placeholder="Pesquisar pelo nome ou NIF"
                inputProps={{ 'aria-label': 'Filtrar lista' }}
                onChange={(e) => { setSearch(e.target.value) }}
            />

            <IconButton style={{ marginLeft: -40 }} align='center' type="submit" aria-label="search">
                {load ?
                    <LoadingShort />
                    :
                    <SearchOutlinedIcon />
                }
            </IconButton>
        </form>
    );
}

export default SearshEmpresa;
