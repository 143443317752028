import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, Avatar } from '@mui/material';
import StatusEstabelecimento from '../utils/statusEstabelecimentoBusca';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../load/loading';
import api from '../services/api';
import { makeStyles } from '@material-ui/core/styles';
import RegisterAccess from '../utils/registerAccess';

const useStyles = makeStyles((theme) => ({
    fundo: {
        minHeight: '70vh',
        backgroundImage: 'url(/img/logo/logoArmed.svg)',
        backgroundSize: 'cover',
        // backgroundPosition: 'end',
        opacity: 0.1,
        // position: 'absolute',
        width: '100%',
        padding: 40
    },
    altura: { minHeight: '70vh' }
}))

function SimpleDialog(props) {
    const { open } = props;
    // const handleClose = () => { onClose('') };
    const navigate = useNavigate()

    return (
        <Dialog fullWidth open={open} scroll='body' >
            {props.estabelecimento &&
                <DialogTitle mb={2}>
                    <Button color='error' title="fechar" style={{ position: 'absolute', right: 5, fontWeight: 'bold' }} onClick={() => navigate('/')}> <CloseIcon /></Button>
                </DialogTitle>
            }

            {props.estabelecimento ?
                <>
                    <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', padding: 10 }}>
                        <Avatar style={{ borderRadius: 5, width: '100%', height: '100%' }} src={props.estabelecimento.logo} alt={props.estabelecimento.nome} />
                    </div>

                    <Typography style={{ lineHeight: 1, margin: 5, fontSize: 25, fontWeight: 'bold' }} align="center" variant="h1">
                        {props.estabelecimento.nome}
                    </Typography>

                    <Typography style={{ lineHeight: 1.5, margin: 10 }} align="center" variant="body1">
                        Nº {props.estabelecimento?.numeroProcesso !== undefined
                            ? props.estabelecimento?.numeroProcesso
                            : 'de entrada: ' + props.estabelecimento?.numeroEntrada
                        }
                    </Typography>


                    <Typography style={{ lineHeight: 1.5, margin: 10 }} align="center" variant="body1">
                        Director Técnico:
                        <br />
                        {props.estabelecimento?.directorTecnico?.dadosPessoais?.nome}
                    </Typography>

                    <Typography style={{ lineHeight: 1.5, margin: 10 }} align="center" variant="body1">
                        Localização:
                        <br />
                        {props.estabelecimento?.bairro}
                    </Typography>

                    <StatusEstabelecimento status={props.estabelecimento.status} />
                </>
                :
                <div style={{ padding: 20 }}>
                    <Typography style={{ lineHeight: 1 }} align="center" variant="subtitle1">
                        Estabelecimento não licenciado pela ARMED
                    </Typography>

                    <Button style={{ marginTop: 20 }} size='small' fullWidth color='error' onClick={() => navigate('/')}>Fechar</Button>
                </div>
            }
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function ConsultaEstabelecimento() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [estabelecimento, setestabelecimento] = React.useState('');
    const handleClose = () => { setOpen(false) };
    const { idEstabelecimento } = useParams()

    React.useEffect(() => {
        api.get(`/estabelecimento/${idEstabelecimento}`)
            .then(response => {
                // console.log(response)
                setestabelecimento(response.data.estabelecimento);
                setOpen(true)
            }).catch(err => {
                // console.log(err)
                setOpen(true)
            })
    }, [idEstabelecimento])

    return (
        <div className={open ? classes.fundo : classes.altura} >
         <RegisterAccess page={'consultor de estabelecimentos'} />
            {open ?
                <SimpleDialog
                    open={open}
                    onClose={handleClose}
                    estabelecimento={estabelecimento}
                />
                :
                <Loading text={'A verificar estabelecimento.'} />
            }
        </div>
    );
}
