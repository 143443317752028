import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingBackdrop from '../../../load/loadingBackdrop';
import { useState } from 'react';
import api from '../../../services/api';

const DeleteCategoria = (props) => {
    const [open, setOpen] = useState(false);

    const HandleDeleteCategoria = () => {
        props.setMessageSuccess('')
        props.setMessageError('')
        setOpen(true)


        api.delete('/categoria-tecnico/delete', { data: { 'id': props.categoriaId, userId: props.userId } })
            .then(response => {
                // console.log(response) 
                setOpen(false)
                props.setMessageSuccess('Eliminado com sucesso.')
                props.setForceUpdate(response)

            }).catch(err => {
                // console.log(err)
                setOpen(false)
                props.setMessageError('Erro ao eliminar')
            })
    }

    return (
        <>
            <LoadingBackdrop open={open} text={'Deletando. Aguarde!'} />
            <Button title='Apagar' onClick={HandleDeleteCategoria} size="small" color="error"><DeleteIcon /></Button >
        </>
    );
}

export default DeleteCategoria;
