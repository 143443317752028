
import { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { Card, Container, Grid, Typography } from "@material-ui/core"
import api from "../../../../services/api";
import { Link } from "react-router-dom";
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import LoadingBackdrop from "../../../../load/loadingBackdrop";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Alert from '@mui/material/Alert';
import DateHora from "../../../../utils/dateHora";
import RegisterAccess from "../../../../utils/registerAccess";
import HeaderSession from "../../../../utils/headerSession";
import ButtonLeft from "../../../../utils/buttonLeft";



export default function FormCreateReclama() {
    const [reclamacao, setReclamacao] = useState({ descricao: '' })
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')
    const [response, setResponse] = useState(false);
    const [open, setOpen] = useState(false)

    function HandleChange(e) {
        e.target.files ?
            setReclamacao({ ...reclamacao, [e.target.name]: e.target.files[0] })
            :
            setReclamacao({ ...reclamacao, [e.target.name]: e.target.value })

    }

    async function createReclamacao() {
        const formData = new FormData();
        formData.append('anexo1', reclamacao?.anexo1);
        formData.append('anexo2', reclamacao?.anexo2);
        formData.append('nome', reclamacao?.nome ? reclamacao.nome : '');
        formData.append('email', reclamacao?.email ? reclamacao.email : '');
        formData.append('tel', reclamacao?.tel ? reclamacao.tel : '');
        formData.append('assunto', reclamacao?.assunto ? reclamacao?.assunto : '');
        formData.append('descricao', reclamacao?.descricao ? reclamacao?.descricao : '');

        setOpen(true)
        seterrorMessage('')
        setMessage('')

        await api.post('/reclamacao/create', formData)
            .then(res => {
                setOpen(false)
                setMessage(res.data.msg)
                setResponse(res.data.reclamacao)
                // console.log(res)
            }).catch(error => {
                seterrorMessage(error.response.data.message)
                setOpen(false)
                // console.log(error)
            })
    }

    return (
        <div>
            <RegisterAccess page={'fazer reclamação'} />
            <LoadingBackdrop open={open} text={'Enviando reclamação.!'} />

            <Container style={{ minHeight: '70vh', maxWidth: 800 }}>
                <HeaderSession title='FAÇA AQUÍ A SUA RECLAMAÇÃO' />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}

                <Typography align="center">
                    <img src="/icones/Reclamacoes.svg" width={100} />
                </Typography>

                {!message &&
                    <Card style={{ padding: 20 }}>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="text"
                                label="Nome"
                                fullWidth
                                size="small"
                                name="nome"
                                variant="outlined"
                                onChange={HandleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="email"
                                label="Email"
                                fullWidth
                                size="small"
                                name="email"
                                variant="outlined"
                                onChange={HandleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="number"
                                label="Contacto"
                                fullWidth
                                size="small"
                                name="tel"
                                variant="outlined"
                                onChange={HandleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="text"
                                label="Assunto"
                                fullWidth
                                size="small"
                                name="assunto"
                                variant="outlined"
                                onChange={HandleChange}
                            />
                        </Grid>

                        <Grid xs={12} md item >
                            <TextField
                                margin="dense"
                                type="text"
                                label="Descrever"
                                fullWidth
                                size="small"
                                multiline
                                minRows={3}
                                name="descricao"
                                variant="outlined"
                                onChange={HandleChange}
                            />
                        </Grid>
                        <small>Até 1000 caracteres: {reclamacao?.descricao.length}</small>


                        {/* ANEXAR DOCUMENTOS COMPLEMENTARES */}

                        <div align="center" style={{ border: '1px dashed', borderRadius: 5, marginTop: 10 }}>
                            <label htmlFor="anexo1" style={{ margin: 5, cursor: 'pointer' }} >

                                {reclamacao?.anexo1 ?
                                    <>
                                        <Typography variant="subtitle2" title="Alterar">
                                            <Alert severity="success">
                                                {reclamacao?.anexo1?.name}
                                            </Alert>
                                        </Typography>
                                        <img src={URL.createObjectURL(reclamacao?.anexo1)} alt="" width={'25%'} />
                                    </>
                                    :
                                    <Typography variant="subtitle2" >
                                        {/* <CloudUploadIcon fontSize="large" /> <br /> */}
                                        Adicionar anexo (opcional)
                                    </Typography>
                                }
                            </label>
                            <input accept="application/pdf, image/png, image/jpg, image/jpeg" type="file" name="anexo1" id="anexo1" style={{ display: 'none' }} onChange={HandleChange} />
                        </div>


                        <ButtonLeft title={'Enviar'} funcao={createReclamacao} disabled={reclamacao?.descricao.length < 20 || reclamacao?.descricao.length > 999} />
                    </Card>
                }

                {message &&
                    <Card style={{ padding: 10, marginTop: 20 }}>
                        <Typography style={{ margin: 20 }} align="center">
                            <CheckCircleOutlineIcon fontSize="large" color="success" />
                            <br />
                            {message}
                        </Typography>

                        <Typography style={{ margin: 20, marginBottom: 30 }} >
                            A sua reclamação encontra-se em <strong>{response?.status}</strong>
                            <br />
                            Número de reclamação: <strong>{response?.numero}</strong>
                            <br />
                            Assunto: <strong>{response?.assunto}</strong>
                            <br />
                            Data: <DateHora date={response?.createdAt} />
                        </Typography>

                        <Link to={'/'} style={{ textDecoration: 'none' }}  >
                            <Typography align="center" >
                                Voltar para Página inicial
                            </Typography>
                        </Link>
                    </Card>
                }
            </Container>
            <br />
        </div>
    );
}
