import { Container } from '@mui/material'
import HeaderSession from '../../../utils/headerSession'
import ListaVazia from '../../../utils/listaVazia'

export default function PedidosDesalfandegamento() {
  return (
    <Container >

      <HeaderSession title='LISTA DE PEDIDOS DE DESALFANDEGAMENTO' />

      <ListaVazia text={'SERVIÇO INDISPONÍVEL'} /> 
    </Container>
  )
}
