import { Button, DialogActions, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import MessageSuccess from '../../messages/messageSuccess';
import MessageError from '../../messages/messageError';
import LoadingShort from '../../load/loadingShort';
import FichaRepresentante from './fichaRepresentante';
import { useNavigate } from 'react-router-dom';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const RepresentanteCreateDirectorTecnico = (props) => {
    const userId = useSelector(state => state.account.user?._id);
    const [load, setLoad] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [categoria, setCategoria] = useState([]);
    const [directorTecnico, setDirectorTecnico] = useState('')
    const navigate = useNavigate()


    const handleClose = () => {
        props.setOpen(false);
    };

    useEffect(() => {
        api.get('/categoria-tecnico')
            .then(res => {
                setCategoria(res.data.categoriaTecnicos);
            })
            .catch(err => console.log(err));
    }, [])

    function HandleChange(e) {
        e.target.files ?
            setDirectorTecnico((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.files[0] }))
            :
            setDirectorTecnico((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.value }))
    }

    const handleCreate = () => {
        setMessageSuccess('')
        setMessageError('')
        setLoad(true)

        api.post('/representante/become-tecnico', {
            ...directorTecnico,
            'representanteId': props.representante?._id,
            userId
        })
            .then(response => {
                // console.log(response) 
                setLoad(false)
                setMessageSuccess('sucesso.')
                navigate(`/admin/get-director-tecnico/${response.data.directorTecnico._id}`)

            }).catch(err => {
                // console.log(err)
                setLoad(false)
                setMessageError('Erro ao salvar')
            })
    }

    return (
        <>
            <BootstrapDialog
                scroll='body'
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <div style={{ padding: 20 }}>

                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {messageError && <MessageError message={messageError} />}
                    <Typography>
                        <strong>CRIAR UM DIRECTOR TÉCNICO COM SEGUITES DADOS:</strong>
                    </Typography>

                    <FichaRepresentante representante={props?.representante} />

                    <Typography >
                        <strong>INSIRA OS SEGUINTES DADOS: </strong>
                    </Typography>

                    <Grid container >

                        <Grid xs={12} item >
                            <TextField
                                margin='dense'
                                type="number"
                                required
                                label="Número de Carteira"
                                fullWidth
                                size="small"
                                name="numeroCarteira"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={directorTecnico?.numeroCarteira}
                            />
                        </Grid>

                        <Grid xs={12} item >
                            <TextField
                                margin='dense'
                                required
                                type="text"
                                label="Categoria"
                                select
                                fullWidth
                                size="small"
                                name="categoriaId"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={directorTecnico?.categoriaId ?? ''}
                            >
                                <MenuItem disabled value=''>Selecione Categoria</MenuItem>
                                {categoria?.map((categoria) => (
                                    <MenuItem key={categoria._id} value={categoria._id}>
                                        {categoria.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid xs={12} item >
                            <TextField
                                required
                                margin='dense'
                                type="text"
                                label="Ordem"
                                fullWidth
                                size="small"
                                name="ordem"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={directorTecnico?.ordem}
                            />
                        </Grid>
                    </Grid>

                    <DialogActions>
                        {/* <Button title='Cancelar' onClick={() => props.setOpen(false)} size="small">Cancelar</Button> */}
                        <Button onClick={handleCreate} size="small">
                            {load ? <LoadingShort /> : 'Salvar'}
                        </Button>
                    </DialogActions>
                </div>
            </BootstrapDialog>
        </>
    );
}

export default RepresentanteCreateDirectorTecnico;
