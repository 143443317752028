import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../../../utils/headerSession'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 50,
        textAlign: 'center'
    },
}))

export default function InfoDesalfandegamento() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
            <HeaderSession title='REQUISITOS DE DESALFANDEGAMENTO' />

            <p className={classes.root} >
                São requisitos para o desalfandegamento dos medicamentos e outros produtos farmacêuticos, os seguintes:
            </p>
            <p className={classes.root} >
                1. Para os medicamentos na generalidade e fitoterapêuticos (incluindo medicamentos naturais industrializados):
            </p>
            <p className={classes.root} >
                a) Certificado de controlo de qualidade por lotes;
            </p>
            <p className={classes.root} >
                b) Factura em Língua Portuguesa;
            </p>
            <p className={classes.root} >
                c) Certificado de Origem dos produtos;
            </p>
            <p className={classes.root} >
                d) Folhetos informativos em língua portuguesa;
            </p>
            <p className={classes.root} >
                e) Em caso de narcóticos, psicotrópicos, substâncias percurssoras e demais produtos sujeitos ao controlo pelo Ministério da Saúde,
                deve anexar também a cópia do Certificado de Importação emitido por esta Agência.
            </p>

            <p className={classes.root} >
                2. Para os equipamentos:
            </p>
            <p className={classes.root} >
                a) Factura em Língua Portuguesa;
            </p>
            <p className={classes.root} >
                b) Certificado de origem dos Produtos;
            </p>
            <p className={classes.root} >
                c) No caso de testes rápidos para diagnóstico de doenças infecciosas, deve anexar também a Cópia do Certificado de Importação emitido por esta Agência.
            </p>

            <p className={classes.root} >
                3. No requerimento de solicitação de desalfandegamento dos pedidos referidos nos nºs 1, 2 e 3 deve constar os seguintes dados:
            </p>

            <p className={classes.root} >
                a) Número da Autorização de Exercicio;
            </p>

            <p className={classes.root} >
                b) Número de Identificação Fiscal;
            </p>
            <p className={classes.root} >
                c) Número da Factura Comercial;
            </p>
            <p className={classes.root} >
                d) Proveniência da mercadoria (especificação do país);
            </p>
            <p className={classes.root} >
                e) País de Origem (de fabrico);
            </p>
            <p className={classes.root} >
                4. Para os medicamentos recepcionados nos Serviços dos Correios de Angola,
                DHL e /ou adquiridos via internet sendo de uso pessoal, deve anexar o justificativo
                (receita médica) assinado pelo médico assistente, constando o respectivo n.º da Ordem dos
                Médicos, com assinatura legível e factura de compra do país de procedência.
            </p>
            <p className={classes.root} >
                <a href="/#">Clica para ver o modelo de requerimento. </a>
            </p>

            <br />
        </Container>
    )
}
