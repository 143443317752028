
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        transition: '.4s',
        cursor: 'pointer',
        // padding:0,
        // border: '1px solid #67348d',
        '&:hover': {
            transform: 'scale(1.5)',
            // opacity: 0.7,
            // height: 100,
            // boxShadow: '5px 5px #888888',
            // width: 100,
        },
    },
})

type imageLinkProps = {
    src: string,
    link: string
}

export default function ImageLink({ src, link }: imageLinkProps) {
    const classes = useStyles()
    return (
        // <div  style={{ borderRight: '1px solid #67348d'}} >
        <a href={link} target={'_blank'} rel="noreferrer"  >
            <img src={src} alt="" height={110} className={classes.root} title='Visitar' />
        </a>
        // </div>
    )
}