
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign:'center',
        width: '25%',
    
        border: '1px solid #a04ae2',
        borderRadius: 10,
        transition: '.5s',
        '&:hover': { background: '#b1b7ea', },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}))

type cardServicesProps = {
    nomeService: string;
    link: string;
}
export default function CardOpcoes({ nomeService, link }: cardServicesProps) {
    const classes = useStyles()

    return (
        <CardActionArea className={classes.root} >
            <Link style={{ textDecoration: 'none' }} to={link}>
                <CardContent >
                    <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1, fontSize: 16 }}>
                        {nomeService}
                    </Typography>
                </CardContent>
            </Link>
        </CardActionArea>
    )
}