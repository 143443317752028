import { useEffect, useState } from "react";
import { Card, Typography, makeStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loading from "../../load/loading";
import Mes from "../../utils/mes";
import SearshAgenda from '../../utils/searchAgenda';
import RegisterAccess from "../../utils/registerAccess";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#d2d6ff', cursor: 'pointer' } }
}))

export default function AgendaMarcada() {
    const classes = useStyles()
    const navigate = useNavigate()
    const [agendas, setagendas] = useState('')
    const [filter, setFilter] = useState('')
    const [page,] = useState(1)

    useEffect(() => {
        api.get(`/agendas?status=Indisponível&page=${page}`)
            .then(response => {

                // console.log(response)
                setagendas(response.data.agendas);
                setFilter(response.data.agendas);
            }).catch(err => '')
    }, [page])


    const filtraAgenda = async (tipo) => {
        setagendas(filter.filter((agenda) => (
            agenda?.estabelecimento?.numeroEntrada?.toLowerCase().includes(tipo.toLowerCase())
            || agenda?.tipo?.toLowerCase().includes(tipo.toLowerCase())
            // || agenda?.dia?.toLowerCase().includes(tipo.toLowerCase())
            // || agenda?.hora?.toLowerCase().includes(tipo.toLowerCase())
        )))
    }

    return (
        <Card style={{ padding: 20, minHeight: '20vh', }}>
           <RegisterAccess page={'agendas marcadas'}/>
            <Typography style={{ fontSize: 20, marginBottom: 20 }}>
                AGENDAS MARCADAS
            </Typography>

            {/* FILTRO DE TIPOS DE AGENDAS */}
            <SearshAgenda functionFilter={filtraAgenda} placeholder={"Filtra pelo número de entrada,tipo.. "}/>

            {agendas ?
                <Card variant="outlined">
                    <TableContainer >
                        <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                            <TableHead >
                                <TableRow>
                                    <TableCell ><Typography noWrap> <strong>Estabelecimento</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Nº de entrada</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Tipo de agenda</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Horário</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Dia</strong></Typography></TableCell>
                                    {/* <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell> */}
                                    <TableCell ><Typography noWrap> <strong>Mês</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Ano</strong></Typography></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {agendas.map((agenda) => (
                                    <TableRow key={agenda._id} title={agenda?.nome} className={classes.hover} onClick={() => navigate(`/admin/analise/solicitacao-de-licenciamento/${agenda?.estabelecimento?._id}`)}>
                                        <TableCell >{agenda?.estabelecimento?.nome}</TableCell>
                                        <TableCell >{agenda?.estabelecimento?.numeroEntrada}</TableCell>
                                        <TableCell >{agenda?.tipo} </TableCell>
                                        <TableCell >{agenda?.hora < 10 && 0}{agenda.hora} :{agenda?.minuto < 10 && 0}{agenda.minuto}</TableCell>
                                        <TableCell >{agenda?.dia}</TableCell>
                                        {/* <TableCell >{agenda?.status}</TableCell> */}
                                        <TableCell ><Mes mes={agenda?.mes} /></TableCell>
                                        <TableCell >{agenda?.ano}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                :
                <Loading />
                // <ListaVazia text={'NENHUMA AGENDA CRIADA'} />
            }
        </Card>
    )
}