import { Container } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HeaderSession from '../../../../utils/headerSession'
import { Link } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        // textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 50,
        // textAlign: 'center',
        color: '#67348d'

    },
}))

export default function InfoLicenciamentoFarmaciaErvanaria() {
    const classes = useStyles()

    return (
        <Container style={{ maxWidth: 800 }}>
            <div>
                <HeaderSession title='REQUISITOS ADMINISTRATIVOS PARA ABERTURA DE FARMÁCIAS/ERVANÁRIAS' />

                <p className={classes.root} >
                    <ol style={{ lineHeight: 1.5 }}>
                        <li>
                            Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde incluindo o NIF ( <Link to={'/_REQUERIMENTO-PARA-LICENCIAMENTO'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Cópia colorida do documento de identificação do requerente.
                        </li>
                        <li>
                            Escritura de Constituição de Sociedade (para empresas).
                        </li>
                        <li>
                            Planta com quotas das instalações, acompanhado com croquis de Localização.
                        </li>
                        <li>
                            Termo de Responsabilidade do Director Técnico autenticado e a Declaração original  da OFA ( <Link to={'/_Termo-de-Responsabilidade'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                    </ol>
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos Técnicos para Abertura de Farmácias/Ervanarias
                </h1>

                <p className={classes.root} >
                    <strong>   1. As infra-estruturas devem ser em alvenaria</strong> (blocos ou tijolos).
                </p>
                <p className={classes.root} >
                    <strong>2. A fachada principal deve obedecer o seguinte:  </strong>
                </p>
                <p className={classes.root} >
                    a) Possuir uma cruz grega, colocados em perpendicular do edifício;
                </p>
                <p className={classes.root} >
                    b) Letreiro com a designação da farmácia, colocado em paralelo ao edifício e sem imagens de medicamentos ou outros produtos de saúde;
                </p>
                <p className={classes.root} >
                    c) As paredes e/ou os vidros não devem conter imagens ou gravuras ou materiais publicitários;
                </p>
                <p className={classes.root} >
                    d) Possuir uma placa com o nome do director técnico;
                </p>
                <p className={classes.root} >
                    e) Possuir condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    f) Rampa de acesso com elevação ou ângulo máximo de 10º para pessoal com deficiência de locomoção;
                </p>

                <br />
                <p className={classes.root} >
                    <strong>3. A sala de atendimento ao público deve obedecer o seguinte:</strong>
                </p>
                <p className={classes.root} >
                    a) Área com superfície igual ou superior a 30m2;
                </p>
                <p className={classes.root} >
                    b) Pintura de cor clara e sem imagens, desenhos, gravuras ou materiais publicitários, incluindo nas vitrinas;
                </p>
                <p className={classes.root} >
                    c) Pavimento ladrilhado;
                </p>
                <p className={classes.root} >
                    d) Tecto falso ou placa de betão;
                </p>
                <p className={classes.root} >
                    e) Balcão para o atendimento ao público;
                </p>
                <p className={classes.root} >
                    f) Climatização com AC;
                </p>
                <p className={classes.root} >
                    g) Iluminação adequada;
                </p>
                <p className={classes.root} >
                    h) Mobiliário / cadeiras (material não plástico);
                </p>
                <p className={classes.root} >
                    i) Possuir condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    j) Medidas de combate às pragas;
                </p>
                <p className={classes.root} >
                    k) Medidas de prevenção contra incêndios (extintores de incêndio).
                </p>

                <br />
                <p className={classes.root} >
                    <strong>4. O armazém deve obedecer o seguinte:</strong>
                </p>
                <p className={classes.root} >
                    a) Área com superfície com mínimo de 20m2;
                </p>
                <p className={classes.root} >
                    b) Pintura de cor clara;
                </p>
                <p className={classes.root} >
                    c) Pavimento ladrilhado;
                </p>
                <p className={classes.root} >
                    d) Tecto falso ou placa de betão;
                </p>
                <p className={classes.root} >
                    e) Armários / estantes / prateleiras adequadas para o stock;
                </p>
                <p className={classes.root} >
                    f) Climatização com AC;
                </p>
                <p className={classes.root} >
                    g) Iluminação adequada;
                </p>
                <p className={classes.root} >
                    h) Possuir condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    i) Medidas de combate às pragas;
                </p>
                <p className={classes.root} >
                    j) Medidas de prevenção contra incêndios (extintores de incêndio);
                </p>
                <p className={classes.root} >
                    k) Termohigrómetros;
                </p>
                <p className={classes.root} >
                    l) Cadeira (s) – em material não plástico.
                </p>

                <br />
                <p className={classes.root} >
                    <strong>5. Instalações sanitárias (WC):</strong>
                </p>
                <p className={classes.root} >
                    a) Área com superfície igual ou superior a 3,5m2;
                </p>
                <p className={classes.root} >
                    b) Condições de higiene e de segurança;
                </p>
                <p className={classes.root} >
                    c) Sanita e lavatório;
                </p>
                <p className={classes.root} >
                    d) Pavimento ladrilhado.
                </p>

                <br />
                <p className={classes.root} >
                    <strong>6. Gabinete do Director Técnico:</strong>
                </p>
                <p className={classes.root} >
                    a) Área com superfície igual ou superior a 8m2;
                </p>
                <p className={classes.root} >
                    b) Climatização com AC;
                </p>
                <p className={classes.root} >
                    c) Iluminação adequada;
                </p>
                <p className={classes.root} >
                    d) Mobiliário do escritório (em material não plástico);
                </p>
                <p className={classes.root} >
                    e) Armário com chave para produtos controlados (quando aplicável);
                </p>
                <p className={classes.root} >
                    f) Livro de registo de produtos controlados (quando aplicável);
                </p>
                <p className={classes.root} >
                    g) Literatura técnica;
                </p>
                <p className={classes.root} >
                    h) Pavimento ladrilhado;
                </p>
                <p className={classes.root} >
                    i) Tecto falso ou placa de betão.
                    <br />
                    <strong>Obs.:</strong> <i>  O não cumprimento dos requisitos técnicos acima, condicionará o licenciamento do estabelecimento. Para mais esclarecimentos, dirigir-se ao balcão de atendimento. </i>
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos para Renovação da Autorização de Exercício da ActividadeFarmacêutica de Farmácias/Ervanárias
                </h1>

                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde ( <Link to={'/_REQUERIMENTO-PARA-LICENCIAMENTO'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Cópia colorida do documento de identificação do requerente.
                        </li>
                        <li>
                            Declaração original e actualizada  da OFA.
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                    </ol>
                </p>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos para Mudança de Denominação de Farmácia/Ervanária (Sendo o mesmo Proprietário)
                </h1>

                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde ( <Link to={'/_REQUERIMENTO-PARA-LICENCIAMENTO'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Termo de responsabilidade do Director técnico autenticado ( <Link to={'/_Termo-de-Responsabilidade'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Comprovativo de pagamento de emolumentos.
                        </li>
                    </ol>
                </p>
                <strong style={{ fontSize: 20 }} >
                    N.B.: O estabelecimento não requer nova vistoria.
                </strong>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos para Mudança de Denominação de Farmácia/Ervanária (Novo Proprietário)
                </h1>

                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento a solicitar a mudança de Nome  dirigido ao Director Geral da Agência Reguladora de Medicamentos e Tecnologias de Saúde incluindo o NIF <a href="/#">(Ver modelo)</a>.
                        </li>
                        <li>
                            Trespasse reconhecido pelo cartório notarial ou conservatória do registo comercial (caso for sociedade).
                        </li>
                        <li>
                            Cópia  do documento de Identidade do novo proprietário.
                        </li>
                        <li>
                            Escritura da Empresa da nova entidade. (em caso de Sociedade).
                        </li>
                        <li>
                            Termo de responsabilidade do Director técnico  autenticado ( <Link to={'/_Termo-de-Responsabilidade'}>Ver modelo</Link> ).
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                    </ol>
                </p>
                <strong style={{ fontSize: 20 }} >
                    N.B.: O estabelecimento requer nova vistoria.
                </strong>
            </div>

            <div>
                <h1 className={classes.title}>
                    Requisitos para Mudança de Endereço Farmácia/Ervanária
                </h1>

                <p className={classes.root} >
                    <ol>
                        <li>
                            Requerimento a solicitar a mudança de Endereço  dirigido  ao Director Geral  da Agência  Reguladora de Medicamentos e Tecnologias  de Saúde incluindo o NIF<a href="/#">(Ver modelo)</a>.
                        </li>
                        <li>
                            Cópia colorida do documento de identificação do requerente.
                        </li>
                        <li>
                            Planta com quotas do novo estabelecimento, com croquis de localização.
                        </li>
                        <li>
                            Comprovativo de pagamento de taxas emolumentares.
                        </li>
                    </ol>
                </p>
                <strong style={{ fontSize: 20 }} >
                    Obs.: Este procedimento é semelhante a instalação de um novo estabelecimento; para o efeito, implica uma vistoria.
                </strong>
            </div>
            <br />
        </Container>
    )
}
